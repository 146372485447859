var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-stack",
    { attrs: { gap: "spacing-1" } },
    [
      _vm.hasSeparateLabel
        ? [
            _c(
              "m-text",
              { attrs: { variant: "body-md", color: "color-text-tertiary" } },
              [_vm._v(_vm._s(_vm.label))]
            ),
            _c(
              "m-group",
              {
                staticClass: "flex-nowrap",
                attrs: { align: "center", gap: "spacing-4" },
              },
              [
                !_vm.$slots.value
                  ? [
                      _vm.computedValue
                        ? _c("m-text", {
                            attrs: { variant: "body-md" },
                            domProps: { innerHTML: _vm._s(_vm.computedValue) },
                          })
                        : _c("no-content"),
                    ]
                  : [_vm._t("value")],
                _vm.copyContent
                  ? _c("copy-content-button", {
                      attrs: {
                        "test-id": `copy-${_vm.toKebabCase(
                          _vm.label.toLowerCase()
                        )}-btn`,
                        message: _vm.copyMessage,
                        content: _vm.copyContent ?? _vm.value,
                      },
                    })
                  : _vm._e(),
              ],
              2
            ),
          ]
        : [
            _c(
              "m-group",
              {
                staticClass: "flex-nowrap",
                attrs: { align: "flex-start", gap: "spacing-4" },
              },
              [
                _c(
                  "m-stack",
                  { attrs: { gap: "spacing-1" } },
                  [
                    _c(
                      "m-text",
                      {
                        attrs: {
                          variant: "body-md",
                          color: "color-text-tertiary",
                        },
                      },
                      [_vm._v(_vm._s(_vm.label))]
                    ),
                    !_vm.$slots.value
                      ? [
                          _vm.computedValue
                            ? _c("m-text", {
                                attrs: { variant: "body-md" },
                                domProps: {
                                  innerHTML: _vm._s(_vm.computedValue),
                                },
                              })
                            : _c("no-content"),
                        ]
                      : [_vm._t("value")],
                  ],
                  2
                ),
                _vm.copyContent
                  ? _c("copy-content-button", {
                      attrs: {
                        "test-id": `copy-${_vm.toKebabCase(
                          _vm.label.toLowerCase()
                        )}-btn`,
                        message: _vm.copyMessage,
                        content: _vm.copyContent ?? _vm.value,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }