import { createFeatureFlagPlugin } from '@loadsmart/vue-feature-flags';

import config from '@/config/config';

export default createFeatureFlagPlugin({
  config: {
    url: config.unleash_url,
    appName: config.unleash_app_name,
    clientKey: 'dummy',
    environment: config.server_env === 'production' ? 'production' : 'development'
  },
  disableAutoStart: true
});
