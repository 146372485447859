<template>
  <div class="text-center">
    <appointment-status-timeline
      v-if="extendedAppointment"
      v-model="statuses"
      external-navigation
      steps-only
      :appointment="extendedAppointment"
      :full-width="false"
      :color="extendedAppointment.status.toLowerCase()">
      <template #additional-step-header-content="{ step }">
        <div v-if="shouldShowTimestamp(step.name)" class="timestamp-text">
          <p class="my-1">
            {{ extendedAppointment.getTimestamp(step.name) }}
          </p>
          <strong>{{ getTimeDiff(step.name) }}</strong>
        </div>
      </template>
    </appointment-status-timeline>
  </div>
</template>

<script>
import { ExtendedAppointment } from '@/modules/appointments/ExtendedAppointment';
import { propValidatorHelper } from '@satellite/plugins/util';
import {
  AppointmentStatus,
  getStatusChangeRules,
  sortBy,
  breakWordsAtCaps
} from '@satellite/../nova/core';
import AppointmentStatusTimeline from '@/components/elements/appointment/AppointmentStatusTimeline';

/**
 * @displayName Status Timeline Wrapper
 */
export default {
  name: 'StatusTimelineWrapper',
  components: { AppointmentStatusTimeline },
  props: {
    extendedAppointment: {
      type: ExtendedAppointment,
      required: true,
      validator(value) {
        return propValidatorHelper({
          value,
          isValid: value instanceof ExtendedAppointment,
          componentName: 'StatusTimelineWrapper',
          propName: 'extendedAppointment',
          message: ''
        });
      }
    }
  },
  computed: {
    activeStep() {
      return this.statuses.findLast(status => status.completed)?.name;
    },
    warehouse() {
      return this.extendedAppointment.warehouse;
    },
    firstStatus() {
      return this.extendedAppointment.statusTimeline[AppointmentStatus.Requested]
        ? AppointmentStatus.Requested
        : AppointmentStatus.Scheduled;
    },
    allChangeRules() {
      const optionalStatuses = [];
      if (this.firstStatus === AppointmentStatus.Requested) {
        optionalStatuses.push(this.firstStatus);
      }
      if (this.useInProgressStatus) {
        optionalStatuses.push(AppointmentStatus.InProgress);
      }
      return getStatusChangeRules(optionalStatuses);
    },
    shouldShowEtaValue() {
      return (
        Boolean(this.extendedAppointment.eta) &&
        this.extendedAppointment.status === this.novaCore.AppointmentStatus.Scheduled
      );
    },
    initialEtaCondition() {
      return (
        this.novaCore.getEtaCondition(
          this.extendedAppointment.start,
          this.extendedAppointment.eta,
          this.extendedAppointment.warehouse.timezone
        ) ?? this.novaCore.EtaCondition.OnTime
      );
    },
    statuses() {
      let statuses = [];
      Object.entries(this.allChangeRules).map(([name, status]) => {
        status.name = name;
        const isAppointmentHappyPath = this.getStatusChangeRules(
          this.extendedAppointment.status
        ).isHappyPath;
        const isStatusHappyPath = this.getStatusChangeRules(status.name).isHappyPath;
        if (!isAppointmentHappyPath) {
          status.show = Boolean(this.extendedAppointment.statusTimeline[status.name]);
        } else {
          status.show = Boolean(isStatusHappyPath);
        }

        statuses.push(status);
      });

      statuses = sortBy(statuses, 'stepNumber');
      statuses = statuses
        .filter(status => status.show)
        .map(status => ({
          name: status.name,
          label: breakWordsAtCaps(status.name),
          completed: this.extendedAppointment.statusTimeline[status.name]
        }));

      return statuses;
    },
    useInProgressStatus() {
      return (
        this.extendedAppointment.statusTimeline[this.inProgressStatus] ||
        this.warehouse?.settings?.statusSucceedingArrived ===
          this.novaCore.AppointmentStatus.InProgress
      );
    }
  },
  data() {
    return {
      timezone: this.extendedAppointment.warehouse.timezone,
      loading: false
    };
  },
  methods: {
    getStatusChangeRules(status) {
      return this.allChangeRules[status];
    },
    shouldShowTimestamp(status) {
      return (
        [
          AppointmentStatus.Arrived,
          AppointmentStatus.Completed,
          AppointmentStatus.InProgress
        ].includes(status) && this.extendedAppointment.statusTimeline[status]
      );
    },
    getTimeDiff(status) {
      return (
        {
          [AppointmentStatus.Arrived]: this.extendedAppointment.getArrivalTimeDiff(),
          [AppointmentStatus.InProgress]: this.extendedAppointment.getInProgressTimeDiff(),
          [AppointmentStatus.Completed]: this.extendedAppointment.getCompletedTimeDiff()
        }[status] || ''
      );
    }
  },
  watch: {
    initialEtaCondition: {
      handler(newVal) {
        this.etaCondition = newVal;
      },
      immediate: true
    }
  }
};
</script>

<style>
.stepper-header {
  box-shadow: none;
}

m-steps {
  display: grid;
  grid-auto-rows: 1fr;
}

.timestamp-text {
  font-size: 13px;
}

m-step {
  height: 100%;
  text-align: center;
  &:before {
    display: none;
  }
}
</style>
