import UserSettingsPage from '@/modules/auth/UserSettingsPage.vue';
import CompanySelectPage from '@/modules/auth/CompanySelectPage.vue';

export default [
  {
    path: '/settings',
    name: 'settings',
    component: UserSettingsPage,
    meta: {
      requiresAuth: true,
      title: 'User Settings',
      hideSidebar: false
    }
  },
  {
    path: '/settings/company',
    name: 'settings.company',
    component: CompanySelectPage,
    meta: {
      requiresAuth: true,
      title: 'Select Company',
      hideSidebar: false
    }
  }
];
