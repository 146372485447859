<template>
  <div>
    <image-message
      :image-path="notFound ? notFoundSvg : searchSvg"
      image-alt="Search image for no results">
      <slot v-if="$slots.default"></slot>
      <template v-else>
        <m-text variant="md-bold">{{ title }}</m-text>
        <m-text variant="body-sm">{{ subtitle }}</m-text>
      </template>
      <div :class="hasFooterSlot ? 'm-t-2 p-b-4' : ''">
        <slot name="footer" />
      </div>
    </image-message>
  </div>
</template>

<script>
import { ImageMessage } from '@/components';
import search from '@satellite/assets/search.svg';
import notfound from '@satellite/assets/appointment404.svg';

/**
 * Used when there are no records returned from API
 * @displayName No Results
 */
export default {
  name: 'NoResults',
  components: { ImageMessage },
  props: {
    title: {
      type: String,
      default: "We couldn't find results for your search"
    },
    subtitle: {
      type: String,
      default: 'Please change the terms of your search and try again'
    },
    notFound: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasFooterSlot() {
      return Boolean(this.$slots['footer']);
    }
  },
  data() {
    return {
      searchSvg: search,
      notFoundSvg: notfound
    };
  }
};
</script>
