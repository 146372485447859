var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-button",
    _vm._g(
      _vm._b(
        {
          staticClass: "button-base",
          class: [
            {
              is__block: _vm.isBlock,
              "width-100": _vm.isBlock,
              "no-bg": _vm.noBg,
            },
            `button-${_vm._uid}`,
          ],
          style: _vm.buttonWrapperStyles,
          attrs: { disabled: _vm.isLoading, "data-testid": _vm.testId },
        },
        "m-button",
        [_vm.$attrs, _vm.$props],
        false
      ),
      _vm.$listeners
    ),
    [
      !_vm.isLoading
        ? [
            _vm.leadingIcon
              ? _c(
                  "span",
                  { attrs: { slot: "leading" }, slot: "leading" },
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          color: _vm.$attrs.disabled
                            ? "color-text-disabled"
                            : _vm.computedColor,
                          size: _vm.iconSize ?? _vm.$attrs.size,
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.leadingIcon) + " ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._t("default"),
            _vm.trailingIcon
              ? _c(
                  "span",
                  { attrs: { slot: "trailing" }, slot: "trailing" },
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          color: _vm.$attrs.disabled
                            ? "color-text-disabled"
                            : _vm.computedColor,
                          size: _vm.iconSize ?? _vm.$attrs.size,
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.trailingIcon) + " ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _c("div", { staticClass: "p-x-6" }, [
            _c("div", { staticClass: "dot-flashing" }),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }