var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("img", {
      attrs: { src: _vm.computedLogoPath, if: "", alt: "Opendock Logo" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }