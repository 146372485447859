var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("i", {
    staticClass: "mdi",
    class: [`mdi-${_vm.getIcon()}`],
    style: `font-size:${_vm.sizeValue}${_vm.sizeUnit};color:${_vm.computedColor};`,
    on: {
      click: function ($event) {
        return _vm.$emit("click")
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }