var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-header",
    {
      class: [
        `page-header-${_vm._uid}`,
        { "should-collapse": _vm.shouldCollapse },
      ],
    },
    [
      _vm.$slots["back-button"]
        ? _c(
            "m-back-button",
            { attrs: { slot: "back-button" }, slot: "back-button" },
            [_vm.$slots["back-button"] ? _vm._t("back-button") : _vm._e()],
            2
          )
        : _vm._e(),
      _c(
        "m-header-content",
        [
          _vm.$slots.title || _vm.title
            ? _c(
                "m-header-title",
                [
                  !_vm.$slots["back-button"] && _vm.hasBackButton
                    ? _c(
                        "icon-button",
                        {
                          staticClass: "m-r-4 display-desktop",
                          attrs: {
                            "test-id": _vm.backButtonTestId,
                            size: "small",
                            "icon-size": "20px",
                          },
                          on: { click: _vm.handleBackClick },
                        },
                        [_vm._v(" chevron-left ")]
                      )
                    : _vm._e(),
                  !_vm.$slots["back-button"] && _vm.hasBackButton
                    ? _c(
                        "icon-button",
                        {
                          staticClass: "m-r-4 display-tablet",
                          attrs: {
                            "test-id": _vm.backButtonTestId,
                            "icon-size": "30px",
                          },
                          on: { click: _vm.handleBackClick },
                        },
                        [_vm._v(" chevron-left ")]
                      )
                    : _vm._e(),
                  _vm.$slots.title ? _vm._t("title") : _vm._e(),
                  _vm.title
                    ? [
                        _c("m-text", { attrs: { variant: "body-lg-bold" } }, [
                          _vm._v(_vm._s(_vm.title)),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm.$slots.tag || _vm.tag
            ? _c(
                "m-tag",
                [
                  _vm.$slots.tag ? _vm._t("tag") : _vm._e(),
                  _vm.tag ? [_vm._v(_vm._s(_vm.tag))] : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.$slots["header-actions"]
        ? _c(
            "m-header-actions",
            { staticClass: "flex-1" },
            [_vm._t("header-actions")],
            2
          )
        : _vm._e(),
      _vm.$slots["sub-header"] || _vm.subHeader
        ? _c(
            "m-sub-header",
            [
              _vm.$slots["sub-header"] ? _vm._t("sub-header") : _vm._e(),
              _vm.subHeader ? [_vm._v(_vm._s(_vm.subHeader))] : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }