import mirandaUtil from '@/plugins/MirandaUtil';
import SizingEnum from '@satellite/enums/SizingEnum';
import satelliteUtil from '@satellite/plugins/util';

const customUtil = {
  /**
   * Parses the sizeInput to get the value/unit
   */
  computeSize(sizeInput) {
    let value;
    let unit;
    let size;
    if (this.stringContainsNoNumbers(sizeInput)) {
      // Supports 'small', 'default', and 'large'
      size = SizingEnum[sizeInput];
    } else if (sizeInput.includes('font-size')) {
      // Supports any valid Miranda font-size token
      size = mirandaUtil.getTokenVal(sizeInput);
    } else {
      // Supports an manual size and unit i.e. 10px or 2.5rem
      size = sizeInput;
    }
    value = this.getSizeValue(size);
    unit = this.getSizeUnit(size);
    if (this.isSizeValid(value, unit)) {
      return {
        sizeValue: value,
        sizeUnit: unit
      };
    } else {
      return;
    }
  }
};

export default {
  ...satelliteUtil,
  ...customUtil
};
