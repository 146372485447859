import WarehouseSelectPage from '@/modules/appointments/WarehouseSelectPage.vue';
import AppointmentListPage from '@/modules/appointments/AppointmentListPage.vue';
import BookAppointmentPage from '@/modules/appointments/BookAppointmentPage.vue';
import AppointmentDetailsPage from '@/modules/appointments/AppointmentDetailsPage.vue';
import store from '@/store';
import { isCarrierUser } from '@satellite/../nova/core';

export default [
  {
    path: '/book',
    name: 'warehouse.select',
    component: WarehouseSelectPage,
    meta: {
      requiresAuth: true,
      title: 'Book appointment',
      hideSidebar: false
    }
  },
  {
    path: '/book/:warehouseId',
    name: 'appointment.book',
    component: BookAppointmentPage,
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Book appointment',
      hideSidebar: false
    }
  },
  // Redirect from old appointment link to new appointment link
  {
    path: '/appointments/:appointmentId',
    redirect: to => {
      return { name: 'appointment.details', params: { appointmentId: to.params.appointmentId } };
    }
  },
  {
    path: '/appointment/:appointmentId',
    name: 'appointment.details',
    component: AppointmentDetailsPage,
    props: true,
    meta: {
      requiresAuth: false,
      title: 'Appointment details',
      hideSidebar: true
    },
    beforeEnter: async (to, from, next) => {
      /**
       * The logic is as follows:
       * - If a user is not authenticated, render the public version of the page
       * - If a user is authenticated
       *    - If carrier user, render the auth-required version of the page
       *    - Otherwise, render the public version of the page
       */

      // Get access_token from the store - gets from localStorage or sessionStorage
      const token = store.getters['Auth/accessToken'];
      if (!token) {
        // If no token, load public page
        return next();
      }

      // If token is set, authenticate the user
      await store.dispatch('Auth/loginAs', token);
      const user = await store.dispatch('Auth/getAuthenticatedUser');
      const isUserAuthenticated = Boolean(user?.id);
      if (isUserAuthenticated && isCarrierUser(user)) {
        // requiresAuth meta is used in AppointmentDetailsPage to determine if page is public or private
        to.meta.hideSidebar = false;
        to.meta.requiresAuth = true;
      }

      next();
    }
  },
  {
    path: '/appointments',
    name: 'appointment.list',
    component: AppointmentListPage,
    props: true,
    meta: {
      requiresAuth: true,
      title: 'My appointments',
      hideSidebar: false
    }
  }
];
