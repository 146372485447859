<template>
  <m-group
    align="center"
    gap="none"
    class="direction-wrapper"
    :class="`load-type-direction-wrapper-${componentId}`">
    <v-icon v-if="!isInbound" size="12px" class="direction-icon" color="#005AD8">
      arrow-left-thick
    </v-icon>

    <m-text class="load-type-direction" variant="body-sm-bold">
      {{ isInbound ? 'IN' : 'OUT' }}
    </m-text>
    <v-icon v-if="isInbound" size="12px" class="direction-icon" color="#005454">
      arrow-right-thick
    </v-icon>
  </m-group>
</template>

<script>
import { VIcon } from '@/components';
import { LoadTypeDirection } from '@satellite/../nova/core';

/**
 * Shorthand indicator of the load type direction - Inbound or Outbound
 * @displayName Loadtype Direction
 */
export default {
  name: 'LoadtypeDirection',
  components: { VIcon },
  props: {
    /**
     * LoadTypeDirection enum value
     */
    direction: {
      type: String,
      required: true,
      validator(value) {
        return Object.keys(LoadTypeDirection).includes(value);
      }
    }
  },
  computed: {
    componentId() {
      return this._uid;
    },
    isInbound() {
      return this.direction.toLowerCase() === 'inbound';
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.util.appendStylesToShadowDomEls(
        document.querySelectorAll(
          `.load-type-direction-wrapper-${this.componentId} .load-type-direction`
        ),
        '.m-text',
        'font-size:10px !important;'
      );
    });
  }
};
</script>

<style lang="scss" scoped>
.direction-wrapper {
  text-transform: uppercase;
  background-color: $m-color-primary-20;
  padding: 0px 1px 0px;
  line-height: 8px;

  i {
    margin-top: 2px;
  }
}
</style>
