import USStatesEnum from '@satellite/enums/USStatesEnum';
import RenderModeEnum from '@satellite/enums/RenderModeEnum';
import MixpanelEvents from './MixpanelEvents';

const APPOINTMENT_CONFLICT_TYPE = Object.freeze({
  REF_NUMBER_TAKEN: 'ref_number_taken',
  SLOT_TAKEN: 'slot_taken'
});

export { USStatesEnum, MixpanelEvents, RenderModeEnum, APPOINTMENT_CONFLICT_TYPE };
