<template>
  <div style="background-color: white" class="password-form" :key="renderKey">
    <text-field
      v-bind="[$attrs]"
      :value="value"
      :label="$attrs.label ?? 'Password'"
      :type="inputType"
      :required="$attrs.required ?? true"
      :test-id="testId"
      @input="updateVal"
      autocomplete="new-password"
      :rules="computedRules"
      :external-error="$attrs.error"
      :validate-on-blur="validateOnBlur"
      @update:error="hasError => $emit('update:error', hasError)"
      :placeholder="$attrs.placeholder ?? 'Create a password following the requirements below'">
      <template v-slot:trailing>
        <icon-button
          variant="icon"
          size="small"
          icon-size="large"
          @click="toggleInputType"
          :test-id="`${testId}-visibility-btn`">
          {{ inputIcon }}
        </icon-button>
      </template>
    </text-field>
    <template v-if="shouldShowRequirements">
      <m-group
        class="step-indicator-group"
        gap="spacing-1"
        :data-testid="`${testId}-step-indicator-group`">
        <div
          :data-testid="`${testId}-step-indicator-${index}`"
          class="step-indicator"
          :class="{
            'requirement-met': index < numberOfRequirementsMet,
            'requirements-have-been-met': haveRequirementsBeenMet
          }"
          v-for="(validationItem, index) in filteredPasswordValidationItems"
          :key="`requirement-indicator-${index}`"></div>
      </m-group>
      <m-stack class="mt-3" gap="none" :data-testid="`${testId}-checkbox-group`">
        <div
          v-for="(validationItem, index) in filteredPasswordValidationItems"
          :key="`requirement-box-${index}`">
          <m-checkbox
            :id="`${validationItem.error}-validation-checkbox`"
            name="requirements"
            :data-testid="`${validationItem.error}-validation-checkbox`"
            :checked="validationItem.passed"
            @click="handleClick($event)">
            <span class="ml-1">{{ validationItem.message }}</span>
          </m-checkbox>
        </div>
      </m-stack>
    </template>
  </div>
</template>

<script>
import { TextField, IconButton } from '@/components';
import { getPasswordValidationErrors } from '@satellite/../nova/core';
import renderMixin from '@satellite/components/mixins/renderMixin';
import testable from '@/components/mixins/testable';

/**
 * Wraps the TextField component.  Props/attributes given to the PasswordField are passed through to the TextField.
 * Consider anything available on the TextField available on the PasswordField
 * @displayName Password Field
 */
export default {
  name: 'PasswordField',
  mixins: [renderMixin, testable],
  components: { IconButton, TextField },
  props: {
    /**
     * @model
     */
    value: {
      type: String,
      required: false
    },
    /**
     * Determines if password requirement checkmarks are shown
     */
    shouldShowRequirements: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * If set to `true`, bypasses the password validation check
     */
    bypassRequirements: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    haveRequirementsBeenMet() {
      return this.numberOfRequirementsMet === this.filteredPasswordValidationItems.length;
    },
    numberOfRequirementsMet() {
      return this.filteredPasswordValidationItems.filter(validationItem => validationItem.passed)
        .length;
    },
    filteredPasswordValidationItems() {
      return this.passwordValidationItems.filter(
        validationItem => validationItem.error !== 'one-letter'
      );
    },
    inputIcon() {
      return `eye${this.inputType === 'text' ? '-off' : ''}`;
    },
    computedRules() {
      let rules = this.$attrs.rules ?? [];
      if (!this.bypassRequirements) {
        rules = [...rules, ...this.$validator.rules.password];
      }
      return rules;
    },
    validateOnBlur() {
      return this.$attrs['validation-iterator'] === 0;
    }
  },
  data() {
    return {
      password: '',
      passwordValidationItems: getPasswordValidationErrors(''),
      inputType: 'password'
    };
  },
  methods: {
    handleClick(e) {
      e.preventDefault();
    },
    /**
     * Used for the eye icon to show/hide the password
     * @private
     */
    toggleInputType() {
      this.inputType = this.inputType === 'password' ? 'text' : 'password';
      this.rerender();
    },
    updateVal(val) {
      this.$nextTick(() => {
        this.$emit('input', val);
      });
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.passwordValidationItems = getPasswordValidationErrors(newVal);
      },
      immediate: true
    }
  }
};
</script>

<style scoped lang="scss">
.step-indicator-group {
  margin: $m-spacing-2 0;
}
.step-indicator {
  height: 5px;
  background-color: $m-color-neutral-40;
  flex-grow: 1;
  transition: background-color 0.4s ease;

  &.requirement-met {
    background-color: $m-color-danger-100;
  }
  &.requirements-have-been-met {
    background-color: $m-color-success-100;
  }
}
</style>
