<template>
  <article class="printable-window" id="appointment-details-printable">
    <header class="printable-header">
      <img src="/images/logo_for_print.png" alt="Opendock Logo" />
      <span>Printed on {{ extendedAppointment.getReadableCurrentTime() }}</span>
    </header>
    <section class="section-1">
      <div class="center-row">
        <v-icon size="60px">calendar</v-icon>
        <div class="appointment-time-wrapper flex-column">
          <div class="label-text">
            {{ extendedAppointment.isRequested() ? 'Requested for:' : 'Scheduled to:' }}
          </div>
          <div class="appointment-time">
            {{ appointmentDateTime }}
          </div>
        </div>
      </div>
      <div class="status-details">
        <template v-if="extendedAppointment.isNoShow()">
          <div>
            <div class="label-text">Reported as No Show:</div>
            <div class="status-date-time">Carrier did not show for appointment.</div>
          </div>
        </template>
        <template v-else-if="extendedAppointment.isRequested()">
          <div>
            <div class="status-date-time">Appointment waiting for warehouse confirmation</div>
          </div>
        </template>
        <template v-else>
          <div>
            <div class="label-text">Arrived at:</div>
            <div class="status-date-time">
              {{ extendedAppointment.getFormattedArrivalTime() }}
            </div>
            <div class="label-text black" v-if="extendedAppointment.isArrived()">
              {{ extendedAppointment.getArrivalTimeDiff() }}
            </div>
          </div>
          <img class="arrow" src="/images/arrow-right.png" alt="Right Arrow" />
          <div>
            <div class="label-text">Completed at:</div>
            <div class="status-date-time">
              {{ extendedAppointment.getFormattedCompletedTime() }}
            </div>
            <div class="label-text black">
              {{ extendedAppointment.getCompletedTimeDiff() }}
            </div>
          </div>
        </template>
      </div>
    </section>
    <section class="section-2">
      <div>
        <div class="label-text">Carrier Contact:</div>
        <div class="data-group">
          <span>
            <span class="bold">Name:</span>
            {{ extendedAppointment.user.firstName }} {{ extendedAppointment.user.lastName }}
          </span>
          <span>
            <span class="bold">Email:</span>
            {{ extendedAppointment.user.email }}
          </span>
          <span>
            <span class="bold">Phone:</span>
            {{ formatPhoneNumber(extendedAppointment.user.phone) }}
          </span>
        </div>

        <div class="label-text">Warehouse:</div>
        <div class="data-group">
          <span class="bold">{{ extendedAppointment.warehouse.name }}</span>
          <span>
            <span class="bold">Address:</span>
            {{ warehouseAddress }}
          </span>
          <span>
            <span class="bold">Email:</span>
            {{ extendedAppointment.warehouse.email }}
          </span>
          <span>
            <span class="bold">Phone:</span>
            {{ extendedAppointment.warehouse.phone }}
          </span>
        </div>

        <div class="label-text">Confirmation #:</div>
        <div class="data-group mb-1">
          <span>{{ extendedAppointment.confirmationNumber }}</span>
        </div>
        <div class="label-text">Appointment ID:</div>
        <div class="data-group">
          <span>{{ extendedAppointment.id }}</span>
        </div>
      </div>
      <div>
        <div class="label-text">Dock name:</div>
        <div class="data-group">
          {{ extendedAppointment.getParentDock().name }}
        </div>
        <div class="label-text">Load type:</div>
        <div class="data-group">
          {{ extendedAppointment.loadType.name }}
        </div>
        <template v-if="refNumSettings.isVisible !== false">
          <div class="label-text">{{ refNumSettings.displayName }}:</div>
          <div class="data-group">
            {{ extendedAppointment.refNumber }}
          </div>
        </template>

        <div class="label-text">Appointment Notes:</div>
        <div class="data-group" v-html="extendedAppointment.notes"></div>
        <template v-for="field in customFields">
          <template v-if="field.value">
            <div :key="field.name">
              <div class="label-text">{{ field.label }}:</div>
              <div
                v-html="
                  novaCore.getCustomFieldFormattedValue(field, {
                    [novaCore.CustomFieldType.Timestamp]: {
                      timezone: extendedAppointment.warehouse.timezone,
                      formatAsMilitary: extendedAppointment.isMilitaryTimeEnabled
                    }
                  })
                "
                style="white-space: pre-line"
                class="data-group"></div>
            </div>
          </template>
        </template>
      </div>
    </section>

    <div style="padding: 2rem 0; text-align: center">
      <span class="bold" style="margin-right: 10px">Opendock</span>
      The ultimate dock scheduling software for warehouses.
    </div>
  </article>
</template>

<script>
import { formatPhoneNumber } from '@satellite/../nova/core';
import customFieldsMixin from '@/components/mixins/customFieldsMixin';
import { VIcon } from '@/components';
import { ExtendedAppointment } from '@/modules/appointments/ExtendedAppointment';
import { propValidatorHelper } from '@satellite/plugins/util';

export default {
  mixins: [customFieldsMixin],
  components: { VIcon },
  props: {
    extendedAppointment: {
      type: ExtendedAppointment,
      required: true,
      validator(value) {
        return propValidatorHelper({
          value,
          isValid: value instanceof ExtendedAppointment,
          componentName: 'PrintableAppointmentDetails',
          propName: 'extendedAppointment',
          message: ''
        });
      }
    }
  },
  data() {
    return {
      customFields: []
    };
  },
  computed: {
    refNumSettings() {
      return this.$refNumSettings(this.extendedAppointment.warehouse);
    },
    appointmentDateTime() {
      const date = this.extendedAppointment.getReadableDate();
      const time = this.extendedAppointment.getReadableTimes();
      return `${date} ${'\u2022'} ${time}`;
    },
    // TODO: Abstract to core lib if we see this used anywhere else!
    warehouseAddress() {
      return `${this.extendedAppointment.warehouse.street}, ${this.extendedAppointment.warehouse.city}, ${this.extendedAppointment.warehouse.state} ${this.extendedAppointment.warehouse.zip}`;
    }
  },
  methods: {
    formatPhoneNumber
  },
  mounted() {
    this.customFields = this.getCustomFieldsWithValues(
      this.extendedAppointment.customFields,
      this.extendedAppointment.customFields
    );
  }
};
</script>

<style lang="scss">
/**
Outer styles on body/html and the wrappers that do not work in a scoped style block
 */
@media screen {
  #print-content {
    display: none;
  }
  .printable-window {
    display: none;
  }
}

@media print {
  @page {
    margin: 0;
    size: letter;
  }

  #html,
  #body {
    margin: 0 !important;
    height: 95% !important;
    overflow: clip !important;
  }
  #body *:not(#print-content):not(#print-content *):not(.printable-window) {
    display: none !important;
  }
  #print-content {
    font-family: 'Roboto';
    position: absolute;
    left: 0;
    top: 0;
    margin: 1.6cm 1.6cm;
    width: calc(100% - 3.2cm);
  }

  .printable-window {
    .header-spacing {
      margin-top: 1.6cm;
    }
  }
}
</style>

<style lang="scss" scoped>
@media print {
  .printable-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .section-1 {
    border: 1px solid rgba(0, 0, 0, 0.3);
    margin-top: 20px;
    padding: 1rem;
  }
  .section-2 {
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-top: 0;
    height: 100%;
    overflow-wrap: break-word;

    > div {
      width: 50%;
      padding: 1.5rem;

      &:first-of-type {
        border-right: 1px solid rgba(0, 0, 0, 0.3);
      }
    }
  }
  .center-row {
    display: flex;
    align-items: center;
  }
  .label-text {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);

    &.black {
      color: #000;
    }
  }
  .status-date-time {
    font-size: 16px;
    line-height: 25px;
  }
  .appointment-time {
    font-size: 20px;
  }
  .arrow {
    display: block;
    margin: 0 25px;
    height: 25px;
    width: 75px;
  }
  .status-details {
    display: flex;
    margin-top: 10px;
    margin-left: 75px;
    align-items: center;
    margin-bottom: 10px;
  }
  .bold {
    font-weight: bold;
  }
  .data-group {
    font-size: 16px;
    padding-top: 0.5rem;
    margin-bottom: 0.8rem;
    > span {
      display: block;
      line-height: 2rem;
    }
  }
  .appointment-time-wrapper {
    margin-left: 15px;
  }
}
</style>
