<template>
  <div>
    <img :src="computedLogoPath" if alt="Opendock Logo" />
  </div>
</template>

<script>
import logoDarkTheme from '@satellite/../public/images/opendock_logo_dark_theme.svg';
import logoLightTheme from '@satellite/../public/images/opendock_logo_light_theme.svg';

export default {
  props: {
    logoPath: {
      type: String,
      required: false,
      default: null
    },
    lightMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    computedLogoPath() {
      return this.logoPath ?? this.lightMode ? logoLightTheme : logoDarkTheme;
    }
  }
};
</script>
