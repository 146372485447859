<template>
  <icon-button :test-id="testId" icon-size="20px" @click="handleClick" :icon-color="iconColor">
    {{ icon }}
  </icon-button>
</template>

<script>
import { IconButton } from '@/components';

/**
 * Button with copy icon that copies any content passed as the `content` prop
 * @displayName Copy Content Button
 */
export default {
  name: 'CopyContentButton',
  components: { IconButton },
  props: {
    /**
     * Content to be copied
     */
    content: {
      type: String,
      default: null
    },
    /**
     * Message displayed on the notifications
     */
    message: {
      type: String,
      default: 'Copied to Clipboard!'
    },
    /**
     * Is the button disabled
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Test id for tests
     */
    testId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      icon: 'content-copy',
      iconColor: 'color-text-primary'
    };
  },
  methods: {
    async handleClick($event) {
      await this.$copyContent(this.content, this.message, () => {
        //  * Emits click event with target's event
        //  * @event click
        //  * @property {object} $event - Target's Event object
        //  */
        this.$emit('copy', $event);
        this.iconColor = 'color-success-60';
        this.icon = 'check';
        setTimeout(() => {
          this.icon = 'content-copy';
          this.iconColor = 'color-text-primary';
        }, 2000);
      });
    }
  }
};
</script>
