<template>
  <vuetify-data-table v-bind="[$props, $attrs]" v-on="$listeners" :options.sync="$parent.options">
    <template v-for="(_, scopedSlot) of $scopedSlots" v-slot:[scopedSlot]="scope">
      <!-- @slot Pass through slots to Vuetify DataTable component -->
      <slot :name="scopedSlot" v-bind="scope" />
    </template>

    <template v-for="(_, slot) of $slots" v-slot:[slot]>
      <!-- @slot Pass through slots to Vuetify DataTable component -->
      <slot :name="slot" />
    </template>
  </vuetify-data-table>
</template>

<script>
import { VDataTable as VuetifyDataTable } from 'vuetify/lib/components/VDataTable';

/**
 * HOC for Vuetify's VDataTable component - see the docs for reference
 * @displayName Data Table Base
 */
export default {
  name: 'DataTableBase',
  components: { VuetifyDataTable }
};
</script>

<style scoped></style>
