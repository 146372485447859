<template>
  <m-stack :id="`stepper-${_uid}`" class="width-100" gap="spacing-2">
    <m-steps
      :active-step="computedActiveStep"
      :class="[`stepper-${_uid}`, { 'steps-only': stepsOnly }]">
      <m-step
        v-for="step in value"
        :name="step.name"
        :completed="step.completed"
        :key="`step-${step.name}`">
        <m-stack gap="none">
          <span>{{ step.label }}</span>
          <slot name="additional-step-header-content" :step="step"></slot>
        </m-stack>
      </m-step>
    </m-steps>
    <template v-if="!stepsOnly">
      <template v-for="step in value">
        <m-box :key="`step-content-${step.name}`" v-if="step.name === computedActiveStep">
          <slot :name="`step.${step.name}`" :step="step"></slot>
        </m-box>
      </template>

      <stepper-nav
        v-if="!externalNavigation"
        :prev-button-attrs="{ disabled: computedActiveStepIndex < 1 }"
        :next-button-attrs="{ disabled: computedActiveStepIndex >= value.length - 1 }"
        @prev="updateCompletedStep(true)"
        @next="updateCompletedStep(false)"></stepper-nav>
    </template>
  </m-stack>
</template>

<script>
import { StepperNav } from '@/components';
import { deepClone } from '@satellite/../nova/core';

export default {
  name: 'VStepper',
  components: { StepperNav },
  props: {
    /**
     * Array of step objects .e.g. { name: 'dock', label: 'Dock', completed: false }
     * @model
     */
    value: {
      type: Array,
      required: true
    },
    /**
     * Manually set the active step
     */
    activeStep: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Display only the steps without the step content
     * Used if externalNavigation
     */
    stepsOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Removes the next/prev buttons/functionality
     */
    externalNavigation: {
      type: Boolean,
      required: false,
      default: false
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    computedActiveStepIndex() {
      const stepIndex = this.value.findLastIndex(step => step.completed);
      if (stepIndex < 0) {
        return 0;
      } else if (stepIndex >= this.value.length - 1) {
        return null;
      } else {
        return stepIndex + 1;
      }
    },
    computedActiveStep() {
      if (!this.activeStep) {
        return this.value?.[this.computedActiveStepIndex]?.name ?? this.ALL_STEPS_COMPLETED_TEXT;
      }
      return this.activeStep;
    },
    computedColor() {
      const color = this.disabled ? 'color-text-disabled' : this.color;
      return color
        ? this.mirandaUtil.getTokenCssValue(color) || `var(--status-${this.color}-base)`
        : null;
    }
  },
  mounted() {
    if (this.stepsOnly) {
      // Removes the content container for the steps from the DOM
      this.util.appendStylesToShadowDomEls(
        document.querySelectorAll(`m-steps.stepper-${this._uid}.steps-only`),
        '.content-container',
        'display:none;'
      );
    }
  },
  created() {
    this.ALL_STEPS_COMPLETED_TEXT = 'All steps completed';
  },
  methods: {
    updateCompletedStep(undoLastStep) {
      /**
       * V-Model Updates steps based on next or prev button click
       * @event input
       * @property {array} stepsCopy
       */
      const stepsCopy = deepClone(this.value);
      const index = this.computedActiveStepIndex - (undoLastStep ? 1 : 0);
      stepsCopy[index].completed = undoLastStep ? false : true;
      this.$emit('input', stepsCopy);
    }
  }
};
</script>

<style lang="scss" scoped>
.steps-only {
  .content-container {
    display: none !important;
  }
}

m-step {
  flex: 1;
  min-width: auto;
}
</style>
