var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      class: { underlined: _vm.underlined },
      attrs: { type: "button", "data-testid": _vm.testId },
      on: {
        click: function ($event) {
          return _vm.handleClick($event)
        },
      },
    },
    [_c("span", [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }