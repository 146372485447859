var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _vm.extendedAppointment
        ? _c("appointment-status-timeline", {
            attrs: {
              "external-navigation": "",
              "steps-only": "",
              appointment: _vm.extendedAppointment,
              "full-width": false,
              color: _vm.extendedAppointment.status.toLowerCase(),
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "additional-step-header-content",
                  fn: function ({ step }) {
                    return [
                      _vm.shouldShowTimestamp(step.name)
                        ? _c("div", { staticClass: "timestamp-text" }, [
                            _c("p", { staticClass: "my-1" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.extendedAppointment.getTimestamp(
                                      step.name
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("strong", [
                              _vm._v(_vm._s(_vm.getTimeDiff(step.name))),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              false,
              4026752985
            ),
            model: {
              value: _vm.statuses,
              callback: function ($$v) {
                _vm.statuses = $$v
              },
              expression: "statuses",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }