var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-accordion",
    [
      _c(
        "m-accordion-title",
        { staticClass: "is__relative" },
        [
          _c(
            "m-stack",
            { staticClass: "p-y-2", attrs: { gap: "spacing-2" } },
            [_vm._t("title")],
            2
          ),
          _c("m-divider", { staticClass: "header-divider" }),
        ],
        1
      ),
      _c(
        "m-accordion-content",
        [
          _c("m-stack", { staticClass: "p-y-4" }, [_vm._t("body")], 2),
          _vm.$slots.footer
            ? _c(
                "div",
                { staticClass: "is__relative p-t-3" },
                [
                  _c("m-divider", { staticClass: "footer-divider" }),
                  _c("div", { staticClass: "m-t-4" }, [_vm._t("footer")], 2),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }