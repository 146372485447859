import { mapState } from 'vuex';

/**
 * Store states map
 * @mixin
 * @displayName Mapped States
 */
export default {
  computed: {
    ...mapState('App', {
      $neutronVersion: state => state.neutronVersion,
      $appEnvironment: state => state.appEnvironment,
      $homePageUrl: state => state.homePageUrl,
      $termsAndConditionsUrl: state => state.termsAndConditionsUrl,
      $privacyPolicyUrl: state => state.privacyPolicyUrl,
      $supportUrl: state => state.supportUrl,
      $isSidebarOpen: state => state.isSidebarOpen,
      $warehouseSearch: state => state.warehouseSearch
    }),
    ...mapState('Appointments', {
      $appointments: state => state.appointments
    }),
    ...mapState('Auth', {
      $rolePermissions: state => state.rolePermissions,
      $previewWarehouseId: state => state.previewWarehouseId,
      $me: state => state.me
    })
  }
};
