<template>
  <dialog-base
    v-bind="$attrs"
    :value="value"
    @input="updateVal"
    ref="reschedule-dialog"
    size="large"
    :should-display-close-button="!isLoading">
    <template #header>
      <m-group gap="spacing-1">
        <m-text variant="heading-md-bold">Reschedule appointment</m-text>
      </m-group>
    </template>
    <template #body>
      <slot-picker
        v-if="selectedSlot && !isLoading"
        :loadtype-id="extendedAppointment.loadTypeId"
        :warehouse="warehouse"
        :appointment-id="extendedAppointment.id"
        :dock-id="parentDock.id"
        :docks="docks"
        v-model="selectedSlot"
        :allow-dock-select="
          extendedAppointment.loadType.settings?.allowCarrierDockSelection
        "></slot-picker>
      <v-loader v-else :is-loading="isLoading">Rescheduling appointment</v-loader>
    </template>
    <template #actions>
      <m-group class="flex-nowrap p-t-4" gap="spacing-2">
        <template>
          <secondary-button
            test-id="cancel-reschedule-btn"
            @click="updateVal(false)"
            :disabled="isLoading">
            Cancel
          </secondary-button>
          <primary-button
            test-id="reschedule-btn"
            @click="reschedule"
            :disabled="isLoading || !newSlotSelected">
            Reschedule
          </primary-button>
        </template>
      </m-group>
    </template>
  </dialog-base>
</template>

<script>
import { DialogBase, PrimaryButton, SecondaryButton, VLoader } from '@/components';
import dialogMixin from '@/components/mixins/dialogMixin';
import SlotPicker from '@/components/elements/appointment/SlotPicker.vue';
import { DateTime } from 'luxon';
import { ExtendedAppointment } from '@/modules/appointments/ExtendedAppointment';
import { propValidatorHelper } from '@satellite/plugins/util';
import { isEqual } from 'lodash';

/**
 * Shown when rescheduling an appointment
 * @displayName Reschedule Appointment Dialog
 */
export default {
  name: 'RescheduleAppointmentDialog',
  mixins: [dialogMixin],
  components: {
    SlotPicker,
    DialogBase,
    PrimaryButton,
    SecondaryButton,
    VLoader
  },
  props: {
    extendedAppointment: {
      type: ExtendedAppointment,
      required: true,
      validator(value) {
        return propValidatorHelper({
          value,
          isValid: value instanceof ExtendedAppointment,
          componentName: 'RescheduleAppointment',
          propName: 'extendedAppointment',
          message: ''
        });
      }
    }
  },
  data() {
    return {
      docks: [],
      selectedSlot: null,
      isLoading: false,
      parentDock: {}
    };
  },
  computed: {
    warehouse() {
      return this.extendedAppointment.warehouse;
    },
    newSlotSelected() {
      if (!this.selectedSlot) {
        return false;
      }
      const existingSlot = {
        start: DateTime.fromISO(this.extendedAppointment.start, { zone: this.warehouse.timezone }),
        docks: [this.parentDock.id]
      };
      return !isEqual(existingSlot, this.selectedSlot);
    }
  },
  methods: {
    async reschedule() {
      this.isLoading = true;
      const fields = { start: null, dockId: null };
      fields.start = this.selectedSlot.start.toUTC().toISO();
      // TODO: We should loop through these until one takes in the slim chance an appointment
      // TODO: is booked at this dock at this time with this loadtype while the carrier is filling out appointment details
      fields.dockId = this.selectedSlot.docks[0];
      this.mixpanel.track(this.mixpanel.events.ACTION.RESCHEDULE_APPOINTMENT, {
        appointmentId: this.extendedAppointment.id,
        'Warehouse ID': this.warehouse.id,
        'Warehouse Name': this.warehouse.name
      });
      await this.services.appointment
        .updateAppointment(this.extendedAppointment.id, fields, {}, { suppressNotification: true })
        .catch(res => {
          this.notify(res?.response?.data?.message || res, 'error');
        })
        .then(appointment => {
          if (appointment?.id) {
            this.$emit('update:appointment', appointment);
            this.notify('Your appointment was rescheduled');
          }
        })
        .finally(() => {
          this.isLoading = false;
          this.updateVal(false);
        });
    }
  },
  async mounted() {
    this.util.appendStyleToShadowDomEl(
      this.$refs['reschedule-dialog'].$el,
      'dialog',
      'min-height: calc(100% - var(--margin));height:100%;'
    );
    await this.services.warehouse
      .getWarehouseDocks(this.warehouse.id, {}, { fields: ['name', 'id', 'capacityParentId'] })
      .then(
        docks =>
          (this.docks = docks.filter(
            dock => !dock.capacityParentId || dock.id === dock.capacityParentId
          ))
      );
    this.parentDock = this.extendedAppointment.getParentDock();
    this.selectedSlot = {
      start: DateTime.fromISO(this.extendedAppointment.start, { zone: this.warehouse.timezone }),
      docks: [this.parentDock.id]
    };
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  m-dialog-body {
    height: 100% !important;
  }
}
</style>
