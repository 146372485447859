<template>
  <m-stack gap="spacing-2">
    <m-text variant="body-md">Operating hours</m-text>
    <template v-for="day in daysOfOperation">
      <m-group :key="day" v-if="schedule[day].length > 0" gap="none">
        <div class="day-name">
          <m-text variant="body-md-bold">{{ getScheduleShortDay(day) }}</m-text>
        </div>
        <m-stack gap="none">
          <m-text
            v-for="(range, index) in schedule[day]"
            :key="`${day}-range-${index}`"
            variant="body-md">
            {{ formatScheduleClock(range.start) }} to
            {{ formatScheduleClock(range.end) }}
          </m-text>
        </m-stack>
      </m-group>
    </template>
    <m-text variant="body-md" v-if="noScheduleSet">No schedule set</m-text>

    <div class="m-t-4" v-if="closedDates.length > 0">
      <m-text variant="body-md">Closed dates</m-text>
    </div>

    <m-stack gap="spacing-2" v-if="closedDates.length > 0">
      <template v-for="(date, index) in closedDates">
        <m-text variant="body-md" :key="`closed-date-${index}`">{{ date }}</m-text>
      </template>
    </m-stack>
  </m-stack>
</template>

<script>
import { DateTime, Info } from 'luxon';
import {
  LuxonDateTimeFormats,
  formatScheduleClock,
  deepClone,
  upperFirst
} from '@satellite/../nova/core';

/**
 * @displayName Hours of Operation List
 */
export default {
  name: 'HoursOfOperationList',
  props: {
    /**
     * Schedule from API - works with any standardized schedule - Warehouse, Dock, Load Type.
     * See schedule.ts in core lib for more info
     */
    schedule: {
      type: Object,
      required: true
    },
    /**
     * Timezone of warehouse entity belongs to
     */
    timezone: {
      type: String,
      required: true
    },
    /**
     * If set to true, military time is displayed
     */
    enableMilitaryTime: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    noScheduleSet() {
      const scheduleClone = deepClone(this.schedule);
      return Object.entries(scheduleClone).every(([key, day]) => {
        if (['version'].includes(key)) {
          return true;
        }
        return day.length === 0;
      });
    },
    closedDates() {
      return (this.schedule?.closedIntervals ?? [])
        .sort((a, b) => new Date(a.start) - new Date(b.start))
        .filter(interval => {
          const start = DateTime.fromISO(interval.start, { zone: this.timezone }).startOf('day');
          const currentDate = DateTime.now().startOf('day').setZone(this.timezone);
          return start >= currentDate;
        })
        .map(interval =>
          DateTime.fromISO(interval.start, { zone: this.timezone }).toFormat(
            LuxonDateTimeFormats.LongDateShortMonth
          )
        );
    },
    weekdays() {
      return Info.weekdays('long', { locale: 'en-US' });
    },
    daysOfOperation() {
      const sortedWeekdays = deepClone(this.weekdays).map(weekday => weekday.toLowerCase());
      // Because weekdays is mon - sun, we want sunday moved to the start of the array
      sortedWeekdays.unshift(sortedWeekdays[sortedWeekdays.length - 1]);
      sortedWeekdays.pop();

      let weekdays = Object.keys(this.schedule).filter(day => {
        return day !== 'version' && day !== 'closedIntervals';
      });

      let sorted = weekdays.sort(function sortByDay(a, b) {
        let day1 = a.toLowerCase();
        let day2 = b.toLowerCase();
        return sortedWeekdays.indexOf(day1) - sortedWeekdays.indexOf(day2);
      });

      return sorted;
    }
  },
  methods: {
    formatScheduleClock(time) {
      return formatScheduleClock(time, this.enableMilitaryTime);
    },
    getScheduleShortDay(day) {
      return upperFirst(day.substring(0, 3));
    }
  }
};
</script>

<style scoped lang="scss">
.day-name {
  width: 40px;
}
</style>
