var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("internal-page", {
    attrs: { "allow-overflow": "" },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("page-header", {
              attrs: { title: "My appointments" },
              scopedSlots: _vm._u([
                {
                  key: "header-actions",
                  fn: function () {
                    return [
                      _vm.hasAppointments
                        ? _c("big-number-boxes", {
                            staticClass: "big-number-box-container",
                            attrs: {
                              "big-numbers": _vm.bigNumbers,
                              selected: _vm.selectedBigNumber,
                            },
                            on: {
                              selected: _vm.handleBigNumberClick,
                              deselected: function ($event) {
                                _vm.selectedBigNumber = ""
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.hasAppointments
                        ? _c(
                            "tertiary-button",
                            {
                              attrs: {
                                "leading-icon": "download",
                                "test-id": "export-appointments-btn",
                                size: "large",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$eventHub.$emit(
                                    "export-carrier-appointments"
                                  )
                                },
                              },
                            },
                            [_vm._v(" Export ")]
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              { staticStyle: { flex: "1" } },
              [
                _vm.hasAppointments
                  ? _c("appointment-list", {
                      attrs: { "status-filter": _vm.selectedBigNumber },
                      on: {
                        "update:appointments": _vm.updateBigNumbers,
                        removeStatusFilter: function ($event) {
                          _vm.selectedBigNumber = ""
                        },
                      },
                    })
                  : _vm.loaded
                  ? _c(
                      "image-message",
                      {
                        attrs: {
                          "image-path": _vm.successSvg,
                          "image-alt": "Truck pulling up to a dock",
                        },
                      },
                      [
                        _c("m-text", { attrs: { variant: "body-md-bold" } }, [
                          _vm._v("Start booking appointments to see them here"),
                        ]),
                        _c("m-text", { attrs: { variant: "body-sm" } }, [
                          _vm._v(
                            " You will be able to view and manage your appointments on this page "
                          ),
                        ]),
                        _c(
                          "primary-button",
                          {
                            staticClass: "book-appointment-btn m-t-3",
                            attrs: {
                              "test-id": "no-appointments-book-appointment-btn",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$router.push({
                                  name: "warehouse.select",
                                })
                              },
                            },
                          },
                          [_vm._v(" Book appointment ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return undefined
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }