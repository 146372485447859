var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.computedText
    ? _c("m-tag", { class: `status-tag-${_vm._uid}` }, [
        _vm._v(_vm._s(_vm.computedText)),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }