var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-toggle-group",
    {
      staticClass: "p-b-1",
      attrs: { type: _vm.$attrs.type ?? "single", value: _vm.value },
      on: { "m-change": _vm.updateVal },
    },
    _vm._l(_vm.buttons, function (button) {
      return _c(
        "m-toggle",
        { key: button.value, attrs: { value: button.value } },
        [
          button.icon
            ? _c(
                "span",
                { attrs: { slot: "leading" }, slot: "leading" },
                [_c("v-icon", [_vm._v(" " + _vm._s(button.icon) + " ")])],
                1
              )
            : _vm._e(),
          _vm._v(" " + _vm._s(button.label) + " "),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }