var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-field",
    { staticClass: "is__relative", class: { "flex-1": !_vm.width } },
    [
      _vm.label ? _c("m-field-label", [_vm._v(_vm._s(_vm.label))]) : _vm._e(),
      _c(
        "vuetify-menu",
        {
          attrs: {
            transition: "scale-transition",
            "close-on-content-click": false,
            "content-class": `date-picker-wrapper date-picker-wrapper-${_vm._uid}`,
            attach: "#date-picker-dialog",
            bottom: "",
            right: "",
            "offset-y": "",
            "offset-overflow": "",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ attrs }) {
                return [
                  _vm.iconActivated
                    ? _c(
                        "icon-button",
                        _vm._b(
                          {
                            ref: "trigger",
                            attrs: {
                              "test-id": "date-picker-trigger",
                              size: "small",
                              "icon-size": "16px",
                            },
                            on: {
                              click: function ($event) {
                                _vm.showDatePicker = true
                              },
                            },
                          },
                          "icon-button",
                          attrs,
                          false
                        ),
                        [_vm._v(" calendar-blank ")]
                      )
                    : _c(
                        "text-field",
                        _vm._b(
                          {
                            ref: "trigger",
                            staticClass: "date-picker-trigger",
                            style: _vm.computedWidth,
                            attrs: {
                              "test-id": "date-picker-field",
                              placeholder: "Select a date",
                              value: _vm.textFieldPlaceholder,
                              color: "blue",
                              disabled: "",
                              "trailing-icon": { icon: "chevron-down" },
                            },
                            on: {
                              click: function ($event) {
                                _vm.showDatePicker = true
                              },
                            },
                          },
                          "text-field",
                          attrs,
                          false
                        )
                      ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showDatePicker,
            callback: function ($$v) {
              _vm.showDatePicker = $$v
            },
            expression: "showDatePicker",
          },
        },
        [
          _c("vuetify-date-picker", {
            ref: "datePicker",
            attrs: {
              "no-title": "",
              min: _vm.formattedMinDate,
              max: _vm.formattedMaxDate,
              color: _vm.computedColor,
              "prev-icon": "mdi-chevron-up",
              "next-icon": "mdi-chevron-down",
              value: _vm.value,
            },
            on: { input: _vm.handleDateClick },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }