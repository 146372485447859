var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-field",
    {
      staticClass: "field-wrapper",
      class: `multi-drop-down-${_vm._uid}`,
      attrs: {
        "data-testid": _vm.testId,
        status: _vm.hasError ? "danger" : "default",
      },
    },
    [
      _vm.label
        ? _c("m-field-label", { attrs: { slot: "label" }, slot: "label" }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
      _c(
        "vuetify-select",
        _vm._b(
          {
            style: _vm.computedWidth,
            attrs: {
              attach: `.multi-drop-down-${_vm._uid}`,
              multiple: "",
              items: _vm.options,
              "hide-details": "hide",
              label: " ",
              "item-text": "label",
              "item-value": "value",
              value: _vm.value,
              "data-testid": `${_vm.testId}-trigger`,
              "menu-props": {
                bottom: true,
                offsetY: true,
                rounded: true,
                ripple: false,
              },
            },
            on: { input: (val) => (_vm.internalValue = val) },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function () {
                  return [
                    _c(
                      "v-icon",
                      { attrs: { size: "19px", color: "color-text-tertiary" } },
                      [_vm._v("chevron-down")]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "item",
                fn: function ({ item }) {
                  return [
                    _c(
                      "m-dropdown-item",
                      {
                        class: { selected: _vm.isSelected(item) },
                        attrs: { "data-testid": `${_vm.testId}-menu-item` },
                      },
                      [
                        _vm._v(" " + _vm._s(item.label) + " "),
                        _vm.isSelected(item)
                          ? _c("v-icon", [_vm._v("check")])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          },
          "vuetify-select",
          [_vm.$attrs, _vm.$props],
          false
        )
      ),
      _vm.shouldDisplayMessages
        ? _c(
            "m-field-hint",
            { attrs: { "data-testid": `${_vm.testId}-errors` } },
            [
              _c("span", [_vm._v(_vm._s(_vm.errorBucket.join(", ")))]),
              _vm.errorBucket.length && _vm.hint
                ? _c("span", [_vm._v(".")])
                : _vm._e(),
              _vm.hint
                ? _c("span", { class: { "p-l-1": _vm.errorBucket.length } }, [
                    _vm._v(_vm._s(_vm.hint)),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }