<template>
  <dialog-base
    :value="value"
    @input="value = !value"
    data-testid="accept-tos-dlg"
    size="medium"
    :should-display-close-button="false">
    <template #header>
      <m-group gap="spacing-1">
        <m-text variant="heading-sm-bold">We value your privacy and security</m-text>
      </m-group>
    </template>
    <template v-slot:body>
      <m-text variant="body-sm" class="m-b-10">
        In order to use our platform, please review Loadsmart’s
        <v-link
          :is-bold="false"
          size="sm"
          href="https://loadsmart.com/subscription-and-professional-services-agreement/"
          target="_blank">
          Terms & Conditions
        </v-link>
        and
        <v-link
          :is-bold="false"
          size="sm"
          href="https://loadsmart.com/privacy-policy/"
          target="_blank">
          Privacy Policy
        </v-link>
        , and mark your consent below
      </m-text>
      <m-group class="m-t-5 flex-nowrap" gap="spacing-1">
        <v-icon
          size="18px"
          @click="toggleTosAccept"
          color="color-primary-60"
          data-testid="accept-tos-checkbox">
          {{ tosAccepted ? 'checkbox-marked' : 'checkbox-blank-outline' }}
        </v-icon>
        <m-text variant="body-sm">
          I have read and agree to be bound by Loadsmart's Terms & Conditions and subject to
          Loadsmart's Privacy Policy in connection with my use of the Opendock platform.
        </m-text>
      </m-group>
      <div class="m-t-5">
        <m-text variant="body-sm">
          If you need any help, reach out to our support team
          <v-link
            :is-bold="false"
            size="sm"
            href="https://community.loadsmart.com/hc/en-us/community/topics/14812876253715-OpenDock"
            target="_blank">
            through this link.
          </v-link>
        </m-text>
      </div>
    </template>
    <template v-slot:actions>
      <primary-button :disabled="!tosAccepted" @click="confirm" test-id="confirm-accept-tos-btn">
        Confirm
      </primary-button>
    </template>
  </dialog-base>
</template>

<script>
import { VIcon, DialogBase, PrimaryButton, VLink } from '@/components';
import dialogMixin from '@/components/mixins/dialogMixin';

/**
 * Shown to prompt the user to accept the TOS
 * @displayName Terms and Conditions Dialog
 */
export default {
  name: 'TermsAndConditionsDialog',
  mixins: [dialogMixin],
  components: {
    DialogBase,
    PrimaryButton,
    VIcon,
    VLink
  },
  data() {
    return {
      tosAccepted: false
    };
  },
  methods: {
    async confirm() {
      if (!this.tosAccepted) {
        return;
      }
      this.loading = true;
      await axios
        .post('user/accept-tc')
        .then(() => {
          this.$store.dispatch('Auth/getMe');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toggleTosAccept() {
      this.tosAccepted = !this.tosAccepted;
    }
  }
};
</script>
