<template>
  <div class="spacer"></div>
</template>

<script>
/**
 * Used in flex containers to take up any unused space.
 * @displayName V Spacer
 */
export default {
  name: 'VSpacer'
};
</script>

<style lang="scss" scoped>
.spacer {
  flex: 1;
}
</style>
