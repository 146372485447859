<template>
  <m-group align="center">
    <icon-button
      :disabled="isPreviousButtonDisabled"
      test-id="date-back-btn"
      size="small"
      icon-size="20px"
      @click="$emit('prev')">
      chevron-left
    </icon-button>
    <single-date-picker
      test-id="slot-picker-date-picker"
      icon-activated
      :min-date="minDate"
      :max-date="maxDate"
      :value="value"
      @input="updateVal"></single-date-picker>
    <icon-button
      :disabled="isNextButtonDisabled"
      test-id="date-forward-btn"
      size="small"
      icon-size="20px"
      @click="$emit('next')">
      chevron-right
    </icon-button>
  </m-group>
</template>

<script>
import { defineComponent } from 'vue';
import { IconButton, SingleDatePicker } from '@/components';

export default defineComponent({
  components: {
    SingleDatePicker,
    IconButton
  },
  props: {
    /**
     * @model
     */
    value: {
      type: String,
      required: false,
      default: null
    },
    isPreviousButtonDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isNextButtonDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    minDate: {
      type: String,
      required: false,
      default: null
    },
    maxDate: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {
    updateVal(val) {
      this.$emit('input', val);
    }
  }
});
</script>
