var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("internal-page", {
    attrs: { "allow-overflow": "" },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("page-header", {
              key: _vm.renderKey,
              attrs: { "has-back-button": "", title: "Appointment details" },
              scopedSlots: _vm._u(
                [
                  _vm.shouldRenderPage
                    ? {
                        key: "header-actions",
                        fn: function () {
                          return [
                            _vm.shouldDisplayManagementActions
                              ? [
                                  _c(
                                    "m-group",
                                    {
                                      staticClass: "display-tablet flex",
                                      attrs: {
                                        justify: "flex-end",
                                        gap: "spacing-2",
                                      },
                                    },
                                    [
                                      _c(
                                        "icon-button",
                                        {
                                          attrs: {
                                            disabled: _vm.isCancelDisabled,
                                            "test-id": "mobile-cancel-btn",
                                            "background-color":
                                              "color-danger-20",
                                            "icon-size": "20px",
                                            "icon-color": "color-danger-100",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.dialogs.cancelAppointment = true
                                            },
                                          },
                                        },
                                        [_vm._v(" close ")]
                                      ),
                                      _c(
                                        "icon-button",
                                        {
                                          attrs: {
                                            disabled: _vm.isRescheduleDisabled,
                                            "test-id": "mobile-reschedule-btn",
                                            "icon-size": "20px",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.dialogs.rescheduleAppointment = true
                                            },
                                          },
                                        },
                                        [_vm._v(" calendar-blank-outline ")]
                                      ),
                                      _c(
                                        "icon-button",
                                        {
                                          attrs: {
                                            disabled: _vm.isEditDisabled,
                                            "test-id": "mobile-edit-btn",
                                            "icon-size": "20px",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.dialogs.editAppointment = true
                                            },
                                          },
                                        },
                                        [_vm._v(" pencil ")]
                                      ),
                                      _vm.shouldRenderPage
                                        ? _c("print-appointment-button", {
                                            attrs: {
                                              "extended-appointment":
                                                _vm.extendedAppointment,
                                              warehouse: _vm.warehouse,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "trigger",
                                                  fn: function ({
                                                    openPrintTab,
                                                  }) {
                                                    return [
                                                      _c(
                                                        "icon-button",
                                                        {
                                                          attrs: {
                                                            "test-id":
                                                              "print-icon",
                                                            "icon-size": "20px",
                                                          },
                                                          on: {
                                                            click: openPrintTab,
                                                          },
                                                        },
                                                        [_vm._v(" printer ")]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3992667897
                                            ),
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "m-group",
                                    {
                                      staticClass: "display-desktop flex",
                                      attrs: {
                                        justify: "flex-end",
                                        gap: "spacing-2",
                                      },
                                    },
                                    [
                                      _vm.extendedAppointment.canBeCancelled()
                                        ? _c(
                                            "tertiary-button",
                                            {
                                              attrs: {
                                                disabled: _vm.isCancelDisabled,
                                                "test-id":
                                                  "appointment-details-cancel-btn",
                                                "leading-icon": "close",
                                                color: "color-danger-100",
                                                title: _vm.carrierLeadTimeRules,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.dialogs.cancelAppointment = true
                                                },
                                              },
                                            },
                                            [_vm._v(" Cancel ")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "tertiary-button",
                                        {
                                          attrs: {
                                            disabled: _vm.isRescheduleDisabled,
                                            "test-id":
                                              "appointment-details-reschedule-btn",
                                            title: _vm.carrierLeadTimeRules,
                                            "leading-icon":
                                              "calendar-blank-outline",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.dialogs.rescheduleAppointment = true
                                            },
                                          },
                                        },
                                        [_vm._v(" Reschedule ")]
                                      ),
                                      _c(
                                        "tertiary-button",
                                        {
                                          attrs: {
                                            disabled: _vm.isEditDisabled,
                                            "test-id":
                                              "appointment-details-edit-btn",
                                            "leading-icon": "pencil",
                                            title: _vm.carrierLeadTimeRules,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.dialogs.editAppointment = true
                                            },
                                          },
                                        },
                                        [_vm._v(" Edit details ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            _vm.isPublic && _vm.shouldRenderPage
                              ? _c(
                                  "tertiary-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.allowCarrierScheduling,
                                        expression: "allowCarrierScheduling",
                                      },
                                    ],
                                    attrs: {
                                      "test-id": "manage-appointment-btn",
                                      "leading-icon": "lock",
                                    },
                                    on: { click: _vm.manageAppointmentLogin },
                                  },
                                  [_vm._v(" Manage appointment ")]
                                )
                              : _vm._e(),
                            _vm.shouldRenderPage
                              ? _c("print-appointment-button", {
                                  staticClass: "display-desktop",
                                  attrs: {
                                    "extended-appointment":
                                      _vm.extendedAppointment,
                                    warehouse: _vm.warehouse,
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            !_vm.isLoading
              ? [
                  _vm.shouldRenderPage
                    ? [
                        _c("sidebar-cards", {
                          attrs: {
                            "main-content-spacing": "none",
                            "put-content-outside-of-card": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "sidebar-content",
                                fn: function () {
                                  return [
                                    _c(
                                      "m-stack",
                                      {
                                        staticClass: "appointment-summary",
                                        attrs: { gap: "spacing-6" },
                                      },
                                      [
                                        _c(
                                          "m-stack",
                                          { attrs: { gap: "none" } },
                                          [
                                            _c(
                                              "m-text",
                                              {
                                                attrs: {
                                                  variant: "heading-md-bold",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.extendedAppointment.getReadableDate()
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "m-text",
                                              {
                                                attrs: {
                                                  variant: "heading-md-bold",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.extendedAppointment.getReadableTimes()
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "vuetify-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                          }) {
                                                            return [
                                                              _vm
                                                                .extendedAppointment
                                                                .isInDSTChange
                                                                ? _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "ml-2",
                                                                        attrs: {
                                                                          size: "20px",
                                                                        },
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        " mdi-sun-clock "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      4188252792
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Includes a Daylight Savings time shift"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("field-value", {
                                          attrs: {
                                            label: _vm.$refNumSettings(
                                              _vm.extendedAppointment.warehouse
                                            ).displayName,
                                            value:
                                              _vm.extendedAppointment
                                                ?.refNumber,
                                            "copy-content":
                                              _vm.extendedAppointment
                                                ?.refNumber,
                                          },
                                        }),
                                        _c("field-value", {
                                          attrs: {
                                            label: "Confirmation number",
                                            value:
                                              _vm.extendedAppointment
                                                ?.confirmationNumber,
                                            "copy-content":
                                              _vm.extendedAppointment
                                                ?.confirmationNumber,
                                          },
                                        }),
                                        _c("field-value", {
                                          attrs: {
                                            label: "Loadtype",
                                            value: _vm.loadtype.name,
                                          },
                                        }),
                                        _c("field-value", {
                                          attrs: {
                                            label: "Dock",
                                            value:
                                              _vm.extendedAppointment.getParentDock()
                                                ?.name,
                                          },
                                        }),
                                        _c("field-value", {
                                          attrs: {
                                            label: "Appointment notes",
                                            value:
                                              _vm.extendedAppointment.notes,
                                          },
                                        }),
                                        _c("field-value", {
                                          attrs: {
                                            label: "Appointment ID",
                                            value: _vm.extendedAppointment.id,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "main-content",
                                fn: function () {
                                  return [
                                    _c(
                                      "m-stack",
                                      [
                                        _c(
                                          "m-card",
                                          { attrs: { align: "center" } },
                                          [
                                            _c(
                                              "m-card-body",
                                              { key: _vm.renderKey },
                                              [
                                                _c("status-timeline-wrapper", {
                                                  attrs: {
                                                    "extended-appointment":
                                                      _vm.extendedAppointment,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.isStatusAllowedForETAUpdate(
                                          _vm.extendedAppointment.status
                                        ) &&
                                        _vm.extendedAppointment.isWithin24Hours() &&
                                        !_vm.isPublic
                                          ? _c(
                                              "m-card",
                                              { attrs: { align: "center" } },
                                              [
                                                _c(
                                                  "m-card-body",
                                                  { staticClass: "p-y-2" },
                                                  [
                                                    _c(
                                                      "m-group",
                                                      {
                                                        attrs: {
                                                          align: "center",
                                                          justify:
                                                            "space-between",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "m-text",
                                                          {
                                                            attrs: {
                                                              variant:
                                                                "body-md-bold",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Let the warehouse know your ETA"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "secondary-button",
                                                          {
                                                            attrs: {
                                                              "test-id":
                                                                "provide-eta-btn",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.dialogs.createEta = true
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Provide ETA "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.isStatusAllowedForETAUpdate(
                                          _vm.extendedAppointment.status
                                        ) &&
                                        !_vm.extendedAppointment.isWithin24Hours() &&
                                        !_vm.extendedAppointment.isPastAppointment() &&
                                        !_vm.isPublic
                                          ? _c(
                                              "m-card",
                                              { attrs: { align: "center" } },
                                              [
                                                _c(
                                                  "m-card-body",
                                                  { staticClass: "p-y-2" },
                                                  [
                                                    _c(
                                                      "m-group",
                                                      {
                                                        attrs: {
                                                          align: "center",
                                                          justify:
                                                            "space-between",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "m-text",
                                                          {
                                                            attrs: {
                                                              variant:
                                                                "body-md",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " ETA will be available within 24 hours of the appointment time "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.isPublic
                                          ? _c(
                                              "m-card",
                                              [
                                                _c("m-card-title", [
                                                  _vm._v("Carrier details"),
                                                ]),
                                                _c(
                                                  "m-card-body",
                                                  [
                                                    _c(
                                                      "m-group",
                                                      {
                                                        attrs: {
                                                          align: "flex-start",
                                                          justify: "flex-start",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "m-stack",
                                                          {
                                                            staticClass:
                                                              "m-r-20",
                                                            attrs: {
                                                              gap: "none",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "m-text",
                                                              {
                                                                attrs: {
                                                                  variant:
                                                                    "body-md-bold",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .extendedAppointment
                                                                        .user
                                                                        .firstName
                                                                    ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .extendedAppointment
                                                                        .user
                                                                        .lastName
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "m-text",
                                                              {
                                                                attrs: {
                                                                  variant:
                                                                    "body-md",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .extendedAppointment
                                                                        .user
                                                                        .company
                                                                        .name
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "m-text",
                                                              {
                                                                attrs: {
                                                                  variant:
                                                                    "body-md",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " SCAC: " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .extendedAppointment
                                                                        .user
                                                                        .company
                                                                        .scac ??
                                                                        "----"
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "warehouse-contact",
                                                          {
                                                            attrs: {
                                                              warehouse:
                                                                _vm
                                                                  .extendedAppointment
                                                                  .user,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "m-card",
                                          [
                                            _c("m-card-title", [
                                              _vm._v("Warehouse details"),
                                            ]),
                                            _c(
                                              "m-card-body",
                                              [
                                                _c(
                                                  "m-group",
                                                  {
                                                    staticClass:
                                                      "warehouse-details-card",
                                                    attrs: {
                                                      align: "flex-start",
                                                      justify: "space-between",
                                                    },
                                                  },
                                                  [
                                                    _c("warehouse-header", {
                                                      attrs: {
                                                        warehouse:
                                                          _vm.warehouse,
                                                        "link-to-warehouse": "",
                                                      },
                                                    }),
                                                    _c("warehouse-address", {
                                                      attrs: {
                                                        warehouse:
                                                          _vm.warehouse,
                                                      },
                                                    }),
                                                    _c("warehouse-contact", {
                                                      attrs: {
                                                        warehouse:
                                                          _vm.warehouse,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "m-accordion",
                                          [
                                            _c("m-accordion-title", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "p-y-2",
                                                  attrs: {
                                                    "data-testid":
                                                      "booking-details-accordion-trigger",
                                                  },
                                                },
                                                [_vm._v(" Booking details ")]
                                              ),
                                            ]),
                                            _c(
                                              "m-accordion-content",
                                              [
                                                _c("field-value", {
                                                  staticClass: "m-b-4",
                                                  attrs: {
                                                    label: _vm.$refNumSettings(
                                                      _vm.extendedAppointment
                                                        .warehouse
                                                    ).displayName,
                                                    value:
                                                      _vm.extendedAppointment
                                                        ?.refNumber,
                                                    "copy-content":
                                                      _vm.extendedAppointment
                                                        ?.refNumber,
                                                  },
                                                }),
                                                _c(
                                                  "m-grid",
                                                  { staticClass: "value-grid" },
                                                  _vm._l(
                                                    _vm.customFields,
                                                    function (customField) {
                                                      return _c(
                                                        "custom-field-value",
                                                        {
                                                          key: _vm.getCustomFieldKey(
                                                            customField
                                                          ),
                                                          attrs: {
                                                            "custom-field":
                                                              customField,
                                                            warehouse:
                                                              _vm
                                                                .extendedAppointment
                                                                .warehouse,
                                                          },
                                                        }
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        !_vm.isPublic
                                          ? _c(
                                              "div",
                                              [
                                                _vm._l(
                                                  _vm.gateManagementTriggers,
                                                  function (trigger) {
                                                    return [
                                                      !trigger.timestampToDisplay
                                                        ? _c(
                                                            "m-accordion",
                                                            {
                                                              key: trigger.id,
                                                              class: {
                                                                "is-disabled":
                                                                  trigger.timestampToDisplay,
                                                              },
                                                              attrs: {
                                                                readonly: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "m-accordion-title",
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "p-y-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            trigger
                                                                              .flow
                                                                              ?.name ||
                                                                              _vm
                                                                                .novaCore
                                                                                .CustomFormsFeaturesData[
                                                                                trigger
                                                                                  .feature
                                                                              ]
                                                                                .title
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "m-accordion-content",
                                                                [
                                                                  _c(
                                                                    "custom-forms-data",
                                                                    {
                                                                      attrs: {
                                                                        "display-no-data-message":
                                                                          !_vm
                                                                            .extendedAppointment
                                                                            .assetVisit
                                                                            ?.id ||
                                                                          trigger.feature ===
                                                                            _vm
                                                                              .novaCore
                                                                              .CustomFormsFeatures
                                                                              .CheckOut,
                                                                        trigger:
                                                                          trigger,
                                                                        "object-id":
                                                                          _vm
                                                                            .extendedAppointment
                                                                            .id,
                                                                        timezone:
                                                                          _vm
                                                                            .extendedAppointment
                                                                            .warehouse
                                                                            .timezone,
                                                                        "military-time-enabled":
                                                                          _vm.isMilitaryTimeEnabled,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _c(
                                                            "m-card",
                                                            {
                                                              key: `${trigger.id}-else`,
                                                            },
                                                            [
                                                              _c(
                                                                "m-card-title",
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex justify-space-between",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "m-text",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              variant:
                                                                                "body-md-bold",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                trigger
                                                                                  .flow
                                                                                  ?.name ||
                                                                                  _vm
                                                                                    .novaCore
                                                                                    .CustomFormsFeaturesData[
                                                                                    trigger
                                                                                      .feature
                                                                                  ]
                                                                                    .title
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "m-text",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              variant:
                                                                                "body-sm",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " Captured at " +
                                                                              _vm._s(
                                                                                trigger.timestampToDisplay
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                    ]
                                                  }
                                                ),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2517994168
                          ),
                        }),
                      ]
                    : _vm.appointmentNotFound
                    ? _c("appointment-not-found")
                    : !_vm.shouldRenderPage
                    ? _c("image-message", {
                        attrs: {
                          "image-path": _vm.notFoundSvg,
                          "image-alt": "Two men loading a truck",
                          header: "Could not load the page",
                          message:
                            "Something went wrong and the page could not be loaded.",
                        },
                      })
                    : _vm._e(),
                ]
              : [
                  _c("v-loader", { attrs: { "is-loading": _vm.isLoading } }, [
                    _vm._v("Loading appointment"),
                  ]),
                ],
            _vm.shouldDisplayManagementActions && _vm.shouldRenderPage
              ? [
                  _vm.shouldRenderPage
                    ? _c("edit-appointment-dialog", {
                        attrs: {
                          "extended-appointment": _vm.extendedAppointment,
                        },
                        on: { "update:appointment": _vm.updateAppointment },
                        model: {
                          value: _vm.dialogs.editAppointment,
                          callback: function ($$v) {
                            _vm.$set(_vm.dialogs, "editAppointment", $$v)
                          },
                          expression: "dialogs.editAppointment",
                        },
                      })
                    : _vm._e(),
                  _vm.shouldRenderPage && _vm.dialogs.rescheduleAppointment
                    ? _c("reschedule-appointment-dialog", {
                        attrs: {
                          "extended-appointment": _vm.extendedAppointment,
                        },
                        on: { "update:appointment": _vm.updateAppointment },
                        model: {
                          value: _vm.dialogs.rescheduleAppointment,
                          callback: function ($$v) {
                            _vm.$set(_vm.dialogs, "rescheduleAppointment", $$v)
                          },
                          expression: "dialogs.rescheduleAppointment",
                        },
                      })
                    : _vm._e(),
                  _vm.shouldRenderPage
                    ? _c("cancel-appointment-dialog", {
                        attrs: { appointment: _vm.extendedAppointment },
                        on: { "update:appointment": _vm.updateAppointment },
                        model: {
                          value: _vm.dialogs.cancelAppointment,
                          callback: function ($$v) {
                            _vm.$set(_vm.dialogs, "cancelAppointment", $$v)
                          },
                          expression: "dialogs.cancelAppointment",
                        },
                      })
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.shouldRenderPage &&
            _vm.isStatusAllowedForETAUpdate(_vm.extendedAppointment.status)
              ? _c("create-eta-dialog", {
                  attrs: { "extended-appointment": _vm.extendedAppointment },
                  on: { "update:appointment": _vm.updateAppointment },
                  model: {
                    value: _vm.dialogs.createEta,
                    callback: function ($$v) {
                      _vm.$set(_vm.dialogs, "createEta", $$v)
                    },
                    expression: "dialogs.createEta",
                  },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }