var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.fieldComponent,
    _vm._b(
      {
        tag: "component",
        attrs: {
          options: _vm.fieldProps.options ?? [],
          "field-name": _vm.fieldName,
          "validation-iterator": _vm.validationIterator,
          "external-error": _vm.fieldProps.error,
          "should-validate": _vm.shouldValidate,
        },
        on: { "update:error": _vm.emitHasErrors },
        model: {
          value: _vm.fieldVal,
          callback: function ($$v) {
            _vm.fieldVal = $$v
          },
          expression: "fieldVal",
        },
      },
      "component",
      _vm.fieldProps,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }