var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("field-value", {
    attrs: {
      "has-separate-label": "",
      label: "Address",
      "copy-message": "Address copied to clipboard",
      "copy-content": `${
        _vm.warehouse.street
      } ${_vm.util.getLargerAreaOfAddress(_vm.warehouse)}`,
    },
    scopedSlots: _vm._u([
      {
        key: "value",
        fn: function () {
          return [
            _c(
              "m-stack",
              { attrs: { gap: "spacing-1", align: "flex-start" } },
              [
                _c("m-text", { attrs: { variant: "body-md" } }, [
                  _vm._v(_vm._s(_vm.warehouse.street)),
                ]),
                _c("m-text", { attrs: { variant: "body-md" } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.util.getLargerAreaOfAddress(_vm.warehouse)) +
                      " "
                  ),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }