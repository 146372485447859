<template>
  <div :class="{ 'internal-page': $route.meta.requiresAuth }">
    <v-app>
      <div id="app-wrapper" :class="$route.meta.pageClass">
        <div id="app-notifications">
          <app-notification
            position="bottom right"
            class="m-b-4 m-r-4"
            group="bottom-notifications"
            :max="5"></app-notification>
          <app-notification
            position="top right"
            class="m-t-4 m-r-4"
            group="top-notifications"
            :max="5"></app-notification>
        </div>
        <div id="app-body">
          <m-sidebar
            v-if="!$route.meta.hideSidebar"
            gap="none"
            min-content-width="400px"
            background-color="color-text-primary-inverted">
            <m-box slot="sidebar" id="main-sidebar" :class="{ open: $isSidebarOpen }">
              <sidebar-menu :is-disabled="$route.meta.disableSidebar"></sidebar-menu>
            </m-box>

            <m-box slot="content" id="main-content">
              <router-view></router-view>
            </m-box>
          </m-sidebar>
          <router-view v-else></router-view>
        </div>
      </div>
      <dialog id="date-picker-dialog"></dialog>
      <terms-and-conditions-dialog v-model="isConfirmationNeeded"></terms-and-conditions-dialog>
    </v-app>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { VApp } from 'vuetify/lib/components/VApp';
import { AppNotification } from '@/components';
import { toKebabCase } from '@satellite/../nova/core';
import SidebarMenu from '@/components/elements/layout/SidebarMenu.vue';
import TermsAndConditionsDialog from '@/components/elements/auth/dialogs/TermsAndConditionsDialog.vue';
import { DateTime } from 'luxon';

export default {
  name: 'App',
  components: { TermsAndConditionsDialog, VApp, AppNotification, SidebarMenu },
  computed: {
    ...mapState('navigation', {
      pageTitle: state => state.pageTitle
    }),
    ...mapState('Auth', {
      appLoaded: state => state.appLoaded
    }),
    isConfirmationNeeded() {
      const tcLastUpdate = DateTime.fromISO(process.env.VUE_APP_TC_LAST_UPDATE);
      return (
        !!this.$me &&
        !this.isInternalUser &&
        (!this.$me.tcConfirmedAt || DateTime.fromISO(this.$me.tcConfirmedAt) < tcLastUpdate)
      );
    }
  },
  data() {
    return {
      orgLoaded: false
    };
  },
  methods: {
    cleanUpMrPreview() {
      if (this.$isMrPreview) {
        sessionStorage.clear();
      }
    },
    getPageClass(pageTitle) {
      if (!pageTitle) {
        return '';
      }
      return `${toKebabCase(pageTitle)}-page`;
    },
    // TODO: Zendesk methods - find a way to abstract for all UIs?
    _enableLauncherBtn() {
      // Enable the launcher button, initially hidden, because it's the only way to minimize the chat
      const iframeLauncher = document.querySelector('iframe#launcher');
      if (iframeLauncher) {
        iframeLauncher.style.display = 'block';
      }
    },
    _openChatWindow() {
      if (window.zE && window.zE?.activate) {
        window.zE.activate();
        // Make sure the messenger is enabled
        // once we disabled it on some pages (TV Mode)
        this._enableLauncherBtn();
        this.$nextTick(() => {
          window.zE('messenger', 'show');
        });
      } else if (typeof zE === 'function') {
        this._enableLauncherBtn();
        this.$nextTick(() => {
          window.zE('messenger', 'show');
          window.zE('messenger', 'open');
        });
      } else {
        this.notify(
          'We are having trouble connecting to Support Chat. Please try again later.',
          'error'
        );
      }
    },
    _setupZendeskMessenger() {
      const isUsingMessengerApi = typeof window.zE === 'function';
      if (isUsingMessengerApi && Boolean(this.$route?.meta?.hideSidebar) === false) {
        window.zE('messenger', 'close');
        window.zE('messenger', 'hide');
        window.zE('messenger:on', 'unreadMessages', number => {
          this.unreadMsgCount = number;
        });
        window.zE('messenger:on', 'close', () => {
          window.zE('messenger', 'hide');
          this.isChatWindowOpen = false;
        });
      } else if (isUsingMessengerApi) {
        window.zE('messenger', 'show');
      }
    },
    _bindZendeskLoadListener() {
      if (typeof window.zE !== 'undefined') {
        this._setupZendeskMessenger();
      } else {
        window.addEventListener(
          'load',
          () => {
            this._setupZendeskMessenger();
          },
          {
            once: true
          }
        );
      }
    }
  },
  mounted() {
    this.$eventHub.$on('open-chat-window', this._openChatWindow);

    // Avoid edge issues
    if (this.$isMrPreview) {
      window.addEventListener('beforeunload', this.cleanUpMrPreview);
    }
  },
  watch: {
    '$route.meta'(newVal, oldVal) {
      if (oldVal?.title) {
        document.body.classList.remove(this.getPageClass(oldVal?.title));
      }
      if (newVal?.title) {
        const classes = [
          this.getPageClass(newVal?.title),
          !['registration', 'login'].includes(newVal?.title?.toLowerCase()) ? 'internal-page' : ''
        ];
        classes.forEach(cls => {
          if (cls) {
            document.body.classList.add(cls);
          }
        });
      }
    },
    $route() {
      this._bindZendeskLoadListener();
    }
  }
};
</script>

<style lang="scss">
@import '@/styles/main.scss';

// Disabled auto-zoom on inputs for mobile
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}
#main-content {
  padding: 0;
  height: 100%;
}
m-sidebar {
  height: 100%;
}
#main-sidebar {
  height: 100%;
  border-radius: 0;
  width: $internalSidebarWidth;

  @media (max-width: $tabletBreakpoint) {
    width: 100%;
    padding: 0;
  }

  @media (max-width: $tabletBreakpoint) {
    background-color: $m-color-primary-100;
    height: 100%;
    position: absolute;
    left: -150%;
    top: 0;
    width: 100%;
    z-index: 100;
    transition: left 0.4s ease-in-out;

    &.open {
      left: 0;
    }
  }
}

#date-picker-dialog {
  border: none;
  padding: 0;
  margin: 0;
  position: absolute;
  min-width: auto !important;
  overflow: hidden;
  width: 290px;
  height: 290px;

  .v-menu__content {
    position: static;
    min-width: auto !important;
    transition: none !important;
  }
}
</style>
