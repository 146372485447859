import settingsMixinBase from '@satellite/components/mixins/settingsMixinBase';

export default {
  computed: {
    ...settingsMixinBase.computed
  },
  methods: {
    ...settingsMixinBase.methods
  }
};
