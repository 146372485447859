var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    _vm._b(
      {
        staticClass: "sidebar-button",
        class: { active: _vm.isActive },
        style: `background-color:${_vm.computedColor};`,
        attrs: { "data-testid": _vm.testId },
        on: { click: _vm.handleClick },
      },
      "button",
      [_vm.$attrs, _vm.$props],
      false
    ),
    [
      _vm.leadingIcon
        ? _c(
            "v-icon",
            {
              staticClass: "m-r-2",
              attrs: { size: "20px", color: _vm.iconColor },
            },
            [_vm._v(" " + _vm._s(_vm.leadingIcon) + " ")]
          )
        : _vm._e(),
      _vm._t("default"),
      _vm.trailingIcon
        ? _c(
            "v-icon",
            {
              staticClass: "m-l-2",
              attrs: { size: "20px", color: _vm.iconColor },
            },
            [_vm._v(" " + _vm._s(_vm.trailingIcon) + " ")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }