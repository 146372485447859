var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("dialog-base", {
    attrs: {
      value: _vm.value,
      size: "medium",
      "data-testid": "forgot-password-dlg",
    },
    on: { input: _vm.updateVal },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_vm._v("Forgot your password?")]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "m-stack",
              { attrs: { gap: "spacing-3" } },
              [
                _c("m-text", { attrs: { variant: "body-md-bold" } }, [
                  _vm._v(
                    " Enter the email address you have registered with Opendock "
                  ),
                ]),
                _c("m-text", { attrs: { variant: "body-md" } }, [
                  _vm._v(
                    " We will send you instructions on how to create a new password "
                  ),
                ]),
                _c("basic-form", {
                  ref: "form",
                  attrs: { fields: _vm.fields },
                  on: {
                    submit: _vm.submit,
                    "update:can-submit": _vm.setCanSubmit,
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c(
              "secondary-button",
              {
                attrs: { "test-id": "forgot-password-cancel-btn" },
                on: { click: _vm.close },
              },
              [_vm._v(" Cancel ")]
            ),
            _c(
              "primary-button",
              {
                attrs: {
                  disabled: !_vm.canSubmit,
                  "test-id": "forgot-password-confirm-btn",
                  "is-loading": _vm.isLoading,
                },
                on: { click: _vm.triggerSubmit },
              },
              [_vm._v(" Confirm ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }