var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "image-message",
    {
      attrs: {
        "image-path": _vm.notFoundSvg,
        "image-alt": "Two workers loading a truck",
      },
    },
    [
      _c("m-text", { attrs: { variant: "body-md-bold" } }, [
        _vm._v("We couldn't load this appointment"),
      ]),
      _c("m-text", { attrs: { variant: "body-sm" } }, [
        _vm._v(
          " This appointment doesn't exist anymore or is not associated with your account "
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }