<template>
  <span>
    <slot name="trigger" :openPrintTab="openPrintTab">
      <tertiary-button test-id="print-btn" leading-icon="printer" @click="openPrintTab">
        Print
      </tertiary-button>
    </slot>
    <printable-appointment-details :extended-appointment="extendedAppointment" />
  </span>
</template>

<script>
import { TertiaryButton } from '@/components';
import PrintableAppointmentDetails from '@/components/elements/appointment/PrintableAppointmentDetails.vue';
import { ExtendedAppointment } from '@/modules/appointments/ExtendedAppointment';
import { propValidatorHelper } from '@satellite/plugins/util';

/**
 * Activator that wraps the PrintableAppointmentDetails component
 */
export default {
  name: 'PrintAppointmentButton',
  components: { PrintableAppointmentDetails, TertiaryButton },
  props: {
    extendedAppointment: {
      type: ExtendedAppointment,
      required: true,
      validator(value) {
        return propValidatorHelper({
          value,
          isValid: value instanceof ExtendedAppointment,
          componentName: 'PrintAppointmentButton',
          propName: 'extendedAppointment',
          message: ''
        });
      }
    }
  },
  methods: {
    openPrintTab() {
      this.mixpanel.track(this.mixpanel.events.ACTION.PRINT_APPOINTMENT, {
        appointmentId: this.extendedAppointment.id
      });
      this.$nextTick(() => {
        let domClone = document.getElementById('appointment-details-printable').cloneNode(true);

        let $printContent = document.getElementById('print-content');
        if (!$printContent) {
          $printContent = document.createElement('section');
          $printContent.id = 'print-content';
          document.body.appendChild($printContent);
        }

        $printContent.innerHTML = '';
        $printContent.appendChild(domClone);
        window.print();
      });
    }
  }
};
</script>
