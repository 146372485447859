<template>
  <m-group
    gap="spacing-4"
    align="center"
    justify="space-between"
    class="big-number-container"
    padding-y="spacing-1">
    <m-box
      v-for="item in bigNumbers"
      :key="item.title"
      :class="`big-number-box ${item.title === selected ? 'selected' : ''}`"
      padding-x="spacing-3"
      @click="handleBoxClick(item)"
      padding-y="spacing-1">
      <m-text variant="body-lg-bold">{{ item.number }}</m-text>
      <br />
      <m-text>{{ item.title }}</m-text>
    </m-box>
  </m-group>
</template>

<script>
export default {
  name: 'BigNumberBoxes',
  props: {
    /**
     * Big numbers
     * [ { title: 'number title', number: 5 } ]
     */
    bigNumbers: [],
    selected: {
      type: String,
      required: true,
      default: null
    }
  },
  methods: {
    handleBoxClick(item) {
      if (this.selected === item.title) {
        this.$emit('deselected');
      } else {
        this.$emit('selected', item.title);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.big-number-box {
  cursor: pointer;
  // TODO ask sam how to get the right colors here
  border: solid 1px rgba(var(--m-color-border, 193, 206, 217), 1);
  background-color: #f8f9fb;
  width: 110px;
  &.selected {
    background-color: #cadcdd;
  }
}
</style>
