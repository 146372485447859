import { getQueryStringFromOptions, getResponse } from '@satellite/services/serviceUtilities';

/**
 * options: { fields: string[], joins: string[], includeMetaData: boolean }
 */

export default {
  RECENT_WAREHOUSES_KEY: 'recent_warehouses',
  RECENT_WAREHOUSES_MAX: 10,
  async getFavoriteWarehouses(params, options = {}) {
    const queryString = getQueryStringFromOptions(options);
    const response = await axios.get(`carrier/settings/favorite-warehouses?${queryString}`, {
      params
    });
    return getResponse(response, options);
  },
  async addFavoriteWarehouse(warehouseId, options = {}) {
    const queryString = getQueryStringFromOptions(options);
    const response = await axios.post(`carrier/settings/favorite-warehouses?${queryString}`, {
      warehouseId
    });
    return getResponse(response, options);
  },
  async removeFavoriteWarehouse(warehouseId, options = {}) {
    const queryString = getQueryStringFromOptions(options);
    const response = await axios.delete(`carrier/settings/favorite-warehouses?${queryString}`, {
      data: { warehouseId }
    });
    return getResponse(response, options);
  },
  setRecentWarehouse(warehouse) {
    const currentRecentWarehouses = this.getRecentWarehouses().filter(
      rwh => rwh.data.id !== warehouse.id
    );

    if (currentRecentWarehouses.length >= this.RECENT_WAREHOUSES_MAX) {
      currentRecentWarehouses.splice(currentRecentWarehouses.length - 1, 1);
    }

    currentRecentWarehouses.unshift({
      data: warehouse,
      timestamp: new Date().getTime()
    });

    localStorage.setItem(this.RECENT_WAREHOUSES_KEY, JSON.stringify(currentRecentWarehouses));

    return currentRecentWarehouses;
  },
  getRecentWarehouses() {
    return JSON.parse(localStorage.getItem(this.RECENT_WAREHOUSES_KEY) || '[]');
  },
  async getAppointmentCounts(params, options = {}) {
    const queryString = getQueryStringFromOptions(options);
    const response = await axios.get(
      `/metrics/counts/appointment-count-for-carrier/status?${queryString}`,
      {
        params
      }
    );
    return getResponse(response, options);
  },
  async resendVerificationEmail(params, options = {}, requestConfig = {}) {
    const response = await axios.post(`/carrier/verification-email`, params, requestConfig);

    return getResponse(response, options);
  }
};
