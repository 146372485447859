var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-group",
    { attrs: { justify: "space-between" } },
    [
      _vm.prevButtonAttrs.hidden ? _c("v-spacer") : _vm._e(),
      !_vm.prevButtonAttrs.hidden
        ? _c(
            "secondary-button",
            {
              attrs: {
                "test-id": "step-prev-btn",
                "leading-icon": "chevron-left",
                "icon-size": "16px",
                disabled: _vm.prevButtonAttrs.disabled,
              },
              on: { click: _vm.handlePrevClick },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.prevButtonAttrs.label ?? "Previous") + " "
              ),
            ]
          )
        : _vm._e(),
      !_vm.nextButtonAttrs.hidden
        ? _c(
            "secondary-button",
            {
              attrs: {
                "test-id": "step-next-btn",
                "trailing-icon": "chevron-right",
                "icon-size": "16px",
                disabled: _vm.nextButtonAttrs.disabled,
              },
              on: { click: _vm.handleNextClick },
            },
            [_vm._v(" " + _vm._s(_vm.prevButtonAttrs.label ?? "Next") + " ")]
          )
        : _vm._e(),
      _vm.nextButtonAttrs.hidden ? _c("v-spacer") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }