var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("dialog-base", {
    attrs: {
      "data-testid": "appointment-submission-dialog",
      value: _vm.value,
      "should-display-header": !_vm.isLoading,
      "has-custom-close-logic": "",
      size: "small",
    },
    on: {
      input: function ($event) {
        _vm.value = !_vm.value
      },
      "custom-close": function ($event) {
        return _vm.$router.push({ name: "warehouse.select" })
      },
    },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "m-group",
              { attrs: { gap: "spacing-1" } },
              [
                _c(
                  "v-icon",
                  { attrs: { size: "25px", color: _vm.headerColor } },
                  [_vm._v(_vm._s(_vm.headerIcon))]
                ),
                _c(
                  "m-text",
                  {
                    attrs: {
                      variant: "heading-md-bold",
                      color: _vm.headerColor,
                    },
                  },
                  [
                    _vm._v(
                      " Appointment " + _vm._s(_vm.appointmentAction) + " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _vm.isLoading
              ? _c(
                  "m-stack",
                  { staticClass: "p-y-8" },
                  [
                    _c("v-loader", { attrs: { "is-loading": _vm.isLoading } }),
                    _c(
                      "m-stack",
                      { attrs: { align: "center", gap: "none" } },
                      [
                        _c("m-text", { attrs: { variant: "body-md-bold" } }, [
                          _vm._v("We're sending your request"),
                        ]),
                        _c("m-text", { attrs: { variant: "body-md" } }, [
                          _vm._v("This won't take long. Thank you for waiting"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c(
                  "m-stack",
                  [
                    _vm.isRequested
                      ? _c("m-text", { attrs: { variant: "body-md" } }, [
                          _vm._v(
                            " Once the warehouse confirms your appointment, its status will change to “Scheduled” "
                          ),
                        ])
                      : _vm._e(),
                    _c("m-text", { attrs: { variant: "heading-sm-bold" } }, [
                      _vm._v(_vm._s(_vm.appointmentDateTime)),
                    ]),
                    _c("warehouse-header", {
                      attrs: { warehouse: _vm.warehouse },
                    }),
                    _c("field-value", {
                      attrs: {
                        label: "Confirmation number",
                        value: _vm.appointment?.confirmationNumber,
                        "copy-content": _vm.appointment?.confirmationNumber,
                      },
                    }),
                    _c("warehouse-address", {
                      attrs: { warehouse: _vm.warehouse },
                    }),
                  ],
                  1
                ),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c(
              "m-group",
              { staticClass: "flex-nowrap p-t-4", attrs: { gap: "spacing-2" } },
              [
                !_vm.isLoading
                  ? [
                      _c(
                        "secondary-button",
                        {
                          attrs: { "test-id": "book-another-btn" },
                          on: {
                            click: function ($event) {
                              return _vm.$router.replace({
                                name: "warehouse.details",
                                params: { warehouseId: _vm.warehouse.id },
                              })
                            },
                          },
                        },
                        [_vm._v(" Book another appointment ")]
                      ),
                      _c(
                        "primary-button",
                        {
                          attrs: { "test-id": "see-details-btn" },
                          on: {
                            click: function ($event) {
                              return _vm.$router.replace({
                                name: "appointment.details",
                                params: { appointmentId: _vm.appointment.id },
                              })
                            },
                          },
                        },
                        [_vm._v(" See all details ")]
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }