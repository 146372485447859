var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: _vm.renderKey,
      staticClass: "password-form",
      staticStyle: { "background-color": "white" },
    },
    [
      _c(
        "text-field",
        _vm._b(
          {
            attrs: {
              value: _vm.value,
              label: _vm.$attrs.label ?? "Password",
              type: _vm.inputType,
              required: _vm.$attrs.required ?? true,
              "test-id": _vm.testId,
              autocomplete: "new-password",
              rules: _vm.computedRules,
              "external-error": _vm.$attrs.error,
              "validate-on-blur": _vm.validateOnBlur,
              placeholder:
                _vm.$attrs.placeholder ??
                "Create a password following the requirements below",
            },
            on: {
              input: _vm.updateVal,
              "update:error": (hasError) => _vm.$emit("update:error", hasError),
            },
            scopedSlots: _vm._u([
              {
                key: "trailing",
                fn: function () {
                  return [
                    _c(
                      "icon-button",
                      {
                        attrs: {
                          variant: "icon",
                          size: "small",
                          "icon-size": "large",
                          "test-id": `${_vm.testId}-visibility-btn`,
                        },
                        on: { click: _vm.toggleInputType },
                      },
                      [_vm._v(" " + _vm._s(_vm.inputIcon) + " ")]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "text-field",
          [_vm.$attrs],
          false
        )
      ),
      _vm.shouldShowRequirements
        ? [
            _c(
              "m-group",
              {
                staticClass: "step-indicator-group",
                attrs: {
                  gap: "spacing-1",
                  "data-testid": `${_vm.testId}-step-indicator-group`,
                },
              },
              _vm._l(
                _vm.filteredPasswordValidationItems,
                function (validationItem, index) {
                  return _c("div", {
                    key: `requirement-indicator-${index}`,
                    staticClass: "step-indicator",
                    class: {
                      "requirement-met": index < _vm.numberOfRequirementsMet,
                      "requirements-have-been-met": _vm.haveRequirementsBeenMet,
                    },
                    attrs: {
                      "data-testid": `${_vm.testId}-step-indicator-${index}`,
                    },
                  })
                }
              ),
              0
            ),
            _c(
              "m-stack",
              {
                staticClass: "mt-3",
                attrs: {
                  gap: "none",
                  "data-testid": `${_vm.testId}-checkbox-group`,
                },
              },
              _vm._l(
                _vm.filteredPasswordValidationItems,
                function (validationItem, index) {
                  return _c(
                    "div",
                    { key: `requirement-box-${index}` },
                    [
                      _c(
                        "m-checkbox",
                        {
                          attrs: {
                            id: `${validationItem.error}-validation-checkbox`,
                            name: "requirements",
                            "data-testid": `${validationItem.error}-validation-checkbox`,
                            checked: validationItem.passed,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleClick($event)
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "ml-1" }, [
                            _vm._v(_vm._s(validationItem.message)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                }
              ),
              0
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }