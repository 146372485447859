export default {
  MODULE: {
    APPOINTMENT: {
      ...makeModuleEvents('Appointment'),
      RESCHEDULED: 'Rescheduled: Appointment',
      ETA_CHANGED: 'Updated: ETA'
    }
  },
  ACTION: {
    BOOK_APPOINTMENT_CLICKED: makeActionEvent('Book Appointment Button Clicked'),
    LOAD_TYPE_SELECTED: makeActionEvent('Load Type Selected'),
    DATE_TIME_SELECTED: makeActionEvent('Date & Time Selected'),
    APPOINTMENT_CREATED: makeActionEvent('Appointment Created'),
    COPIED_APPOINTMENT_ID: makeActionEvent('Appointment ID Copied'),
    ACTIVATE_ACCOUNT: makeActionEvent('Activate Account'),
    LOGGED_IN: makeActionEvent('Logged In'),
    SELECT_WAREHOUSE: makeActionEvent('Select Warehouse'),
    SEARCH_WAREHOUSE: makeActionEvent('Search Warehouse'),
    CHANGE_WAREHOUSE_SEARCH_ORDER: makeActionEvent('Change Warehouse Search Order'),
    FILTER_WAREHOUSE_SEARCH: makeActionEvent('Filter Warehouse Search'),
    TOGGLE_WAREHOUSE_FAVORITE: makeActionEvent('Toggle Warehouse Favorites'),
    BOOK_WAREHOUSE_ACTION: makeActionEvent('Click Button to Book Warehouse'),
    ADD_WAREHOUSE_TO_FAVORITES: makeActionEvent('Add Warehouse to Favorites'),
    DOCK_SELECTED: makeActionEvent('Dock Selected'),
    EDIT_APPOINTMENT_DETAILS: makeActionEvent('Edit Appointment Details'),
    RESCHEDULE_APPOINTMENT: makeActionEvent('Reschedule Appointment'),
    PRINT_APPOINTMENT: makeActionEvent('Print Appointment'),
    CANCEL_APPOINTMENT: makeActionEvent('Cancel Appointment'),
    PROVIDE_ETA: makeActionEvent('Provide ETA'),
    EXPORT_APPOINTMENTS: makeActionEvent('Export Appointments'),
    FILTER_APPOINTMENTS_BY_STATUS: makeActionEvent('Filter Appointments by Status'),
    FILTER_APPOINTMENTS_BY_DATE: makeActionEvent('Filter Appointments by Date'),
    SEARCH_APPOINTMENTS: makeActionEvent('Search Appointments')
  },
  VIEW: {
    WAREHOUSE: {
      PAGE: makeViewEvent('Warehouse Page')
    },
    MY_APPOINTMENTS_PAGE: makeViewEvent('My Appointments Page'),
    REGISTRATION_PAGE: makeViewEvent('Registration Page'),
    ACTIVATION_EMAIL: makeViewEvent('Activation Email Sent'),
    LOGIN_PAGE: makeViewEvent('Sign-in Page'),
    BOOK_APPOINTMENT: makeViewEvent('Book Appointment Homepage'),
    WAREHOUSE_SEARCH_RESULTS: makeViewEvent('Warehouse Search Results'),
    WAREHOUSE_DETAILS_PAGE: makeViewEvent('Warehouse Details Page'),
    APPOINTMENT_DETAIL: makeViewEvent('Appointment Details')
  }
};

function makeViewEvent(event) {
  return `Viewed: ${event}`;
}

function makeActionEvent(event) {
  return `Action: ${event}`;
}

function makeModuleEvents(moduleName) {
  return {
    CREATED: `Created: ${moduleName}`,
    UPDATED: `Updated: ${moduleName}`,
    DELETED: `Deleted: ${moduleName}`
  };
}
