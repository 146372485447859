<template>
  <m-field
    :class="[{ 'flex-1': !width }, `text-field-${_uid}`]"
    :data-testid="testId"
    class="text-field-wrapper"
    :status="hasError ? 'danger' : 'default'">
    <m-field-label v-if="label" slot="label" :data-testid="`${testId}-label`">
      {{ label }}
    </m-field-label>
    <m-text-field
      v-on="{
        ...$listeners,
        input: event => (internalValue = event.target.value)
      }"
      @focus="handleFocus"
      @blur="handleBlur"
      class="text-field"
      @m-clear="$emit('clear')"
      :style="computedWidth"
      v-bind="[$attrs]"
      :name="fieldName"
      :placeholder="placeholder"
      :value="lazyValue"
      :disabled="disabled"
      @keydown="readonly ? $event.preventDefault() : null"
      :type="type"
      :id="testId">
      <span v-if="$slots.leading || leadingIcon" slot="leading" :data-testid="`${testId}-leading`">
        <!-- @slot Leading content inside input -->
        <slot v-if="$slots.leading" name="leading"></slot>
        <v-icon v-if="leadingIcon" :color="leadingIcon.color" :size="leadingIcon.size">
          {{ leadingIcon.icon }}
        </v-icon>
      </span>
      <span
        v-if="$slots.trailing || trailingIcon"
        slot="trailing"
        :data-testid="`${testId}-trailing`">
        <!-- @slot Trailing content inside input -->
        <slot v-if="$slots.trailing" name="trailing"></slot>
        <v-icon v-if="trailingIcon" :color="trailingIcon.color" :size="trailingIcon.size">
          {{ trailingIcon.icon }}
        </v-icon>
      </span>
    </m-text-field>
    <m-field-hint :data-testid="`${testId}-errors`" v-if="shouldDisplayMessages">
      <span>{{ errorBucket.join(', ') }}</span>
      <span v-if="errorBucket.length && hint">.</span>
      <span v-if="hint" :class="{ 'p-l-1': errorBucket.length }">{{ hint }}</span>
    </m-field-hint>
  </m-field>
</template>

<script>
import fieldMixin from '@/components/mixins/fieldMixin';
import validatable from '@/components/mixins/validatable';
import testable from '@/components/mixins/testable';
import { VIcon } from '@/components';
import { propValidatorHelper } from '@satellite/plugins/util';

/**
 * TextField wraps Mirdanda WC library's <m-field> and <m-text-field> components and is used to reduce the amount
 * of code written to create a field
 * @displayName Text Field
 */
export default {
  // Note for v-model and v-on="$listeners" issues - read https://github.com/vuejs/vue/issues/7042
  name: 'TextField',
  mixins: [fieldMixin, validatable, testable],
  components: { VIcon },
  props: {
    /**
     * @model
     */
    value: {
      type: [String, Number],
      required: false
    },
    /**
     * Field Type
     */
    type: {
      type: String,
      required: false,
      default: 'text',
      validator: value => {
        return ['text', 'password', 'number', 'email'].includes(value);
      }
    },
    /**
     * Leading Icon
     * @values { icon: 'email', color: 'color-text-secondary', size: 'font-size-5' }
     */
    leadingIcon: {
      type: Object,
      required: false,
      validator: value => {
        const isValid = ['icon'].every(required => Object.keys(value).includes(required));
        return propValidatorHelper({
          value,
          isValid,
          componentName: 'TextField',
          propName: 'leadingIcon',
          message: ''
        });
      }
    },
    /**
     * Trailing Icon
     * @values { icon: 'email', color: 'color-text-secondary', size: 'font-size-3' }
     */
    trailingIcon: {
      type: Object,
      required: false,
      validator: value => {
        const isValid = ['icon'].every(required => Object.keys(value).includes(required));
        return propValidatorHelper({
          value,
          isValid,
          componentName: 'TextField',
          propName: 'trailingIcon',
          message: ''
        });
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted() {
    this.util.appendStyleToShadowDomEl(
      document.querySelector(`.text-field-${this._uid} m-text-field`),
      'input',
      'font-size: 16px !important;height: calc(100% - 20px);'
    );
  }
};
</script>

<style scoped lang="scss">
m-field,
.text-field {
  @media (max-width: $tabletBreakpoint) {
    width: 100% !important;
  }
}
</style>
