var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("basic-form", {
    ref: "details-form",
    attrs: { fields: _vm.detailsFields },
    on: { submit: _vm.submitForm },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }