var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoading
    ? _c(
        "m-stack",
        {
          staticClass: "height-100",
          attrs: { align: "center", justify: "center", gap: "spacing-2" },
        },
        [
          _c("m-spinner-wheel", { attrs: { size: "48px" } }),
          _vm.$slots.default
            ? _c(
                "m-text",
                { attrs: { variant: "body-md-bold" } },
                [_vm._t("default"), _vm._v(" ... ")],
                2
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }