var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "m-group",
    { attrs: { align: "center" } },
    [
      _c(
        "icon-button",
        {
          attrs: {
            disabled: _vm.isPreviousButtonDisabled,
            "test-id": "date-back-btn",
            size: "small",
            "icon-size": "20px",
          },
          on: {
            click: function ($event) {
              return _vm.$emit("prev")
            },
          },
        },
        [_vm._v(" chevron-left ")]
      ),
      _c("single-date-picker", {
        attrs: {
          "test-id": "slot-picker-date-picker",
          "icon-activated": "",
          "min-date": _vm.minDate,
          "max-date": _vm.maxDate,
          value: _vm.value,
        },
        on: { input: _vm.updateVal },
      }),
      _c(
        "icon-button",
        {
          attrs: {
            disabled: _vm.isNextButtonDisabled,
            "test-id": "date-forward-btn",
            size: "small",
            "icon-size": "20px",
          },
          on: {
            click: function ($event) {
              return _vm.$emit("next")
            },
          },
        },
        [_vm._v(" chevron-right ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }