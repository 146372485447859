import WarehouseDetails from '@/modules/warehouses/WarehouseDetails.vue';

export default [
  {
    path: '/warehouses/:warehouseId',
    name: 'warehouse.details',
    component: WarehouseDetails,
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Warehouse details'
    }
  }
];
