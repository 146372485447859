<template>
  <m-group justify="space-between">
    <v-spacer v-if="prevButtonAttrs.hidden" />
    <secondary-button
      v-if="!prevButtonAttrs.hidden"
      test-id="step-prev-btn"
      @click="handlePrevClick"
      leading-icon="chevron-left"
      icon-size="16px"
      :disabled="prevButtonAttrs.disabled">
      {{ prevButtonAttrs.label ?? 'Previous' }}
    </secondary-button>
    <secondary-button
      v-if="!nextButtonAttrs.hidden"
      test-id="step-next-btn"
      @click="handleNextClick"
      trailing-icon="chevron-right"
      icon-size="16px"
      :disabled="nextButtonAttrs.disabled">
      {{ prevButtonAttrs.label ?? 'Next' }}
    </secondary-button>
    <v-spacer v-if="nextButtonAttrs.hidden" />
  </m-group>
</template>

<script>
import { SecondaryButton, VSpacer } from '@/components';

/**
 * Easy external navigation to use with the stepper component
 * @displayName Stepper Nav
 */
export default {
  name: 'StepperNav',
  components: { SecondaryButton, VSpacer },
  props: {
    /**
     * Previous button attributes { label: string, hidden: boolean, disabled: boolean}
     */
    prevButtonAttrs: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    /**
     * Next button attributes { label: string, hidden: boolean, disabled: boolean}
     */
    nextButtonAttrs: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    }
  },
  methods: {
    handlePrevClick() {
      /**
       * Emits the prev event
       * @event prev
       */
      this.$emit('prev');
    },
    handleNextClick() {
      /**
       * Emits the next event
       * @event next
       */
      this.$emit('next');
    }
  }
};
</script>
