var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-stack",
    { staticClass: "flex-1 height-100" },
    [
      _c(
        "m-group",
        {
          staticClass: "search-header",
          attrs: { align: "center", justify: "space-between" },
        },
        [
          _c("m-text", { attrs: { variant: "heading-sm-bold" } }, [
            _vm._v("Select a load type"),
          ]),
          _c("text-field", {
            staticClass: "search-field",
            attrs: {
              width: "280px",
              "test-id": "loadtype-search-field",
              "field-name": "loadtype_search",
              placeholder: "Search loadtype",
              "leading-icon": { icon: "magnify", color: "color-text-tertiary" },
            },
            model: {
              value: _vm.searchStr,
              callback: function ($$v) {
                _vm.searchStr = $$v
              },
              expression: "searchStr",
            },
          }),
        ],
        1
      ),
      _vm.isLoading && _vm.isNull(_vm.results)
        ? _c("v-loader", { attrs: { "is-loading": _vm.isLoading } }, [
            _vm._v(" Looking for load types "),
          ])
        : _vm._e(),
      !_vm.isLoading && !_vm.isNull(_vm.results)
        ? _c("card-grid", {
            attrs: {
              "test-id": "loadtype-search-results",
              items: _vm.results,
              "items-per-row": 2,
              "selected-item": _vm.value,
              "display-outer-card": false,
              "outer-card-body-styles": "padding-top:0 !important;",
            },
            on: { click: _vm.handleItemClick },
            scopedSlots: _vm._u(
              [
                {
                  key: "row",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "m-stack",
                        {
                          staticClass: "flex-1",
                          attrs: { gap: "none", align: "flex-start" },
                        },
                        [
                          _c(
                            "m-group",
                            {
                              staticClass: "width-100",
                              attrs: {
                                align: "center",
                                justify: "space-between",
                              },
                            },
                            [
                              _c(
                                "m-stack",
                                {
                                  staticClass: "flex-1",
                                  attrs: { gap: "none", align: "flex-start" },
                                },
                                [
                                  _c(
                                    "m-group",
                                    {
                                      attrs: {
                                        gap: "spacing-2",
                                        align: "center",
                                        justify: "flex-start",
                                      },
                                    },
                                    [
                                      _c("loadtype-direction", {
                                        attrs: { direction: item.direction },
                                      }),
                                      _c(
                                        "m-text",
                                        { attrs: { variant: "body-md-bold" } },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "m-text",
                                    { attrs: { variant: "body-md" } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.direction) + " "
                                      ),
                                      !_vm.hideDuration
                                        ? _c("span", [
                                            _vm._v(
                                              "- " +
                                                _vm._s(item.duration_min) +
                                                " minutes"
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "icon-button",
                                {
                                  attrs: {
                                    disabled: !item.description,
                                    "test-id": `description-trigger-${item.id}`,
                                    "icon-size": "20px",
                                    color: "color-text-secondary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.showDescription(item)
                                    },
                                  },
                                },
                                [_vm._v(" information-slab-circle ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "m-group",
                            { staticClass: "width-100 m-t-4" },
                            [
                              _c(
                                "m-stack",
                                {
                                  staticClass: "flex-1",
                                  attrs: { align: "flex-start", gap: "none" },
                                },
                                [
                                  _c(
                                    "m-text",
                                    {
                                      attrs: {
                                        variant: "body-md",
                                        color: "color-text-tertiary",
                                      },
                                    },
                                    [_vm._v("Operation")]
                                  ),
                                  item.operation
                                    ? _c(
                                        "m-text",
                                        { attrs: { variant: "body-md" } },
                                        [_vm._v(_vm._s(item.operation))]
                                      )
                                    : _c("no-content"),
                                ],
                                1
                              ),
                              _c(
                                "m-stack",
                                {
                                  staticClass: "flex-1",
                                  attrs: { align: "flex-start", gap: "none" },
                                },
                                [
                                  _c(
                                    "m-text",
                                    {
                                      attrs: {
                                        variant: "body-md",
                                        color: "color-text-tertiary",
                                      },
                                    },
                                    [_vm._v("Equipment Type")]
                                  ),
                                  item.equipmentType
                                    ? _c(
                                        "m-text",
                                        { attrs: { variant: "body-md" } },
                                        [_vm._v(_vm._s(item.equipmentType))]
                                      )
                                    : _c("no-content"),
                                ],
                                1
                              ),
                              _c(
                                "m-stack",
                                {
                                  staticClass: "flex-1",
                                  attrs: { align: "flex-start", gap: "none" },
                                },
                                [
                                  _c(
                                    "m-text",
                                    {
                                      attrs: {
                                        variant: "body-md",
                                        color: "color-text-tertiary",
                                      },
                                    },
                                    [_vm._v("Transportation Mode")]
                                  ),
                                  item.transportationMode
                                    ? _c(
                                        "m-text",
                                        { attrs: { variant: "body-md" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.transportationMode) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _c("no-content"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "no-items",
                  fn: function () {
                    return [
                      _vm.searchStr
                        ? _c("no-results")
                        : _c("no-results", {
                            attrs: {
                              "not-found": "",
                              title:
                                "No load types available on this warehouse",
                              subtitle: "Please contact the facility",
                            },
                          }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3296576009
            ),
          })
        : _vm._e(),
      _c("dialog-base", {
        attrs: { size: "medium", "is-persistent": false },
        on: {
          close: function ($event) {
            _vm.descriptionItem = null
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("m-text", { attrs: { variant: "header-md-bold" } }, [
                  _vm._v(_vm._s(_vm.descriptionItem.name)),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "m-text",
                  {
                    attrs: { variant: "body-md", color: "color-text-tertiary" },
                  },
                  [_vm._v("Description")]
                ),
                _c("m-text", { attrs: { variant: "body-md" } }, [
                  _vm._v(_vm._s(_vm.descriptionItem.description)),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.dialogs.description,
          callback: function ($$v) {
            _vm.$set(_vm.dialogs, "description", $$v)
          },
          expression: "dialogs.description",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }