import MixpanelEvents from '@/enums/MixpanelEvents';
import config from '@/config/config';
import mixpanel from 'mixpanel-browser';

/**
 * Mixpanel Mixin
 * @mixin
 * @displayName Mixpanel Mixin
 */
export default {
  data() {
    return {
      mixpanel: {
        track: this.trackMixpanelEvent,
        init: this.initMixpanel,
        events: MixpanelEvents,
        api: mixpanel,
        identify: this.setMixpanelIdentity,
        reset: this.resetMixpanel,
        isInitialized: false
      }
    };
  },
  methods: {
    /**
     * Track mixpanel event
     * @public
     * @param event
     * @param {object} data
     */
    trackMixpanelEvent(event, data = {}) {
      // Silently ignore events if no token is set
      if (!config.mixpanel_token) {
        return;
      }

      try {
        mixpanel.track(event, data);
      } catch (e) {
        console.log('MP Event Error', e);
      }
    },
    /**
     * Initialize Mixpanel
     * @public
     */
    initMixpanel() {
      if (!config.mixpanel_token) {
        return;
      }

      this.mixpanel.isInitialized = true;
      mixpanel.init(config.mixpanel_token, { debug: false });
    },
    /**
     * Set Mixpanel Identity
     * @public
     * @param {User} me (current user)
     */
    setMixpanelIdentity(me) {
      if (!config.mixpanel_token || !me) {
        return;
      }

      mixpanel.identify(me.email);

      mixpanel.people.set({
        $name: `${me.firstName} ${me.lastName}`,
        $email: me.email,
        'Nova User ID': me.id,
        'Company ID': me.company?.id,
        'Company name': me.company?.name
      });
    },
    /**
     * Reset Mixpanel
     * @public
     */
    resetMixpanel() {
      if (this.mixpanel.isInitialized) {
        mixpanel.reset();
      }
    }
  }
};
