var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "image-message",
        {
          attrs: {
            "image-path": _vm.notFound ? _vm.notFoundSvg : _vm.searchSvg,
            "image-alt": "Search image for no results",
          },
        },
        [
          _vm.$slots.default
            ? _vm._t("default")
            : [
                _c("m-text", { attrs: { variant: "md-bold" } }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
                _c("m-text", { attrs: { variant: "body-sm" } }, [
                  _vm._v(_vm._s(_vm.subtitle)),
                ]),
              ],
          _c(
            "div",
            { class: _vm.hasFooterSlot ? "m-t-2 p-b-4" : "" },
            [_vm._t("footer")],
            2
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }