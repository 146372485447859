var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-field",
    {
      attrs: {
        "data-testid": _vm.testId,
        status: _vm.hasError ? "danger" : "default",
      },
    },
    [
      _c(
        "m-switch",
        _vm._b(
          {
            attrs: { checked: _vm.lazyValue },
            on: { "m-change": (e) => (_vm.internalValue = e.target.checked) },
          },
          "m-switch",
          _vm.$attrs,
          false
        ),
        [_c("span", [_vm._v(" " + _vm._s(_vm.label) + " ")])]
      ),
      _c("m-field-hint", { attrs: { "data-testid": `${_vm.testId}-errors` } }, [
        _c("span", [_vm._v(_vm._s(_vm.errorBucket.join(", ")))]),
        _vm.errorBucket.length && _vm.hint
          ? _c("span", [_vm._v(".")])
          : _vm._e(),
        _vm.hint
          ? _c("span", { class: { "p-l-1": _vm.errorBucket.length } }, [
              _vm._v(_vm._s(_vm.hint)),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }