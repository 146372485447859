var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        attrs: {
          value: _vm.value,
          "has-custom-close-logic": "",
          "hide-close": "",
          size: "small",
        },
        on: { input: _vm.updateVal },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c(
                  "m-group",
                  { attrs: { gap: "spacing-1" } },
                  [
                    _c(
                      "v-icon",
                      { attrs: { size: "25px", color: "color-warning-60" } },
                      [_vm._v("alert-circle")]
                    ),
                    _c("m-text", { attrs: { variant: "heading-md-bold" } }, [
                      _vm._v("Appointment not booked"),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("m-text", { attrs: { variant: "body-md" } }, [
                  _vm._v(" " + _vm._s(_vm.message) + " "),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "actions",
            fn: function () {
              return [
                _c(
                  "m-group",
                  {
                    staticClass: "flex-nowrap p-t-4",
                    attrs: { gap: "spacing-2" },
                  },
                  [
                    [
                      _c(
                        "secondary-button",
                        {
                          attrs: { "test-id": "book-another-btn" },
                          on: {
                            click: function ($event) {
                              return _vm.$router.replace({
                                name: "warehouse.select",
                              })
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "primary-button",
                        {
                          attrs: { "test-id": "see-details-btn" },
                          on: { click: _vm.handleConfirmClick },
                        },
                        [_vm._v(" " + _vm._s(_vm.confirmButtonText) + " ")]
                      ),
                    ],
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "dialog-base",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }