var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "height-100" },
    [
      _vm.isLoading
        ? _c(
            "m-stack",
            {
              staticClass: "height-100",
              attrs: { justify: "center", align: "center" },
            },
            [
              _c("v-loader", { attrs: { "is-loading": "" } }, [
                _vm._v("Loading warehouse"),
              ]),
            ],
            1
          )
        : _vm.warehouse
        ? _c("internal-page", {
            attrs: { "page-title": "Book appointment", "allow-overflow": "" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c("page-header", {
                      attrs: {
                        title: _vm.warehouse.name,
                        "has-back-button": !_vm.$previewWarehouseId,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "header-actions",
                          fn: function () {
                            return [
                              !_vm.$isMrPreview
                                ? _c(
                                    "secondary-button",
                                    {
                                      attrs: {
                                        "leading-icon": "star",
                                        "test-id": "add-to-favorites-btn",
                                      },
                                      on: { click: _vm.toggleFavorite },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.isWarehouseFavorited
                                              ? "Remove from favorites"
                                              : "Add to favorites"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "primary-button",
                                {
                                  attrs: { "test-id": "book-warehouse-btn" },
                                  on: { click: _vm.bookWarehouse },
                                },
                                [_vm._v(" Book appointment ")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "body",
                fn: function () {
                  return [
                    _c("sidebar-cards", {
                      scopedSlots: _vm._u([
                        {
                          key: "sidebar-content",
                          fn: function () {
                            return [
                              _c("warehouse-header", {
                                attrs: { warehouse: _vm.warehouse },
                              }),
                              _c("warehouse-address", {
                                attrs: { warehouse: _vm.warehouse },
                              }),
                              _c("warehouse-contact", {
                                attrs: { warehouse: _vm.warehouse },
                              }),
                              _vm.warehouse.amenities.length > 0
                                ? _c(
                                    "m-stack",
                                    { attrs: { gap: "spacing-3" } },
                                    [
                                      _c(
                                        "m-text",
                                        { attrs: { variant: "body-md" } },
                                        [_vm._v("Amenities available")]
                                      ),
                                      _vm.warehouse.amenities.length > 0
                                        ? _c(
                                            "m-grid",
                                            {
                                              staticClass: "amenities-grid",
                                              attrs: { gap: "spacing-1" },
                                            },
                                            _vm._l(
                                              _vm.warehouse.amenities,
                                              function (amenity, index) {
                                                return _c(
                                                  "m-tag",
                                                  { key: `amenity-${index}` },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(amenity) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("hours-of-operation-list", {
                                attrs: {
                                  "data-testId": "warehouse-hoops",
                                  "enable-military-time":
                                    _vm.warehouse.settings.enableMilitaryTime,
                                  schedule:
                                    _vm.warehouse.schedule ??
                                    _vm.DEFAULT_SCHEDULE,
                                  timezone: _vm.warehouse.timezone,
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "main-content",
                          fn: function () {
                            return [
                              _c(
                                "m-stack",
                                { attrs: { gap: "spacing-1" } },
                                [
                                  _c(
                                    "m-text",
                                    { attrs: { variant: "heading-sm-bold" } },
                                    [_vm._v("Instructions")]
                                  ),
                                  _c(
                                    "m-text",
                                    [
                                      _vm.warehouse.instructions
                                        ? _c("section", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.warehouse.instructions
                                              ),
                                            },
                                          })
                                        : _c("no-content"),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.warehouse.ppeRequirements.length > 0
                                ? _c(
                                    "m-stack",
                                    { attrs: { gap: "spacing-1" } },
                                    [
                                      _c(
                                        "m-text",
                                        {
                                          attrs: { variant: "heading-sm-bold" },
                                        },
                                        [_vm._v("PPE and other equipment")]
                                      ),
                                      _c(
                                        "m-group",
                                        { staticClass: "m-t-5" },
                                        [
                                          _vm.warehouse.ppeRequirements.length >
                                          0
                                            ? [
                                                _vm._l(
                                                  _vm.warehouse.ppeRequirements,
                                                  function (
                                                    ppeRequirement,
                                                    index
                                                  ) {
                                                    return [
                                                      _c(
                                                        "m-stack",
                                                        {
                                                          key: `ppe-requirement-${index}`,
                                                          attrs: {
                                                            gap: "spacing-2",
                                                            align: "center",
                                                          },
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              height: "50",
                                                              width: "50",
                                                              src: `/images/warehouse/${_vm.getPPERequirementIcon(
                                                                ppeRequirement
                                                              )}.png`,
                                                            },
                                                          }),
                                                          _c(
                                                            "m-text",
                                                            {
                                                              key: `ppe-requirement=${index}`,
                                                              attrs: {
                                                                variant:
                                                                  "body-sm",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    ppeRequirement
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  }
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.getYardMapPath(_vm.warehouse.settings)
                                ? _c(
                                    "m-stack",
                                    { attrs: { gap: "spacing-1" } },
                                    [
                                      _c(
                                        "m-text",
                                        {
                                          attrs: { variant: "heading-sm-bold" },
                                        },
                                        [_vm._v("Yard Map")]
                                      ),
                                      [
                                        _c(
                                          "m-text",
                                          { attrs: { variant: "body-md" } },
                                          [
                                            _vm._v(
                                              " This image was provided by the warehouse. You can click on it to see the full image. "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: _vm.getYardMapPath(
                                                _vm.warehouse.settings
                                              ),
                                              rel: "noopener noreferrer",
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "yard-map-img",
                                              attrs: {
                                                alt: "Yard Map",
                                                src: _vm.getYardMapPath(
                                                  _vm.warehouse.settings
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                      ],
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }