var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "is__relative" },
    [
      _c("div", { class: `date-picker-${_vm._uid}` }),
      !_vm.useCustomTrigger
        ? _c(
            "v-icon",
            {
              staticClass: "m-l-2 close-icon",
              attrs: { size: "35" },
              on: {
                click: function ($event) {
                  return _vm.toggleDatePicker(true)
                },
              },
            },
            [_vm._v(" calendar ")]
          )
        : _vm._e(),
      _c(
        "vuetify-menu",
        {
          attrs: {
            attach: `.date-picker-${_vm._uid}`,
            transition: "scale-transition",
            "close-on-content-click": false,
            "content-class": "date-picker-wrapper",
            bottom: "",
            right: "",
            "offset-y": "",
            "offset-overflow": "",
            "min-width": "auto",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _vm.iconActivated
                    ? _c(
                        "icon-button",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: {
                                "test-id": "date-picker-trigger",
                                size: "small",
                                "icon-size": "16px",
                              },
                            },
                            "icon-button",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_vm._v(" calendar-blank ")]
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showDatePicker,
            callback: function ($$v) {
              _vm.showDatePicker = $$v
            },
            expression: "showDatePicker",
          },
        },
        [
          _c("vuetify-date-picker", {
            ref: "datePicker",
            attrs: {
              "no-title": "",
              range: "",
              color: _vm.computedColor,
              "prev-icon": "mdi-chevron-up",
              "next-icon": "mdi-chevron-down",
              value: _vm.value,
            },
            on: { input: _vm.handleDateClick },
            model: {
              value: _vm.selectedDates,
              callback: function ($$v) {
                _vm.selectedDates = $$v
              },
              expression: "selectedDates",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }