var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-field",
    {
      staticClass: "text-field-wrapper flex-1",
      class: `text-area-${_vm._uid}`,
      attrs: {
        "data-testid": _vm.testId,
        status: _vm.hasError ? "danger" : "default",
      },
    },
    [
      _vm.label
        ? _c(
            "m-field-label",
            {
              attrs: { slot: "label", "data-testid": `${_vm.testId}-label` },
              slot: "label",
            },
            [_vm._v(" " + _vm._s(_vm.label) + " ")]
          )
        : _vm._e(),
      _c(
        "m-text-area",
        _vm._g(
          _vm._b(
            {
              class: { "is-compact": _vm.isCompact },
              style: `${_vm.computedWidth};max-width: 100%;`,
              attrs: {
                resize: "none",
                name: _vm.fieldName,
                placeholder: _vm.placeholder,
                value: _vm.lazyValue,
              },
              on: { focus: _vm.handleFocus, blur: _vm.handleBlur },
            },
            "m-text-area",
            [_vm.$attrs],
            false
          ),
          {
            ..._vm.$listeners,
            input: (event) => (_vm.internalValue = event.target.value),
          }
        )
      ),
      _c("m-field-hint", { attrs: { "data-testid": `${_vm.testId}-errors` } }, [
        _c("span", [_vm._v(_vm._s(_vm.errorBucket.join(", ")))]),
        _vm.errorBucket.length && _vm.hint
          ? _c("span", [_vm._v(".")])
          : _vm._e(),
        _vm.hint
          ? _c("span", { class: { "p-l-1": _vm.errorBucket.length } }, [
              _vm._v(_vm._s(_vm.hint)),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }