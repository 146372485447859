var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm._t(
        "trigger",
        function () {
          return [
            _c(
              "tertiary-button",
              {
                attrs: { "test-id": "print-btn", "leading-icon": "printer" },
                on: { click: _vm.openPrintTab },
              },
              [_vm._v(" Print ")]
            ),
          ]
        },
        { openPrintTab: _vm.openPrintTab }
      ),
      _c("printable-appointment-details", {
        attrs: { "extended-appointment": _vm.extendedAppointment },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }