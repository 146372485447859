var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    {
      staticClass: "printable-window",
      attrs: { id: "appointment-details-printable" },
    },
    [
      _c("header", { staticClass: "printable-header" }, [
        _c("img", {
          attrs: { src: "/images/logo_for_print.png", alt: "Opendock Logo" },
        }),
        _c("span", [
          _vm._v(
            "Printed on " +
              _vm._s(_vm.extendedAppointment.getReadableCurrentTime())
          ),
        ]),
      ]),
      _c("section", { staticClass: "section-1" }, [
        _c(
          "div",
          { staticClass: "center-row" },
          [
            _c("v-icon", { attrs: { size: "60px" } }, [_vm._v("calendar")]),
            _c("div", { staticClass: "appointment-time-wrapper flex-column" }, [
              _c("div", { staticClass: "label-text" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.extendedAppointment.isRequested()
                        ? "Requested for:"
                        : "Scheduled to:"
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "appointment-time" }, [
                _vm._v(" " + _vm._s(_vm.appointmentDateTime) + " "),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "status-details" },
          [
            _vm.extendedAppointment.isNoShow()
              ? [_vm._m(0)]
              : _vm.extendedAppointment.isRequested()
              ? [_vm._m(1)]
              : [
                  _c("div", [
                    _c("div", { staticClass: "label-text" }, [
                      _vm._v("Arrived at:"),
                    ]),
                    _c("div", { staticClass: "status-date-time" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.extendedAppointment.getFormattedArrivalTime()
                          ) +
                          " "
                      ),
                    ]),
                    _vm.extendedAppointment.isArrived()
                      ? _c("div", { staticClass: "label-text black" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.extendedAppointment.getArrivalTimeDiff()
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c("img", {
                    staticClass: "arrow",
                    attrs: {
                      src: "/images/arrow-right.png",
                      alt: "Right Arrow",
                    },
                  }),
                  _c("div", [
                    _c("div", { staticClass: "label-text" }, [
                      _vm._v("Completed at:"),
                    ]),
                    _c("div", { staticClass: "status-date-time" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.extendedAppointment.getFormattedCompletedTime()
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "label-text black" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.extendedAppointment.getCompletedTimeDiff()
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ],
          ],
          2
        ),
      ]),
      _c("section", { staticClass: "section-2" }, [
        _c("div", [
          _c("div", { staticClass: "label-text" }, [
            _vm._v("Carrier Contact:"),
          ]),
          _c("div", { staticClass: "data-group" }, [
            _c("span", [
              _c("span", { staticClass: "bold" }, [_vm._v("Name:")]),
              _vm._v(
                " " +
                  _vm._s(_vm.extendedAppointment.user.firstName) +
                  " " +
                  _vm._s(_vm.extendedAppointment.user.lastName) +
                  " "
              ),
            ]),
            _c("span", [
              _c("span", { staticClass: "bold" }, [_vm._v("Email:")]),
              _vm._v(" " + _vm._s(_vm.extendedAppointment.user.email) + " "),
            ]),
            _c("span", [
              _c("span", { staticClass: "bold" }, [_vm._v("Phone:")]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.formatPhoneNumber(_vm.extendedAppointment.user.phone)
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "label-text" }, [_vm._v("Warehouse:")]),
          _c("div", { staticClass: "data-group" }, [
            _c("span", { staticClass: "bold" }, [
              _vm._v(_vm._s(_vm.extendedAppointment.warehouse.name)),
            ]),
            _c("span", [
              _c("span", { staticClass: "bold" }, [_vm._v("Address:")]),
              _vm._v(" " + _vm._s(_vm.warehouseAddress) + " "),
            ]),
            _c("span", [
              _c("span", { staticClass: "bold" }, [_vm._v("Email:")]),
              _vm._v(
                " " + _vm._s(_vm.extendedAppointment.warehouse.email) + " "
              ),
            ]),
            _c("span", [
              _c("span", { staticClass: "bold" }, [_vm._v("Phone:")]),
              _vm._v(
                " " + _vm._s(_vm.extendedAppointment.warehouse.phone) + " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "label-text" }, [_vm._v("Confirmation #:")]),
          _c("div", { staticClass: "data-group mb-1" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.extendedAppointment.confirmationNumber)),
            ]),
          ]),
          _c("div", { staticClass: "label-text" }, [_vm._v("Appointment ID:")]),
          _c("div", { staticClass: "data-group" }, [
            _c("span", [_vm._v(_vm._s(_vm.extendedAppointment.id))]),
          ]),
        ]),
        _c(
          "div",
          [
            _c("div", { staticClass: "label-text" }, [_vm._v("Dock name:")]),
            _c("div", { staticClass: "data-group" }, [
              _vm._v(
                " " + _vm._s(_vm.extendedAppointment.getParentDock().name) + " "
              ),
            ]),
            _c("div", { staticClass: "label-text" }, [_vm._v("Load type:")]),
            _c("div", { staticClass: "data-group" }, [
              _vm._v(" " + _vm._s(_vm.extendedAppointment.loadType.name) + " "),
            ]),
            _vm.refNumSettings.isVisible !== false
              ? [
                  _c("div", { staticClass: "label-text" }, [
                    _vm._v(_vm._s(_vm.refNumSettings.displayName) + ":"),
                  ]),
                  _c("div", { staticClass: "data-group" }, [
                    _vm._v(
                      " " + _vm._s(_vm.extendedAppointment.refNumber) + " "
                    ),
                  ]),
                ]
              : _vm._e(),
            _c("div", { staticClass: "label-text" }, [
              _vm._v("Appointment Notes:"),
            ]),
            _c("div", {
              staticClass: "data-group",
              domProps: { innerHTML: _vm._s(_vm.extendedAppointment.notes) },
            }),
            _vm._l(_vm.customFields, function (field) {
              return [
                field.value
                  ? [
                      _c("div", { key: field.name }, [
                        _c("div", { staticClass: "label-text" }, [
                          _vm._v(_vm._s(field.label) + ":"),
                        ]),
                        _c("div", {
                          staticClass: "data-group",
                          staticStyle: { "white-space": "pre-line" },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.novaCore.getCustomFieldFormattedValue(field, {
                                [_vm.novaCore.CustomFieldType.Timestamp]: {
                                  timezone:
                                    _vm.extendedAppointment.warehouse.timezone,
                                  formatAsMilitary:
                                    _vm.extendedAppointment
                                      .isMilitaryTimeEnabled,
                                },
                              })
                            ),
                          },
                        }),
                      ]),
                    ]
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
      ]),
      _vm._m(2),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "label-text" }, [
        _vm._v("Reported as No Show:"),
      ]),
      _c("div", { staticClass: "status-date-time" }, [
        _vm._v("Carrier did not show for appointment."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "status-date-time" }, [
        _vm._v("Appointment waiting for warehouse confirmation"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticStyle: { padding: "2rem 0", "text-align": "center" } },
      [
        _c(
          "span",
          { staticClass: "bold", staticStyle: { "margin-right": "10px" } },
          [_vm._v("Opendock")]
        ),
        _vm._v(" The ultimate dock scheduling software for warehouses. "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }