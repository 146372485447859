var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      ref: "form",
      staticClass: "full-width",
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          return _vm.submitForm.apply(null, arguments)
        },
      },
    },
    [
      Object.keys(_vm.internalFields).length > 0
        ? _c(
            "m-stack",
            { key: _vm.resetIterator, attrs: { gap: "spacing-4" } },
            [
              _vm._l(_vm.groupedFields, function (group, groupKey) {
                return [
                  _vm.shouldRenderGroup(groupKey)
                    ? _c(
                        "m-group",
                        { key: groupKey },
                        _vm._l(group, function (field, fieldKey) {
                          return _c("basic-form-field", {
                            key: fieldKey,
                            attrs: {
                              "field-name": fieldKey,
                              "field-component": field.component,
                              "field-props": field.fieldProps,
                              "validation-iterator": _vm.validationIterator,
                              "should-validate": _vm.hasBeenSubmitted,
                            },
                            on: {
                              "update:value": _vm.handleValUpdate,
                              "update:error": (hasErrors) =>
                                _vm.setFieldHasErrors(fieldKey, hasErrors),
                            },
                          })
                        }),
                        1
                      )
                    : _vm._l(group, function (field, fieldKey) {
                        return _c("basic-form-field", {
                          key: fieldKey,
                          attrs: {
                            "field-name": fieldKey,
                            "field-component": field.component,
                            "field-props": field.fieldProps,
                            "should-validate": _vm.hasBeenSubmitted,
                            "validation-iterator": _vm.validationIterator,
                          },
                          on: {
                            "update:value": _vm.handleValUpdate,
                            "update:error": (hasErrors) =>
                              _vm.setFieldHasErrors(fieldKey, hasErrors),
                          },
                        })
                      }),
                ]
              }),
              _vm._t("pre-submit-actions"),
              _vm.submitButton
                ? [
                    _c(
                      "primary-button",
                      {
                        staticClass: "m-t-2",
                        attrs: {
                          "is-loading": _vm.submitButton.isLoading,
                          "test-id": _vm.submitButton.testId,
                          disabled: !_vm.canSubmit,
                          "is-block": _vm.submitButton.isBlock ?? true,
                          "leading-icon": _vm.submitButton.leadingIcon,
                          "trailing-icon": _vm.submitButton.trailingIcon,
                        },
                        on: { click: _vm.submitForm },
                      },
                      [_vm._v(" " + _vm._s(_vm.submitButton.label) + " ")]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }