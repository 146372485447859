// Load .env vars into the config

/**
 * @typedef LunaEnvVars
 * @type {object}
 * @property {string} application_name
 * @property {string} unleash_url
 * @property {string} unleash_app_name
 * @property {string} unleash_client_key
 * @property {string} server_env
 */

/** @type {LunaEnvVars} */
const formattedVars = {};

for (const [key, value] of Object.entries(process.env)) {
  formattedVars[key.replace('VUE_APP_', '').toLowerCase()] = value;
}

const config = {
  ...formattedVars,
  ...{
    // Add app config/constants here that you do not want in the .env files
    application_name: 'Opendock Scheduling'
  }
};

export default config;
