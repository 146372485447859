import { DateTime } from 'luxon';
import {
  formatDateTimeWithMilitarySupport,
  LuxonDateTimeFormats,
  AppointmentStatus,
  isCancelAllowed,
  isInProgress,
  isRequested,
  isReserve,
  isPastAppointment,
  isWithin24HoursWindow
} from '@satellite/../nova/core';
import appointmentService from '@satellite/services/appointment-service';
import warehouseService from '@satellite/services/warehouse-service';
import dockService from '@satellite/services/dock-service';
import userService from '@satellite/services/user-service';

export const diffLabels = Object.freeze({
  dwell: 'dwell',
  late: 'late',
  wait: 'wait'
});

/**
 * A "super appointment" that has all properties of an appointment extended with methods to make it easy to get readable data
 * and information about the appointment
 * Includes needed warehouse, dock, and loadtype data
 */
export class ExtendedAppointment {
  /**
   *
   * @param {object} appointment
   * @param {object} warehouse
   * @param {boolean} isMilitaryTimeEnabled
   * @param {object} user
   * @param {object} dock
   * @param {object} queryOptions
   */

  /**
   * Constructor is private to force instantiation using the static initialize method to make its construction async
   * @private
   * @param appointmentId
   */
  constructor({ appointment, warehouse, isMilitaryTimeEnabled, user, dock, queryOptions }) {
    Object.assign(this, {
      ...appointment,
      warehouse,
      user,
      dock,
      isMilitaryTimeEnabled,
      queryOptions
    });
  }

  /**
   * Fetch data and instantiate the extended appointment
   * @param appointmentId
   * @param options Optional request options to control fields and joins
   * @returns {Promise<ExtendedAppointment>}
   */
  static async initialize(
    appointmentId,
    isPublic = false,
    options = {
      appointment: {
        joins: ['loadType||name,direction,settings', 'assetVisit||id,phone,lastChangedDateTime']
      },
      dock: {
        fields: [
          'name',
          'warehouseId',
          'capacityParentId',
          'minCarrierLeadTimeForUpdates_hr',
          'minCarrierLeadTime_hr'
        ],
        joins: ['capacityParent||name,id']
      },
      warehouse: {
        fields: [
          'settings',
          'name',
          'street',
          'city',
          'state',
          'zip',
          'country',
          'phone',
          'email',
          'timezone',
          'customApptFieldsTemplate'
        ],
        joins: ['org||name']
      },
      user: {
        fields: ['email', 'phone', 'firstName', 'lastName'],
        joins: ['company||name']
      }
    }
  ) {
    let appointment;
    if (isPublic) {
      appointment = await appointmentService.getPublicAppointment(
        appointmentId,
        options.appointment
      );
    } else {
      appointment = await appointmentService.getAppointmentById(
        appointmentId,
        {},
        options.appointment
      );
    }

    const dock =
      appointment.dock ?? (await dockService.getDockById(appointment.dockId, {}, options.dock));

    // NOTE: Getting a warehouse separately here gives us the computed settings with the Org
    const warehouse =
      appointment.dock?.warehouse ??
      (await warehouseService.getWarehouseById(dock.warehouseId, {}, options.warehouse));

    const user =
      appointment.user ?? (await userService.getUserById(appointment.userId, {}, options.user));

    const startDateTime = DateTime.fromISO(appointment.start, { zone: warehouse.timezone });
    const endDateTime = DateTime.fromISO(appointment.end, { zone: warehouse.timezone });
    appointment.isInDSTChange = startDateTime.offset !== endDateTime.offset;

    const isMilitaryTimeEnabled = warehouse.settings?.enableMilitaryTime ?? false;
    return new ExtendedAppointment({
      appointment,
      warehouse,
      dock,
      user,
      isMilitaryTimeEnabled,
      queryOptions: options
    });
  }

  setAppointment(appointment) {
    Object.assign(this, appointment);
  }

  async refreshAppointment() {
    const appointment = await appointmentService.getAppointmentById(
      this.id,
      {},
      this.queryOptions.appointment
    );
    this.setAppointment(appointment);
  }

  /**
   * Get a readable version of the appointment date
   * @returns {string}
   */
  getReadableDate() {
    return formatDateTimeWithMilitarySupport(
      this.start,
      this.warehouse.timezone,
      LuxonDateTimeFormats.ShortDayDateSlashed,
      this.isMilitaryTimeEnabled,
      LuxonDateTimeFormats.ShortDayDateSlashed
    );
  }

  getReadableStartTime() {
    return formatDateTimeWithMilitarySupport(
      this.start,
      this.warehouse.timezone,
      LuxonDateTimeFormats.Extended12HrTimePaddingAMPM,
      this.isMilitaryTimeEnabled,
      LuxonDateTimeFormats.Extended24HrTime
    );
  }

  getReadableEndTime() {
    return formatDateTimeWithMilitarySupport(
      this.end,
      this.warehouse.timezone,
      LuxonDateTimeFormats.Extended12HrTimePaddingAMPM,
      this.isMilitaryTimeEnabled,
      LuxonDateTimeFormats.Extended24HrTime
    );
  }

  getTimezoneAbbreviation(dateTime = null) {
    return formatDateTimeWithMilitarySupport(
      dateTime ?? this.start,
      this.warehouse.timezone,
      `(${LuxonDateTimeFormats.AbbreviatedNamedOffset})`,
      this.isMilitaryTimeEnabled,
      `(${LuxonDateTimeFormats.AbbreviatedNamedOffset})`
    );
  }

  /**
   * Get a readable version of start and end times with timezone abbreviation
   * @returns {string}
   */
  getReadableTimes() {
    const start = this.getReadableStartTime();
    const end = this.getReadableEndTime();
    const timezoneAbbr = this.getTimezoneAbbreviation();

    let timeString;
    if (this.isInDSTChange) {
      const endTimezoneAbbr = this.getTimezoneAbbreviation(this.end);
      timeString = `${start} ${timezoneAbbr} - ${end} ${endTimezoneAbbr}`;
    } else {
      timeString = `${start} - ${end} ${timezoneAbbr}`;
    }
    return timeString;
  }

  /**
   * Get a readable version of the current time
   * @returns {string}
   */
  getReadableCurrentTime() {
    return formatDateTimeWithMilitarySupport(
      DateTime.now().toISO(),
      this.warehouse.timezone,
      LuxonDateTimeFormats.MonthDayYearSlashedTimeAMPM,
      this.isMilitaryTimeEnabled,
      LuxonDateTimeFormats.MonthDayYearSlashedTime24
    );
  }

  getCurrentWarehouseISO() {
    return DateTime.now({ zone: this.warehouse.timezone }).toISO();
  }

  /**
   * Is appointment cancelled?
   * @returns {boolean}
   */
  isCancelled() {
    return this.doesStatusMatch(this.status, AppointmentStatus.Cancelled);
  }
  /**
   * Is appointment no show?
   * @returns {boolean}
   */
  isNoShow() {
    return this.doesStatusMatch(this.status, AppointmentStatus.NoShow);
  }
  /**
   * Is appointment requested?
   * @returns {boolean}
   */
  isRequested() {
    return isRequested(this.status);
  }
  /**
   * Is appointment scheduled?
   * @returns {boolean}
   */
  isScheduled() {
    return this.doesStatusMatch(this.status, AppointmentStatus.Scheduled);
  }
  /**
   * Has appointment arrived?
   * @returns {boolean}
   */
  isArrived() {
    return this.doesStatusMatch(this.status, AppointmentStatus.Arrived);
  }
  /**
   * Is appointment in progress?
   * @returns {boolean}
   */
  isInProgress() {
    return isInProgress(this.status);
  }
  /**
   * Is appointment completed?
   * @returns {boolean}
   */
  isCompleted() {
    return this.doesStatusMatch(this.status, AppointmentStatus.Completed);
  }

  /*
   * If the current status allows it to be rescheduled
   */
  canBeRescheduled() {
    return (
      this.doesStatusMatch(this.status, AppointmentStatus.Requested) ||
      this.doesStatusMatch(this.status, AppointmentStatus.Scheduled)
    );
  }

  /*
   * If the current status allows it to have its fields changed
   */
  canBeEdited() {
    return !this.doesStatusMatch(this.status, AppointmentStatus.Completed);
  }

  /*
   * If the current status allows it to be cancelled
   */
  canBeCancelled() {
    return !this.doesStatusMatch(this.status, AppointmentStatus.Completed);
  }

  /**
   * Helper to determine if a given status matches a provided AppointmentStatus enum value
   * @param {AppointmentStatus} appointmentStatus
   * @param {AppointmentStatus} status
   * @returns {boolean}
   */
  doesStatusMatch(appointmentStatus, status) {
    return appointmentStatus === status;
  }

  /**
   * Is appointment a reserve?
   * @returns {*}
   */
  isReserve() {
    return isReserve(this);
  }

  /**
   * Can the appointment be cancelled?
   * @returns {*}
   */
  canCancel() {
    return isCancelAllowed(this.status);
  }

  /**
   * Is the appointment end in the past?
   * @returns {boolean}
   */
  isPastAppointment() {
    return isPastAppointment({ end: this.end });
  }

  /**
   * Readable difference between arrival time and appointment start time
   * @returns {string}
   */
  getArrivalTimeDiff() {
    let readableDuration = '';
    const arrivalTime = this.getArrivalTime();
    if (arrivalTime) {
      readableDuration = this.getReadableDuration(this.start, arrivalTime, diffLabels.late);
    }

    return readableDuration;
  }

  /**
   * Readable diff between completion time and arrival time
   * @returns {string}
   */
  getInProgressTimeDiff() {
    let readableDuration = '';
    const arrivalTime = this.getArrivalTime();
    const inProgressTime = this.getInProgressTime();

    if (inProgressTime) {
      readableDuration = this.getReadableDuration(arrivalTime, inProgressTime, diffLabels.wait);
    }

    return readableDuration;
  }

  /**
   * Readable diff between completion time and arrival time
   * @returns {string}
   */
  getCompletedTimeDiff() {
    let readableDuration = '';
    const arrivalTime = this.getArrivalTime();
    const completedTime = this.getCompletedTime();
    if (completedTime) {
      readableDuration = this.getReadableDuration(arrivalTime, completedTime, diffLabels.dwell);
    }

    return readableDuration;
  }

  /**
   *
   * Returns a readable duration between two ISO datetimes
   * @param startISO
   * @param endISO
   * @param label
   * @returns {string}
   */
  getReadableDuration(startISO, endISO, label) {
    let readableDuration = '';
    const diff = this.makeTimeDiff(startISO, endISO, ['days', 'hours', 'minutes']);
    const diffSum = Object.values(diff).reduce((acc, val) => acc + val, 0);
    const hasDiff = diffSum > 0;

    if (hasDiff) {
      readableDuration += diff.days ? `${diff.days}` : '';
      readableDuration += diff.days ? 'd ' : '';
      readableDuration += diff.hours ? `${diff.hours}` : '';
      readableDuration += diff.hours ? 'h ' : '';
      readableDuration += diff.minutes ? `${diff.minutes}m` : '';
      readableDuration += label ? ` ${label}` : '';
    } else {
      if (label === diffLabels.dwell) {
        if (!hasDiff) {
          readableDuration = `0m ${diffLabels.dwell}`;
        }
      } else if (label === diffLabels.late) {
        readableDuration = diffSum === 0 ? 'On Time' : 'Early';
      } else if (label === diffLabels.wait) {
        if (!hasDiff) {
          readableDuration = 'No wait time';
        }
      }
    }

    return readableDuration.trim().replace(/  +/g, ' ');
  }

  /**
   * Returns formated status timestamp with the timezone settings
   * @param status
   * @returns {string}
   */
  getTimestamp(status) {
    return formatDateTimeWithMilitarySupport(
      this.statusTimeline[status],
      this.warehouse.timezone,
      LuxonDateTimeFormats.DateSlashedDashTimestamp12HourTimeAMPM,
      this.isMilitaryTimeEnabled,
      LuxonDateTimeFormats.DateSlashedDashTimestamp24HourTime
    );
  }

  /**
   * Returns an object with specified units between two ISO datetimes
   * @param startISO
   * @param endISO
   * @param units
   * @returns {DurationObjectUnits}
   */
  makeTimeDiff(
    startISO,
    endISO,
    units = ['years', 'months', 'days', 'hours', 'minutes', 'seconds', 'milliseconds']
  ) {
    const startDateTime = DateTime.fromISO(startISO, { zone: this.warehouse.timezone }).startOf(
      'minute'
    );
    const endDateTime = DateTime.fromISO(endISO, { zone: this.warehouse.timezone }).startOf(
      'minute'
    );
    return endDateTime.diff(startDateTime, units).toObject();
  }
  isWithin24Hours() {
    return isWithin24HoursWindow(this.start, this.warehouse.timezone);
  }

  /**
   * Arrival time from status timeline
   * @returns {*}
   */
  getArrivalTime() {
    return this.statusTimeline[AppointmentStatus.Arrived];
  }

  /**
   * In Progress time from status timeline
   * @returns {*}
   */
  getInProgressTime() {
    return this.statusTimeline[AppointmentStatus.InProgress];
  }

  /**
   * Completed time from status timeline
   * @returns {*}
   */
  getCompletedTime() {
    return this.statusTimeline[AppointmentStatus.Completed];
  }

  /**
   * Get a formatted arrival time - example in the printable appointment component
   * @returns {*|string}
   */
  getFormattedArrivalTime() {
    const arrivalTime = this.getArrivalTime();
    return arrivalTime
      ? formatDateTimeWithMilitarySupport(
          arrivalTime,
          this.warehouse.timezone,
          LuxonDateTimeFormats.MonthDayYearSlashedTimeAMPM,
          this.isMilitaryTimeEnabled,
          LuxonDateTimeFormats.MonthDayYearSlashedTime24
        )
      : 'Waiting for carrier to arrive';
  }
  /**
   * Get a formatted completed time - example in the printable appointment component
   * @returns {*|string}
   */
  getFormattedCompletedTime() {
    const completedTime = this.getCompletedTime();
    return completedTime
      ? formatDateTimeWithMilitarySupport(
          completedTime,
          this.warehouse.timezone,
          LuxonDateTimeFormats.MonthDayYearSlashedTimeAMPM,
          this.isMilitaryTimeEnabled,
          LuxonDateTimeFormats.MonthDayYearSlashedTime24
        )
      : 'Not completed yet';
  }

  getParentDock() {
    return this.dock.capacityParent ?? this.dock;
  }
}
