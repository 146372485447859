var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-sidebar",
    {
      class: `sidebar-cards-${_vm.componentId}`,
      attrs: {
        gap: "spacing-4",
        "side-width": "325px",
        "min-content-width": "400px",
        padding: "none",
      },
    },
    [
      _c(
        "m-box",
        { staticClass: "sidebar", attrs: { slot: "sidebar" }, slot: "sidebar" },
        [
          _c(
            "m-card",
            [
              _c(
                "m-card-body",
                { ref: "sidebar-body", staticClass: "flex-1" },
                [
                  _c(
                    "m-stack",
                    { staticClass: "height-100", attrs: { gap: "spacing-8" } },
                    [_vm._t("sidebar-content")],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "m-box",
        {
          staticClass: "card-content",
          attrs: { slot: "content" },
          slot: "content",
        },
        [
          _vm.putContentOutsideOfCard
            ? [_vm._t("main-content")]
            : _c(
                "m-card",
                [
                  _c(
                    "m-card-body",
                    { ref: "content-body", staticClass: "flex-1" },
                    [
                      _c(
                        "m-stack",
                        {
                          staticClass: "height-100",
                          attrs: { gap: _vm.mainContentSpacing },
                        },
                        [_vm._t("main-content")],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }