var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-dialog",
    _vm._b(
      {
        staticClass: "dialog",
        attrs: { open: _vm.value },
        on: { "m-close": _vm.handleCancel },
      },
      "m-dialog",
      [_vm.$attrs, _vm.$props],
      false
    ),
    [
      _vm.shouldDisplayHeader
        ? _c(
            "m-dialog-header",
            [
              _c("div", { staticClass: "text-left" }, [_vm._t("header")], 2),
              _vm.shouldDisplayCloseButton
                ? _c("m-dialog-close", { staticClass: "close-button" })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("m-dialog-body", [_vm._t("body")], 2),
      _vm.$slots.footer
        ? _c("m-dialog-footer", [_vm._t("footer")], 2)
        : _vm._e(),
      _vm.$slots.actions
        ? _c(
            "m-dialog-actions",
            [_vm._t("actions", null, { handleCancel: _vm.handleCancel })],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }