var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        attrs: {
          value: _vm.value,
          size: "medium",
          "should-display-close-button": !_vm.isLoading,
        },
        on: { input: _vm.updateVal },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("m-text", { attrs: { variant: "heading-sm-bold" } }, [
                  _vm._v("Cancel appointment"),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                !_vm.isLoading
                  ? _c(
                      "m-group",
                      [
                        _c("m-text", { attrs: { variant: "body-md" } }, [
                          _vm._v(
                            " This action will make the time slot available for others to book "
                          ),
                        ]),
                        _c("basic-form", {
                          ref: "cancellation-form",
                          attrs: { fields: _vm.fields },
                          on: {
                            submit: _vm.submit,
                            "update:can-submit": _vm.setCanSubmit,
                          },
                        }),
                      ],
                      1
                    )
                  : _c("v-loader", { attrs: { "is-loading": _vm.isLoading } }, [
                      _vm._v("Cancelling appointment"),
                    ]),
              ]
            },
            proxy: true,
          },
          {
            key: "actions",
            fn: function () {
              return [
                _c(
                  "m-group",
                  {
                    staticClass: "flex-nowrap p-t-4",
                    attrs: { gap: "spacing-2" },
                  },
                  [
                    [
                      _c(
                        "tertiary-button",
                        {
                          attrs: {
                            "test-id": "keep-appointment-btn",
                            disabled: _vm.isLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.updateVal(false)
                            },
                          },
                        },
                        [_vm._v(" Keep appointment ")]
                      ),
                      _c(
                        "error-button",
                        {
                          attrs: {
                            "test-id": "cancel-appointment-btn",
                            disabled: _vm.isLoading || !_vm.canSubmit,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$refs["cancellation-form"].submitForm()
                            },
                          },
                        },
                        [_vm._v(" Confirm cancellation ")]
                      ),
                    ],
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "dialog-base",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }