<template>
  <m-tag :class="`status-tag-${_uid}`" v-if="computedText">{{ computedText }}</m-tag>
</template>

<script>
import { AppointmentStatus } from '@satellite/../nova/core';

/**
 * Displayed a colored tag for appointment statuses
 * @displayName Appointment Status Tag
 */
export default {
  name: 'AppointmentStatusTag',
  props: {
    status: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: false
    },
    color: {
      type: String,
      required: false
    },
    textColor: {
      type: String,
      required: false
    }
  },
  computed: {
    computedText() {
      return this.text || this.novaCore.camelCaseToHumanReadable(this.status);
    },
    computedTextColor() {
      return this.textColor ??
        [AppointmentStatus.Requested, AppointmentStatus.InProgress].includes(this.status)
        ? '#000000'
        : '#ffffff';
    }
  },
  mounted() {
    this.$nextTick(() => {
      const bgColor = this.color
        ? `${this.color}`
        : `var(--status-${this.status.toLowerCase()}-base)`;
      this.util.appendStylesToShadowDomEls(
        document.querySelectorAll(`m-tag.status-tag-${this._uid}`),
        '.tag',
        `background-color: ${bgColor} !important; color: ${this.computedTextColor} !important`
      );
    });
  }
};
</script>
