var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-text",
    _vm._b(
      { attrs: { variant: _vm.$attrs.variant ?? "body-sm" } },
      "m-text",
      [_vm.$attrs],
      false
    ),
    [
      _vm._v(
        " Opendock is Powered by Loadsmart Inc. © " +
          _vm._s(new Date().getFullYear()) +
          " - All rights reserved "
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }