var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "sidebar-menu" } },
    [
      _c(
        "m-group",
        { attrs: { justify: "space-between", align: "center" } },
        [
          _c("v-logo", { attrs: { id: "sidebar-logo" } }),
          _c(
            "icon-button",
            {
              staticClass: "display-tablet",
              attrs: {
                "test-id": "close-sidebar-btn",
                "no-bg": "",
                "icon-color": "color-text-inverted",
                "icon-size": "25px",
              },
              on: {
                click: function ($event) {
                  return _vm.$store.commit("App/setIsSidebarOpen", false)
                },
              },
            },
            [_vm._v(" close ")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "divider" }),
      _c(
        "m-stack",
        {
          staticClass: "m-t-4",
          attrs: {
            gap: "spacing-2",
            align: "center",
            justify: "space-between",
          },
        },
        _vm._l(_vm.menuItems, function (menuItem) {
          return _c(
            "sidebar-button",
            {
              key: menuItem.label,
              attrs: {
                disabled: menuItem.isDisabled,
                color: menuItem.color,
                isActive: menuItem.isActive,
                "icon-color": "color-text-inverted",
                "leading-icon": menuItem.icon,
                "test-id": menuItem.testId,
              },
              on: {
                click: function ($event) {
                  menuItem.additionalAction?.()
                  _vm.$router.push(menuItem.route)
                  _vm.closeSidebar()
                },
              },
            },
            [_vm._v(" " + _vm._s(menuItem.label) + " ")]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }