export default {
  props: {
    /**
     * Used for enhanced/safer targeting
     * Needs to be bound to the root of the component it is used in
     * i.e. <template><div :data-testid="testId"></div></template>
     */
    testId: {
      type: String,
      required: true
    }
  }
};
