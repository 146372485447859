/**
 * Mixin to use when validating appointment fields
 */
export default {
  data() {
    return {
      validationIterator: 0, // Pass this as a prop to any fields you'd like to validate
      fieldErrors: {} // { field1: true, field2: false }
    };
  },
  computed: {
    /**
     * Checks all values in fieldErrors for true
     * @returns {boolean}
     */
    hasErrors() {
      let hasErrors = false;
      Object.values(this.fieldErrors).forEach(doesFieldHaveErrors => {
        if (doesFieldHaveErrors) {
          hasErrors = true;
        }
      });
      return hasErrors;
    }
  },
  methods: {
    /**
     * Call this to validate all fields being passed the validationIterator prop
     */
    validateFields() {
      this.validationIterator++;
    },
    /**
     * Use @update:error="hasError => updateFieldError(hasError, 'field1')" on the fields you are validating
     * @param {boolean} hasError
     * @param {string} field
     */
    updateFieldError(hasError, field) {
      this.$set(this.fieldErrors, field, hasError);
    }
  }
};
