<template>
  <m-field
    :data-testid="testId"
    class="field-wrapper"
    :class="[{ 'flex-1': !width, 'is-multiple': isMultiple }, `doc-upload-${_uid}`]"
    :status="hasError ? 'danger' : 'default'">
    <m-field-label v-if="label" slot="label" :data-testid="`${testId}-label`">
      {{ label }}
    </m-field-label>
    <m-group>
      <template v-if="shouldDisplayUploadButton">
        <div class="button-wrapper" :key="renderKey">
          <secondary-button
            :is-loading="isLoading"
            test-id="file-upload-btn"
            leading-icon="upload"
            :disabled="isButtonDisabled"
            is-block
            @click="handleFileImport">
            {{ uploadButtonLabel }}
          </secondary-button>
          <input
            ref="uploader"
            class="file-input"
            type="file"
            @change="onFileChanged"
            :multiple="isMultiple" />
        </div>
      </template>
      <m-grid gap="spacing-1" class="tag-group">
        <span class="tag-wrapper" v-for="fileKey in fileKeys" :key="fileKey">
          <m-tag>
            <v-icon class="p-r-1" color="color-text-tertiary">file-outline</v-icon>
            {{ getFileDisplayName(fileKey) }}
            <v-icon
              class="p-r-1 remove-file"
              color="color-text-tertiary"
              @click="removeFile(fileKey)">
              close
            </v-icon>
          </m-tag>
        </span>
      </m-grid>
    </m-group>

    <m-field-hint :data-testid="`${testId}-errors`">
      <span v-if="shouldDisplayMessages">{{ errorBucket.join(', ') }}</span>
      <span v-if="errorBucket.length">.&nbsp;</span>
      <span>{{ computedFieldHint }}</span>
      <span v-if="hint && shouldDisplayMessages" :class="{ 'p-l-1': errorBucket.length }">
        &nbsp;{{ hint }}
      </span>
    </m-field-hint>
  </m-field>
</template>

<script>
import fieldMixin from '@/components/mixins/fieldMixin';
import validatable from '@/components/mixins/validatable';
import testable from '@/components/mixins/testable';
import renderMixin from '@satellite/components/mixins/renderMixin';
import { SecondaryButton, VIcon } from '@/components';
import { isArray } from 'class-validator';
import {
  GlobalLimits,
  getDocumentNameFromUrl,
  isImageUrl,
  convertBytes,
  FileUnits
} from '@satellite/../nova/core';

/**
 * Document upload button/display
 * @displayName Document Upload
 */
export default {
  name: 'DocumentUpload',
  mixins: [fieldMixin, renderMixin, testable, validatable],
  components: { SecondaryButton, VIcon },
  props: {
    /**
     * @model
     */
    value: {
      type: [String, Array],
      required: false,
      default: () => []
    },
    /**
     * Allows multiple document uploads
     */
    isMultiple: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      mounted: false,
      isLoading: false,
      fileUrls: [],
      fileKeys: []
    };
  },
  computed: {
    selectedFiles() {
      return (isArray(this.value) ? this.value : [this.value]).filter(file => file);
    },
    uploadButtonLabel() {
      return `Add file${this.isMultiple ? 's' : ''}`;
    },
    computedFieldHint() {
      return this.isMultiple
        ? `Image, text, spreadsheet or zip files allowed. Up to ${GlobalLimits.MULTI_DOC_MAX_FILES_PER_APPOINTMENT.value} files, with a maximum size of ${this.maxUploadSize} each`
        : `One image, text, spreadsheet or zip allowed. Max size: ${this.maxUploadSize}`;
    },
    shouldDisplayUploadButton() {
      return this.isMultiple || this.selectedFiles.length === 0;
    },
    isButtonDisabled() {
      return (
        this.disabled ||
        (this.isMultiple
          ? this.selectedFiles.length === GlobalLimits.MULTI_DOC_MAX_FILES_PER_APPOINTMENT.value
          : false)
      );
    },
    maxUploadSize() {
      return `${convertBytes(GlobalLimits.MAX_STORAGE_FILE_SIZE_BYTES.value, FileUnits.MB)}${
        FileUnits.MB
      }`;
    }
  },
  methods: {
    /**
     * Determines if file is image for thumbnail display purposes
     * @returns {*|boolean}
     */
    isImageFile(fileKey) {
      return isImageUrl(fileKey);
    },
    /**
     * Handles file upload button click and sets button back to initial state after upload window is closed
     * @public
     */
    fileUploadClick() {
      window.addEventListener(
        'focus',
        () => {
          this.isLoading = false;
        },
        { once: true }
      );

      this.$refs.fileinput.$refs.input.click();
    },
    /**
     * Handles file selection change
     * @public
     * @returns {Promise<void>}
     */
    async onFileChanged(e) {
      const newFiles = e.target.files;
      const remainingSlots =
        GlobalLimits.MULTI_DOC_MAX_FILES_PER_APPOINTMENT.value - this.fileKeys.length;

      if (newFiles.length > remainingSlots) {
        this.notify(
          `Maximum of ${GlobalLimits.MULTI_DOC_MAX_FILES_PER_APPOINTMENT.value} files allowed`,
          'error'
        );

        return;
      }

      this.$emit('uploading');
      this.isLoading = true;
      if (newFiles.length > 0) {
        const promises = Array.from(newFiles).map(selectedFile => {
          let formData = new FormData();
          formData.append('file', selectedFile);
          return axios.post('/storage', formData);
        });

        try {
          const responses = await Promise.all(promises);
          responses.forEach(response => {
            this.fileUrls.push(response.data.url);
            this.fileKeys.push(response.data.key);
          });
        } finally {
          this.isLoading = false;
        }
      }

      this.internalValue = this.fileKeys;
      this.$emit('uploaded');
    },
    removeFile(fileKey) {
      this.fileUrls = this.fileUrls.filter(url => !url.endsWith(fileKey));
      this.fileKeys = this.fileKeys.filter(key => key !== fileKey);
      this.internalValue = this.fileKeys;
    },
    handleFileImport() {
      this.isLoading = true;

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener('focus', () => (this.isLoading = false), { once: true });

      // Trigger click on the FileInput
      this.$refs.uploader.click();
    },
    getFileDisplayName(fileKey) {
      // NOTE:
      // The appended icon has to be absolutely positioned so it is not cut off when the tag component's max-width is reached
      // We add three spaces to prevent appended icon from obscuring the file name and to match the prepended icon spacing
      return `${getDocumentNameFromUrl(fileKey)}${'\u00A0\u00A0\u00A0'}`;
    }
  },
  mounted() {
    this.fileUrls = this.value ? (isArray(this.value) ? this.value : [this.value]) : [];

    // Set default value for the v-model
    if (this.fileUrls?.length) {
      this.fileKeys = this.fileUrls.map(value => {
        try {
          return new URL(value).pathname.slice(1);
        } catch {
          return value;
        }
      });
    }

    this.mounted = true;

    if (this.fileKeys.length > 0) {
      this.internalValue = this.fileKeys;
    }
  },
  watch: {
    isButtonDisabled() {
      this.rerender();
    },
    value: {
      handler() {
        this.$nextTick(() => {
          const nodes = document.querySelectorAll(`.doc-upload-${this._uid} m-tag:not(.adjusted)`);
          this.util.appendStylesToShadowDomEls(
            nodes,
            '.tag',
            'width:100%;padding:8px 20px 8px 12px !important;'
          );
          nodes.forEach(node => node.classList.add('adjusted'));
        });
      },
      immediate: true
    }
  }
};
</script>

<style scoped lang="scss">
.file-input {
  display: none;
}
.tag-wrapper {
  position: relative;
}
.button-wrapper {
  width: 112px;
}
.tag-group {
  max-width: 405px;
}

.is-multiple {
  .tag-group {
    grid-template-columns: repeat(2, 1fr);
  }
}
.remove-file {
  position: absolute;
  background-color: $m-color-background-tertiary;
  padding: 0;
  top: 9px;
  right: 8px;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
  }
}
</style>
