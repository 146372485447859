<template>
  <m-group>
    <img
      v-if="customLogoPath(warehouse, $s3BaseUrl)"
      class="warehouse-logo"
      :src="customLogoPath(warehouse, $s3BaseUrl)"
      :alt="`${warehouse.name} logo`" />
    <m-stack gap="spacing-1" justify="center">
      <router-link
        class="link"
        :to="{ name: 'warehouse.details', params: { warehouseId: warehouse.id } }"
        v-if="linkToWarehouse">
        {{ warehouse.name }}
      </router-link>
      <m-text variant="body-md-bold" v-else>{{ warehouse.name }}</m-text>
      <m-text variant="body-sm-bold" color="color-text-tertiary">{{ warehouse.org.name }}</m-text>
    </m-stack>
  </m-group>
</template>
<script>
import { customLogoPath, propValidatorHelper } from '@satellite/plugins/util';

/**
 * Warehouse details header that includes warehouse logo, name, and org name
 * @displayName WarehouseHeader
 */
export default {
  methods: { customLogoPath },
  props: {
    /**
     * Partial Warehouse
     * @values { name: string, org: { name: string}, settings: { customLogo?: string }}
     */
    warehouse: {
      type: Object,
      required: true,
      validator(value) {
        const isValid = ['name', 'org', 'settings'].every(requiredKey => {
          return Object.keys(value).includes(requiredKey);
        });
        return propValidatorHelper({
          value,
          isValid: isValid,
          componentName: 'WarehouseHeader',
          propName: 'warehouse',
          message: ''
        });
      }
    },
    linkToWarehouse: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.warehouse-logo {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid $m-color-background-tertiary;
}

.link {
  // Miranda link color
  color: $m-color-text-link;
  font-weight: 700;

  // body-md-underline
  font-family: var(--m-font-family-default, 'Manrope', sans-serif);
  font-size: 14px;
  font-style: normal;
  line-height: 140%; /* 19.6px */
  text-decoration-line: underline;
}
</style>
