import { getQueryStringFromOptions, getResponse } from '@satellite/services/serviceUtilities';

/**
 * options: { fields: string[], joins: string[], includeMetaData: boolean }
 */

export default {
  async getLoadtypeById(loadtypeId, options) {
    const response = await axios.get(`loadtype/${loadtypeId}`);
    return getResponse(response, options);
  },
  async getLoadtypes(params, options = {}) {
    const queryString = getQueryStringFromOptions(options);
    const response = await axios.get(`loadtype?${queryString}`, {
      params
    });
    return getResponse(response, options);
  },
  async getAvailability(id, params, options) {
    const response = await axios.post(`loadtype/${id}/get-availability`, params);
    return getResponse(response, options);
  }
};
