export default {
  name: 'fieldMixin',
  props: {
    /**
     * Field label
     */
    label: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Field hint
     */
    hint: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Field name
     */
    fieldName: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Field placeholder
     */
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Width of input
     */
    width: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    computedWidth() {
      return (
        (this.widthValue ? `width:${this.widthValue}${this.widthUnit ?? 'px'}` : 'auto') +
        ';max-width: 100%;'
      );
    }
  },
  data() {
    return {
      widthValue: null,
      widthUnit: null
    };
  },
  mounted() {
    this.setWidth();
  },
  methods: {
    /**
     * @private
     * Parses the size prop to get the value/unit
     */
    setWidth() {
      const { sizeValue, sizeUnit } = this.util.computeSize(this.width);
      if (sizeValue && sizeUnit) {
        this.widthValue = sizeValue;
        this.widthUnit = sizeUnit;
      }
    }
  }
};
