var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-stack",
    {
      staticClass: "height-100",
      attrs: { align: "center", justify: "center" },
    },
    [
      _c("img", { attrs: { src: _vm.imagePath, alt: _vm.imageAlt } }),
      _c(
        "m-stack",
        { staticClass: "content-wrapper", attrs: { gap: "spacing-2" } },
        [
          _vm._t("default", function () {
            return [
              _c("m-text", { attrs: { variant: "body-md-bold" } }, [
                _vm._v(_vm._s(_vm.header)),
              ]),
              _c("m-text", { attrs: { variant: "body-sm" } }, [
                _vm._v(" " + _vm._s(_vm.message) + " "),
              ]),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }