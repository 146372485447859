var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("dialog-base", {
    attrs: {
      value: _vm.value,
      size: "medium",
      "data-testid": "create-company-dlg",
    },
    on: { input: (val) => _vm.$emit("input", val) },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_vm._v(" " + _vm._s(_vm.headerText) + " ")]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "m-stack",
              { attrs: { gap: "spacing-3" } },
              [
                _vm.existingCompany
                  ? _c(
                      "m-table",
                      [
                        _c(
                          "m-table-head",
                          [
                            _c(
                              "m-table-row",
                              [
                                _c("m-table-cell", [_vm._v("Company name")]),
                                _c("m-table-cell", [_vm._v("USDOT number")]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "m-table-body",
                          [
                            _c(
                              "m-table-row",
                              [
                                _c("m-table-cell", [
                                  _vm._v(_vm._s(_vm.existingCompany.name)),
                                ]),
                                _c("m-table-cell", [
                                  _vm._v(_vm._s(_vm.existingCompany.usdot)),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm.checkingForExistingCompany
                  ? _c("v-loader", { attrs: { "is-loading": "" } }, [
                      _vm._v(" This won't take long... "),
                    ])
                  : _c("basic-form", {
                      ref: "create-company-form",
                      attrs: { fields: _vm.fields },
                      on: { submit: _vm.createAndAttachCompany },
                    }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c(
              "secondary-button",
              {
                attrs: {
                  disabled: _vm.checkingForExistingCompany,
                  "test-id": "cancel-create-company-btn",
                },
                on: { click: _vm.handleCancelClick },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      !_vm.existingCompany
                        ? "Cancel"
                        : "Back to company creation"
                    ) +
                    " "
                ),
              ]
            ),
            !_vm.existingCompany
              ? _c(
                  "primary-button",
                  {
                    attrs: {
                      disabled: _vm.checkingForExistingCompany,
                      "test-id": "confirm-create-company-btn",
                      "is-loading": _vm.isLoading,
                    },
                    on: { click: _vm.createAndAttachCompany },
                  },
                  [_vm._v(" Confirm ")]
                )
              : _c(
                  "primary-button",
                  {
                    attrs: {
                      disabled: _vm.checkingForExistingCompany,
                      "test-id": "confirm-create-company-btn",
                      "is-loading": _vm.isLoading,
                    },
                    on: { click: _vm.attachExistingCompany },
                  },
                  [_vm._v(" Choose existing company ")]
                ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }