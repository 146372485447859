import { mapGetters } from 'vuex';

/**
 * Store getters map
 * @mixin
 * @displayName Mapped Getters
 */
export default {
  computed: {
    ...mapGetters({
      $accessToken: 'Auth/accessToken',
      $isMrPreview: 'Auth/isMrPreview',
      $previewWarehouseId: 'Auth/previewWarehouseId',
      $consolidatedSettings: 'App/consolidatedSettings',
      $getSettingValue: 'Settings/settingValue',
      $refNumSettings: 'Settings/refNumSettings',
      $isMilitaryTimeEnabled: 'Settings/isMilitaryTimeEnabled',
      $isRescheduleByCarrierForbidden: 'Settings/isRescheduleByCarrierForbidden',
      $isRequestedStatusEnabled: 'Settings/isRequestedStatusEnabled',
      $s3BaseUrl: 'App/s3BaseUrl',
      $orgsToExcludeFromSchedulingPortal: 'App/orgsToExcludeFromSchedulingPortal'
    })
  }
};
