/**
 * plugins/webfontloader.js
 *
 * webfontloader documentation: https://github.com/typekit/webfontloader
 */

import * as webFontLoader from 'webfontloader';

export async function loadFonts() {
  webFontLoader.load({
    google: {
      families: ['Plus+Jakarta+Sans:500,600,700', 'Manrope:500,600,700']
    }
  });
}
