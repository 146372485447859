var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "appointment-list", class: `appointment-list-${_vm._uid}` },
    [
      _c(
        "div",
        { ref: "sticky-header", staticClass: "header" },
        [
          _c(
            "m-group",
            { attrs: { align: "center" } },
            [
              _c(
                "text-field",
                {
                  staticClass: "appointment-search-field m-r-5",
                  attrs: {
                    placeholder: "Search",
                    "test-id": "my-appointments-search-field",
                  },
                  model: {
                    value: _vm.filters.searchStr,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "searchStr", $$v)
                    },
                    expression: "filters.searchStr",
                  },
                },
                [
                  _c(
                    "span",
                    { attrs: { slot: "leading" }, slot: "leading" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "flex",
                          attrs: { size: "22px", color: "color-text-tertiary" },
                        },
                        [_vm._v("magnify")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("date-range-picker", {
                ref: "appointment-list-date-range-picker",
                attrs: {
                  "use-custom-trigger": "",
                  "test-id": "appointment-list-date-range-picker",
                },
                model: {
                  value: _vm.filters.dateRange,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters, "dateRange", $$v)
                  },
                  expression: "filters.dateRange",
                },
              }),
              _c("button-toggle-group", {
                attrs: {
                  id: "toggle-buttons",
                  buttons: [
                    {
                      value: "custom",
                      label: _vm.customDateRangeDisplay,
                      icon: "calendar",
                    },
                    { value: "7_days", label: "7 Days" },
                    { value: "today", label: "Today" },
                    { value: "all", label: "All" },
                  ],
                },
                model: {
                  value: _vm.namedDateRange,
                  callback: function ($$v) {
                    _vm.namedDateRange = $$v
                  },
                  expression: "namedDateRange",
                },
              }),
              _c("v-spacer"),
              !_vm.loading
                ? _c(
                    "m-text",
                    {
                      attrs: {
                        variant: "body-md",
                        color: "color-text-tertiary",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.totalItems) +
                          " " +
                          _vm._s(_vm.resultText) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "m-group",
            {
              staticClass: "p-t-2",
              attrs: { justify: "space-between", align: "center" },
            },
            [
              _vm.statusFilter
                ? _c(
                    "m-tag",
                    [
                      _vm._v(" " + _vm._s(_vm.statusFilter) + " "),
                      _c(
                        "v-icon",
                        {
                          staticClass: "m-l-2 close-icon",
                          attrs: { size: "12" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("removeStatusFilter")
                            },
                          },
                        },
                        [_vm._v(" close ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.statusFilter
                ? _c(
                    "tertiary-button",
                    {
                      attrs: {
                        "leading-icon": "close",
                        "test-id": "appointment-list-clear-filters-btn",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("removeStatusFilter")
                        },
                      },
                    },
                    [_vm._v(" Clear Filters ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-wrapper" },
        [
          _c("data-table-base", {
            staticClass: "clickable-rows appointment-list-table",
            attrs: {
              headers: _vm.headers,
              items: _vm.appointments,
              "footer-props": {
                itemsPerPageOptions: [5, 10, 15, 30],
              },
              "item-key": "id",
              loading: _vm.loading,
              "fixed-header": "",
              "data-testid": "appointment-list-table",
              "custom-filter": _vm.searchRows,
              "server-items-length": _vm.totalItems,
            },
            on: { "click:row": (item) => _vm.handleRowClick(item) },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.headers, function (header) {
                  return {
                    key: `item.${header.value}`,
                    fn: function ({ value }) {
                      return [
                        _c("span", {
                          key: header.value,
                          domProps: {
                            innerHTML: _vm._f("highlight")(
                              value,
                              _vm.regexSafeSearch,
                              null,
                              null,
                              _vm.searchHighlightClass
                            ),
                          },
                        }),
                      ]
                    },
                  }
                }),
                {
                  key: `item.status`,
                  fn: function ({ item }) {
                    return [
                      _c("appointment-status-tag", {
                        attrs: { status: item.status },
                      }),
                    ]
                  },
                },
                {
                  key: `item.actions`,
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "row-actions" },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                size: "25px",
                                color: "color-text-tertiary",
                              },
                            },
                            [_vm._v("chevron-right")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "loading",
                  fn: function () {
                    return [
                      _c(
                        "v-loader",
                        {
                          staticClass: "m-y-12",
                          attrs: { "is-loading": _vm.loading },
                        },
                        [_vm._v("Loading appointments")]
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: `item.start`,
                  fn: function ({ item }) {
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getTimeInWarehouseTimezone(
                                item,
                                `${_vm.novaCore.LuxonDateTimeFormats.LongDateShortMonth} @ ${_vm.novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM} - ${_vm.novaCore.LuxonDateTimeFormats.AbbreviatedNamedOffset}`,
                                `${_vm.novaCore.LuxonDateTimeFormats.LongDateShortMonth} @ ${_vm.novaCore.LuxonDateTimeFormats.Extended24HrTime} - ${_vm.novaCore.LuxonDateTimeFormats.AbbreviatedNamedOffset}`
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "no-data",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "p-10" },
                        [
                          _vm.initialDataFetched
                            ? _c(
                                "no-results",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "footer",
                                        fn: function () {
                                          return [
                                            _c(
                                              "primary-button",
                                              {
                                                attrs: {
                                                  "test-id":
                                                    "no-results-clear-filters-btn",
                                                  size: "small",
                                                },
                                                on: { click: _vm.clearFilters },
                                              },
                                              [_vm._v(" Clear Filters ")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    3347377410
                                  ),
                                },
                                [
                                  _c(
                                    "m-text",
                                    { attrs: { variant: "md-bold" } },
                                    [
                                      _vm._v(
                                        "We couldn't find any appointments"
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "m-text",
                                    { attrs: { variant: "body-sm" } },
                                    [
                                      _vm._v(
                                        " You can try different filters or clearing all of them "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }