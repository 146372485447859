var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-sidebar",
    {
      staticClass: "sidebar-component",
      attrs: { gap: "none", "min-content-width": "600px" },
    },
    [
      _c(
        "m-box",
        {
          staticClass: "flex flex-column",
          attrs: { slot: "sidebar", id: "sidebar" },
          slot: "sidebar",
        },
        [
          _c(
            "header",
            { staticClass: "heading" },
            [
              _c("v-logo"),
              _c(
                "m-text",
                {
                  staticClass: "uppercase",
                  attrs: {
                    variant: "heading-md-bold",
                    as: "h2",
                    color: "color-text-secondary-inverted",
                  },
                },
                [_vm._v(" Scheduling Portal ")]
              ),
            ],
            1
          ),
          _c(
            "m-stack",
            {
              staticClass: "benefits-list hide-mobile",
              attrs: { gap: "spacing-6" },
            },
            [
              _c(
                "m-stack",
                { staticClass: "list-item", attrs: { gap: "none" } },
                [
                  _c(
                    "m-group",
                    { attrs: { align: "center", padding: "none" } },
                    [
                      _c(
                        "v-icon",
                        { attrs: { color: "white", size: "font-size-7" } },
                        [_vm._v("view-grid-plus")]
                      ),
                      _c(
                        "m-text",
                        {
                          attrs: {
                            variant: "heading-md-bold",
                            as: "h3",
                            color: "color-text-secondary-inverted",
                          },
                        },
                        [_vm._v(" One-stop-shop ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "m-text",
                    {
                      attrs: {
                        variant: "body-md",
                        as: "p",
                        color: "color-text-secondary-inverted",
                      },
                    },
                    [
                      _vm._v(
                        " No more keeping a list of URLs, user names, and passwords for every shipper. "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "m-stack",
                { staticClass: "list-item", attrs: { gap: "none" } },
                [
                  _c(
                    "m-group",
                    { attrs: { align: "center", padding: "none" } },
                    [
                      _c(
                        "v-icon",
                        { attrs: { color: "white", size: "font-size-7" } },
                        [_vm._v("lightning-bolt")]
                      ),
                      _c(
                        "m-text",
                        {
                          attrs: {
                            variant: "heading-md-bold",
                            as: "h3",
                            color: "color-text-secondary-inverted",
                          },
                        },
                        [_vm._v(" Quick & Convenient ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "m-text",
                    {
                      attrs: {
                        variant: "body-md",
                        as: "p",
                        color: "color-text-secondary-inverted",
                      },
                    },
                    [
                      _vm._v(
                        " Schedule appointments online without making a phone call or waiting for email responses. "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "m-stack",
                { staticClass: "list-item", attrs: { gap: "none" } },
                [
                  _c(
                    "m-group",
                    { attrs: { align: "center", padding: "none" } },
                    [
                      _c(
                        "v-icon",
                        { attrs: { color: "white", size: "font-size-7" } },
                        [_vm._v("meditation")]
                      ),
                      _c(
                        "m-text",
                        {
                          attrs: {
                            variant: "heading-md-bold",
                            as: "h3",
                            color: "color-text-secondary-inverted",
                          },
                        },
                        [_vm._v(" Easy for drivers ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "m-text",
                    {
                      attrs: {
                        variant: "body-md",
                        as: "p",
                        color: "color-text-secondary-inverted",
                      },
                    },
                    [
                      _vm._v(
                        " Confirmation emails with appointment date, time, address, check-in instructions, and contact information for the warehouse. "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-spacer", { staticClass: "hide-mobile" }),
          _c("copyright-text", {
            staticClass: "hide-mobile",
            attrs: { color: "color-text-inverted" },
          }),
          _c("auth-links", {
            staticClass: "hide-mobile",
            attrs: { color: "color-text-inverted" },
          }),
        ],
        1
      ),
      _c(
        "m-box",
        { attrs: { slot: "content", id: "main-content" }, slot: "content" },
        [
          _c(
            "m-stack",
            { attrs: { id: "registration-form-container" } },
            [
              _c("registration-form"),
              _c("copyright-text", { staticClass: "hide-mid-desktop" }),
              _c("auth-links", { staticClass: "hide-mid-desktop" }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }