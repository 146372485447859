<template>
  <m-stack class="flex-1 height-100">
    <m-group class="search-header" align="center" justify="space-between">
      <m-text variant="heading-sm-bold">Select a load type</m-text>
      <text-field
        class="search-field"
        width="280px"
        test-id="loadtype-search-field"
        field-name="loadtype_search"
        placeholder="Search loadtype"
        v-model="searchStr"
        :leading-icon="{ icon: 'magnify', color: 'color-text-tertiary' }"></text-field>
    </m-group>

    <!-- TODO: Include this in the card grid?? -->
    <v-loader v-if="isLoading && isNull(results)" :is-loading="isLoading">
      Looking for load types
    </v-loader>

    <card-grid
      v-if="!isLoading && !isNull(results)"
      test-id="loadtype-search-results"
      :items="results"
      :items-per-row="2"
      :selected-item="value"
      :display-outer-card="false"
      outer-card-body-styles="padding-top:0 !important;"
      @click="handleItemClick">
      <template v-slot:row="{ item }">
        <m-stack gap="none" align="flex-start" class="flex-1">
          <m-group align="center" justify="space-between" class="width-100">
            <m-stack class="flex-1" gap="none" align="flex-start">
              <m-group gap="spacing-2" align="center" justify="flex-start">
                <loadtype-direction :direction="item.direction"></loadtype-direction>
                <m-text variant="body-md-bold">{{ item.name }}</m-text>
              </m-group>
              <m-text variant="body-md">
                {{ item.direction }}
                <span v-if="!hideDuration">- {{ item.duration_min }} minutes</span>
              </m-text>
            </m-stack>

            <icon-button
              :disabled="!item.description"
              :test-id="`description-trigger-${item.id}`"
              icon-size="20px"
              color="color-text-secondary"
              @click.stop="showDescription(item)">
              information-slab-circle
            </icon-button>
          </m-group>
          <m-group class="width-100 m-t-4">
            <m-stack class="flex-1" align="flex-start" gap="none">
              <m-text variant="body-md" color="color-text-tertiary">Operation</m-text>
              <m-text variant="body-md" v-if="item.operation">{{ item.operation }}</m-text>
              <no-content v-else></no-content>
            </m-stack>
            <m-stack class="flex-1" align="flex-start" gap="none">
              <m-text variant="body-md" color="color-text-tertiary">Equipment Type</m-text>
              <m-text v-if="item.equipmentType" variant="body-md">{{ item.equipmentType }}</m-text>
              <no-content v-else></no-content>
            </m-stack>
            <m-stack class="flex-1" align="flex-start" gap="none">
              <m-text variant="body-md" color="color-text-tertiary">Transportation Mode</m-text>
              <m-text v-if="item.transportationMode" variant="body-md">
                {{ item.transportationMode }}
              </m-text>
              <no-content v-else></no-content>
            </m-stack>
          </m-group>
        </m-stack>
      </template>
      <template v-slot:no-items>
        <no-results v-if="searchStr"></no-results>
        <no-results
          v-else
          not-found
          title="No load types available on this warehouse"
          subtitle="Please contact the facility"></no-results>
      </template>
    </card-grid>
    <dialog-base
      v-model="dialogs.description"
      size="medium"
      @close="descriptionItem = null"
      :is-persistent="false">
      <template v-slot:header>
        <m-text variant="header-md-bold">{{ descriptionItem.name }}</m-text>
      </template>
      <template v-slot:body>
        <m-text variant="body-md" color="color-text-tertiary">Description</m-text>
        <m-text variant="body-md">{{ descriptionItem.description }}</m-text>
      </template>
    </dialog-base>
  </m-stack>
</template>

<script>
import {
  TextField,
  CardGrid,
  IconButton,
  DialogBase,
  VLoader,
  NoResults,
  NoContent
} from '@/components';
import LoadtypeDirection from '@/components/elements/loadtype/LoadtypeDirection.vue';
import { isNull } from 'lodash';

export default {
  name: 'LoadtypeSearch',
  components: {
    LoadtypeDirection,
    TextField,
    CardGrid,
    IconButton,
    DialogBase,
    VLoader,
    NoResults,
    NoContent
  },
  props: {
    /**
     * Selected Loadtype
     * @model
     */
    value: {
      type: Object,
      required: false,
      default: null
    },
    /**
     * Warehouse where appointment will be booked
     */
    warehouseId: {
      type: String,
      required: true
    },
    hideDuration: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      searchStr: null,
      results: null,
      descriptionItem: {},
      isLoading: false,
      dialogs: {
        description: false
      }
    };
  },
  mounted() {
    this.setResults();
  },
  methods: {
    isNull,
    async setResults() {
      this.results = null;
      this.isLoading = true;
      const queryParams = {
        warehouseId: this.warehouseId,
        showOnlyAssignedLoadTypes: true,
        s: {
          allowCarrierScheduling: true
        }
      };

      if (this.searchStr) {
        queryParams.s = { ...queryParams.s, name: { $contL: this.searchStr } };
      }

      await this.services.loadtype
        .getLoadtypes(queryParams)
        .then(response => {
          this.results = response;
        })
        .finally(() => (this.isLoading = false));
    },
    search: _.debounce(async function () {
      await this.setResults();
    }, 350),
    showDescription(loadtype) {
      this.descriptionItem = loadtype;
      this.dialogs.description = true;
    },
    handleItemClick(item) {
      /**
       * v-model input event
       * @event input
       * @property {object} Loadtype
       */
      this.$emit('input', item);
    }
  },
  watch: {
    searchStr() {
      this.search();
    }
  }
};
</script>

<style lang="scss" scoped>
.search-header {
  position: sticky;
  top: -$m-spacing-6;
  padding: $m-spacing-4 0;
  z-index: 1;
  background: $m-color-background-primary;
  @media (max-width: $midDesktopBreakpoint) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0;
    top: -$m-spacing-4;

    ::v-deep {
      m-field {
        width: 100%;
      }
      m-text-field {
        width: 100% !important;
      }
    }
  }
}

::v-deep {
  .row-items {
    &-2 {
      grid-template-columns: repeat(2, 1fr);

      @media (max-width: $midDesktopBreakpoint) {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>
