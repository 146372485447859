// initial state
const state = {
  pageTitle: ''
};

// actions
const actions = {};

// mutations
const mutations = {
  setPageTitle(state, pageTitle) {
    state.pageTitle = pageTitle;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
