<template>
  <button-base
    variant="icon"
    v-bind="[$attrs, $props]"
    @click="handleClick($event)"
    :test-id="testId">
    <v-icon :size="iconSize ?? $attrs.size" :disabled="$attrs.disabled" :color="iconColor">
      <!-- @slot MDI icon to display. Can optionally omit 'mdi-' prefix from icon e.g. "mdi-email" or "email" will both work -->
      <slot></slot>
    </v-icon>
  </button-base>
</template>

<script>
import VIcon from '@/components/elements/basic/VIcon';
import ButtonBase from '@/components/elements/buttons/ButtonBase';
import buttonMixin from '@satellite/components/mixins/buttonMixin';
import testable from '@/components/mixins/testable';

/**
 * Button Base with hard-coded "icon" variant
 * @displayName Icon Button
 */
export default {
  name: 'IconButton',
  mixins: [buttonMixin, testable],
  components: { ButtonBase, VIcon },
  props: {
    /**
     * Size of the icon - can be miranda font size token, small/default/large, or explicit value/unit e.g. 20px, 10rem
     * If not provided, the button's "size" prop will be used
     */
    iconSize: {
      type: String,
      required: false
    },
    iconColor: {
      type: String,
      required: false,
      default: 'color-text-primary'
    }
  }
};
</script>
