var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("internal-page", {
    attrs: { "page-title": "User settings", "allow-overflow": "" },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "m-stack",
              [
                _c("collapsable-card", {
                  key: `personal-info-${_vm.cards.personalInfo.resetIterator}`,
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c(
                            "m-group",
                            { attrs: { align: "center", gap: "spacing-1" } },
                            [
                              _c("v-icon", { attrs: { size: "15px" } }, [
                                _vm._v("account"),
                              ]),
                              _c(
                                "m-text",
                                { attrs: { variant: "body-md-bold" } },
                                [_vm._v("Personal information")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "m-text",
                            {
                              attrs: {
                                variant: "body-md",
                                color: "color-text-tertiary",
                              },
                            },
                            [
                              _vm._v(
                                " Edit your name, country, timezone, or phone "
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "body",
                      fn: function () {
                        return [
                          !_vm.loaders.personalInfo
                            ? _c("basic-form", {
                                ref: "personal-info-form",
                                attrs: { fields: _vm.personalFields },
                                on: { submit: _vm.savePersonalInfo },
                              })
                            : _c(
                                "v-loader",
                                {
                                  staticClass: "m-y-10",
                                  attrs: {
                                    "is-loading": _vm.loaders.personalInfo,
                                  },
                                },
                                [_vm._v(" Updating personal information ")]
                              ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "footer",
                      fn: function () {
                        return [
                          _c(
                            "m-group",
                            { attrs: { gap: "spacing-2" } },
                            [
                              _c(
                                "primary-button",
                                {
                                  attrs: {
                                    disabled: _vm.loaders.personalInfo,
                                    "test-id": "save-personal-info-btn",
                                  },
                                  on: { click: _vm.triggerPersonalInfoSubmit },
                                },
                                [_vm._v(" Save changes ")]
                              ),
                              _c(
                                "secondary-button",
                                {
                                  attrs: {
                                    disabled: _vm.loaders.personalInfo,
                                    "test-id": "close-personal-info-btn",
                                  },
                                  on: { click: _vm.cancelPersonalInfo },
                                },
                                [_vm._v(" Cancel ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _c("collapsable-card", {
                  key: `password-${_vm.cards.password.resetIterator}`,
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c(
                            "m-group",
                            { attrs: { align: "center", gap: "spacing-1" } },
                            [
                              _c("v-icon", { attrs: { size: "15px" } }, [
                                _vm._v("lock"),
                              ]),
                              _c(
                                "m-text",
                                { attrs: { variant: "body-md-bold" } },
                                [_vm._v("Password")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "m-text",
                            {
                              attrs: {
                                variant: "body-md",
                                color: "color-text-tertiary",
                              },
                            },
                            [_vm._v(" Change your current password ")]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "body",
                      fn: function () {
                        return [
                          _c("basic-form", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.loaders.password,
                                expression: "!loaders.password",
                              },
                            ],
                            ref: "password-form",
                            attrs: {
                              fields: _vm.passwordFields,
                              "enable-field-update-events": "",
                            },
                            on: {
                              "update:new-password": _vm.validateNewPassword,
                              "update:new-password-confirm":
                                _vm.validateNewPassword,
                              submit: _vm.savePassword,
                            },
                          }),
                          _c(
                            "v-loader",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.loaders.password,
                                  expression: "loaders.password",
                                },
                              ],
                              staticClass: "m-y-10",
                              attrs: { "is-loading": _vm.loaders.password },
                            },
                            [_vm._v(" Updating password ")]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "footer",
                      fn: function () {
                        return [
                          _c(
                            "m-group",
                            { attrs: { gap: "spacing-2" } },
                            [
                              _c(
                                "primary-button",
                                {
                                  attrs: {
                                    disabled: _vm.loaders.password,
                                    "test-id": "save-password-btn",
                                  },
                                  on: { click: _vm.triggerPasswordSubmit },
                                },
                                [_vm._v(" Save new password ")]
                              ),
                              _c(
                                "secondary-button",
                                {
                                  attrs: {
                                    disabled: _vm.loaders.password,
                                    "test-id": "close-password-btn",
                                  },
                                  on: { click: _vm.cancelPassword },
                                },
                                [_vm._v(" cancel ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _c(
                  "m-card",
                  [
                    _c(
                      "m-card-body",
                      [
                        _c(
                          "m-group",
                          {
                            staticClass: "p-y-0 p-x-1",
                            attrs: {
                              align: "center",
                              justify: "space-between",
                            },
                          },
                          [
                            _c(
                              "m-stack",
                              {
                                attrs: {
                                  gap: "spacing-1",
                                  align: "flex-start",
                                },
                              },
                              [
                                _c(
                                  "m-group",
                                  {
                                    attrs: {
                                      align: "center",
                                      gap: "spacing-1",
                                    },
                                  },
                                  [
                                    _c("v-icon", { attrs: { size: "20px" } }, [
                                      _vm._v("warehouse"),
                                    ]),
                                    _c(
                                      "m-text",
                                      { attrs: { variant: "body-md-bold" } },
                                      [_vm._v("Company")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "m-text",
                                  {
                                    attrs: {
                                      variant: "body-md",
                                      color: "color-text-tertiary",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$me.company.name) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "tertiary-button",
                              {
                                attrs: {
                                  "test-id": "change-company-btn",
                                  "trailing-icon": "chevron-right",
                                  "icon-size": "1.5rem",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$router.push({
                                      name: "settings.company",
                                    })
                                  },
                                },
                              },
                              [_vm._v(" Change company ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }