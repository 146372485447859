var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-group",
    [
      _c(
        "v-link",
        _vm._b(
          {
            attrs: { size: "small", href: _vm.$homePageUrl, target: "_blank" },
          },
          "v-link",
          _vm.$attrs,
          false
        ),
        [_vm._v(" Opendock Website ")]
      ),
      _c(
        "v-link",
        _vm._b(
          {
            attrs: {
              size: "small",
              href: _vm.$termsAndConditionsUrl,
              target: "_blank",
            },
          },
          "v-link",
          _vm.$attrs,
          false
        ),
        [_vm._v(" Terms and Conditions ")]
      ),
      _c(
        "v-link",
        _vm._b(
          {
            attrs: {
              size: "small",
              href: _vm.$privacyPolicyUrl,
              target: "_blank",
            },
          },
          "v-link",
          _vm.$attrs,
          false
        ),
        [_vm._v(" Privacy Policy ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }