import { createFeatureFlagPlugin } from '@loadsmart/vue-feature-flags';
import { useContext } from '@loadsmart/vue-feature-flags';

import config from '@/config/config';

/**
 * @param {import("vuex").Store} store
 */
export function synchronizeFeatureFlagsContext(store) {
  store.subscribe(async ({ type, payload }) => {
    const { updateContext } = useContext();
    if (type === 'Auth/setMe') {
      await updateContext({
        userId: payload.id,
        orgId: payload.orgId,
        role: payload.role,
        companyId: payload.companyId
      });
    }
  });

  store.subscribeAction({
    after: async ({ type }) => {
      const { updateContext } = useContext();
      if (type === 'Auth/logout') {
        await updateContext({ userId: null, orgId: null, role: null, companyId: null });
      }
    }
  });
}

export default createFeatureFlagPlugin({
  config: {
    url: config.unleash_url,
    appName: config.unleash_app_name,
    clientKey: config.unleash_client_key,
    environment: config.server_env === 'production' ? 'production' : 'development'
  }
});
