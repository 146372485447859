import RegistrationPage from '@/modules/auth/RegistrationPage.vue';
import LoginPage from '@/modules/auth/LoginPage.vue';
import CompanySelectPage from '@/modules/auth/CompanySelectPage.vue';
import store from '@/store';

export default [
  {
    path: '/register',
    name: 'register',
    component: RegistrationPage,
    meta: {
      requiresAuth: false,
      title: 'Registration',
      hideSidebar: true
    }
  },
  {
    path: '/login/:verificationToken?',
    name: 'login',
    component: LoginPage,
    props: true,
    meta: {
      requiresAuth: false,
      title: 'Login',
      hideSidebar: true
    }
  },
  {
    path: '/register/company',
    name: 'register.company',
    component: CompanySelectPage,
    props: { isRegistrationStep: true },
    meta: {
      requiresAuth: true,
      title: 'Select Company',
      hideSidebar: false,
      disableSidebar: true
    },
    beforeEnter: async (to, from, next) => {
      if (from?.name !== 'login') {
        const vueContext = store.$app;
        const me = vueContext.me;
        const previewCompany = await vueContext.services.company.getPreviewCompany();
        if (me?.companyId && me?.companyId !== previewCompany.id) {
          next({ path: '/book' });
          return;
        }
      }

      next();
    }
  },
  {
    path: '/auth/verify/:verificationToken',
    name: 'auth.verifyEmail',
    redirect: '/login/:verificationToken',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/auth/reset-password/:resetEmail/:resetToken',
    name: 'auth.resetPassword',
    props: true,
    component: LoginPage,
    meta: {
      title: 'Reset Password',
      requiresAuth: false,
      hideSidebar: true
    }
  }
];
