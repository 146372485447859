var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-stack",
    { attrs: { gap: "none" } },
    [
      _c("m-text", { attrs: { variant: "heading-md-bold", as: "h1" } }, [
        _vm._v("Welcome to Opendock"),
      ]),
      _c(
        "span",
        { staticClass: "p-t-4" },
        [
          _c("m-text", { attrs: { variant: "body-md-bold" } }, [
            _vm._v(
              " Sign in here to use our Warehouse portal or Scheduling Portal "
            ),
          ]),
        ],
        1
      ),
      _c("basic-form", {
        staticClass: "p-t-8",
        attrs: {
          fields: _vm.fields,
          "submit-button": {
            label: "Sign in",
            testId: "login-btn",
            isLoading: _vm.isLoading,
          },
        },
        on: { submit: _vm.submit },
        scopedSlots: _vm._u([
          {
            key: "pre-submit-actions",
            fn: function () {
              return [
                _c(
                  "m-group",
                  [
                    _c(
                      "link-button",
                      {
                        attrs: { "test-id": "forgot-password-btn" },
                        on: {
                          click: function ($event) {
                            _vm.dialogs.forgotPassword = true
                          },
                        },
                      },
                      [_vm._v(" Forgot your password ")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("forgot-password-dialog", {
        on: {
          close: function ($event) {
            _vm.dialogs.forgotPassword = false
          },
        },
        model: {
          value: _vm.dialogs.forgotPassword,
          callback: function ($$v) {
            _vm.$set(_vm.dialogs, "forgotPassword", $$v)
          },
          expression: "dialogs.forgotPassword",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }