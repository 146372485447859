var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("dialog-base", {
    attrs: { value: _vm.value },
    on: { input: _vm.updateVal },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "m-stack",
              { staticClass: "p-t-1", attrs: { align: "center" } },
              [
                _c(
                  "v-icon",
                  { attrs: { size: "48px", color: "color-warning-100" } },
                  [_vm._v("message-alert")]
                ),
                _c("m-text", { attrs: { variant: "heading-sm-bold" } }, [
                  _vm._v("This email address is already being used"),
                ]),
                _c("m-text", { attrs: { variant: "body-sm" } }, [
                  _vm._v(
                    " Sign in to use your existing account, or register again with a different email address "
                  ),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c(
              "secondary-button",
              {
                attrs: { "test-id": "register-again-btn" },
                on: { click: _vm.close },
              },
              [_vm._v(" Register Again ")]
            ),
            _c(
              "primary-button",
              {
                attrs: { "leading-icon": "login", "test-id": "sign-in-btn" },
                on: { click: _vm.confirm },
              },
              [_vm._v(" Sign In ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }