import { isNumber } from 'class-validator';
import appointmentMixinBase from '@satellite/components/mixins/appointmentMixinBase';

/**
 * Mixin used for appointments
 * @mixin
 * @displayName Appointment Mixin
 */
export default {
  computed: {
    ...appointmentMixinBase.computed,
    enforcedLeadTimeRules() {
      return (
        this.$me &&
        this.novaCore.validateCarrierLeadTimeRules(
          this.$me,
          this.appointment,
          this.appointment.dock,
          this.appointment.dock.warehouse
        ) !== null
      );
    },
    hasNoLeadTimeConstraints() {
      return !this.enforcedLeadTimeRules;
    },
    canReschedule() {
      let isRescheduleByCarrierForbidden = false;

      if (this.isCarrierUser) {
        isRescheduleByCarrierForbidden = this.$isRescheduleByCarrierForbidden(
          this.appointment.dock.warehouse
        );
      }

      return (
        this.canUpdate &&
        this.novaCore.isRescheduleAllowed(this.appointment.status) &&
        !isRescheduleByCarrierForbidden
      );
    },
    canUpdate() {
      const hasCancellableStatus = this.novaCore.isCancelAllowed(this.appointment?.status);
      const isStartStatus = this.novaCore.isStartStatus(this.appointment?.status);

      const allowCarrierScheduling =
        this.appointment.dock.warehouse.allowCarrierScheduling &&
        this.appointment.loadType.allowCarrierScheduling &&
        this.appointment.dock.allowCarrierScheduling;

      return Boolean(
        this.hasNoLeadTimeConstraints &&
          hasCancellableStatus &&
          isStartStatus &&
          allowCarrierScheduling
      );
    },
    // TODO: Revisit the name of this property - seems misleading
    canCancel() {
      return this.canUpdate;
    },
    isCarrierUser() {
      return this.$me?.id && this.novaCore.isCarrierUser(this.$me);
    },
    isGuestUser() {
      return !this.$me?.id;
    }
  },
  data() {
    return {
      ...appointmentMixinBase.data()
    };
  },
  methods: {
    ...appointmentMixinBase.methods,
    canCarrierViewCustomField(customField) {
      const hideIfCarrier =
        this.isCarrierUser || this.isGuestUser ? customField.hiddenFromCarrier ?? false : false;
      return !hideIfCarrier;
    },
    showCustomField(customField) {
      if (!customField) {
        return false;
      }

      const isDocument = [
        this.novaCore.CustomFieldType.Document,
        this.novaCore.CustomFieldType.MultiDocument
      ].includes(customField.type);

      if (isDocument && this.isGuestUser) {
        return false;
      }

      if (!this.canCarrierViewCustomField(customField)) {
        return false;
      }

      return (
        Boolean(customField.value) || customField.value === false || isNumber(customField.value)
      );
    }
  }
};
