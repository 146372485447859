var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-field",
    {
      staticClass: "field-wrapper",
      class: [
        { "flex-1": !_vm.width, "is-multiple": _vm.isMultiple },
        `doc-upload-${_vm._uid}`,
      ],
      attrs: {
        "data-testid": _vm.testId,
        status: _vm.hasError ? "danger" : "default",
      },
    },
    [
      _vm.label
        ? _c(
            "m-field-label",
            {
              attrs: { slot: "label", "data-testid": `${_vm.testId}-label` },
              slot: "label",
            },
            [_vm._v(" " + _vm._s(_vm.label) + " ")]
          )
        : _vm._e(),
      _c(
        "m-group",
        [
          _vm.shouldDisplayUploadButton
            ? [
                _c(
                  "div",
                  { key: _vm.renderKey, staticClass: "button-wrapper" },
                  [
                    _c(
                      "secondary-button",
                      {
                        attrs: {
                          "is-loading": _vm.isLoading,
                          "test-id": "file-upload-btn",
                          "leading-icon": "upload",
                          disabled: _vm.isButtonDisabled,
                          "is-block": "",
                        },
                        on: { click: _vm.handleFileImport },
                      },
                      [_vm._v(" " + _vm._s(_vm.uploadButtonLabel) + " ")]
                    ),
                    _c("input", {
                      ref: "uploader",
                      staticClass: "file-input",
                      attrs: { type: "file", multiple: _vm.isMultiple },
                      on: { change: _vm.onFileChanged },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "m-grid",
            { staticClass: "tag-group", attrs: { gap: "spacing-1" } },
            _vm._l(_vm.fileKeys, function (fileKey) {
              return _c(
                "span",
                { key: fileKey, staticClass: "tag-wrapper" },
                [
                  _c(
                    "m-tag",
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "p-r-1",
                          attrs: { color: "color-text-tertiary" },
                        },
                        [_vm._v("file-outline")]
                      ),
                      _vm._v(
                        " " + _vm._s(_vm.getFileDisplayName(fileKey)) + " "
                      ),
                      _c(
                        "v-icon",
                        {
                          staticClass: "p-r-1 remove-file",
                          attrs: { color: "color-text-tertiary" },
                          on: {
                            click: function ($event) {
                              return _vm.removeFile(fileKey)
                            },
                          },
                        },
                        [_vm._v(" close ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
        ],
        2
      ),
      _c("m-field-hint", { attrs: { "data-testid": `${_vm.testId}-errors` } }, [
        _vm.shouldDisplayMessages
          ? _c("span", [_vm._v(_vm._s(_vm.errorBucket.join(", ")))])
          : _vm._e(),
        _vm.errorBucket.length ? _c("span", [_vm._v(". ")]) : _vm._e(),
        _c("span", [_vm._v(_vm._s(_vm.computedFieldHint))]),
        _vm.hint && _vm.shouldDisplayMessages
          ? _c("span", { class: { "p-l-1": _vm.errorBucket.length } }, [
              _vm._v("  " + _vm._s(_vm.hint) + " "),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }