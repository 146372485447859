import notificationHelpers from '@/helpers/notifications';
import { sanitizeInput, upperFirst } from '@satellite/../nova/core';

/**
 * Global utility mixin
 * @mixin
 * @displayName Utility Mixin
 */
export default {
  data() {
    return {};
  },
  methods: {
    notify(message, type = 'success', options = {}) {
      options = {
        ...{
          duration: 5000,
          group: 'bottom-notifications',
          clean: false,
          actionText: null,
          actionFn: null,
          title: null
        },
        ...options
      };

      this.$notify({
        group: options.group,
        title: sanitizeInput(upperFirst(options.title ?? type)),
        text: sanitizeInput(message),
        duration: options.duration,
        data: { ...notificationHelpers.getNotificationConfig(type), ...options },
        clean: options.clean
      });
    },
    clearNotifications(group = 'bottom-notifications') {
      this.$notify({
        group: group,
        clean: true
      });
    },
    navigate(name, params) {
      this.$router.push({ name, params });
    },
    async $copyContent(contentToCopy, successMessage = 'Copied to clipboard', successCallback) {
      // Permissions API is NOT supported on firefox
      // ref.: https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API#security_considerations

      try {
        await navigator.clipboard.writeText(contentToCopy);
        this.notify(successMessage, 'success');
        if (successCallback) {
          successCallback();
        }
      } catch {
        this.notify(
          'Permission to access clipboard was denied. To enable access, update your browser settings.' +
            `<br />Alternatively, you can manually copy: <br />${contentToCopy}`,
          'warning'
        );
      }
    }
  }
};
