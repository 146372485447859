var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("internal-page", {
    attrs: { "allow-overflow": "" },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("page-header", {
              attrs: { "has-back-button": "", title: "Book Appointment" },
              scopedSlots: _vm._u([
                {
                  key: "header-actions",
                  fn: function () {
                    return [
                      _c("v-stepper", {
                        attrs: {
                          "steps-only": "",
                          "external-navigation": "",
                          "data-testid": "appointment-stepper",
                          "active-step": _vm.activeStep,
                        },
                        model: {
                          value: _vm.steps,
                          callback: function ($$v) {
                            _vm.steps = $$v
                          },
                          expression: "steps",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            !_vm.isLoading && _vm.warehouse
              ? [
                  _c("sidebar-cards", {
                    attrs: { "main-content-spacing": "none" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "sidebar-content",
                          fn: function () {
                            return [
                              _c(
                                "m-stack",
                                { staticClass: "appointment-summary" },
                                [
                                  _c("warehouse-header", {
                                    attrs: { warehouse: _vm.warehouse },
                                  }),
                                  _c("warehouse-address", {
                                    attrs: { warehouse: _vm.warehouse },
                                  }),
                                  _c(
                                    "m-text",
                                    { attrs: { variant: "heading-sm-bold" } },
                                    [_vm._v("Appointment summary")]
                                  ),
                                  _vm.noAppointmentSummary
                                    ? _c("no-content")
                                    : _vm._e(),
                                  _vm.appointmentData.loadtype
                                    ? _c("field-value", {
                                        attrs: {
                                          label: "Load type",
                                          value:
                                            _vm.appointmentData.loadtype?.name,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.appointmentData.dock
                                    ? _c("field-value", {
                                        attrs: {
                                          label: "Dock",
                                          value: _vm.appointmentData.dock.name,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.appointmentData.slot?.start
                                    ? _c("field-value", {
                                        attrs: {
                                          label: "Date & Time",
                                          value: _vm.formattedSelectedStart,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "main-content",
                          fn: function () {
                            return [
                              _c(
                                "m-text",
                                {
                                  staticClass: "mobile-steps",
                                  attrs: { variant: "body-md" },
                                },
                                [
                                  _vm._v(
                                    " Step " +
                                      _vm._s(_vm.currentStepNumber) +
                                      " of " +
                                      _vm._s(_vm.steps.length) +
                                      " "
                                  ),
                                ]
                              ),
                              _vm.activeStep === _vm.stepNames.loadtype
                                ? [
                                    _c("loadtype-search", {
                                      attrs: {
                                        "warehouse-id": _vm.warehouseId,
                                        "hide-duration": _vm.$getSettingValue(
                                          "isLoadTypeDurationCarrierViewHidden",
                                          _vm.warehouse?.settings
                                        ),
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "prepend-header",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "m-text",
                                                  {
                                                    staticClass: "mobile-steps",
                                                    attrs: {
                                                      variant: "body-md",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Step " +
                                                        _vm._s(
                                                          _vm.currentStepNumber
                                                        ) +
                                                        " of " +
                                                        _vm._s(
                                                          _vm.steps.length
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        2971812462
                                      ),
                                      model: {
                                        value: _vm.appointmentData.loadtype,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.appointmentData,
                                            "loadtype",
                                            $$v
                                          )
                                        },
                                        expression: "appointmentData.loadtype",
                                      },
                                    }),
                                    _c(
                                      "footer",
                                      { staticClass: "footer-actions" },
                                      [
                                        _c("v-spacer"),
                                        _c(
                                          "secondary-button",
                                          {
                                            attrs: {
                                              "test-id": "loadtype-next-btn",
                                              disabled:
                                                !_vm.appointmentData.loadtype,
                                              "trailing-icon": "chevron-right",
                                              "icon-size": "16px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.activeStep =
                                                  _vm.loadTypeAllowsDockSelect
                                                    ? _vm.stepNames.dock
                                                    : _vm.stepNames.slot
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.loadTypeAllowsDockSelect
                                                    ? "Dock"
                                                    : "Date and time"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                              _vm.activeStep === _vm.stepNames.dock
                                ? [
                                    _c("dock-search", {
                                      attrs: {
                                        "warehouse-id": _vm.warehouseId,
                                        "load-type-id":
                                          _vm.appointmentData.loadtype.id,
                                      },
                                      model: {
                                        value: _vm.appointmentData.dock,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.appointmentData,
                                            "dock",
                                            $$v
                                          )
                                        },
                                        expression: "appointmentData.dock",
                                      },
                                    }),
                                    _c(
                                      "footer",
                                      { staticClass: "footer-actions" },
                                      [
                                        _c(
                                          "secondary-button",
                                          {
                                            attrs: {
                                              "test-id": "loadtype-back-btn",
                                              "leading-icon": "chevron-left",
                                              "icon-size": "16px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.activeStep =
                                                  _vm.stepNames.loadtype
                                              },
                                            },
                                          },
                                          [_vm._v(" Load type ")]
                                        ),
                                        _c(
                                          "secondary-button",
                                          {
                                            attrs: {
                                              "test-id": "slot-next-btn",
                                              disabled:
                                                !_vm.appointmentData.dock,
                                              "trailing-icon": "chevron-right",
                                              "icon-size": "16px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.activeStep =
                                                  _vm.stepNames.slot
                                              },
                                            },
                                          },
                                          [_vm._v(" Date and time ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                              _vm.activeStep === _vm.stepNames.slot
                                ? [
                                    _c("slot-picker", {
                                      staticClass: "m-t-4",
                                      attrs: {
                                        "header-bg-color":
                                          "color-background-primary",
                                        warehouse: _vm.warehouse,
                                        "dock-id": _vm.appointmentData.dock?.id,
                                        "loadtype-id":
                                          _vm.appointmentData.loadtype.id,
                                      },
                                      model: {
                                        value: _vm.appointmentData.slot,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.appointmentData,
                                            "slot",
                                            $$v
                                          )
                                        },
                                        expression: "appointmentData.slot",
                                      },
                                    }),
                                    _c(
                                      "footer",
                                      { staticClass: "footer-actions" },
                                      [
                                        _c(
                                          "secondary-button",
                                          {
                                            attrs: {
                                              "test-id":
                                                _vm.loadTypeAllowsDockSelect
                                                  ? "dock-back-btn"
                                                  : "loadtype-back-btn",
                                              "leading-icon": "chevron-left",
                                              "icon-size": "16px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.activeStep =
                                                  _vm.loadTypeAllowsDockSelect
                                                    ? _vm.stepNames.dock
                                                    : _vm.stepNames.loadtype
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.loadTypeAllowsDockSelect
                                                    ? "Dock"
                                                    : "Load type"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "secondary-button",
                                          {
                                            attrs: {
                                              "test-id": "details-next-btn",
                                              disabled:
                                                !_vm.appointmentData.slot
                                                  ?.start,
                                              "trailing-icon": "chevron-right",
                                              "icon-size": "16px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.activeStep =
                                                  _vm.stepNames.details
                                              },
                                            },
                                          },
                                          [_vm._v(" Details ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                              _vm.activeStep === _vm.stepNames.details
                                ? [
                                    _c(
                                      "m-stack",
                                      { staticClass: "height-100" },
                                      [
                                        _c(
                                          "m-text",
                                          {
                                            attrs: { variant: "body-md-bold" },
                                          },
                                          [_vm._v("Enter appointment details")]
                                        ),
                                        _c("appointment-details-form", {
                                          ref: "appointment-details-form",
                                          attrs: {
                                            "custom-fields":
                                              _vm.warehouse
                                                .customApptFieldsTemplate,
                                            warehouse: _vm.warehouse,
                                          },
                                          on: {
                                            click: _vm.triggerSubmit,
                                            submit: _vm.createAppointment,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "footer",
                                      { staticClass: "footer-actions" },
                                      [
                                        _c(
                                          "secondary-button",
                                          {
                                            attrs: {
                                              "test-id": "slot-back-btn",
                                              "leading-icon": "chevron-left",
                                              "icon-size": "16px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.activeStep =
                                                  _vm.stepNames.slot
                                              },
                                            },
                                          },
                                          [_vm._v(" Date and time ")]
                                        ),
                                        _c(
                                          "primary-button",
                                          {
                                            attrs: {
                                              "test-id":
                                                "create-appointment-btn",
                                              "trailing-icon": "check",
                                            },
                                            on: { click: _vm.triggerSubmit },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.submitButtonLabel) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2471279268
                    ),
                  }),
                ]
              : [
                  _c("v-loader", { attrs: { "is-loading": _vm.isLoading } }, [
                    _vm._v("Loading warehouse"),
                  ]),
                ],
            _vm.warehouse
              ? [
                  !_vm.$isMrPreview && !_vm.dialogs.appointmentError
                    ? _c("appointment-submission-dialog", {
                        attrs: {
                          warehouse: _vm.warehouse,
                          appointment: _vm.appointment,
                          "is-loading": _vm.isSubmittingAppointment,
                        },
                        model: {
                          value: _vm.dialogs.appointmentSubmission,
                          callback: function ($$v) {
                            _vm.$set(_vm.dialogs, "appointmentSubmission", $$v)
                          },
                          expression: "dialogs.appointmentSubmission",
                        },
                      })
                    : _vm._e(),
                  _c("appointment-error-dialog", {
                    key: _vm.renderKey,
                    attrs: {
                      "status-code": _vm.statusCode,
                      "error-message": _vm.errorMessage,
                      "warehouse-id": _vm.warehouse.id,
                      "should-display-close-button": false,
                    },
                    on: {
                      close: function ($event) {
                        _vm.dialogs.appointmentError = false
                      },
                      reset: _vm.resetBookingProcess,
                      retry: _vm.triggerSubmit,
                    },
                    model: {
                      value: _vm.dialogs.appointmentError,
                      callback: function ($$v) {
                        _vm.$set(_vm.dialogs, "appointmentError", $$v)
                      },
                      expression: "dialogs.appointmentError",
                    },
                  }),
                  _vm.$isMrPreview && !_vm.dialogs.appointmentError
                    ? _c("appointment-submission-preview-dialog", {
                        attrs: {
                          value: true,
                          warehouse: _vm.warehouse,
                          appointment: {
                            start: _vm.appointmentData.slot.start,
                            confirmationNumber: "1234 (example)",
                          },
                        },
                        model: {
                          value: _vm.dialogs.appointmentSubmission,
                          callback: function ($$v) {
                            _vm.$set(_vm.dialogs, "appointmentSubmission", $$v)
                          },
                          expression: "dialogs.appointmentSubmission",
                        },
                      })
                    : _vm._e(),
                ]
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }