var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-page-content",
    { staticClass: "full-vh flex flex-column page-wrapper" },
    [
      _c("page-header", {
        staticClass: "auth-bar",
        attrs: { "is-compact": "", "should-collapse": false },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c("v-logo", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: true,
                      expression: "true",
                    },
                  ],
                  staticClass: "logo-spacing",
                }),
                _c(
                  "icon-button",
                  {
                    staticClass: "display-tablet",
                    attrs: {
                      "test-id": "sidebar-trigger-btn",
                      "no-bg": "",
                      "icon-size": "25px",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.toggleSidebar(true)
                      },
                    },
                  },
                  [_vm._v(" menu ")]
                ),
                _c("v-logo", {
                  staticClass: "p-l-3 display-tablet",
                  attrs: { "light-mode": "" },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "header-actions",
            fn: function () {
              return [
                _vm.$isMrPreview
                  ? [
                      _c("m-tag", { attrs: { variant: "warning" } }, [
                        _vm._v("Preview Mode"),
                      ]),
                    ]
                  : _c(
                      "m-group",
                      { attrs: { align: "center" } },
                      [
                        _vm.$me
                          ? _c(
                              "vuetify-menu",
                              {
                                attrs: { bottom: "", left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "icon-button",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  size: "20px",
                                                  "icon-size": "12px",
                                                  "icon-color":
                                                    "color-text-inverted",
                                                  "background-color":
                                                    "color-primary-60",
                                                  "test-id": "help-options-btn",
                                                  variant: "icon",
                                                },
                                              },
                                              on
                                            ),
                                            [_vm._v(" help ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3423541754
                                ),
                                model: {
                                  value: _vm.showHelpOptions,
                                  callback: function ($$v) {
                                    _vm.showHelpOptions = $$v
                                  },
                                  expression: "showHelpOptions",
                                },
                              },
                              [
                                _c(
                                  "m-card",
                                  [
                                    _c(
                                      "m-card-title",
                                      [
                                        _c(
                                          "m-group",
                                          {
                                            staticClass: "clickable",
                                            attrs: { role: "button" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticStyle: {
                                                  display: "contents",
                                                },
                                                attrs: {
                                                  target: "_blank",
                                                  rel: "noopener noreferrer",
                                                  href: "https://community.loadsmart.com/hc/en-us/sections/28818611583891-Opendock-Scheduling-Portal",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { size: "20px" } },
                                                  [_vm._v("help-circle")]
                                                ),
                                                _c(
                                                  "m-stack",
                                                  [
                                                    _c(
                                                      "m-text",
                                                      {
                                                        attrs: {
                                                          variant:
                                                            "body-md-bold",
                                                        },
                                                      },
                                                      [_vm._v("FAQs")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "m-card-body",
                                      [
                                        _c(
                                          "m-group",
                                          {
                                            staticClass: "clickable",
                                            attrs: {
                                              gap: "none",
                                              align: "center",
                                              "data-testid": "logout-btn",
                                              role: "button",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$eventHub.$emit(
                                                      "open-chat-window"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "m-r-3 p-t-1",
                                                    attrs: { size: "15px" },
                                                  },
                                                  [_vm._v("forum")]
                                                ),
                                                _c(
                                                  "m-text",
                                                  {
                                                    attrs: {
                                                      variant: "body-md-bold",
                                                    },
                                                  },
                                                  [_vm._v("Support chat")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.$me
                          ? _c(
                              "vuetify-menu",
                              {
                                attrs: { bottom: "", left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "button-base",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  "test-id": "user-options-btn",
                                                  variant: "icon",
                                                  color: "color-primary-60",
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.userInitials) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1565769982
                                ),
                                model: {
                                  value: _vm.showUserOptions,
                                  callback: function ($$v) {
                                    _vm.showUserOptions = $$v
                                  },
                                  expression: "showUserOptions",
                                },
                              },
                              [
                                _c(
                                  "m-card",
                                  [
                                    _c(
                                      "m-card-title",
                                      [
                                        _c(
                                          "m-group",
                                          {
                                            staticClass: "clickable",
                                            attrs: {
                                              gap: "spacing-2",
                                              role: "button",
                                            },
                                            on: { click: _vm.viewUserProfile },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "20px" } },
                                              [_vm._v("account")]
                                            ),
                                            _c(
                                              "m-stack",
                                              { attrs: { gap: "none" } },
                                              [
                                                _c(
                                                  "m-text",
                                                  {
                                                    attrs: {
                                                      variant: "body-md-bold",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.userFullName
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _vm.$me?.company?.name
                                                  ? _c(
                                                      "m-text",
                                                      {
                                                        attrs: {
                                                          variant: "body-sm",
                                                          color:
                                                            "color-text-tertiary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$me.company
                                                                .name
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("m-card-divider", {
                                      staticClass: "p-x-2",
                                    }),
                                    _c(
                                      "m-card-body",
                                      [
                                        _c(
                                          "m-group",
                                          {
                                            staticClass: "clickable",
                                            attrs: {
                                              gap: "none",
                                              align: "center",
                                              "data-testid": "logout-btn",
                                              role: "button",
                                            },
                                            on: { click: _vm.logout },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "m-r-3 p-t-1",
                                                attrs: { size: "15px" },
                                              },
                                              [_vm._v("logout")]
                                            ),
                                            _c(
                                              "m-text",
                                              {
                                                attrs: {
                                                  variant: "body-md-bold",
                                                },
                                              },
                                              [_vm._v("Logout")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._t("header", function () {
        return [
          !_vm.$slots["header"]
            ? _c("page-header", { attrs: { title: _vm.pageTitle } })
            : _vm._e(),
        ]
      }),
      _c(
        "m-body",
        {
          ref: "page-body",
          class: {
            "allow-overflow": _vm.allowOverflow,
            "has-footer": _vm.$slots["footer"],
            "has-sidebar": _vm.hasSidebar,
          },
          attrs: { id: "page-body" },
        },
        [
          _c(
            "section",
            { staticClass: "page-body-content" },
            [_vm._t("body")],
            2
          ),
          _c(
            "section",
            { ref: "footer", staticClass: "page-footer" },
            [_vm._t("footer")],
            2
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }