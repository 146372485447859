var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-group",
    {
      staticClass: "big-number-container",
      attrs: {
        gap: "spacing-4",
        align: "center",
        justify: "space-between",
        "padding-y": "spacing-1",
      },
    },
    _vm._l(_vm.bigNumbers, function (item) {
      return _c(
        "m-box",
        {
          key: item.title,
          class: `big-number-box ${
            item.title === _vm.selected ? "selected" : ""
          }`,
          attrs: { "padding-x": "spacing-3", "padding-y": "spacing-1" },
          on: {
            click: function ($event) {
              return _vm.handleBoxClick(item)
            },
          },
        },
        [
          _c("m-text", { attrs: { variant: "body-lg-bold" } }, [
            _vm._v(_vm._s(item.number)),
          ]),
          _c("br"),
          _c("m-text", [_vm._v(_vm._s(item.title))]),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }