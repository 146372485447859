<template>
  <dialog-base
    :value="value"
    @input="val => $emit('input', val)"
    size="medium"
    data-testid="create-company-dlg">
    <template v-slot:header>
      {{ headerText }}
    </template>
    <template v-slot:body>
      <m-stack gap="spacing-3">
        <m-table v-if="existingCompany">
          <m-table-head>
            <m-table-row>
              <m-table-cell>Company name</m-table-cell>
              <m-table-cell>USDOT number</m-table-cell>
            </m-table-row>
          </m-table-head>
          <m-table-body>
            <m-table-row>
              <m-table-cell>{{ existingCompany.name }}</m-table-cell>
              <m-table-cell>{{ existingCompany.usdot }}</m-table-cell>
            </m-table-row>
          </m-table-body>
        </m-table>
        <v-loader is-loading v-else-if="checkingForExistingCompany">
          This won't take long...
        </v-loader>
        <basic-form
          ref="create-company-form"
          :fields="fields"
          @submit="createAndAttachCompany"
          v-else />
      </m-stack>
    </template>
    <template v-slot:actions>
      <secondary-button
        @click="handleCancelClick"
        :disabled="checkingForExistingCompany"
        test-id="cancel-create-company-btn">
        {{ !existingCompany ? 'Cancel' : 'Back to company creation' }}
      </secondary-button>
      <primary-button
        v-if="!existingCompany"
        @click="createAndAttachCompany"
        :disabled="checkingForExistingCompany"
        test-id="confirm-create-company-btn"
        :is-loading="isLoading">
        Confirm
      </primary-button>
      <primary-button
        v-else
        @click="attachExistingCompany"
        :disabled="checkingForExistingCompany"
        test-id="confirm-create-company-btn"
        :is-loading="isLoading">
        Choose existing company
      </primary-button>
    </template>
  </dialog-base>
</template>

<script>
import { DialogBase, BasicForm, PrimaryButton, SecondaryButton, VLoader } from '@/components';
import dialogMixin from '@/components/mixins/dialogMixin';
import { ISO3166FilteredCountries } from '@satellite/../nova/core';

/**
 * Dialog with company creation form
 * @displayName Create Company Dialog
 */
export default {
  name: 'CreateCompanyDialog',
  mixins: [dialogMixin],
  components: {
    VLoader,
    DialogBase,
    BasicForm,
    PrimaryButton,
    SecondaryButton
  },
  computed: {
    // TODO this is duplicate code from the registration page, probably should abstract
    // the country select
    countries() {
      const countries = [];
      for (const key in ISO3166FilteredCountries) {
        const country = ISO3166FilteredCountries[key];
        countries.push({
          label: country.name,
          value: country.iso2
        });
      }
      return countries;
    },
    headerText() {
      if (this.checkingForExistingCompany) {
        return 'Checking company information';
      } else if (this.existingCompany) {
        return 'Existing company found';
      }

      return 'Create new company';
    }
  },
  data() {
    return {
      isLoading: false,
      checkingForExistingCompany: false,
      existingCompany: null,
      fields: {
        // TODO this is duplicate code from the registration page, probably should abstract
        // the country select
        // Also the height of this element is not right in the dialog
        // TODO this is supposed to autofill with the user's country. Users dont currently have countries
        country: {
          component: 'drop-down-field',
          fieldProps: {
            label: 'Country',
            required: true,
            value: ISO3166FilteredCountries.USA.iso2,
            rules: this.$validator.rules.required(),
            placeholder: '',
            options: [],
            testId: 'create-company-country-field'
          }
        },
        name: {
          component: 'text-field',
          fieldProps: {
            required: true,
            testId: 'create-company-name-field',
            placeholder: 'Insert company name',
            value: '',
            label: 'Company Name'
          }
        },
        // TODO there is supposed to be a help link with this field, how?
        usdot: {
          component: 'text-field',
          fieldProps: {
            required: false, // TODO should this be based on user's country?
            testId: 'create-company-usdot-field',
            placeholder: 'Enter your USDOT number',
            value: '',
            label: 'USDOT'
          }
        }
      }
    };
  },
  methods: {
    async createAndAttachCompany() {
      const fieldData = await this.$refs['create-company-form'].getFieldValues();

      if (fieldData.usdot) {
        this.checkingForExistingCompany = true;
        const existingCompanies = await this.services.company.getCompanies({
          s: { usdot: { $eqL: fieldData.usdot.toLowerCase() } }
        });
        if (existingCompanies?.length) {
          this.existingCompany = existingCompanies[0];
        }
        this.checkingForExistingCompany = false;
      }

      if (!this.existingCompany) {
        const newCompany = await this.services.company.createCompany(fieldData);
        if (newCompany.id) {
          this.$emit('companySelected', newCompany.id);
        } else {
          // show error
        }
      }
    },
    attachExistingCompany() {
      this.$emit('companySelected', this.existingCompany.id);
    },
    handleCancelClick() {
      if (this.existingCompany) {
        this.existingCompany = null;
      } else {
        this.close();
      }
    }
  },
  mounted() {
    // TODO this is duplicate code from the registration page, probably should abstract
    // the country select
    this.$set(this.fields.country.fieldProps, 'options', this.countries);
  }
};
</script>
