<template>
  <m-card
    :data-testid="testId"
    class="flex-1 card-grid-container"
    :class="{
      [`card-grid-${_uid}`]: true,
      'hide-outer-card': !displayOuterCard,
      'no-items': items.length === 0
    }"
    :style="`max-height:${computedMaxHeight}`">
    <m-card-title v-if="title && !$slots.title">{{ title }}</m-card-title>
    <m-card-title v-if="$slots.title">
      <!-- @slot Outer card title content -->
      <slot name="title"></slot>
    </m-card-title>
    <m-card-body class="height-100 overflow-auto">
      <m-grid
        class="card-grid"
        v-if="items.length > 0"
        gap="spacing-4"
        :class="`row-items-${itemsPerRow}`">
        <m-card
          v-bind="[$attrs]"
          :class="{ active: isItemSelected(item), clickable: clickable }"
          v-for="(item, index) in items"
          @click="clickable ? handleCardClick(item) : ''"
          :key="`${_uid}-${index}`">
          <m-card-title v-if="$scopedSlots['row-title']">
            <!-- @slot Individual item card title content -->
            <slot name="row-title" :item="item"></slot>
          </m-card-title>
          <m-card-divider v-if="$scopedSlots['row-title']"></m-card-divider>
          <m-card-body>
            <!-- @slot Individual item card body content -->
            <slot name="row" :item="item"></slot>
          </m-card-body>
        </m-card>
      </m-grid>
      <!-- @slot Content to display when there are no items in the items array -->
      <slot name="no-items" v-else></slot>
      <!-- @slot Card footer content -->
      <slot name="footer"></slot>
    </m-card-body>
  </m-card>
</template>

<script>
import { propValidatorHelper } from '@satellite/plugins/util';

/**
 * Used for grids of card components like search results, card lists, etc.
 * @displayName Card Grid
 */
export default {
  name: 'CardGrid',
  props: {
    /**
     * Array of items to displayed as cards inside the grid
     */
    items: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    /**
     * Title of the card if title named slot is not needed
     */
    title: {
      type: String,
      required: false,
      default: null
    },
    /**
     * How many items are displayed per row on desktop - fully responsive
     */
    itemsPerRow: {
      type: Number,
      required: false,
      default: 2,
      validator(value) {
        const isValid = [1, 2, 3, 4, 5].includes(value);
        return propValidatorHelper({
          value,
          isValid,
          componentName: 'CardGrid',
          propName: 'itemsPerRow',
          message: ''
        });
      }
    },
    /**
     * The max height of the outer card - the inner content will scroll if it overflows
     */
    maxHeight: {
      type: String,
      required: false,
      default: null
    },
    /**
     * If set to false, the outer card border and padding is removed and the grid no longer
     * looks like it's inside a larger card
     */
    displayOuterCard: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Outer grid test id
     */
    testId: {
      type: String,
      required: true
    },
    /**
     * Applies styles that make the cards appear clickable
     */
    clickable: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Applies styles that make a card item appear to be selected
     */
    selectedItem: {
      type: Object,
      required: false,
      default: null
    }
  },
  computed: {
    computedMaxHeight() {
      const { sizeValue, sizeUnit } = this.util.computeSize(this.maxHeight);
      let size = `${sizeValue}${sizeUnit}`;
      if (!size) {
        size = 'auto';
      }
      return size;
    }
  },
  methods: {
    /**
     * Until we can access the shadow dom directly for Miranda components via ::part or ::theme selectors,
     * this is the only option since :host, ::shadow, and /deep/ have all been deprecated to preserve encapsulation
     */
    applyDefaultShadowStyles() {
      let topLevelCardStyles = 'flex:1;overflow:auto;box-sizing:border-box;min-width:0;';
      if (!this.displayOuterCard) {
        topLevelCardStyles +=
          'background:transparent !important;border:none !important;box-sizing: border-box;';
      }

      this.util.appendStylesToShadowDomEls(
        document.querySelectorAll(`.card-grid-${this._uid}`),
        '.m-card',
        topLevelCardStyles
      );
      this.util.appendStylesToShadowDomEls(
        document.querySelectorAll(`.card-grid-${this._uid}`),
        '.m-card-flag',
        'display:none!important;box-sizing: border-box;'
      );
      this.util.appendStylesToShadowDomEls(
        document.querySelectorAll(`.card-grid-${this._uid} m-card`),
        '.m-card',
        'height:100%;box-sizing: border-box;'
      );
      this.util.appendStylesToShadowDomEls(
        document.querySelectorAll(`.card-grid-${this._uid} .active`),
        '.m-card',
        'border-radius:4px !important;border: 1px solid var(--Accent-100, #0F4261) !important;background: var(--Background-secondary, #F8F9FB) !important;'
      );
    },
    handleCardClick(item) {
      /**
       * Emits click event with the item that was clicked as the parameter
       * @event click
       * @property item Grid item that has been clicked
       */
      this.$emit('click', item);
    },
    isItemSelected(item) {
      return JSON.stringify(item) === JSON.stringify(this.selectedItem);
    }
  },
  mounted() {
    this.applyDefaultShadowStyles();
  },
  watch: {
    items() {
      this.$nextTick(() => {
        this.applyDefaultShadowStyles();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
:root {
  .clickable {
    &:hover {
      --m-card-background-color: #{$m-color-background-tertiary} !important;
      cursor: pointer;
      opacity: 1;
    }
  }
  .hide-outer-card {
    padding-bottom: $m-spacing-4;
    > m-card {
      background: transparent !important;
      border: none !important;
      box-sizing: border-box;
    }
    > m-card-body {
      --m-card-body-padding-y: 0;
      --m-card-body-padding-x: 0;
    }
  }

  .no-items > m-card-body .m-card-body {
    height: calc(100% - 40px);
    box-sizing: border-box;
  }
}

.card-grid-container {
  display: flex;
  min-height: 0;

  > m-card-title {
    top: 0;
    position: sticky;
    z-index: 1;
    padding-bottom: 16px;
  }
}

.card-grid {
  overflow: hidden;
  padding-bottom: 2px;

  &.row-items {
    &-1 {
      grid-template-columns: repeat(1, 1fr);

      @media (max-width: $largeMobileBreakpoint) {
        display: flex;
        flex-direction: column;
      }
    }
    &-2 {
      grid-template-columns: repeat(2, 1fr);

      @media (max-width: $largeMobileBreakpoint) {
        display: flex;
        flex-direction: column;
      }
    }
    &-3 {
      grid-template-columns: repeat(3, 1fr);

      @media (max-width: $tabletBreakpoint) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: $largeMobileBreakpoint) {
        display: flex;
        flex-direction: column;
      }
    }
    &-4 {
      grid-template-columns: repeat(4, 1fr);

      @media (max-width: $midDesktopBreakpoint) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: $tabletBreakpoint) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: $largeMobileBreakpoint) {
        display: flex;
        flex-direction: column;
      }
    }
    &-5 {
      grid-template-columns: repeat(5, 1fr);

      @media (max-width: $midDesktopBreakpoint) {
        grid-template-columns: repeat(4, 1fr);
      }
      @media (max-width: $tabletBreakpoint) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: $largeMobileBreakpoint) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: $mobileBreakpoint) {
        display: flex;
        flex-direction: column;
      }
    }
  }

  @media (max-width: $largeMobileBreakpoint) {
    display: flex;
    flex-direction: column;
  }
}
</style>
