var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        ref: "reschedule-dialog",
        attrs: {
          value: _vm.value,
          size: "large",
          "should-display-close-button": !_vm.isLoading,
        },
        on: { input: _vm.updateVal },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c(
                  "m-group",
                  { attrs: { gap: "spacing-1" } },
                  [
                    _c("m-text", { attrs: { variant: "heading-md-bold" } }, [
                      _vm._v("Reschedule appointment"),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm.selectedSlot && !_vm.isLoading
                  ? _c("slot-picker", {
                      attrs: {
                        "loadtype-id": _vm.extendedAppointment.loadTypeId,
                        "warehouse-id": _vm.warehouse.id,
                        timezone: _vm.warehouse.timezone,
                        "appointment-id": _vm.extendedAppointment.id,
                        "dock-id": _vm.parentDock.id,
                        docks: _vm.docks,
                        "allow-dock-select":
                          _vm.extendedAppointment.loadType.settings
                            ?.allowCarrierDockSelection,
                      },
                      model: {
                        value: _vm.selectedSlot,
                        callback: function ($$v) {
                          _vm.selectedSlot = $$v
                        },
                        expression: "selectedSlot",
                      },
                    })
                  : _c("v-loader", { attrs: { "is-loading": _vm.isLoading } }, [
                      _vm._v("Rescheduling appointment"),
                    ]),
              ]
            },
            proxy: true,
          },
          {
            key: "actions",
            fn: function () {
              return [
                _c(
                  "m-group",
                  {
                    staticClass: "flex-nowrap p-t-4",
                    attrs: { gap: "spacing-2" },
                  },
                  [
                    [
                      _c(
                        "secondary-button",
                        {
                          attrs: {
                            "test-id": "cancel-reschedule-btn",
                            disabled: _vm.isLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.updateVal(false)
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "primary-button",
                        {
                          attrs: {
                            "test-id": "reschedule-btn",
                            disabled: _vm.isLoading || !_vm.newSlotSelected,
                          },
                          on: { click: _vm.reschedule },
                        },
                        [_vm._v(" Reschedule ")]
                      ),
                    ],
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "dialog-base",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }