<template>
  <m-accordion>
    <m-accordion-title class="is__relative">
      <m-stack class="p-y-2" gap="spacing-2">
        <slot name="title"></slot>
      </m-stack>
      <m-divider class="header-divider"></m-divider>
    </m-accordion-title>
    <m-accordion-content>
      <m-stack class="p-y-4">
        <slot name="body"></slot>
      </m-stack>

      <div class="is__relative p-t-3" v-if="$slots.footer">
        <m-divider class="footer-divider"></m-divider>
        <div class="m-t-4">
          <slot name="footer" />
        </div>
      </div>
    </m-accordion-content>
  </m-accordion>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
m-divider {
  display: block;
  position: absolute;
  width: calc(100% + 48px);
  left: -$m-spacing-4;
  right: 0;

  &.header-divider {
    bottom: -13px;
  }
}
</style>
