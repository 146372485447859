var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("internal-page", {
    attrs: { "page-title": "Book appointment", "allow-overflow": "" },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "m-group",
              [
                _c("text-field", {
                  staticClass: "width-100",
                  attrs: {
                    "test-id": "warehouse-search",
                    label: "Start by selecting a warehouse",
                    placeholder:
                      "Search by company name, warehouse name, or address",
                    "leading-icon": {
                      icon: "magnify",
                      color: "color-text-primary",
                      size: "20px",
                    },
                    clearable: "",
                  },
                  on: { clear: _vm.clearSearch },
                  model: {
                    value: _vm.searchStr,
                    callback: function ($$v) {
                      _vm.searchStr = $$v
                    },
                    expression: "searchStr",
                  },
                }),
              ],
              1
            ),
            _vm.isLoading || _vm.noResults
              ? _c(
                  "m-stack",
                  {
                    staticClass: "full-height",
                    attrs: { align: "center", justify: "center" },
                  },
                  [
                    _c("v-loader", { attrs: { "is-loading": _vm.isLoading } }, [
                      _vm._v("Looking for warehouses"),
                    ]),
                    !_vm.isLoading && _vm.noResults
                      ? _c("no-results")
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.shouldShowDefault
              ? [
                  _vm.hasFavorites || _vm.hasRecents
                    ? [
                        _c(
                          "m-group",
                          {
                            staticClass:
                              "flex-contain m-t-8 flex-nowrap flex-1 widgets",
                          },
                          [
                            _c("card-grid", {
                              staticClass: "widget",
                              attrs: {
                                "test-id": "favorite-warehouses-grid",
                                items: _vm.favoriteWarehouses,
                                flagged: "",
                                "flag-color": "color-background-highlight",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "16px" } },
                                          [_vm._v("star")]
                                        ),
                                        _vm._v(" Favorite warehouses "),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                  {
                                    key: "row",
                                    fn: function ({ item }) {
                                      return [
                                        _c(
                                          "m-stack",
                                          {
                                            attrs: { gap: "spacing-1" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.viewWarehouse(item)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "m-text",
                                              {
                                                attrs: {
                                                  variant: "body-md-bold",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.name))]
                                            ),
                                            _c(
                                              "m-text",
                                              { attrs: { variant: "body-md" } },
                                              [_vm._v(_vm._s(item.org.name))]
                                            ),
                                            _c(
                                              "m-text",
                                              { attrs: { variant: "body-md" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getLargerAreaOfAddress(
                                                        item
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "no-items",
                                    fn: function () {
                                      return [
                                        _c(
                                          "m-stack",
                                          {
                                            staticClass: "height-100",
                                            attrs: {
                                              align: "center",
                                              justify: "center",
                                              gap: "none",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "48px" } },
                                              [_vm._v("warehouse")]
                                            ),
                                            _c(
                                              "m-text",
                                              { attrs: { variant: "body-md" } },
                                              [
                                                _vm._v(
                                                  "The warehouses you mark as favorites will"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "m-text",
                                              { attrs: { variant: "body-md" } },
                                              [
                                                _vm._v(
                                                  "show up here for quick access"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2794414610
                              ),
                            }),
                            _c("card-grid", {
                              staticClass: "widget",
                              attrs: {
                                "test-id": "recent-warehouses-grid",
                                title: "Recent warehouses",
                                items: _vm.recentlyVisitedWarehouses,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "row",
                                    fn: function ({ item }) {
                                      return [
                                        _c(
                                          "m-stack",
                                          {
                                            attrs: { gap: "spacing-1" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.viewWarehouse(item)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "m-text",
                                              {
                                                attrs: {
                                                  variant: "body-md-bold",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.name))]
                                            ),
                                            _c(
                                              "m-text",
                                              { attrs: { variant: "body-md" } },
                                              [_vm._v(_vm._s(item.org.name))]
                                            ),
                                            _c(
                                              "m-text",
                                              { attrs: { variant: "body-md" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getLargerAreaOfAddress(
                                                        item,
                                                        false
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "no-items",
                                    fn: function () {
                                      return [
                                        _c(
                                          "m-stack",
                                          {
                                            staticClass: "height-100",
                                            attrs: {
                                              align: "center",
                                              justify: "center",
                                              gap: "none",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "48px" } },
                                              [_vm._v("warehouse")]
                                            ),
                                            _c(
                                              "m-text",
                                              { attrs: { variant: "body-md" } },
                                              [
                                                _vm._v(
                                                  "The warehouses you most recently visited will"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "m-text",
                                              { attrs: { variant: "body-md" } },
                                              [
                                                _vm._v(
                                                  "show up here for quick access"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2891624175
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c(
                          "m-stack",
                          {
                            staticClass: "m-t-12",
                            attrs: { gap: "spacing-1" },
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  size: "36px",
                                  color: "color-accent-60",
                                },
                              },
                              [_vm._v("arrow-up")]
                            ),
                            _c(
                              "m-text",
                              { attrs: { variant: "body-md-bold" } },
                              [
                                _vm._v(
                                  "Welcome! Ready to book your first appointment?"
                                ),
                              ]
                            ),
                            _c("m-text", { attrs: { variant: "body-md" } }, [
                              _vm._v(
                                " Search for a warehouse to see all its details and availability "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "m-stack",
                          { staticClass: "m-t-8", attrs: { gap: "spacing-1" } },
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  size: "36px",
                                  color: "color-accent-60",
                                },
                              },
                              [_vm._v("star")]
                            ),
                            _c(
                              "m-text",
                              { attrs: { variant: "body-md-bold" } },
                              [
                                _vm._v(
                                  " Quick access to your favorite and recent warehouses "
                                ),
                              ]
                            ),
                            _c("m-text", { attrs: { variant: "body-md" } }, [
                              _vm._v(
                                " As you book appointments, your favorite and recent warehouses will show up on this page "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                ]
              : _vm._e(),
            _vm.hasResults
              ? [
                  _c(
                    "m-group",
                    {
                      staticClass: "m-t-2 m-b-4",
                      attrs: { align: "center", justify: "space-between" },
                    },
                    [
                      _c("m-text", { attrs: { variant: "heading-sm" } }, [
                        _vm._v(_vm._s(_vm.pagination.total) + " results"),
                      ]),
                    ],
                    1
                  ),
                  _c("card-grid", {
                    attrs: {
                      "test-id": "warehouse-search-results",
                      items: _vm.results,
                      "items-per-row": 4,
                      "display-outer-card": false,
                    },
                    on: { click: _vm.viewWarehouse },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "row",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "m-stack",
                                { attrs: { gap: "none" } },
                                [
                                  _c(
                                    "m-text",
                                    { attrs: { variant: "body-md-bold" } },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                  _c(
                                    "m-text",
                                    {
                                      attrs: {
                                        variant: "body-md",
                                        color: "color-text-primary",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.org.name) + " ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "m-stack",
                                {
                                  staticClass: "m-t-3",
                                  attrs: { gap: "none" },
                                },
                                [
                                  _c(
                                    "m-text",
                                    {
                                      attrs: {
                                        variant: "body-md",
                                        color: "color-text-primary",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.street) + " ")]
                                  ),
                                  _c(
                                    "m-text",
                                    {
                                      attrs: {
                                        variant: "body-md",
                                        color: "color-text-primary",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getLargerAreaOfAddress(item)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "m-group",
                                { staticClass: "m-t-3" },
                                [
                                  _vm.isWarehouseFavorited(item.id)
                                    ? _c(
                                        "m-tag",
                                        { attrs: { variant: "success" } },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "leading" },
                                              slot: "leading",
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "success" } },
                                                [_vm._v("star")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" Favorite "),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.isWarehouseRecentlyVisited(item.id)
                                    ? _c("m-tag", [_vm._v("Recent")])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4150018939
                    ),
                  }),
                ]
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _vm.pagination.totalPages
              ? _c("v-pagination", {
                  staticClass: "search-pagination",
                  attrs: {
                    "test-id": "warehouse-search-pagination",
                    length: _vm.pagination.totalPages,
                    "total-visible": 5,
                  },
                  model: {
                    value: _vm.pagination.page,
                    callback: function ($$v) {
                      _vm.$set(_vm.pagination, "page", $$v)
                    },
                    expression: "pagination.page",
                  },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }