var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-stack",
    {
      staticClass: "width-100",
      attrs: { id: `stepper-${_vm._uid}`, gap: "spacing-2" },
    },
    [
      _c(
        "m-steps",
        {
          class: [`stepper-${_vm._uid}`, { "steps-only": _vm.stepsOnly }],
          attrs: { "active-step": _vm.computedActiveStep },
        },
        _vm._l(_vm.value, function (step) {
          return _c(
            "m-step",
            {
              key: `step-${step.name}`,
              attrs: { name: step.name, completed: step.completed },
            },
            [
              _c(
                "m-stack",
                { attrs: { gap: "none" } },
                [
                  _c("span", [_vm._v(_vm._s(step.label))]),
                  _vm._t("additional-step-header-content", null, {
                    step: step,
                  }),
                ],
                2
              ),
            ],
            1
          )
        }),
        1
      ),
      !_vm.stepsOnly
        ? [
            _vm._l(_vm.value, function (step) {
              return [
                step.name === _vm.computedActiveStep
                  ? _c(
                      "m-box",
                      { key: `step-content-${step.name}` },
                      [_vm._t(`step.${step.name}`, null, { step: step })],
                      2
                    )
                  : _vm._e(),
              ]
            }),
            !_vm.externalNavigation
              ? _c("stepper-nav", {
                  attrs: {
                    "prev-button-attrs": {
                      disabled: _vm.computedActiveStepIndex < 1,
                    },
                    "next-button-attrs": {
                      disabled:
                        _vm.computedActiveStepIndex >= _vm.value.length - 1,
                    },
                  },
                  on: {
                    prev: function ($event) {
                      return _vm.updateCompletedStep(true)
                    },
                    next: function ($event) {
                      return _vm.updateCompletedStep(false)
                    },
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }