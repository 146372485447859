var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-group",
    [
      _vm.customLogoPath(_vm.warehouse, _vm.$s3BaseUrl)
        ? _c("img", {
            staticClass: "warehouse-logo",
            attrs: {
              src: _vm.customLogoPath(_vm.warehouse, _vm.$s3BaseUrl),
              alt: `${_vm.warehouse.name} logo`,
            },
          })
        : _vm._e(),
      _c(
        "m-stack",
        { attrs: { gap: "spacing-1", justify: "center" } },
        [
          _vm.linkToWarehouse
            ? _c(
                "router-link",
                {
                  staticClass: "link",
                  attrs: {
                    to: {
                      name: "warehouse.details",
                      params: { warehouseId: _vm.warehouse.id },
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.warehouse.name) + " ")]
              )
            : _c("m-text", { attrs: { variant: "body-md-bold" } }, [
                _vm._v(_vm._s(_vm.warehouse.name)),
              ]),
          _c(
            "m-text",
            {
              attrs: { variant: "body-sm-bold", color: "color-text-tertiary" },
            },
            [_vm._v(_vm._s(_vm.warehouse.org.name))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }