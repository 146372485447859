var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "icon-button",
    {
      attrs: {
        "test-id": _vm.testId,
        "icon-size": "20px",
        "icon-color": _vm.iconColor,
      },
      on: { click: _vm.handleClick },
    },
    [_vm._v(" " + _vm._s(_vm.icon) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }