var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("field-value", {
    attrs: { label: "Contact Info" },
    scopedSlots: _vm._u([
      {
        key: "value",
        fn: function () {
          return [
            _c(
              "m-stack",
              { attrs: { gap: "spacing-1" } },
              [
                _c(
                  "m-group",
                  { attrs: { align: "center" } },
                  [
                    _c("v-icon", { attrs: { size: "16px" } }, [
                      _vm._v("cellphone"),
                    ]),
                    _vm.warehouse.phone
                      ? _c(
                          "v-link",
                          {
                            attrs: {
                              color: "color-text-primary",
                              "is-bold": false,
                              href: `tel:${_vm.warehouse.phone}`,
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.warehouse.phone) + " ")]
                        )
                      : _c("no-content"),
                  ],
                  1
                ),
                _c(
                  "m-group",
                  {
                    staticClass: "flex-nowrap nobreak",
                    attrs: { align: "flex-start" },
                  },
                  [
                    _c("v-icon", { attrs: { size: "16px" } }, [
                      _vm._v("email-outline"),
                    ]),
                    _vm.warehouse.email
                      ? _c(
                          "v-link",
                          {
                            attrs: {
                              color: "color-text-primary",
                              "is-bold": false,
                              href: `mailto:${_vm.warehouse.email}`,
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.warehouse.email) + " ")]
                        )
                      : _c("no-content"),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }