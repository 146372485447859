<template>
  <m-stack class="flex-1 height-100" :class="{ 'is-loading': isLoading }">
    <m-group
      align="center"
      justify="space-between"
      class="header"
      :style="`background-color:${computedHeaderBgColor}`">
      <drop-down-field
        v-if="docks && allowDockSelect"
        test-id="dock-select"
        label="Docks"
        v-model="selectedDock"
        :options="docks.map(dock => ({ label: dock.name, value: dock.id }))" />
      <m-text v-else variant="heading-sm-bold">Select date and time</m-text>

      <date-nav
        :class="{ 'p-t-4': docks }"
        :is-previous-button-disabled="isPrevDisabled"
        :min-date="minDate"
        :max-date="maxDate"
        v-model="selectedDate"
        @prev="loadPrevDateRange"
        @next="loadNextDateRange"></date-nav>
    </m-group>

    <m-grid id="availability-grid">
      <m-group
        v-for="(item, index) in dateRange"
        :key="`slot-${item.date}-header`"
        :class="`flex-order-${2 * index}`"
        gap="none"
        class="date-header"
        justify="center"
        align="center">
        <m-stack align="flex-start" gap="none">
          <m-text variant="body-md">{{ item.day }}</m-text>
          <m-text variant="heading-sm-bold">{{ item.date }}</m-text>
        </m-stack>
      </m-group>

      <template v-if="!isLoading">
        <m-stack
          gap="spacing-2"
          v-for="(item, index) in dateRange"
          :key="`slot-${item.date}-times`"
          :class="`flex-order-${index + (index + 1)}`"
          class="text-center time-column d-flex flex-column align-center slots">
          <template v-if="Boolean(availability[item.date] && availability[item.date].length)">
            <template v-for="(slot, i) in availability[item.date]">
              <dst-divider v-if="slot.isDSTChange" :key="`${i}-divider`" />
              <secondary-button
                :test-id="slot.start.toISO()"
                :key="`${i}-time`"
                class="time-button"
                :class="{ active: isSlotSelected(slot.start) }"
                @click="handleSlotClick(slot)">
                {{ formatTime(slot.start.toISO()) }}
              </secondary-button>
            </template>
          </template>
          <template v-else>
            <div>No availability</div>
          </template>
        </m-stack>
      </template>
    </m-grid>
    <m-group align="center" justify="center" class="flex-1" v-if="isLoading">
      <v-loader :is-loading="isLoading">Loading availability</v-loader>
    </m-group>
  </m-stack>
</template>

<script>
import { VLoader, SecondaryButton, DateNav, DropDownField } from '@/components';
import DstDivider from '@satellite/components/elements/DstDivider';
import { isNull } from 'lodash';
import { DateTime } from 'luxon';
import { LuxonDateTimeFormats, createSlotsFromAvailabilityResponse } from '@satellite/../nova/core';

/**
 * List of available slots as buttons with built-in date navigation
 * @displayName Slot Picker
 */
export default {
  name: 'SlotPicker',
  components: {
    VLoader,
    SecondaryButton,
    DateNav,
    DropDownField,
    DstDivider
  },
  props: {
    /**
     * The selected Slot
     * @model
     */
    value: {
      type: Object,
      required: false,
      default: null
    },
    /**
     * Loadtype ID
     */
    loadtypeId: {
      type: String,
      required: true
    },
    /**
     * Warehouse ID
     */
    warehouseId: {
      type: String,
      required: true
    },
    /**
     * Appointment ID
     */
    appointmentId: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Timezone to render times in
     */
    timezone: {
      type: String,
      required: true
    },
    /**
     * Specific Dock ID to use - if not included, carrier-available warehouse docks will be used
     * This will filter availability to this specific dock
     */
    dockId: {
      type: String,
      required: false
    },
    /**
     * Array of docks to show in a dock select.  Dock select will not show if this is not provided
     * @values [ { label: 'dock 1', value: 'b632efcc-8f2b-494d-978c-bdb6cae47247' } ]
     */
    docks: {
      type: Array,
      required: false,
      default: null
    },
    /**
     * Header may be different color depending on if it's in a dialog or not
     */
    headerBgColor: {
      type: String,
      required: false,
      default: 'white'
    },
    allowDockSelect: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    computedHeaderBgColor() {
      return this.mirandaUtil.getTokenCssValue(this.headerBgColor) || this.headerBgColor;
    },
    isPrevDisabled() {
      if (this.startOfDateRange) {
        return (
          this.startOfDateRange.setZone(this.timezone).startOf('day') <
          DateTime.fromFormat(this.minDate, this.dateFormat).startOf('day')
        );
      }
      return false;
    },
    isNextDisabled() {
      if (this.startOfDateRange) {
        return (
          this.startOfDateRange
            .setZone(this.timezone)
            .plus({ days: this.numDaysToDisplay })
            .endOf('day') > DateTime.fromFormat(this.maxDate, this.dateFormat).endOf('day')
        );
      }
      return false;
    },
    dockIdsToFilter() {
      return this.selectedDock ?? this.warehouseDocks.map(dock => dock.id);
    }
  },
  data() {
    return {
      isLoading: false,
      showDatePicker: false,
      startOfDateRange: null,
      numDaysToDisplay: 5,
      dateRange: [],
      warehouseDocks: [],
      availability: {},
      minDate: null,
      maxDate: null,
      selectedDate: null,
      dateFormat: LuxonDateTimeFormats.DateDashed,
      LuxonDateTimeFormats,
      selectedDock: null,
      mounted: false
    };
  },
  async mounted() {
    this.setMinMaxDates();
    this.initializeSelectedDate();
    this.mounted = true;
  },
  methods: {
    initializeSelectedDate() {
      this.selectedDate = this.value?.start
        ? DateTime.fromISO(this.value.start).setZone(this.timezone).toFormat(this.dateFormat)
        : DateTime.now().setZone(this.timezone).toFormat(this.dateFormat);
    },
    setMinMaxDates() {
      // TODO: Use compute-open-dates to correctly set the min/max date on the calendar
      const yearInHours = (365 * 24 * 60) / 60;
      const baseDateTime = DateTime.now().setZone(this.timezone);
      this.minDate = baseDateTime.startOf('day').toFormat(this.dateFormat);
      this.maxDate = baseDateTime
        .plus({ hour: this.dock?.maxCarrierLeadTime_hr ?? yearInHours })
        .toFormat(this.dateFormat);
    },
    async getWarehouseDocks() {
      this.isLoading = true;
      const queryParams = {
        s: {
          allowCarrierScheduling: true,
          loadTypeIds: { $cont: this.loadtypeId },
          name: { $exclL: '--- capacity' }, // TODO: Feels flimsy if we ever remove this...
          warehouseId: this.warehouseId
        }
      };

      await this.services.dock
        .getDocks(queryParams, { fields: ['id', 'name'] })
        .then(response => {
          this.warehouseDocks = response;
        })
        .finally(() => (this.isLoading = false));
    },
    setStartOfDateRange(value) {
      this.startOfDateRange = DateTime.fromFormat(value, LuxonDateTimeFormats.DateDashed)
        .setZone(this.timezone)
        .startOf('day');
    },
    isNull,
    createDateRange() {
      let dateRange = [];
      let i = 0;
      while (i < this.numDaysToDisplay) {
        dateRange.push({
          day: this.startOfDateRange.plus({ days: i }).weekdayLong,
          date: this.startOfDateRange
            .plus({ days: i })
            .toFormat(LuxonDateTimeFormats.MonthDayYearSlashed)
        });
        i++;
      }

      this.dateRange = dateRange;
    },
    formatTime(time, format) {
      const tz = null;
      return this.novaCore.formatDateTimeWithMilitarySupport(
        time,
        tz,
        format ?? this.novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM,
        this.$isMilitaryTimeEnabled(this.warehouse),
        format ?? this.novaCore.LuxonDateTimeFormats.Extended24HrTime
      );
    },
    debounceAvailability: _.debounce(async function () {
      await this.getAvailability();
    }, 350),
    async getAvailability() {
      this.isLoading = true;
      this.createDateRange();
      let params = {
        warehouseId: this.warehouseId,
        includeStartTimes: true,
        start: this.startOfDateRange.startOf('day').toISO(),
        end: this.startOfDateRange.plus({ days: this.numDaysToDisplay }).endOf('day').toISO()
      };
      if (this.appointmentId) {
        params.excludeApptId = this.appointmentId;
      }
      await this.services.loadtype
        .getAvailability(this.loadtypeId, params)
        .then(response => {
          // Filter down the results by the Dock(s) we'd like to show availability for
          const dockAvailabilities = response.filter(item => {
            return this.dockIdsToFilter.includes(item.dock.id);
          });
          this.isLoading = true;
          this.availability = {};
          this.isLoading = true;
          const avail = createSlotsFromAvailabilityResponse(dockAvailabilities, this.timezone);
          this.availability = avail;

          this.sortSlots();
          this.isLoading = false;
        })
        .finally(() => (this.isLoading = false));
    },
    handleSlotClick(slot) {
      /**
       * v-model input event
       * @event input
       * @property {{docks: string[], start: string, }}
       */
      this.$emit('input', slot);
    },
    sortSlots() {
      Object.entries(this.availability).map(([key, value]) => {
        this.availability[key] = value.sort((a, b) => a.start.toJSDate() - b.start.toJSDate());
      });
    },
    async loadNextDateRange() {
      this.startOfDateRange = this.startOfDateRange.plus({ days: this.numDaysToDisplay });
    },
    async loadPrevDateRange() {
      this.startOfDateRange = this.startOfDateRange.minus({ days: this.numDaysToDisplay });
    },
    isSlotSelected(start) {
      return JSON.stringify(start) === JSON.stringify(this.value.start);
    }
  },
  watch: {
    startOfDateRange() {
      this.createDateRange();
      if (this.dockIdsToFilter.length > 0) {
        this.isLoading = true;
        this.debounceAvailability();
      }
    },
    value(newVal) {
      if (newVal?.start) {
        this.util.appendStyleToShadowDomEl(
          '.time-button.active',
          'button',
          'border-radius:4px;border: 1px solid var(--Accent-100, #0F4261);background: var(--Background-secondary, #F8F9FB);'
        );
      }
    },
    selectedDate(newVal) {
      this.setStartOfDateRange(newVal);
    },
    dockId: {
      async handler(newDockId) {
        if (!newDockId) {
          await this.getWarehouseDocks();
        }
        if (newDockId) {
          this.selectedDock = newDockId;
        }
      },
      immediate: true
    },
    dockIdsToFilter() {
      if (this.startOfDateRange) {
        this.getAvailability();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
// TODO: Report bug - Miranda dropdown always shows "large" size styling
:root .time-button {
  width: 100px;

  &.active {
    border: 1px solid var(--Accent-100, #0f4261) !important;
    background: var(--Background-secondary, #f8f9fb) !important;
  }
}

.is-loading {
  .date-header {
    @media (max-width: $midDesktopBreakpoint) {
      display: none;
    }
  }
}
#availability-grid {
  grid-template-columns: repeat(5, 1fr);

  m-stack.slots {
    m-button {
      width: 100%;
      max-width: 120px;
      min-width: 60px;
    }
  }

  @media (max-width: $midDesktopBreakpoint) {
    grid-template-columns: repeat(1, 1fr);

    > m-group {
      justify-content: flex-start;
    }

    > m-stack.slots {
      display: grid !important;
      gap: $m-spacing-4;
      justify-content: space-between;
      grid-template-columns: repeat(3, 1fr);
    }

    .slots,
    .date-header {
      &.flex-order {
        &-0 {
          order: 0;
        }
        &-1 {
          order: 1;
        }
        &-2 {
          order: 2;
        }
        &-3 {
          order: 3;
        }
        &-4 {
          order: 4;
        }
        &-5 {
          order: 5;
        }
        &-6 {
          order: 6;
        }
        &-7 {
          order: 7;
        }
        &-8 {
          order: 8;
        }
        &-9 {
          order: 9;
        }
        &-10 {
          order: 10;
        }
      }
    }
  }

  @media (max-width: $smallDesktopBreakpoint) {
    > m-stack.slots {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media (max-width: $tabletBreakpoint) {
    > m-stack.slots {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
.header {
  background-color: $m-color-background-primary;
  position: sticky;
  top: -$m-spacing-6;
  padding: $m-spacing-4 1px;
  margin-top: -$m-spacing-4;
  margin-left: -1px;
  width: calc(100% + 2px);
  z-index: 1;

  @media (max-width: $midDesktopBreakpoint) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0;
    top: -$m-spacing-4;

    m-field {
      width: 100%;
    }
  }
}
</style>
