import { AppointmentStatus, DatabaseEntities, makeEntitySettings } from '@satellite/../nova/core';
import config from '@/config/config';

// initial state
const state = {
  warehouse: {},
  org: {},
  isSidebarOpen: false,
  warehouseSearch: null
};

// getters
const getters = {
  warehouseId(state) {
    return state.warehouse?.id;
  },
  warehouseName(state) {
    return state.warehouse?.name;
  },
  warehouseOrgName(state) {
    return state.warehouse?.org?.name;
  },
  warehouseOrgId(state) {
    return state.warehouse?.orgId;
  },
  warehouseAddress(state) {
    let address = '';
    if (state.warehouse?.id) {
      address += `${state.warehouse?.street}, ${state.warehouse?.city}, ${state.warehouse?.state} ${state.warehouse?.zip}`;
    }
    return address;
  },
  warehouseInstructions(state) {
    return state.warehouse?.instructions || 'There are currently no warehouse instructions.';
  },
  warehousePhone(state) {
    return state.warehouse?.phone || 'N/A';
  },
  warehouseEmail(state) {
    return state.warehouse?.email || 'N/A';
  },
  warehouseTimezone(state) {
    return state.warehouse?.timezone;
  },
  warehouseSchedule(state) {
    return state.warehouse?.schedule;
  },
  warehouseAmenities(state) {
    return state.warehouse?.amenities;
  },
  warehousePpeRequirements(state) {
    return state.warehouse?.ppeRequirements;
  },
  consolidatedSettings(state) {
    return makeEntitySettings(DatabaseEntities.Warehouse, state.warehouse || state.org);
  },
  orgWarehouseName(state) {
    return `${state.org?.name} - ${state.warehouse?.name}`;
  },
  appointmentCreationStatus(state) {
    return state.warehouse?.settings?.appointmentCreationStatus ?? AppointmentStatus.Scheduled;
  },
  orgsToExcludeFromSchedulingPortal() {
    return (
      config.orgs_to_exclude_from_scheduling_portal
        ?.split(' ')
        ?.map(orgId => orgId.trim())
        ?.filter(orgId => orgId) ?? []
    );
  }
};

// actions
const actions = {
  //
};

// mutations
const mutations = {
  setWarehouse(state, warehouse) {
    this.$app.$updateObjectInLocalStorage('warehouse', warehouse);
    state.warehouse = warehouse;
    state.org = warehouse.org;
  },
  setIsSidebarOpen(state, val) {
    state.isSidebarOpen = val;
  },
  setWarehouseSearch(state, searchStr) {
    state.warehouseSearch = searchStr;
  }
};

export default {
  namespaced: true,
  name: 'App',
  state,
  getters,
  actions,
  mutations
};
