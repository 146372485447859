<template>
  <m-dialog :open="value" v-bind="[$attrs, $props]" @m-close="handleCancel" class="dialog">
    <m-dialog-header v-if="shouldDisplayHeader">
      <div class="text-left">
        <!-- @slot The header of the dialog -->
        <slot name="header"></slot>
      </div>

      <m-dialog-close v-if="shouldDisplayCloseButton" class="close-button"></m-dialog-close>
    </m-dialog-header>
    <m-dialog-body>
      <!-- @slot The body of the dialog -->
      <slot name="body"></slot>
    </m-dialog-body>
    <m-dialog-footer v-if="$slots.footer">
      <!-- @slot The footer of the dialog -->
      <slot name="footer"></slot>
    </m-dialog-footer>
    <m-dialog-actions v-if="$slots.actions">
      <!-- @slot The actions for the dialog -->
      <slot name="actions" :handle-cancel="handleCancel"></slot>
    </m-dialog-actions>
  </m-dialog>
</template>

<script>
/**
 *
 * Dialog Base that can be used in specific dialog components e.g. AccountCreationErrorsDialog or DuplicateEmailErrorDialog
 *
 * Notice the convention of the dialog display data properties in the example code.  Should be a "dialogs" object with
 * the key being the name of the component sans "dialog" and a boolean value used for the underlying m-dialog component's "open" prop
 * @displayName Dialog Base
 */
export default {
  name: 'DialogBase',
  props: {
    /**
     * @model
     * Whether dialog is open or not
     */
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * If set false, header - including close button - is hidden
     */
    shouldDisplayHeader: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Dialog does not close when the outer overlay is clicked
     */
    isPersistent: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * When true, input is no longer emitted and a custom-close event is emitted
     */
    hasCustomCloseLogic: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Allows for display of the upper right close button
     */
    shouldDisplayCloseButton: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    /**
     * Handle cancel event
     * @private
     */
    handleCancel(e) {
      if (this.isPersistent && e.target.localName !== 'm-dialog-close') {
        return;
      }

      if (this.hasCustomCloseLogic) {
        /**
         * Custom close event to handle the dialog closing from the parent in a way other than the v-model binding
         * @event custom-close
         */
        this.$emit('custom-close');
      } else {
        /**
         * Emits input event with a value of false
         * Used by the parent to close the dialog
         * @property {Boolean} - Always false to hide dialog
         */
        this.$emit('input', false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
:root {
  m-dialog {
    --margin: 100px;
    @media (max-width: $tabletBreakpoint) {
      --margin: 24px;
    }
  }
}
.close-button {
  position: absolute;
  right: 0;
  top: 10px;
  margin: 16px;
  cursor: pointer;
}
</style>
