var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("dialog-base", {
    attrs: { value: _vm.value, size: "small", "has-custom-close-logic": "" },
    on: { input: _vm.updateVal, "custom-close": _vm.redirectToMain },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "m-group",
              { attrs: { gap: "spacing-1" } },
              [
                _c(
                  "v-icon",
                  { attrs: { size: "25px", color: "color-warning-100" } },
                  [_vm._v("information")]
                ),
                _c(
                  "m-text",
                  {
                    attrs: {
                      variant: "heading-md-bold",
                      color: "color-warning-100",
                    },
                  },
                  [_vm._v("Preview mode")]
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "m-text",
              { attrs: { variant: "body-sm", color: "color-text-tertiary" } },
              [
                _vm._v(
                  " You're on Preview Mode, so the appointment was not created. The scheduling user will see a confirmation after booking. "
                ),
              ]
            ),
            _c(
              "m-stack",
              { staticClass: "m-t-4 m-b-2" },
              [
                _c("m-text", { attrs: { variant: "body-md-bold" } }, [
                  _vm._v(_vm._s(_vm.appointmentDateTime)),
                ]),
                _c("warehouse-header", { attrs: { warehouse: _vm.warehouse } }),
                _c("field-value", {
                  attrs: {
                    label: "Confirmation number",
                    value: _vm.appointment?.confirmationNumber,
                    "copy-content": _vm.appointment?.confirmationNumber,
                  },
                }),
                _c("warehouse-address", {
                  attrs: { warehouse: _vm.warehouse },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }