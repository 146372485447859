<template>
  <image-message :image-path="notFoundSvg" image-alt="Two workers loading a truck">
    <m-text variant="body-md-bold">We couldn't load this appointment</m-text>
    <m-text variant="body-sm">
      This appointment doesn't exist anymore or is not associated with your account
    </m-text>
  </image-message>
</template>

<script>
import notFoundSvg from '@satellite/assets/appointment404.svg';
import { ImageMessage } from '@/components';
export default {
  components: { ImageMessage },
  data() {
    return {
      notFoundSvg
    };
  }
};
</script>

<style lang="scss" scoped>
::v-deep .content-wrapper {
  width: 330px;
}
</style>
