<template>
  <internal-page page-title="Book appointment" allow-overflow>
    <template v-slot:body>
      <m-group>
        <text-field
          test-id="warehouse-search"
          v-model="searchStr"
          class="width-100"
          label="Start by selecting a warehouse"
          placeholder="Search by company name, warehouse name, or address"
          :leading-icon="{ icon: 'magnify', color: 'color-text-primary', size: '20px' }"
          @clear="clearSearch"
          clearable />
      </m-group>

      <m-stack align="center" justify="center" class="full-height" v-if="isLoading || noResults">
        <v-loader :is-loading="isLoading">Looking for warehouses</v-loader>
        <no-results v-if="!isLoading && noResults"></no-results>
      </m-stack>

      <template v-if="shouldShowDefault">
        <template v-if="hasFavorites || hasRecents">
          <m-group class="flex-contain m-t-8 flex-nowrap flex-1 widgets">
            <card-grid
              test-id="favorite-warehouses-grid"
              :items="favoriteWarehouses"
              class="widget"
              flagged
              flag-color="color-background-highlight">
              <template v-slot:title>
                <v-icon size="16px">star</v-icon>
                Favorite warehouses
              </template>
              <template v-slot:row="{ item }">
                <m-stack gap="spacing-1" @click="viewWarehouse(item)">
                  <m-text variant="body-md-bold">{{ item.name }}</m-text>
                  <m-text variant="body-md">{{ item.org.name }}</m-text>
                  <m-text variant="body-md">
                    {{ getLargerAreaOfAddress(item) }}
                  </m-text>
                </m-stack>
              </template>
              <template v-slot:no-items>
                <m-stack align="center" justify="center" class="height-100" gap="none">
                  <v-icon size="48px">warehouse</v-icon>
                  <m-text variant="body-md">The warehouses you mark as favorites will</m-text>
                  <m-text variant="body-md">show up here for quick access</m-text>
                </m-stack>
              </template>
            </card-grid>

            <card-grid
              test-id="recent-warehouses-grid"
              title="Recent warehouses"
              :items="recentlyVisitedWarehouses"
              class="widget">
              <template v-slot:row="{ item }">
                <m-stack gap="spacing-1" @click="viewWarehouse(item)">
                  <m-text variant="body-md-bold">{{ item.name }}</m-text>
                  <m-text variant="body-md">{{ item.org.name }}</m-text>
                  <m-text variant="body-md">
                    {{ getLargerAreaOfAddress(item, false) }}
                  </m-text>
                </m-stack>
              </template>
              <template v-slot:no-items>
                <m-stack align="center" justify="center" class="height-100" gap="none">
                  <v-icon size="48px">warehouse</v-icon>
                  <m-text variant="body-md">The warehouses you most recently visited will</m-text>
                  <m-text variant="body-md">show up here for quick access</m-text>
                </m-stack>
              </template>
            </card-grid>
          </m-group>
        </template>

        <template v-else>
          <m-stack class="m-t-12" gap="spacing-1">
            <v-icon size="36px" color="color-accent-60">arrow-up</v-icon>
            <m-text variant="body-md-bold">Welcome! Ready to book your first appointment?</m-text>
            <m-text variant="body-md">
              Search for a warehouse to see all its details and availability
            </m-text>
          </m-stack>
          <m-stack class="m-t-8" gap="spacing-1">
            <v-icon size="36px" color="color-accent-60">star</v-icon>
            <m-text variant="body-md-bold">
              Quick access to your favorite and recent warehouses
            </m-text>
            <m-text variant="body-md">
              As you book appointments, your favorite and recent warehouses will show up on this
              page
            </m-text>
          </m-stack>
        </template>
      </template>

      <template v-if="hasResults">
        <m-group align="center" justify="space-between" class="m-t-2 m-b-4">
          <m-text variant="heading-sm">{{ pagination.total }} results</m-text>
        </m-group>
        <card-grid
          test-id="warehouse-search-results"
          :items="results"
          :items-per-row="4"
          :display-outer-card="false"
          @click="viewWarehouse">
          <template v-slot:row="{ item }">
            <m-stack gap="none">
              <m-text variant="body-md-bold">{{ item.name }}</m-text>
              <m-text variant="body-md" color="color-text-primary">
                {{ item.org.name }}
              </m-text>
            </m-stack>
            <m-stack gap="none" class="m-t-3">
              <m-text variant="body-md" color="color-text-primary">
                {{ item.street }}
              </m-text>
              <m-text variant="body-md" color="color-text-primary">
                {{ getLargerAreaOfAddress(item) }}
              </m-text>
            </m-stack>
            <m-group class="m-t-3">
              <m-tag variant="success" v-if="isWarehouseFavorited(item.id)">
                <span slot="leading">
                  <v-icon color="success">star</v-icon>
                </span>
                Favorite
              </m-tag>
              <m-tag v-if="isWarehouseRecentlyVisited(item.id)">Recent</m-tag>
            </m-group>
          </template>
        </card-grid>
      </template>
    </template>

    <template v-slot:footer>
      <v-pagination
        test-id="warehouse-search-pagination"
        v-if="pagination.totalPages"
        v-model="pagination.page"
        :length="pagination.totalPages"
        :total-visible="5"
        class="search-pagination" />
    </template>
  </internal-page>
</template>

<script>
import {
  TextField,
  VLoader,
  NoResults,
  VIcon,
  InternalPage,
  CardGrid,
  VPagination
} from '@/components';
import { isNull } from 'lodash';

export default {
  components: {
    TextField,
    VLoader,
    NoResults,
    VPagination,
    VIcon,
    InternalPage,
    CardGrid
  },
  data() {
    return {
      searchStr: '',
      pagination: {
        page: 1,
        totalPages: 0,
        total: 0,
        sortColumn: 'warehouse.name',
        itemsPerPage: 8
      },
      results: null,
      isLoading: false,
      isNewSearch: true,
      sortOptions: [
        {
          label: 'Warehouse name',
          value: 'warehouse.name'
        },
        {
          label: 'Company name',
          value: 'org.name'
        },
        {
          label: 'City',
          value: 'warehouse.city'
        },
        {
          label: 'Address',
          value: 'warehouse.street'
        },
        {
          label: 'Favorites/Recents',
          value: 'favorites-and-recents'
        }
      ],
      favoriteWarehouses: [],
      recentlyVisitedWarehouses: []
    };
  },
  computed: {
    noResults() {
      return !isNull(this.results) && this.results.length === 0;
    },
    hasResults() {
      return !isNull(this.results) && this.results.length > 0;
    },
    apiPagination() {
      return {
        size: this.pagination.itemsPerPage,
        page: this.pagination.page,
        sortColumn: this.pagination.sortColumn
      };
    },
    shouldShowDefault() {
      return isNull(this.results) && !this.isLoading;
    },
    hasFavorites() {
      return this.favoriteWarehouses.length > 0;
    },
    hasRecents() {
      return this.recentlyVisitedWarehouses.length > 0;
    },
    recentWarehouseIds() {
      return this.novaCore.pluck(this.recentlyVisitedWarehouses, 'id');
    }
  },
  methods: {
    clearSearch() {
      this.searchWarehouses('', this, true);
    },
    searchWarehouses: _.debounce(async function (searchStr, isNewSearch, sendMixpanelEvent = true) {
      this.results = null;
      this.pagination.total = 0;
      this.pagination.totalPages = 0;
      this.isNewSearch = isNewSearch;
      if (isNewSearch) {
        this.pagination.page = 1;
      }
      if (searchStr && !this.isLoading) {
        this.isLoading = true;
        // Make sure mr preview sees only the requested warehouse
        if (this.$isMrPreview) {
          await this.services.warehouse
            .getWarehouseById(
              this.$previewWarehouseId,
              { includeHierarchySettings: true },
              { joins: ['org||name,settings'] }
            )
            .then(warehouse => {
              this.pagination.page = 1;
              this.pagination.total = 1;
              this.pagination.totalPages = 1;

              this.results = [warehouse];
            })
            .finally(() => (this.isLoading = false));
        } else {
          await axios
            .get('carrier/warehouse-search', {
              params: {
                searchStr,
                ...this.apiPagination,
                recentWarehouseIds: this.recentWarehouseIds,
                excludedOrgIds: this.$orgsToExcludeFromSchedulingPortal
              }
            })
            .then(response => {
              if (response?.data) {
                this.pagination.totalPages = Math.ceil(
                  response.data.total / this.pagination.itemsPerPage
                );
                this.pagination.total = response.data.total;
                this.results = response.data.data ?? [];
                if (sendMixpanelEvent) {
                  this.mixpanel.track(this.mixpanel.events.ACTION.SEARCH_WAREHOUSE, {
                    'Search String': searchStr,
                    'Number of Results': this.pagination.total
                  });
                }
              }
            })
            .finally(() => {
              this.isLoading = false;
              this.isNewSearch = false;
            });
        }
      }
    }, 350),
    getLargerAreaOfAddress({ city, state, zip, country }) {
      return this.util.getLargerAreaOfAddress({
        city,
        state,
        zip,
        country
      });
    },
    viewWarehouse(warehouse) {
      this.$store.commit('App/setWarehouseSearch', this.searchStr);
      this.mixpanel.track(this.mixpanel.events.ACTION.SELECT_WAREHOUSE, {
        'Warehouse Name': warehouse.name,
        'Warehouse ID': warehouse.id
      });
      this.$router.push({
        name: 'warehouse.details',
        params: { warehouseId: warehouse.id }
      });
    },
    isWarehouseFavorited(warehouseId) {
      return this.novaCore.pluck(this.favoriteWarehouses, 'id').includes(warehouseId);
    },
    isWarehouseRecentlyVisited(warehouseId) {
      return this.recentWarehouseIds.includes(warehouseId);
    }
  },

  watch: {
    searchStr(searchStr) {
      this.searchWarehouses(searchStr, true);
    },
    'pagination.page'() {
      if (!this.isNewSearch) {
        this.searchWarehouses(this.searchStr);
      }
    },
    'pagination.sortColumn'() {
      if (!this.isNewSearch) {
        const selectedSortOption = this.sortOptions.find(
          option => option.value === this.pagination.sortColumn
        );
        this.mixpanel.track(this.mixpanel.events.ACTION.CHANGE_WAREHOUSE_SEARCH_ORDER, {
          'Sort By': selectedSortOption?.label ?? null
        });
        this.searchWarehouses(this.searchStr, true, false);
      }
    }
  },

  async mounted() {
    await this.services.carrier.getFavoriteWarehouses().then(response => {
      this.favoriteWarehouses = response;
    });
    this.recentlyVisitedWarehouses = this.novaCore.pluck(
      this.services.carrier.getRecentWarehouses(),
      'data'
    );
    this.searchStr = this.$warehouseSearch ?? null;
  }
};
</script>

<style lang="scss" scoped>
.widgets {
  @media (max-width: $smallDesktopBreakpoint) {
    flex-direction: column;
  }
}

.widget {
  width: 100%;
  @media (max-width: $smallDesktopBreakpoint) {
    flex: 1;
  }
}
</style>
