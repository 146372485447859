<template>
  <m-text variant="body-md">----</m-text>
</template>

<script>
/**
 * Used when there is no content and we want to display an indicator besides leaving the space blank
 * @displayName No Content
 */
export default {
  name: 'NoContent'
};
</script>
