var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-stack",
    { staticClass: "height-100", attrs: { gap: "none" } },
    [
      _c(
        "m-group",
        {
          staticClass: "display-tablet flex width-100 flex-0",
          attrs: { align: "center", justify: "space-between" },
        },
        [
          _c(
            "tertiary-button",
            {
              attrs: {
                "test-id": "get-company-help-btn",
                "leading-icon": "help-circle",
                "icon-size": "15px",
              },
            },
            [_vm._v(" Get help ")]
          ),
          _c(
            "v-link",
            {
              attrs: {
                href: "https://safer.fmcsa.dot.gov/CompanySnapshot.aspx",
                target: "_blank",
              },
            },
            [_vm._v(" Look up USDOT number ")]
          ),
        ],
        1
      ),
      _c(
        "m-tabs",
        {
          attrs: { "active-tab": "name" },
          on: { "m-tab-change": _vm.handleTabChange },
        },
        [
          _c(
            "m-group",
            { attrs: { justify: "space-between", align: "center" } },
            [
              _c(
                "div",
                { staticClass: "tabs" },
                [
                  _c(
                    "m-tab",
                    {
                      attrs: {
                        name: "name",
                        "data-testid": "company-search-tab-name",
                      },
                    },
                    [
                      _c("span", [
                        _c("span", { staticClass: "display-desktop" }, [
                          _vm._v("Search by"),
                        ]),
                        _vm._v(" company name "),
                      ]),
                    ]
                  ),
                  _c(
                    "m-tab",
                    {
                      attrs: {
                        name: "usdot",
                        "data-testid": "company-search-tab-usdot",
                      },
                    },
                    [
                      _c("span", [
                        _c("span", { staticClass: "display-desktop" }, [
                          _vm._v("Search by"),
                        ]),
                        _vm._v(" USDOT number "),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-link",
                {
                  staticClass: "display-desktop",
                  attrs: {
                    href: "https://safer.fmcsa.dot.gov/CompanySnapshot.aspx",
                    target: "_blank",
                  },
                },
                [_vm._v(" Look up USDOT number ")]
              ),
            ],
            1
          ),
          _c(
            "m-tab-panel",
            {
              key: `${_vm.renderKey}-searchUsdot`,
              attrs: { "for-tab": "usdot" },
            },
            [
              _c(
                "text-field",
                {
                  attrs: {
                    label: "",
                    placeholder: "Start typing to search by USDOT number",
                    "test-id": "company-usdot-search",
                  },
                  model: {
                    value: _vm.searchStrings.usdot,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchStrings, "usdot", $$v)
                    },
                    expression: "searchStrings.usdot",
                  },
                },
                [
                  _c(
                    "span",
                    { attrs: { slot: "leading" }, slot: "leading" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "flex",
                          attrs: { size: "22px", color: "color-text-tertiary" },
                        },
                        [_vm._v("magnify")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "m-tab-panel",
            {
              key: `${_vm.renderKey}-searchName`,
              attrs: { "for-tab": "name" },
            },
            [
              _c(
                "text-field",
                {
                  attrs: {
                    label: "",
                    placeholder: "Start typing to search by company name",
                    "test-id": "company-name-search",
                  },
                  model: {
                    value: _vm.searchStrings.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchStrings, "name", $$v)
                    },
                    expression: "searchStrings.name",
                  },
                },
                [
                  _c(
                    "span",
                    { attrs: { slot: "leading" }, slot: "leading" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "m-t-0-5 is__block",
                          attrs: { size: "20px", color: "color-primary-60" },
                        },
                        [_vm._v("magnify")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.companies?.length
        ? _c("data-table-base", {
            staticClass: "hide-rows-per-page company-table mb-2",
            attrs: {
              items: _vm.companies,
              headers: _vm.tableHeaders,
              "mobile-breakpoint": 0,
              "overflow-x": "auto",
              "server-items-length": _vm.totalItems,
              "items-per-page": _vm.options.itemsPerPage,
            },
            on: { "click:row": (item) => _vm.setSelectedCompany(item.id) },
            scopedSlots: _vm._u(
              [
                {
                  key: `item.select`,
                  fn: function ({ item }) {
                    return [
                      _c(
                        "div",
                        {
                          attrs: { "data-testId": "select-company-radio-btn" },
                          on: {
                            click: function ($event) {
                              return _vm.setSelectedCompany(item.id)
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { size: "24px" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.id === _vm.selectedCompany
                                    ? "radiobox-marked"
                                    : "radiobox-blank"
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        : _vm._e(),
      _vm.isLoading
        ? _c("v-loader", { attrs: { "is-loading": _vm.isLoading } }, [
            _vm._v("Loading companies"),
          ])
        : [
            _vm.hasSearchValue && _vm.companies.length
              ? _c(
                  "m-group",
                  {
                    attrs: {
                      gap: "none",
                      justify: "space-between",
                      id: "select-company-footer",
                    },
                  },
                  [
                    _c(
                      "m-group",
                      {
                        staticClass: "width-100",
                        attrs: { align: "center", justify: "space-between" },
                      },
                      [
                        _c(
                          "m-text",
                          [
                            _vm._v(" Couldn't find your company? "),
                            _c(
                              "link-button",
                              {
                                attrs: {
                                  "test-id": "create-new-company-footer-button",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("show-new-company-dialog")
                                  },
                                },
                              },
                              [_vm._v(" Create a new carrier company ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "primary-button",
                          {
                            attrs: {
                              "test-id": "save-company-btn",
                              disabled: !_vm.selectedCompany,
                            },
                            on: { click: _vm.saveCompanySelection },
                          },
                          [_vm._v(" " + _vm._s(_vm.saveButtonLabel) + " ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : !_vm.companies.length && _vm.hasSearched
              ? _c(
                  "no-results",
                  [
                    _c(
                      "m-stack",
                      { attrs: { gap: "none" } },
                      [
                        !_vm.$slots.message
                          ? _c("m-text", { attrs: { variant: "body-sm" } }, [
                              _vm._v("Please try a different search or"),
                            ])
                          : _vm._e(),
                        _c(
                          "link-button",
                          {
                            attrs: { "test-id": "create-new-company-btn" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("show-new-company-dialog")
                              },
                            },
                          },
                          [_vm._v(" create a new company ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }