import {
  CustomFieldType,
  toKebabCase,
  toCamelCase,
  removeNonAlphaNumericChars,
  getRequiredValue
} from '@satellite/../nova/core';
import { isArray } from 'class-validator';
import { isNull } from 'lodash';

const customFieldsTypeToComponentMap = Object.freeze({
  bool: 'v-switch',
  str: 'text-field',
  int: 'text-field',
  bigstr: 'text-area',
  date: 'single-date-picker',
  phone: 'phone-number-field',
  timestamp: 'time-stamp-field',
  dropdown: 'drop-down-field',
  dropdownmultiselect: 'drop-down-field',
  combobox: 'combo-box',
  doc: 'document-upload',
  multidoc: 'document-upload'
});

export default {
  name: 'customFieldsMixin',
  data() {
    return {
      formattedCustomFields: {}
    };
  },
  methods: {
    /**
     * This is the custom field property name when setting and interacting with value returned from setCustomValues.
     * Note: Ran into an issue where the 'name' property of the custom field was showing as another field's.  I'm unsure
     * or why this happened, but this is why this uses both the name and the label and formats it.
     * @public
     * @param customField
     * @returns {*}
     */
    getCustomFieldKey(customField) {
      return toCamelCase(removeNonAlphaNumericChars(`${customField.name} ${customField.label}`));
    },
    /**
     * Some of the Miranda fields do not return correct types - i.e. number type of text field returns string.
     * This is to ensure the values are the correct type.
     * @param type
     * @param value
     * @returns {*}
     */
    ensureCorrectValueType(type, value) {
      let castedValue;
      switch (type) {
        case CustomFieldType.Number:
          castedValue = value ? parseInt(value) : value;
          break;
        case CustomFieldType.Document:
          castedValue = isArray(value) ? value[0] : value;
          break;
        case CustomFieldType.MultiDocument:
          castedValue = !isArray(value) ? [value] : value;
          break;
        default:
          castedValue = value;
          break;
      }
      return castedValue;
    },
    customFieldHasValue(value) {
      return !isNull(value) && typeof value !== 'undefined';
    },
    /**
     * Rectifies custom field values with the original custom field template items
     * @public
     * @param values
     * @param customFields
     * @param isCarrierView
     * @returns {{}}
     */
    getCustomFieldsWithValues(values, customFields = {}, isCarrierView = true) {
      const customFieldsWithVals = {};
      Object.values(customFields ?? {}).forEach(customField => {
        const customFieldKey = this.getCustomFieldKey(customField);
        const value = values[customFieldKey];

        customField.required = getRequiredValue(customField, isCarrierView);
        customFieldsWithVals[customFieldKey] = this.customFieldHasValue(value)
          ? {
              ...customField,
              value: this.ensureCorrectValueType(customField.type, value)
            }
          : customField;
      });

      return customFieldsWithVals;
    },
    /**
     * Formats custom fields in the basicForm 'fields' schema and sets to formattedCustomFields data property
     * @public
     */
    setFormattedcustomFields(customApptFieldsTemplate, warehouse, additionalFieldProps = {}) {
      const fields = {};
      customApptFieldsTemplate
        ?.filter(customField => !customField.hiddenFromCarrier)
        ?.forEach(customField => {
          let fieldProps;
          const field = {
            component: customFieldsTypeToComponentMap[customField.type] ?? 'text-field',
            fieldKey: 'customFields',
            fieldProps: {
              hint: customField.description,
              label: customField.label,
              required: customField.requiredForCarrier,
              value: customField.value,
              placeholder: customField.placeholder,
              testId: `custom-field-${toKebabCase(customField.label)}`,
              hideDetails: !customField.description,
              rules: this.$validator.rules.customField(
                '',
                false,
                customField.type,
                customField.minLengthOrValue,
                customField.maxLengthOrValue
              )
            }
          };

          const isString = customField.type === CustomFieldType.String;
          const isNumber = customField.type === CustomFieldType.Number;
          const isEmail = customField.type === CustomFieldType.Email;
          const isPhone = customField.type === CustomFieldType.Phone;
          const isBoolean = customField.type === CustomFieldType.Bool;
          const isTimeStamp = customField.type === CustomFieldType.Timestamp;
          const isBigString = customField.type === CustomFieldType.BigString;
          const isDate = customField.type === CustomFieldType.Date;
          const isDropDown = customField.type === CustomFieldType.DropDown;
          const isDropDownMulti = customField.type === CustomFieldType.DropDownMultiSelect;
          const isCombobox = customField.type === CustomFieldType.ComboBox;
          // const isDoc = customField.type === CustomFieldType.Document;
          const isMultiDoc = customField.type === CustomFieldType.MultiDocument;

          if (isString) {
            fieldProps = { type: 'text' };
          }
          if (isNumber) {
            fieldProps = { type: 'number' };
          }
          if (isEmail) {
            fieldProps = { type: 'email' };
          }

          if (isTimeStamp) {
            fieldProps = {
              isMilitaryTimeEnabled: this.$isMilitaryTimeEnabled(warehouse),
              timezone: warehouse.timezone
            };
          }
          if (isBoolean) {
            // True/False can't be required - it does not make any sense here
            fieldProps = { value: field.fieldProps.value ?? false, required: false, size: 'large' };
          }
          if (isBigString) {
            fieldProps = {
              isCompact: true
            };
          }
          if (isDropDown || isDropDownMulti || isCombobox) {
            fieldProps = {
              options: customField.dropDownValues.map(option => {
                return { label: option, value: option };
              }),
              multiple: isDropDownMulti ? true : undefined
            };
          }
          if (isMultiDoc) {
            fieldProps = { isMultiple: true };
          }
          if (isNumber || isPhone || isTimeStamp || isDate) {
            fieldProps = { ...fieldProps, width: '400px' };
          }
          field.fieldProps = { ...field.fieldProps, ...fieldProps, ...additionalFieldProps };
          fields[this.getCustomFieldKey(customField)] = field;
        });
      this.formattedCustomFields = fields;
    }
  }
};
