var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button-base",
    _vm._b(
      {
        attrs: { variant: "icon", "test-id": _vm.testId },
        on: {
          click: function ($event) {
            return _vm.handleClick($event)
          },
        },
      },
      "button-base",
      [_vm.$attrs, _vm.$props],
      false
    ),
    [
      _c(
        "v-icon",
        {
          attrs: {
            size: _vm.iconSize ?? _vm.$attrs.size,
            disabled: _vm.$attrs.disabled,
            color: _vm.iconColor,
          },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }