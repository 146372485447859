<template>
  <i
    @click="$emit('click')"
    class="mdi"
    :class="[`mdi-${getIcon()}`]"
    :style="`font-size:${sizeValue}${sizeUnit};color:${computedColor};`"></i>
</template>

<script>
/**
 * Icon component.  To view Miranda color or font tokens, see "mounted" hook in example code for token logging
 * @displayName V Icon
 */

export default {
  name: 'VIcon',
  props: {
    /**
     * Accepts either Miranda "font-size" tokens, "small" "default" or "large", or a direct value/unit
     * e.g. "font-size-6", "small", or "2.3rem"
     */
    size: {
      type: String,
      required: false,
      default: 'default'
    },
    /**
     * Accepts either Miranda "color" tokens or hex values
     * e.g. "color-text-inverted", "#000"
     */
    color: {
      type: String,
      required: false,
      default: 'color-text-primary'
    },
    /**
     * Is icon visibly disabled
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    computedColor() {
      const color = this.disabled ? 'color-text-disabled' : this.color;
      return this.mirandaUtil.getTokenCssValue(color) || color;
    }
  },
  data() {
    return {
      sizeUnit: 'px',
      sizeValue: '',
      classList: []
    };
  },
  methods: {
    /**
     * @private
     * @returns {string}
     */
    getIcon() {
      let icon = this.$slots?.default?.[0]?.text;
      if (icon) {
        // To support both with and without mdi- in icon name
        icon = icon.replace('mdi-', '').trim();
      }
      return icon;
    },
    /**
     * @private
     * Parses the size prop to get the value/unit
     */
    setFontSize() {
      const { sizeValue, sizeUnit } = this.util.computeSize(this.size);
      this.sizeValue = sizeValue;
      this.sizeUnit = sizeUnit;
    }
  },
  beforeMount() {
    this.setFontSize();
  }
};
</script>
