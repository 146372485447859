<template>
  <m-field
    :status="computedErrors.length > 0 ? 'danger' : 'default'"
    :data-testid="testId"
    class="field-wrapper"
    :class="{ 'flex-1': !width, 'military-time': isMilitaryTimeEnabled }">
    <m-field-label v-if="label" slot="label" :data-testid="`${testId}-label`">
      {{ label }}
    </m-field-label>
    <div :style="computedWidth" align="center" gap="spacing-2" class="timestamp-group">
      <single-date-picker
        class="timestamp-input"
        @focus="handleFocus"
        @blur="handleBlur"
        v-bind="[$attrs]"
        :name="fieldName"
        :timezone="timezone"
        :placeholder="placeholder"
        v-model="selectedDate"
        :test-id="`${testId}-date-picker-field`" />
      <text-field
        class="p-b-1 timestamp-input"
        :key="renderKey"
        @focus="handleFocus"
        @blur="handleTimeInputBlur"
        ref="time-input"
        v-bind="[$attrs]"
        :field-name="fieldName"
        :placeholder="placeholder"
        v-model="time"
        @keydown="preventBadKeys"
        :external-error="timeErrors.length > 0 ? timeErrors[0] : null"
        :shouldDisplayMessages="false"
        :test-id="`${testId}-text-field`"></text-field>
      <button-toggle-group
        class="ampm-buttons"
        type="single-strict"
        v-if="!isMilitaryTimeEnabled"
        v-model="ampm"
        :buttons="[
          { value: 'AM', label: 'AM' },
          { value: 'PM', label: 'PM' }
        ]"></button-toggle-group>
      <slot name="trailing"></slot>
    </div>
    <m-field-hint :data-testid="`${testId}-errors`">
      <span>{{ computedErrors.join(', ') }}</span>
      <span v-if="computedErrors.length && hint">.</span>
      <span v-if="hint" :class="{ 'p-l-1': computedErrors.length }">{{ hint }}</span>
    </m-field-hint>
  </m-field>
</template>

<script>
import fieldMixin from '@/components/mixins/fieldMixin';
import validatable from '@/components/mixins/validatable';
import testable from '@/components/mixins/testable';
import {
  LuxonDateTimeFormats,
  formatDateTimeWithMilitarySupport,
  formatClockTime,
  CharacterKeys
} from '@satellite/../nova/core';
import { SingleDatePicker, TextField, ButtonToggleGroup } from '@/components';
import { DateTime } from 'luxon';
import renderMixin from '@satellite/components/mixins/renderMixin';

/**
 * Military-supported timestamp entry field.  Defaults to browser timezone but accepts timezone prop
 * @displayName Timestamp Field
 */
export default {
  // TODO: Figure out why text field widths are causing overflow
  name: 'TimeStamp',
  mixins: [fieldMixin, renderMixin, validatable, testable],
  components: { SingleDatePicker, TextField, ButtonToggleGroup },
  props: {
    /**
     * @model
     */
    value: {
      type: String,
      required: false
    },
    /**
     * Field wrapper width
     */
    width: {
      type: String,
      required: false,
      default: '400px'
    },
    /**
     * Military time mode
     */
    isMilitaryTimeEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Timezone to be used
     */
    timezone: {
      type: String,
      required: false,
      default: 'UTC'
    }
  },
  computed: {
    computedErrors() {
      return [...this.errorBucket, ...this.timeErrors] ?? [];
    }
  },
  data() {
    return {
      selectedDate: null,
      time: null,
      ampm: 'AM',
      timeErrors: [],
      timeRules: null
    };
  },
  methods: {
    formatTime(time) {
      if (this.timeErrors.length > 0) {
        return;
      }
      return formatClockTime(time, this.isMilitaryTimeEnabled);
    },
    buildValue() {
      const formattedTime = this.formatTime(this.time);
      let dateTime;
      if (this.isMilitaryTimeEnabled) {
        dateTime = DateTime.fromFormat(
          `${this.selectedDate}-${formattedTime}`,
          'yyyy-MM-dd-HH:mm',
          { zone: this.timezone }
        );
      } else {
        dateTime = DateTime.fromFormat(
          `${this.selectedDate}-${formattedTime}${this.ampm}`,
          'yyyy-MM-dd-hh:mma',
          { zone: this.timezone }
        );
      }

      return dateTime.toUTC().toISO();
    },
    updateVal() {
      this.$nextTick(() => {
        if (this.timeErrors?.length === 0 && this.time) {
          this.time = this.formatTime(this.time);
          this.rerender();

          if (this.selectedDate) {
            const val = this.buildValue();
            this.internalValue = val;
          } else {
            if (this.value) {
              this.setTimeFromVal();
              this.rerender();
            }
          }
        }
      });
    },
    setTimeFromVal() {
      this.time = this.value
        ? formatDateTimeWithMilitarySupport(
            this.value,
            this.timezone,
            LuxonDateTimeFormats.Extended12HrTimePadding,
            this.isMilitaryTimeEnabled,
            LuxonDateTimeFormats.Extended24HrTime
          )
        : null;
    },
    setDateFromVal() {
      this.selectedDate = this.value
        ? DateTime.fromISO(this.value)
            .setZone(this.timezone)
            .toFormat(LuxonDateTimeFormats.DateDashed)
        : null;
    },
    setAmPmFromVal() {
      if (!this.isMilitaryTimeEnabled) {
        this.ampm = this.value
          ? DateTime.fromISO(this.value, { zone: this.timezone }).toFormat('a')
          : 'AM';
      }
    },
    setTimeInputRules() {
      this.timeRules = this.isMilitaryTimeEnabled
        ? this.$validator.rules.time24
        : this.$validator.rules.time;
    },
    handleTimeInputBlur() {
      this.handleBlur();
      this.updateVal();
    },
    preventBadKeys(e) {
      const key = e.key;
      const allowedKeys = new Set([
        CharacterKeys.COLON,
        CharacterKeys.LEFT_ARROW,
        CharacterKeys.RIGHT_ARROW,
        CharacterKeys.BACKSPACE,
        CharacterKeys.DELETE
      ]);

      const isNumber = !isNaN(Number(key));
      const isSpacebar = key === CharacterKeys.SPACE;

      if (!allowedKeys.has(key) && (isSpacebar || !isNumber)) {
        e.preventDefault();
      } else {
        return true;
      }
    }
  },
  mounted() {
    this.setTimeInputRules();
    this.setDateFromVal();
    this.setTimeFromVal();
    this.setAmPmFromVal();
  },
  watch: {
    value() {
      this.setDateFromVal();
      this.setTimeFromVal();
      this.setAmPmFromVal();
      this.rerender();
    },
    time(newTime) {
      this.timeErrors = this.util.validateValuesAgainstRules(newTime, this.timeRules) ?? [];
    },
    selectedDate() {
      this.updateVal();
    },
    ampm() {
      this.updateVal();
    }
  }
};
</script>

<style lang="scss" scoped>
.timestamp-input {
  overflow: hidden;
}

:host {
  --m-grid-min-column-width: 30px !important;
}

.timestamp-group {
  display: grid;
  grid-template-columns: 1fr 1fr 92px;
  grid-gap: $m-spacing-2;

  @media (max-width: $tabletBreakpoint) {
    width: auto !important;
  }
}
</style>
