var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      attrs: {
        "data-testid": _vm.toKebabCase(
          `${_vm.customField.type}-${_vm.customField.label}`
        ),
      },
    },
    [
      _c(
        "field-value",
        _vm._b(
          {
            attrs: { label: _vm.customField.label },
            scopedSlots: _vm._u([
              {
                key: "value",
                fn: function () {
                  return [
                    !_vm.isNull(_vm.computedValue) &&
                    typeof _vm.computedValue !== "undefined"
                      ? [
                          _vm.customField.type ===
                          _vm.CustomFieldType.MultiDocument
                            ? _vm._l(_vm.computedValue, function (link, index) {
                                return _c(
                                  "v-link",
                                  {
                                    key: `${link}-${index}`,
                                    attrs: { href: link },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.truncateString(
                                            _vm.getDocumentNameFromUrl(link),
                                            20
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              })
                            : _vm.customField.type ===
                              _vm.CustomFieldType.Document
                            ? _c(
                                "m-link",
                                { attrs: { href: _vm.computedValue } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.truncateString(
                                          _vm.getDocumentNameFromUrl(
                                            _vm.computedValue
                                          ),
                                          20
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm.customField.type ===
                              _vm.CustomFieldType.DropDownMultiSelect
                            ? _c(
                                "m-group",
                                { attrs: { gap: "spacing-2" } },
                                _vm._l(
                                  _vm.computedValue,
                                  function (val, index) {
                                    return _c(
                                      "m-tag",
                                      { key: `${val}-${index}` },
                                      [_vm._v(" " + _vm._s(val) + " ")]
                                    )
                                  }
                                ),
                                1
                              )
                            : _c("m-text", { attrs: { variant: "body-md" } }, [
                                _vm._v(" " + _vm._s(_vm.computedValue) + " "),
                              ]),
                        ]
                      : _c("no-content"),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "field-value",
          [_vm.$attrs],
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }