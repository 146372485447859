import { getQueryStringFromOptions, getResponse } from '@satellite/services/serviceUtilities';

const defaultRequestOptions = {
  fields: [
    'name',
    'street',
    'city',
    'state',
    'country',
    'zip',
    'facilityNumber',
    'timezone',
    'settings',
    'schedule',
    'geolocation'
  ],
  joins: []
};

/**
 * options: { fields: string[], joins: string[], includeMetaData: boolean }
 */

export default {
  async getWarehouseById(warehouseId, params, options = {}, requestConfig = {}) {
    const queryString = getQueryStringFromOptions(options);
    const response = await axios.get(
      `warehouse/${warehouseId}?${queryString}`,
      {
        params
      },
      requestConfig
    );
    return getResponse(response, options);
  },
  async getWarehouses(params, options = {}) {
    const queryString = getQueryStringFromOptions(options);
    const response = await axios.get(`warehouse?${queryString}`, {
      params
    });
    return getResponse(response, options);
  },
  async getListWarehouses(params, options = {}) {
    const response = await this.getWarehouses(params, {
      ...this.requestOptions.list,
      includeMetaData: options?.includeMetaData
    });
    return response;
  },
  requestOptions: {
    default: {
      field: defaultRequestOptions.fields,
      joins: defaultRequestOptions.joins
    },
    list: {
      fields: [...defaultRequestOptions.fields],
      joins: [...defaultRequestOptions.joins, 'org||name']
    },
    grid: {
      fields: [...defaultRequestOptions.fields],
      joins: [
        ...defaultRequestOptions.joins,
        'docks||name,loadTypeIds,isActive,capacityParentId,schedule,sortOrder',
        'docks.capacityChildren||name,isActive,capacityParentId,loadTypeIds,schedule,sortOrder',
        'org||name,settings'
      ]
    }
  },
  async getWarehouseDocks(warehouseId, params = {}, options = {}) {
    params.s = { ...params.s, warehouseId: warehouseId };
    const queryString = getQueryStringFromOptions(options);
    const response = await axios.get(`dock?${queryString}`, {
      params
    });
    return getResponse(response, options);
  }
};
