<template>
  <m-text v-bind="[$attrs]" :variant="$attrs.variant ?? 'body-sm'">
    <!-- TODO: Published? -->
    Opendock is Powered by Loadsmart Inc. © {{ new Date().getFullYear() }} - All rights reserved
  </m-text>
</template>
<script>
/**
 * Copyright text with dynamic copyright year
 * @displayName Copyright Text
 */
export default {
  name: 'CopyrightText'
};
</script>
