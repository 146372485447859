<template>
  <internal-page allow-overflow>
    <template v-slot:header>
      <page-header has-back-button title="Appointment details" :key="renderKey">
        <template v-slot:header-actions v-if="shouldRenderPage">
          <template v-if="shouldDisplayManagementActions">
            <m-group justify="flex-end" gap="spacing-2" class="display-tablet flex">
              <icon-button
                :disabled="isCancelDisabled"
                test-id="mobile-cancel-btn"
                background-color="color-danger-20"
                icon-size="20px"
                icon-color="color-danger-100"
                @click="dialogs.cancelAppointment = true">
                close
              </icon-button>
              <icon-button
                :disabled="isRescheduleDisabled"
                test-id="mobile-reschedule-btn"
                icon-size="20px"
                @click="dialogs.rescheduleAppointment = true">
                calendar-blank-outline
              </icon-button>
              <icon-button
                :disabled="isEditDisabled"
                test-id="mobile-edit-btn"
                icon-size="20px"
                @click="dialogs.editAppointment = true">
                pencil
              </icon-button>
              <print-appointment-button
                v-if="shouldRenderPage"
                :extended-appointment="extendedAppointment"
                :warehouse="warehouse">
                <template #trigger="{ openPrintTab }">
                  <icon-button test-id="print-icon" icon-size="20px" @click="openPrintTab">
                    printer
                  </icon-button>
                </template>
              </print-appointment-button>
            </m-group>

            <m-group justify="flex-end" gap="spacing-2" class="display-desktop flex">
              <tertiary-button
                :disabled="isCancelDisabled"
                v-if="extendedAppointment.canBeCancelled()"
                test-id="appointment-details-cancel-btn"
                leading-icon="close"
                color="color-danger-100"
                :title="carrierLeadTimeRules"
                @click="dialogs.cancelAppointment = true">
                Cancel
              </tertiary-button>
              <tertiary-button
                :disabled="isRescheduleDisabled"
                test-id="appointment-details-reschedule-btn"
                :title="carrierLeadTimeRules"
                leading-icon="calendar-blank-outline"
                @click="dialogs.rescheduleAppointment = true">
                Reschedule
              </tertiary-button>
              <tertiary-button
                :disabled="isEditDisabled"
                test-id="appointment-details-edit-btn"
                leading-icon="pencil"
                :title="carrierLeadTimeRules"
                @click="dialogs.editAppointment = true">
                Edit details
              </tertiary-button>
            </m-group>
          </template>

          <tertiary-button
            v-if="isPublic && shouldRenderPage"
            v-show="allowCarrierScheduling"
            test-id="manage-appointment-btn"
            leading-icon="lock"
            @click="manageAppointmentLogin">
            Manage appointment
          </tertiary-button>
          <print-appointment-button
            class="display-desktop"
            v-if="shouldRenderPage"
            :extended-appointment="extendedAppointment"
            :warehouse="warehouse" />
        </template>
      </page-header>
    </template>
    <template v-slot:body>
      <template v-if="!isLoading">
        <template v-if="shouldRenderPage">
          <sidebar-cards main-content-spacing="none" put-content-outside-of-card>
            <template v-slot:sidebar-content>
              <m-stack class="appointment-summary" gap="spacing-6">
                <m-stack gap="none">
                  <m-text variant="heading-md-bold">
                    {{ extendedAppointment.getReadableDate() }}
                  </m-text>
                  <m-text variant="heading-md-bold">
                    {{ extendedAppointment.getReadableTimes() }}
                    <vuetify-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          class="ml-2"
                          size="20px"
                          v-if="extendedAppointment.isInDSTChange">
                          mdi-sun-clock
                        </v-icon>
                      </template>
                      <span>Includes a Daylight Savings time shift</span>
                    </vuetify-tooltip>
                  </m-text>
                </m-stack>
                <field-value
                  :label="$refNumSettings(extendedAppointment.warehouse).displayName"
                  :value="extendedAppointment?.refNumber"
                  :copy-content="extendedAppointment?.refNumber" />

                <field-value
                  label="Confirmation number"
                  :value="extendedAppointment?.confirmationNumber"
                  :copy-content="extendedAppointment?.confirmationNumber" />

                <field-value label="Loadtype" :value="loadtype.name" />

                <field-value label="Dock" :value="extendedAppointment.getParentDock()?.name" />

                <field-value label="Appointment notes" :value="extendedAppointment.notes" />

                <field-value label="Appointment ID" :value="extendedAppointment.id" />
              </m-stack>
            </template>
            <template v-slot:main-content>
              <m-stack>
                <m-card align="center">
                  <m-card-body :key="renderKey">
                    <status-timeline-wrapper
                      :extended-appointment="extendedAppointment"></status-timeline-wrapper>
                  </m-card-body>
                </m-card>

                <m-card
                  align="center"
                  v-if="
                    isStatusAllowedForETAUpdate(extendedAppointment.status) &&
                    extendedAppointment.isWithin24Hours() &&
                    !isPublic
                  ">
                  <m-card-body class="p-y-2">
                    <m-group align="center" justify="space-between">
                      <m-text variant="body-md-bold">Let the warehouse know your ETA</m-text>
                      <secondary-button test-id="provide-eta-btn" @click="dialogs.createEta = true">
                        Provide ETA
                      </secondary-button>
                    </m-group>
                  </m-card-body>
                </m-card>

                <m-card
                  align="center"
                  v-if="
                    isStatusAllowedForETAUpdate(extendedAppointment.status) &&
                    !extendedAppointment.isWithin24Hours() &&
                    !extendedAppointment.isPastAppointment() &&
                    !isPublic
                  ">
                  <m-card-body class="p-y-2">
                    <m-group align="center" justify="space-between">
                      <m-text variant="body-md">
                        ETA will be available within 24 hours of the appointment time
                      </m-text>
                    </m-group>
                  </m-card-body>
                </m-card>

                <m-card v-if="isPublic">
                  <m-card-title>Carrier details</m-card-title>
                  <m-card-body>
                    <m-group align="flex-start" justify="flex-start">
                      <m-stack gap="none" class="m-r-20">
                        <m-text variant="body-md-bold">
                          {{ extendedAppointment.user.firstName }}
                          {{ extendedAppointment.user.lastName }}
                        </m-text>
                        <m-text variant="body-md">
                          {{ extendedAppointment.user.company.name }}
                        </m-text>
                        <m-text variant="body-md">
                          SCAC: {{ extendedAppointment.user.company.scac ?? '----' }}
                        </m-text>
                      </m-stack>
                      <warehouse-contact :warehouse="extendedAppointment.user"></warehouse-contact>
                    </m-group>
                  </m-card-body>
                </m-card>

                <m-card>
                  <m-card-title>Warehouse details</m-card-title>
                  <m-card-body>
                    <m-group
                      align="flex-start"
                      justify="space-between"
                      class="warehouse-details-card">
                      <warehouse-header :warehouse="warehouse" link-to-warehouse></warehouse-header>
                      <warehouse-address :warehouse="warehouse"></warehouse-address>
                      <warehouse-contact :warehouse="warehouse"></warehouse-contact>
                    </m-group>
                  </m-card-body>
                </m-card>

                <m-accordion>
                  <m-accordion-title>
                    <div class="p-y-2" data-testid="booking-details-accordion-trigger">
                      Booking details
                    </div>
                  </m-accordion-title>
                  <m-accordion-content>
                    <field-value
                      class="m-b-4"
                      :label="$refNumSettings(extendedAppointment.warehouse).displayName"
                      :value="extendedAppointment?.refNumber"
                      :copy-content="extendedAppointment?.refNumber" />
                    <m-grid class="value-grid">
                      <custom-field-value
                        v-for="customField in customFields"
                        :key="getCustomFieldKey(customField)"
                        :custom-field="customField"
                        :warehouse="extendedAppointment.warehouse"></custom-field-value>
                    </m-grid>
                  </m-accordion-content>
                </m-accordion>

                <div v-if="!isPublic">
                  <template v-for="trigger of gateManagementTriggers">
                    <m-accordion
                      v-if="!trigger.timestampToDisplay"
                      :key="trigger.id"
                      readonly
                      :class="{ 'is-disabled': trigger.timestampToDisplay }">
                      <m-accordion-title>
                        <div class="p-y-2">
                          {{
                            trigger.flow?.name ||
                            novaCore.CustomFormsFeaturesData[trigger.feature].title
                          }}
                        </div>
                      </m-accordion-title>
                      <m-accordion-content>
                        <custom-forms-data
                          :display-no-data-message="
                            !extendedAppointment.assetVisit?.id ||
                            trigger.feature === novaCore.CustomFormsFeatures.CheckOut
                          "
                          :trigger="trigger"
                          :object-id="extendedAppointment.id"
                          :timezone="extendedAppointment.warehouse.timezone"
                          :military-time-enabled="isMilitaryTimeEnabled"></custom-forms-data>
                      </m-accordion-content>
                    </m-accordion>
                    <m-card v-else :key="`${trigger.id}-else`">
                      <m-card-title>
                        <div class="d-flex justify-space-between">
                          <m-text variant="body-md-bold">
                            {{
                              trigger.flow?.name ||
                              novaCore.CustomFormsFeaturesData[trigger.feature].title
                            }}
                          </m-text>
                          <m-text variant="body-sm">
                            Captured at {{ trigger.timestampToDisplay }}
                          </m-text>
                        </div>
                      </m-card-title>
                    </m-card>
                  </template>
                </div>
              </m-stack>
            </template>
          </sidebar-cards>
        </template>
        <appointment-not-found v-else-if="appointmentNotFound"></appointment-not-found>
        <image-message
          v-else-if="!shouldRenderPage"
          :image-path="notFoundSvg"
          image-alt="Two men loading a truck"
          header="Could not load the page"
          message="Something went wrong and the page could not be loaded."></image-message>
      </template>
      <template v-else>
        <v-loader :is-loading="isLoading">Loading appointment</v-loader>
      </template>

      <template v-if="shouldDisplayManagementActions && shouldRenderPage">
        <edit-appointment-dialog
          v-if="shouldRenderPage"
          v-model="dialogs.editAppointment"
          :extended-appointment="extendedAppointment"
          @update:appointment="updateAppointment" />

        <reschedule-appointment-dialog
          v-if="shouldRenderPage && dialogs.rescheduleAppointment"
          v-model="dialogs.rescheduleAppointment"
          :extended-appointment="extendedAppointment"
          @update:appointment="updateAppointment" />

        <cancel-appointment-dialog
          v-if="shouldRenderPage"
          v-model="dialogs.cancelAppointment"
          :appointment="extendedAppointment"
          @update:appointment="updateAppointment" />
      </template>

      <create-eta-dialog
        v-if="shouldRenderPage && isStatusAllowedForETAUpdate(extendedAppointment.status)"
        v-model="dialogs.createEta"
        :extended-appointment="extendedAppointment"
        @update:appointment="updateAppointment" />
    </template>
  </internal-page>
</template>

<script>
import { VTooltip as VuetifyTooltip } from 'vuetify/lib/components';
import notFoundSvg from '@satellite/assets/appointment404.svg';
import {
  InternalPage,
  PageHeader,
  SidebarCards,
  VLoader,
  ImageMessage,
  FieldValue,
  CustomFieldValue,
  TertiaryButton,
  SecondaryButton,
  IconButton,
  VIcon
} from '@/components';
import AppointmentNotFound from '@/components/elements/appointment/AppointmentNotFound.vue';
import WarehouseHeader from '@/components/elements/warehouse/WarehouseHeader.vue';
import WarehouseAddress from '@/components/elements/warehouse/WarehouseAddress.vue';
import WarehouseContact from '@/components/elements/warehouse/WarehouseContact.vue';
import customFieldsMixin from '@/components/mixins/customFieldsMixin';
import EditAppointmentDialog from '@/components/elements/appointment/dialogs/EditAppointmentDialog.vue';
import RescheduleAppointmentDialog from '@/components/elements/appointment/dialogs/RescheduleAppointmentDialog.vue';
import PrintAppointmentButton from '@/components/elements/appointment/PrintAppointmentButton.vue';
import renderMixin from '@satellite/components/mixins/renderMixin';
import CancelAppointmentDialog from '@/components/elements/appointment/dialogs/CancelAppointmentDialog.vue';
import { ExtendedAppointment } from '@/modules/appointments/ExtendedAppointment';
import StatusTimelineWrapper from '@/components/elements/appointment/StatusTimelineWrapper.vue';
import CreateEtaDialog from '@/components/elements/appointment/dialogs/CreateEtaDialog.vue';
import {
  validateCarrierLeadTimeRules,
  toKebabCase,
  ETACarrierValidations
} from '@satellite/../nova/core';
import CustomFormsData from '@/components/elements/customforms/CustomFormsData.vue';

export default {
  // TODO: This component has both mobile and desktop content.  This gets confusing to read through.  Optimize readability.
  mixins: [customFieldsMixin, renderMixin],
  components: {
    CustomFormsData,
    StatusTimelineWrapper,
    RescheduleAppointmentDialog,
    WarehouseContact,
    WarehouseAddress,
    WarehouseHeader,
    AppointmentNotFound,
    VLoader,
    InternalPage,
    SidebarCards,
    PageHeader,
    ImageMessage,
    FieldValue,
    CustomFieldValue,
    TertiaryButton,
    EditAppointmentDialog,
    PrintAppointmentButton,
    CancelAppointmentDialog,
    SecondaryButton,
    CreateEtaDialog,
    IconButton,
    VuetifyTooltip,
    VIcon
  },
  props: {
    appointmentId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      shouldRenderPage: false,
      isLoading: true,
      extendedAppointment: null,
      appointmentNotFound: false,
      notFoundSvg,
      preparedCustomFields: [],
      warehouseTriggers: [],
      dialogs: {
        editAppointment: false,
        rescheduleAppointment: false,
        cancelAppointment: false,
        createEta: false
      },
      allowCarrierScheduling: true
    };
  },
  computed: {
    customFields() {
      return this.extendedAppointment?.customFields?.filter(field => !field.hiddenFromCarrier);
    },
    isStatusAllowedForETAUpdate() {
      return ETACarrierValidations.isStatusAllowedForETAUpdate;
    },
    isMilitaryTimeEnabled() {
      return this.extendedAppointment?.isMilitaryTimeEnabled;
    },
    gateManagementTriggers() {
      return (
        this.warehouseTriggers
          ?.filter(
            t =>
              this.novaCore.CustomFormsGateManagementFeatures.includes(t.feature) &&
              t.dataEntityName === this.novaCore.CustomFormDataEntitiesEnum.appointment
          )
          ?.map(trigger => {
            if (trigger.feature === this.novaCore.CustomFormsFeatures.CheckIn) {
              if (this.extendedAppointment.assetVisit?.id) {
                trigger.timestampToDisplay = this.novaCore.formatDateTimeWithMilitarySupport(
                  this.extendedAppointment.assetVisit?.lastChangedDateTime,
                  this.extendedAppointment.warehouse.timezone,
                  this.novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTimeAMPM,
                  this.extendedAppointment.isMilitaryTimeEnabled,
                  this.novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTime24
                );
              }
            }
            return trigger;
          }) || []
      );
    },
    dock() {
      return this.extendedAppointment?.dock ?? {};
    },
    loadtype() {
      return this.extendedAppointment?.loadType ?? {};
    },
    warehouse() {
      return this.extendedAppointment?.warehouse ?? {};
    },
    isPublic() {
      return !this.$route.meta.requiresAuth || !this.allowCarrierScheduling;
    },
    shouldDisplayManagementActions() {
      return (
        !this.extendedAppointment?.isPastAppointment() &&
        !this.extendedAppointment?.isCancelled() &&
        !this.isPublic
      );
    },
    /*
     * If this is null, no rules were applied
     * If it is a String, it is an error message
     */
    carrierLeadTimeRules() {
      return validateCarrierLeadTimeRules(
        this.$me,
        this.extendedAppointment,
        this.extendedAppointment.dock,
        this.extendedAppointment.warehouse
      );
    },
    isCancelDisabled() {
      return !this.$rolePermissions.canCancelAppointment || this.areLeadTimeRulesBlocking;
    },
    isRescheduleDisabled() {
      return (
        !this.$rolePermissions.canUpdateAppointment ||
        this.$isRescheduleByCarrierForbidden(this.warehouse) ||
        this.areLeadTimeRulesBlocking ||
        !this.extendedAppointment.canBeRescheduled()
      );
    },
    isEditDisabled() {
      return (
        !this.$rolePermissions.canUpdateAppointment ||
        this.areLeadTimeRulesBlocking ||
        !this.extendedAppointment.canBeEdited()
      );
    },
    areLeadTimeRulesBlocking() {
      return Boolean(this.carrierLeadTimeRules);
    }
  },
  methods: {
    toKebabCase,
    updateAppointment(updatedAppointment) {
      this.extendedAppointment.setAppointment(updatedAppointment);
      this.rerender();
    },
    async createExtendedAppointment() {
      try {
        this.extendedAppointment = await ExtendedAppointment.initialize(
          this.appointmentId,
          this.isPublic
        );
        this.shouldRenderPage = this.extendedAppointment?.id;
      } catch (e) {
        if (e?.response?.status === 404) {
          this.appointmentNotFound = true;
        }
      } finally {
        this.isLoading = false;
      }
    },
    manageAppointmentLogin() {
      this.$router.push({
        name: 'login',
        params: {
          postLoginRoute: {
            name: 'appointment.details',
            params: { appointmentId: this.extendedAppointment?.id }
          },
          isManageAppointmentLogin: true
        }
      });
    },
    async getTriggers() {
      if (!this.extendedAppointment.warehouse?.id || this.isPublic) {
        return (this.warehouseTriggers = []);
      }

      const triggerResponse = await axios.get('/custom-forms/trigger', {
        params: {
          s: { objectId: this.extendedAppointment.warehouse.id }
        }
      });
      this.warehouseTriggers = triggerResponse?.data?.data || [];
    }
  },
  async mounted() {
    if (this.$route.meta.requiresAuth) {
      // Check warehouse to see if allows carrier scheduling before we attempt to build extended appointment
      // Which would fail because it fetches the warehouse
      this.allowCarrierScheduling = (
        await this.services.appointment.getAppointmentById(
          this.appointmentId,
          {},
          {
            joins: ['dock||warehouseId', 'dock.warehouse||allowCarrierScheduling'],
            fields: ['dockId']
          }
        )
      )?.dock?.warehouse?.allowCarrierScheduling;
    }
    await this.createExtendedAppointment();
    await this.getTriggers();

    this.mixpanel.track(this.mixpanel.events.VIEW.APPOINTMENT_DETAIL, {
      'Warehouse ID': this.extendedAppointment?.warehouse?.id,
      'Warehouse Name': this.extendedAppointment?.warehouse?.name,
      appointmentId: this.appointmentId,
      Public: this.isPublic ? 'Yes' : 'No'
    });
  }
};
</script>

<style lang="scss" scoped>
.value-grid {
  grid-template-columns: repeat(5, 1fr);
}
::v-deep .sidebar {
  @media (max-width: $smallDesktopBreakpoint) {
    order: 2;
  }
}

.value-grid {
  @media (max-width: $smallDesktopBreakpoint) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: $largeMobileBreakpoint) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $mobileBreakpoint) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.warehouse-details-card {
  @media (max-width: $smallDesktopBreakpoint) {
    flex-direction: column;
  }
}
</style>
