var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-card",
    {
      staticClass: "flex-1 card-grid-container",
      class: {
        [`card-grid-${_vm._uid}`]: true,
        "hide-outer-card": !_vm.displayOuterCard,
        "no-items": _vm.items.length === 0,
      },
      style: `max-height:${_vm.computedMaxHeight}`,
      attrs: { "data-testid": _vm.testId },
    },
    [
      _vm.title && !_vm.$slots.title
        ? _c("m-card-title", [_vm._v(_vm._s(_vm.title))])
        : _vm._e(),
      _vm.$slots.title ? _c("m-card-title", [_vm._t("title")], 2) : _vm._e(),
      _c(
        "m-card-body",
        { staticClass: "height-100 overflow-auto" },
        [
          _vm.items.length > 0
            ? _c(
                "m-grid",
                {
                  staticClass: "card-grid",
                  class: `row-items-${_vm.itemsPerRow}`,
                  attrs: { gap: "spacing-4" },
                },
                _vm._l(_vm.items, function (item, index) {
                  return _c(
                    "m-card",
                    _vm._b(
                      {
                        key: `${_vm._uid}-${index}`,
                        class: {
                          active: _vm.isItemSelected(item),
                          clickable: _vm.clickable,
                        },
                        on: {
                          click: function ($event) {
                            _vm.clickable ? _vm.handleCardClick(item) : ""
                          },
                        },
                      },
                      "m-card",
                      [_vm.$attrs],
                      false
                    ),
                    [
                      _vm.$scopedSlots["row-title"]
                        ? _c(
                            "m-card-title",
                            [_vm._t("row-title", null, { item: item })],
                            2
                          )
                        : _vm._e(),
                      _vm.$scopedSlots["row-title"]
                        ? _c("m-card-divider")
                        : _vm._e(),
                      _c(
                        "m-card-body",
                        [_vm._t("row", null, { item: item })],
                        2
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._t("no-items"),
          _vm._t("footer"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }