var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-stack",
    { staticClass: "flex-1 height-100" },
    [
      _c(
        "m-group",
        {
          staticClass: "search-header",
          attrs: { align: "center", justify: "space-between" },
        },
        [
          _c("m-text", { attrs: { variant: "heading-sm-bold" } }, [
            _vm._v("Select a dock"),
          ]),
          _c("text-field", {
            staticClass: "search-field",
            attrs: {
              width: "280px",
              "test-id": "dock-search-field",
              "field-name": "dock-search",
              placeholder: "Search Dock",
              "leading-icon": { icon: "magnify", color: "color-text-tertiary" },
            },
            model: {
              value: _vm.searchStr,
              callback: function ($$v) {
                _vm.searchStr = $$v
              },
              expression: "searchStr",
            },
          }),
        ],
        1
      ),
      _vm.isLoading && _vm.isNull(_vm.results)
        ? _c("v-loader", { attrs: { "is-loading": _vm.isLoading } }, [
            _vm._v(" Looking for docks "),
          ])
        : _vm._e(),
      !_vm.isLoading && !_vm.isNull(_vm.results)
        ? _c("card-grid", {
            attrs: {
              "test-id": "dock-search-results",
              items: _vm.results,
              "items-per-row": 2,
              "display-outer-card": false,
              "selected-item": _vm.value,
            },
            on: { click: _vm.handleItemClick },
            scopedSlots: _vm._u(
              [
                {
                  key: "row",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "m-stack",
                        { attrs: { gap: "spacing-2" } },
                        [
                          _c("m-text", { attrs: { variant: "body-md-bold" } }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                          _c(
                            "m-stack",
                            { attrs: { gap: "none" } },
                            [
                              _c(
                                "m-text",
                                {
                                  attrs: {
                                    variant: "body-md",
                                    color: "color-text-secondary",
                                  },
                                },
                                [_vm._v("Door number")]
                              ),
                              item.doorNumber
                                ? _c(
                                    "m-text",
                                    { attrs: { variant: "body-md" } },
                                    [_vm._v(_vm._s(item.doorNumber))]
                                  )
                                : _c("no-content"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "no-items",
                  fn: function () {
                    return [_c("no-results")]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1164406664
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }