<template>
  <internal-page allow-overflow>
    <template v-slot:header>
      <page-header has-back-button title="Book Appointment">
        <template v-slot:header-actions>
          <v-stepper
            steps-only
            external-navigation
            data-testid="appointment-stepper"
            v-model="steps"
            :active-step="activeStep"></v-stepper>
        </template>
      </page-header>
    </template>
    <template v-slot:body>
      <template v-if="!isLoading && warehouse">
        <sidebar-cards main-content-spacing="none">
          <template v-slot:sidebar-content>
            <m-stack class="appointment-summary">
              <warehouse-header :warehouse="warehouse"></warehouse-header>
              <warehouse-address :warehouse="warehouse"></warehouse-address>
              <m-text variant="heading-sm-bold">Appointment summary</m-text>
              <no-content v-if="noAppointmentSummary"></no-content>
              <field-value
                v-if="appointmentData.loadtype"
                label="Load type"
                :value="appointmentData.loadtype?.name"></field-value>
              <field-value
                v-if="appointmentData.dock"
                label="Dock"
                :value="appointmentData.dock.name"></field-value>
              <field-value
                v-if="appointmentData.slot?.start"
                label="Date &amp; Time"
                :value="formattedSelectedStart"></field-value>
            </m-stack>
          </template>
          <template v-slot:main-content>
            <m-text variant="body-md" class="mobile-steps">
              Step {{ currentStepNumber }} of {{ steps.length }}
            </m-text>
            <template v-if="activeStep === stepNames.loadtype">
              <loadtype-search
                :warehouse-id="warehouseId"
                v-model="appointmentData.loadtype"
                :hide-duration="
                  $getSettingValue('isLoadTypeDurationCarrierViewHidden', warehouse?.settings)
                ">
                <template v-slot:prepend-header>
                  <m-text variant="body-md" class="mobile-steps">
                    Step {{ currentStepNumber }} of {{ steps.length }}
                  </m-text>
                </template>
              </loadtype-search>
              <footer class="footer-actions">
                <v-spacer></v-spacer>
                <secondary-button
                  test-id="loadtype-next-btn"
                  :disabled="!appointmentData.loadtype"
                  trailing-icon="chevron-right"
                  icon-size="16px"
                  @click="activeStep = loadTypeAllowsDockSelect ? stepNames.dock : stepNames.slot">
                  {{ loadTypeAllowsDockSelect ? 'Dock' : 'Date and time' }}
                </secondary-button>
              </footer>
            </template>

            <template v-if="activeStep === stepNames.dock">
              <dock-search
                :warehouse-id="warehouseId"
                :load-type-id="appointmentData.loadtype.id"
                v-model="appointmentData.dock"></dock-search>
              <footer class="footer-actions">
                <secondary-button
                  test-id="loadtype-back-btn"
                  leading-icon="chevron-left"
                  icon-size="16px"
                  @click="activeStep = stepNames.loadtype">
                  Load type
                </secondary-button>
                <secondary-button
                  test-id="slot-next-btn"
                  :disabled="!appointmentData.dock"
                  trailing-icon="chevron-right"
                  icon-size="16px"
                  @click="activeStep = stepNames.slot">
                  Date and time
                </secondary-button>
              </footer>
            </template>

            <template v-if="activeStep === stepNames.slot">
              <slot-picker
                class="m-t-4"
                header-bg-color="color-background-primary"
                :warehouse-id="warehouse.id"
                :dock-id="appointmentData.dock?.id"
                :loadtype-id="appointmentData.loadtype.id"
                :timezone="warehouse.timezone"
                v-model="appointmentData.slot"></slot-picker>
              <footer class="footer-actions">
                <secondary-button
                  :test-id="loadTypeAllowsDockSelect ? 'dock-back-btn' : 'loadtype-back-btn'"
                  leading-icon="chevron-left"
                  icon-size="16px"
                  @click="
                    activeStep = loadTypeAllowsDockSelect ? stepNames.dock : stepNames.loadtype
                  ">
                  {{ loadTypeAllowsDockSelect ? 'Dock' : 'Load type' }}
                </secondary-button>
                <secondary-button
                  test-id="details-next-btn"
                  :disabled="!appointmentData.slot?.start"
                  trailing-icon="chevron-right"
                  icon-size="16px"
                  @click="activeStep = stepNames.details">
                  Details
                </secondary-button>
              </footer>
            </template>

            <template v-if="activeStep === stepNames.details">
              <m-stack class="height-100">
                <m-text variant="body-md-bold">Enter appointment details</m-text>
                <appointment-details-form
                  ref="appointment-details-form"
                  @click="triggerSubmit"
                  @submit="createAppointment"
                  :custom-fields="warehouse.customApptFieldsTemplate"
                  :warehouse="warehouse" />
              </m-stack>

              <footer class="footer-actions">
                <secondary-button
                  test-id="slot-back-btn"
                  leading-icon="chevron-left"
                  icon-size="16px"
                  @click="activeStep = stepNames.slot">
                  Date and time
                </secondary-button>
                <primary-button
                  test-id="create-appointment-btn"
                  trailing-icon="check"
                  @click="triggerSubmit">
                  {{ submitButtonLabel }}
                </primary-button>
              </footer>
            </template>
          </template>
        </sidebar-cards>
      </template>
      <template v-else>
        <v-loader :is-loading="isLoading">Loading warehouse</v-loader>
      </template>

      <template v-if="warehouse">
        <appointment-submission-dialog
          v-if="!$isMrPreview && !dialogs.appointmentError"
          :warehouse="warehouse"
          :appointment="appointment"
          :is-loading="isSubmittingAppointment"
          v-model="dialogs.appointmentSubmission" />
        <appointment-error-dialog
          :key="renderKey"
          :status-code="statusCode"
          :error-message="errorMessage"
          :warehouse-id="warehouse.id"
          :should-display-close-button="false"
          v-model="dialogs.appointmentError"
          @close="dialogs.appointmentError = false"
          @reset="resetBookingProcess"
          @retry="triggerSubmit" />
        <appointment-submission-preview-dialog
          v-if="$isMrPreview && !dialogs.appointmentError"
          :value="true"
          :warehouse="warehouse"
          v-model="dialogs.appointmentSubmission"
          :appointment="{
            start: appointmentData.slot.start,
            confirmationNumber: '1234 (example)'
          }" />
      </template>
    </template>
  </internal-page>
</template>

<script>
// TODO: Make sure new appointment details form works here
import {
  InternalPage,
  PageHeader,
  VStepper,
  SidebarCards,
  VLoader,
  SecondaryButton,
  VSpacer,
  NoContent,
  FieldValue,
  PrimaryButton
} from '@/components';
import WarehouseHeader from '@/components/elements/warehouse/WarehouseHeader.vue';
import WarehouseAddress from '@/components/elements/warehouse/WarehouseAddress.vue';
import LoadtypeSearch from '@/components/elements/loadtype/LoadtypeSearch.vue';
import DockSearch from '@/components/elements/dock/DockSearch.vue';
import SlotPicker from '@/components/elements/appointment/SlotPicker.vue';
import {
  formatDateTimeWithMilitarySupport,
  LuxonDateTimeFormats,
  AppointmentStatus,
  deepClone
} from '@satellite/../nova/core';
import customFieldsMixin from '@/components/mixins/customFieldsMixin';
import AppointmentSubmissionDialog from '@/components/elements/appointment/dialogs/AppointmentSubmissionDialog.vue';
import AppointmentErrorDialog from '@/components/elements/appointment/dialogs/AppointmentErrorDialog.vue';
import renderMixin from '@satellite/components/mixins/renderMixin';
import AppointmentDetailsForm from '@/components/elements/appointment/AppointmentDetailsForm.vue';
import AppointmentSubmissionPreviewDialog from '@/components/elements/appointment/dialogs/docs/AppointmentSubmissionPreviewDialog.vue';

const stepNames = Object.freeze({
  warehouse: 'warehouse',
  loadtype: 'loadtype',
  dock: 'dock',
  slot: 'slot',
  details: 'details'
});

export default {
  mixins: [customFieldsMixin, renderMixin],
  components: {
    AppointmentDetailsForm,
    AppointmentErrorDialog,
    AppointmentSubmissionDialog,
    AppointmentSubmissionPreviewDialog,
    WarehouseAddress,
    InternalPage,
    PageHeader,
    VStepper,
    SidebarCards,
    WarehouseHeader,
    VLoader,
    LoadtypeSearch,
    SecondaryButton,
    VSpacer,
    DockSearch,
    SlotPicker,
    NoContent,
    FieldValue,
    PrimaryButton
  },
  props: {
    warehouseId: {
      type: String,
      required: true
    }
  },
  computed: {
    stepNames() {
      return stepNames;
    },
    noAppointmentSummary() {
      return (
        !this.appointmentData.dock?.id &&
        !this.appointmentData.loadtype?.id &&
        !this.appointmentData.slot.start
      );
    },
    formattedSelectedStart() {
      let formattedStart = '';
      const slot = this.appointmentData.slot;
      if (slot.start) {
        formattedStart = `${this.appointmentData.slot.start?.weekdayShort}, `;
        formattedStart += `${formatDateTimeWithMilitarySupport(
          slot.start,
          this.warehouse.timezone,
          LuxonDateTimeFormats.MonthDayYearSlashed
        )} - `;
        formattedStart += formatDateTimeWithMilitarySupport(
          slot.start,
          this.warehouse.timezone,
          LuxonDateTimeFormats.Extended12HrTimeAMPMWithAbbreviatedNamedOffset,
          this.$isMilitaryTimeEnabled(this.warehouse),
          LuxonDateTimeFormats.Extended24HrTimeWithAbbreviatedNamedOffset
        );
      }

      return formattedStart;
    },
    currentStepNumber() {
      return this.steps.findIndex(step => step.name === this.activeStep) + 1;
    },
    loadTypeAllowsDockSelect() {
      return this.appointmentData.loadtype?.settings?.allowCarrierDockSelection;
    },
    refNumSettings() {
      return this.$refNumSettings(this.warehouse);
    },
    submitButtonLabel() {
      return this.$isRequestedStatusEnabled(this.warehouse)
        ? 'Request appointment'
        : 'Confirm booking';
    }
  },
  data() {
    return {
      AppointmentStatus,
      warehouse: null,
      isLoading: true,
      isSubmittingAppointment: false,
      defaultSlot: {
        start: null,
        docks: null
      },
      appointmentData: {
        loadtype: null,
        dock: null,
        slot: {
          start: null,
          docks: null
        }
      },
      steps: [
        {
          completed: true,
          label: 'Warehouse',
          name: stepNames.warehouse
        },
        {
          completed: false,
          label: 'Loadtype',
          name: stepNames.loadtype
        },
        {
          completed: false,
          label: 'Date & Time',
          name: stepNames.slot
        },
        {
          completed: false,
          label: 'Details',
          name: stepNames.details
        }
      ],
      loadtypeSearchStr: null,
      activeStep: stepNames.loadtype,
      dialogs: {
        appointmentSubmission: false,
        appointmentError: false
      },
      errorMessage: null,
      appointment: null,
      statusCode: null
    };
  },
  methods: {
    resetBookingProcess() {
      this.appointmentData = { loadtype: null, dock: null, slot: this.defaultSlot };
      this.activeStep = stepNames.loadtype;
      this.steps = deepClone(this.steps).map(step => {
        if (step.name !== stepNames.warehouse) {
          step.completed = false;
        }
        return step;
      });
      this.dialogs.appointmentSubmission = false;
      this.dialogs.appointmentError = false;
    },
    triggerSubmit() {
      this.mixpanel.track(this.mixpanel.events.ACTION.BOOK_APPOINTMENT_CLICKED, {
        'Warehouse ID': this.warehouseId,
        'Warehouse Name': this.warehouse?.name ?? 'Unknown'
      });
      this.$refs['appointment-details-form'].submissionIterator++;
    },
    async createAppointment({ fields }) {
      if (this.$isMrPreview) {
        this.dialogs.appointmentSubmission = true;
        return;
      }
      this.dialogs.appointmentError = false;
      this.statusCode = null;
      this.isSubmittingAppointment = true;
      if (Object.keys(fields.customFields ?? {}).length === 0) {
        delete fields.customFields;
      } else {
        fields.customFields = Object.values(
          this.getCustomFieldsWithValues(
            fields.customFields,
            this.warehouse.customApptFieldsTemplate
          )
        );
      }
      fields.start = this.appointmentData.slot.start.toUTC().toISO();
      fields.loadTypeId = this.appointmentData.loadtype.id;
      fields.warehouseId = this.warehouse.id;
      // TODO: We should loop through these until one takes in the slim chance an appointment
      // TODO: is booked at this dock at this time with this loadtype while the carrier is filling out appointment details
      fields.dockId = this.appointmentData.slot.docks[0];
      fields.userId = this.$me.id;
      await this.services.appointment
        .createAppointment(fields, false, {}, { suppressNotification: true })
        .then(appointment => {
          this.appointment = appointment;
          this.dialogs.appointmentSubmission = true;
          this.mixpanel.track(this.mixpanel.events.ACTION.APPOINTMENT_CREATED, {
            'Warehouse ID': this.warehouseId,
            'Warehouse Name': this.warehouse?.name ?? 'Unknown'
          });
        })
        .catch(e => {
          this.statusCode = e.request.status;
          if (e.request.status === 409 || e.request.status >= 500) {
            // This sets the error modal text
            if (e.response?.data?.message) {
              this.errorMessage = e.response.data.message;
            }
            this.dialogs.appointmentError = true;
            this.rerender();
          } else {
            this.dialogs.appointmentSubmission = false;
            this.notify(e?.response?.data?.message ?? e, 'error');
          }
        })
        .finally(() => (this.isSubmittingAppointment = false));
    }
  },
  async mounted() {
    await this.services.warehouse
      .getWarehouseById(
        this.warehouseId,
        { includeHierarchySettings: true },
        { joins: ['org||name,settings', 'docks||id,name'] }
      )
      .then(response => {
        if (!this.$orgsToExcludeFromSchedulingPortal.includes(response.org.id)) {
          this.warehouse = response;
        } else {
          this.notify('Warehouse not found', 'error');
          this.$router.replace({ name: 'warehouse.select' });
        }
      })
      .finally(() => (this.isLoading = false));
    this.mixpanel.track(this.mixpanel.events.VIEW.BOOK_APPOINTMENT);
  },
  watch: {
    'appointmentData.loadtype'(selectedLoadtype) {
      if (selectedLoadtype) {
        this.mixpanel.track(this.mixpanel.events.ACTION.LOAD_TYPE_SELECTED, {
          'Warehouse ID': this.warehouseId,
          'Warehouse Name': this.warehouse?.name ?? 'Unknown',
          'Loadtype Name': selectedLoadtype.name,
          'Loadtype ID': selectedLoadtype.id
        });
        this.appointmentData.dock = null;
        this.appointmentData.slot = this.defaultSlot;
        this.steps[1].completed = true;
        const dockStepIndex = this.steps.findIndex(step => step.name === stepNames.dock);
        const steps = this.novaCore.deepClone(this.steps);
        if (this.loadTypeAllowsDockSelect) {
          if (dockStepIndex === -1) {
            steps.splice(2, 0, {
              completed: false,
              label: 'Dock',
              name: stepNames.dock
            });
          }

          this.activeStep = stepNames.dock;
        } else {
          if (dockStepIndex > -1) {
            steps.splice(dockStepIndex, 1);
          }

          this.activeStep = stepNames.slot;
        }
        this.steps = steps;
      }
    },
    'appointmentData.dock'(selectedDock) {
      if (selectedDock?.id) {
        this.mixpanel.track(this.mixpanel.events.ACTION.DOCK_SELECTED, {
          'Warehouse ID': this.warehouseId,
          'Warehouse Name': this.warehouse?.name ?? 'Unknown',
          'Dock Name': selectedDock.name,
          'Dock ID': selectedDock.id
        });
        this.appointmentData.slot = this.defaultSlot;
        this.steps[2].completed = true;
        this.activeStep = stepNames.slot;
      }
    },
    'appointmentData.slot'(selectedSlot) {
      if (selectedSlot.start) {
        this.mixpanel.track(this.mixpanel.events.ACTION.DATE_TIME_SELECTED, {
          'Warehouse ID': this.warehouseId,
          'Warehouse Name': this.warehouse?.name ?? 'Unknown',
          'Selected Time': selectedSlot.start.toISO()
        });
        const index = this.loadTypeAllowsDockSelect ? 3 : 2;
        this.steps[index].completed = true;
        this.activeStep = stepNames.details;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.footer-actions {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: $m-spacing-4 $m-spacing-0-5;
  background: $m-color-background-primary;
}
.appointment-summary {
  position: sticky;
  top: 0;
}

.mobile-steps {
  display: block;
  @media (min-width: $smallDesktopBreakpoint) {
    display: none;
  }
}

@media (max-width: $smallDesktopBreakpoint) {
  ::v-deep {
    [class*='sidebar-cards'] .sidebar {
      display: none;
    }
    m-header:not(.auth-bar) m-header-actions {
      display: none;
    }
  }
}
</style>
