var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vuetify-data-table",
    _vm._g(
      _vm._b(
        {
          attrs: { options: _vm.$parent.options },
          on: {
            "update:options": function ($event) {
              return _vm.$set(_vm.$parent, "options", $event)
            },
          },
          scopedSlots: _vm._u(
            [
              _vm._l(_vm.$scopedSlots, function (_, scopedSlot) {
                return {
                  key: scopedSlot,
                  fn: function (scope) {
                    return [_vm._t(scopedSlot, null, null, scope)]
                  },
                }
              }),
              _vm._l(_vm.$slots, function (_, slot) {
                return {
                  key: slot,
                  fn: function () {
                    return [_vm._t(slot)]
                  },
                  proxy: true,
                }
              }),
            ],
            null,
            true
          ),
        },
        "vuetify-data-table",
        [_vm.$props, _vm.$attrs],
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }