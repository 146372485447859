var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button-base",
    _vm._b(
      {
        attrs: { variant: "warning", "test-id": _vm.testId },
        on: {
          click: function ($event) {
            return _vm.handleClick($event)
          },
        },
      },
      "button-base",
      [_vm.$attrs],
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }