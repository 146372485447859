var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("dialog-base", {
    attrs: {
      value: _vm.value,
      "data-testid": "accept-tos-dlg",
      size: "medium",
      "should-display-close-button": false,
    },
    on: {
      input: function ($event) {
        _vm.value = !_vm.value
      },
    },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "m-group",
              { attrs: { gap: "spacing-1" } },
              [
                _c("m-text", { attrs: { variant: "heading-sm-bold" } }, [
                  _vm._v("We value your privacy and security"),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "m-text",
              { staticClass: "m-b-10", attrs: { variant: "body-sm" } },
              [
                _vm._v(
                  " In order to use our platform, please review Loadsmart’s "
                ),
                _c(
                  "v-link",
                  {
                    attrs: {
                      "is-bold": false,
                      size: "sm",
                      href: "https://loadsmart.com/subscription-and-professional-services-agreement/",
                      target: "_blank",
                    },
                  },
                  [_vm._v(" Terms & Conditions ")]
                ),
                _vm._v(" and "),
                _c(
                  "v-link",
                  {
                    attrs: {
                      "is-bold": false,
                      size: "sm",
                      href: "https://loadsmart.com/privacy-policy/",
                      target: "_blank",
                    },
                  },
                  [_vm._v(" Privacy Policy ")]
                ),
                _vm._v(" , and mark your consent below "),
              ],
              1
            ),
            _c(
              "m-group",
              { staticClass: "m-t-5 flex-nowrap", attrs: { gap: "spacing-1" } },
              [
                _c(
                  "v-icon",
                  {
                    attrs: {
                      size: "18px",
                      color: "color-primary-60",
                      "data-testid": "accept-tos-checkbox",
                    },
                    on: { click: _vm.toggleTosAccept },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.tosAccepted
                            ? "checkbox-marked"
                            : "checkbox-blank-outline"
                        ) +
                        " "
                    ),
                  ]
                ),
                _c("m-text", { attrs: { variant: "body-sm" } }, [
                  _vm._v(
                    " I have read and agree to be bound by Loadsmart's Terms & Conditions and subject to Loadsmart's Privacy Policy in connection with my use of the Opendock platform. "
                  ),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "m-t-5" },
              [
                _c(
                  "m-text",
                  { attrs: { variant: "body-sm" } },
                  [
                    _vm._v(
                      " If you need any help, reach out to our support team "
                    ),
                    _c(
                      "v-link",
                      {
                        attrs: {
                          "is-bold": false,
                          size: "sm",
                          href: "https://community.loadsmart.com/hc/en-us/community/topics/14812876253715-OpenDock",
                          target: "_blank",
                        },
                      },
                      [_vm._v(" through this link. ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c(
              "primary-button",
              {
                attrs: {
                  disabled: !_vm.tosAccepted,
                  "test-id": "confirm-accept-tos-btn",
                },
                on: { click: _vm.confirm },
              },
              [_vm._v(" Confirm ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }