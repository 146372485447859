<template>
  <dialog-base
    v-bind="$attrs"
    :value="value"
    @input="updateVal"
    has-custom-close-logic
    hide-close
    size="small">
    <template #header>
      <m-group gap="spacing-1">
        <v-icon size="25px" color="color-warning-60">alert-circle</v-icon>
        <m-text variant="heading-md-bold">Appointment not booked</m-text>
      </m-group>
    </template>
    <template #body>
      <m-text variant="body-md">
        {{ message }}
      </m-text>
    </template>
    <template #actions>
      <m-group class="flex-nowrap p-t-4" gap="spacing-2">
        <template>
          <secondary-button
            test-id="book-another-btn"
            @click="$router.replace({ name: 'warehouse.select' })">
            Cancel
          </secondary-button>
          <primary-button @click="handleConfirmClick" test-id="see-details-btn">
            {{ confirmButtonText }}
          </primary-button>
        </template>
      </m-group>
    </template>
  </dialog-base>
</template>

<script>
import { DialogBase, PrimaryButton, VIcon, SecondaryButton } from '@/components';
import dialogMixin from '@/components/mixins/dialogMixin';
import { APPOINTMENT_CONFLICT_TYPE } from '@/enums';

/**
 * Shown when creating an appointment returns an error
 * @displayName Appointment Error Dialog
 */
export default {
  name: 'AppointmentErrorDialog',
  mixins: [dialogMixin],
  components: {
    DialogBase,
    PrimaryButton,
    VIcon,
    SecondaryButton
  },
  props: {
    /**
     * Warehouse ID
     */
    warehouseId: {
      type: String,
      required: true
    },
    /**
     * Status code for the error
     */
    statusCode: {
      type: Number,
      required: false,
      default: 500
    },
    errorMessage: {
      type: String,
      required: false
    }
  },
  computed: {
    confirmButtonText() {
      if (this.getConflictType === APPOINTMENT_CONFLICT_TYPE.REF_NUMBER_TAKEN) {
        return 'Edit details';
      }

      if (this.getConflictType === APPOINTMENT_CONFLICT_TYPE.SLOT_TAKEN) {
        return 'Book again';
      }

      return 'Try again';
    },
    getConflictType() {
      if (this.isConflict) {
        if (
          String(this.errorMessage).includes(
            'is already in use. You can enter a different one in the appointment details.'
          )
        ) {
          return APPOINTMENT_CONFLICT_TYPE.REF_NUMBER_TAKEN;
        }
        return APPOINTMENT_CONFLICT_TYPE.SLOT_TAKEN;
      }

      return null;
    },
    message() {
      if (this.getConflictType === APPOINTMENT_CONFLICT_TYPE.SLOT_TAKEN) {
        return `The time slot you picked doesn't seem to be available anymore. You can try choosing a different load typ, dock or time slot.`;
      }

      if (this.getConflictType === APPOINTMENT_CONFLICT_TYPE.REF_NUMBER_TAKEN) {
        return this.errorMessage;
      }

      return 'We were unable to book your appointment.  Please try again.';
    },
    isConflict() {
      return this.statusCode === 409;
    }
  },
  methods: {
    handleConfirmClick() {
      if (this.isConflict) {
        if (this.getConflictType === APPOINTMENT_CONFLICT_TYPE.REF_NUMBER_TAKEN) {
          return this.$emit('close');
        }
        return this.$emit('reset');
      } else {
        return this.$emit('retry');
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
