/**
 * Mixin used in dialogs
 * @mixin
 * @displayName Dialog Mixin
 */

export default {
  props: {
    /**
     *  @model
     */
    value: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    /**
     * Emits close event
     * @public
     */
    close() {
      /**
       * Emits close event
       */
      this.$emit('close');
    },
    /**
     * Emits confirm event and then calls the "close" method
     * @public
     */
    confirm() {
      /**
       * Emits confirm event
       */
      this.$emit('confirm');
      this.close();
    },
    /**
     * Used to easily interact with v-model
     * @param value
     */
    updateVal(value) {
      this.$emit('input', value);
      if (!value) {
        this.close();
      }
    }
  }
};
