<template>
  <div :data-testid="toKebabCase(`${customField.type}-${customField.label}`)">
    <field-value v-bind="[$attrs]" :label="customField.label">
      <template #value>
        <template v-if="!isNull(computedValue) && typeof computedValue !== 'undefined'">
          <template v-if="customField.type === CustomFieldType.MultiDocument">
            <v-link v-for="(link, index) in computedValue" :key="`${link}-${index}`" :href="link">
              {{ truncateString(getDocumentNameFromUrl(link), 20) }}
            </v-link>
          </template>
          <m-link v-else-if="customField.type === CustomFieldType.Document" :href="computedValue">
            {{ truncateString(getDocumentNameFromUrl(computedValue), 20) }}
          </m-link>
          <m-group
            v-else-if="customField.type === CustomFieldType.DropDownMultiSelect"
            gap="spacing-2">
            <m-tag v-for="(val, index) in computedValue" :key="`${val}-${index}`">
              {{ val }}
            </m-tag>
          </m-group>

          <m-text v-else variant="body-md">
            {{ computedValue }}
          </m-text>
        </template>

        <no-content v-else></no-content>
      </template>
    </field-value>
  </div>
</template>
<script>
import { FieldValue, NoContent, VLink } from '@/components';
import {
  toKebabCase,
  ICustomField,
  CustomFieldType,
  getDocumentNameFromUrl,
  truncateString,
  formatDateTimeWithMilitarySupport,
  LuxonDateTimeFormats
} from '@satellite/../nova/core';
import { isURL } from 'class-validator';
import { isNull } from 'lodash';
// TODO: Cleanup duplicate code and document this
/**
 * Custom Field Value - used to display a custom field key/value in a stacked format
 * @displayName Custom Field Value
 */
export default {
  name: 'CustomFieldValue',
  components: { NoContent, FieldValue, VLink },
  props: {
    /**
     * shape of ICustomField
     */
    customField: {
      type: ICustomField,
      required: true,
      default: null
    },
    /**
     * Warehouse of Appointment where the custom field is on
     */
    warehouse: {
      type: Object,
      required: true
    }
  },
  computed: {
    isMilitaryTimeEnabled() {
      return this.$isMilitaryTimeEnabled(this.warehouse);
    },
    computedValue() {
      let computedValue = this.customField.value;
      if (this.customField.type === CustomFieldType.Timestamp) {
        computedValue = computedValue
          ? formatDateTimeWithMilitarySupport(
              computedValue,
              this.warehouse.timezone,
              LuxonDateTimeFormats.DateSlashedTimestamp12HourTimeAMPM,
              this.isMilitaryTimeEnabled,
              LuxonDateTimeFormats.DateSlashedTimestamp24HourTime
            )
          : null;
      } else if (this.customField.type === CustomFieldType.Bool) {
        computedValue = computedValue ? 'Yes' : 'No';
      }
      return computedValue;
    }
  },
  data() {
    return {
      CustomFieldType
    };
  },
  methods: {
    isURL,
    isNull,
    toKebabCase,
    getDocumentNameFromUrl,
    truncateString
  }
};
</script>
