<template>
  <field-value
    has-separate-label
    label="Address"
    copy-message="Address copied to clipboard"
    :copy-content="`${warehouse.street} ${util.getLargerAreaOfAddress(warehouse)}`">
    <template #value>
      <m-stack gap="spacing-1" align="flex-start">
        <m-text variant="body-md">{{ warehouse.street }}</m-text>
        <m-text variant="body-md">
          {{ util.getLargerAreaOfAddress(warehouse) }}
        </m-text>
      </m-stack>
    </template>
  </field-value>
</template>
<script>
import { FieldValue } from '@/components';
import { propValidatorHelper } from '@satellite/plugins/util';
/**
 * Warehouse address
 * @displayName WarehouseAddress
 */
export default {
  name: 'WarehouseAddress',
  components: {
    FieldValue
  },
  props: {
    /**
     * Partial Warehouse
     * @values { street: string, city: string, state: string, zip: string}
     */
    warehouse: {
      type: Object,
      required: true,
      validator(value) {
        const isValid = ['street', 'city', 'state', 'zip'].every(requiredKey => {
          return Object.keys(value).includes(requiredKey);
        });
        return propValidatorHelper({
          value,
          isValid: isValid,
          componentName: 'WarehouseAddress',
          propName: 'warehouse',
          message: ''
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.warehouse-logo {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid $m-color-background-tertiary;
}
</style>
