var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-stack",
    { attrs: { gap: "spacing-2" } },
    [
      _c("m-text", { attrs: { variant: "body-md" } }, [
        _vm._v("Operating hours"),
      ]),
      _vm._l(_vm.daysOfOperation, function (day) {
        return [
          _vm.schedule[day].length > 0
            ? _c(
                "m-group",
                { key: day, attrs: { gap: "none" } },
                [
                  _c(
                    "div",
                    { staticClass: "day-name" },
                    [
                      _c("m-text", { attrs: { variant: "body-md-bold" } }, [
                        _vm._v(_vm._s(_vm.getScheduleShortDay(day))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "m-stack",
                    { attrs: { gap: "none" } },
                    _vm._l(_vm.schedule[day], function (range, index) {
                      return _c(
                        "m-text",
                        {
                          key: `${day}-range-${index}`,
                          attrs: { variant: "body-md" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.formatScheduleClock(range.start)) +
                              " to " +
                              _vm._s(_vm.formatScheduleClock(range.end)) +
                              " "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      }),
      _vm.noScheduleSet
        ? _c("m-text", { attrs: { variant: "body-md" } }, [
            _vm._v("No schedule set"),
          ])
        : _vm._e(),
      _vm.closedDates.length > 0
        ? _c(
            "div",
            { staticClass: "m-t-4" },
            [
              _c("m-text", { attrs: { variant: "body-md" } }, [
                _vm._v("Closed dates"),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.closedDates.length > 0
        ? _c(
            "m-stack",
            { attrs: { gap: "spacing-2" } },
            [
              _vm._l(_vm.closedDates, function (date, index) {
                return [
                  _c(
                    "m-text",
                    {
                      key: `closed-date-${index}`,
                      attrs: { variant: "body-md" },
                    },
                    [_vm._v(_vm._s(date))]
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }