<template>
  <m-toggle-group
    :type="$attrs.type ?? 'single'"
    :value="value"
    @m-change="updateVal"
    class="p-b-1">
    <m-toggle v-for="button in buttons" :key="button.value" :value="button.value">
      <span slot="leading" v-if="button.icon">
        <v-icon>
          {{ button.icon }}
        </v-icon>
      </span>
      {{ button.label }}
    </m-toggle>
  </m-toggle-group>
</template>

<script>
import { propValidatorHelper } from '@satellite/plugins/util';
import { VIcon } from '@/components';

/**
 * Single toggle group of buttons wrapping Miranda's <m-toggle-group> component
 * @displayName Button Toggle Group
 */
export default {
  name: 'ButtonToggleGroup',
  components: { VIcon },
  props: {
    /**
     * @model
     */
    value: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Buttons to display
     * { label: string, value: string, icon?: string }
     */
    buttons: {
      type: Array,
      required: true,
      validator(value) {
        const isValid = value.every(button =>
          Object.keys(button).every(buttonKey => ['label', 'value', 'icon'].includes(buttonKey))
        );
        return propValidatorHelper({
          value,
          isValid,
          componentName: 'ButtonToggleGroup',
          propName: 'buttons',
          message: ''
        });
      }
    }
  },
  methods: {
    updateVal(e) {
      /**
       * input event
       * @event
       * @property { string } value
       */
      this.$emit('input', e.detail.value);
    }
  }
};
</script>
