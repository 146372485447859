<template>
  <m-stack gap="none">
    <m-text variant="heading-md-bold" as="h1">Welcome to Opendock</m-text>
    <span class="p-t-4">
      <m-text variant="body-md-bold">
        Sign in here to use our Warehouse portal or Scheduling Portal
      </m-text>
    </span>
    <basic-form
      :fields="fields"
      class="p-t-8"
      @submit="submit"
      :submit-button="{ label: 'Sign in', testId: 'login-btn', isLoading }">
      <template v-slot:pre-submit-actions>
        <m-group>
          <link-button @click="dialogs.forgotPassword = true" test-id="forgot-password-btn">
            Forgot your password
          </link-button>
        </m-group>
      </template>
    </basic-form>

    <forgot-password-dialog
      v-model="dialogs.forgotPassword"
      @close="dialogs.forgotPassword = false" />
  </m-stack>
</template>

<script>
import { BasicForm, LinkButton } from '@/components';
import ForgotPasswordDialog from '@/components/elements/auth/dialogs/ForgotPasswordDialog.vue';
import { isCarrierUser, NovaErrorCode } from '@satellite/../nova/core';

export default {
  name: 'LoginForm',
  components: {
    ForgotPasswordDialog,
    BasicForm,
    LinkButton
  },
  props: {
    /**
     * Email verification token from verification email
     */
    verificationToken: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Route to go to once login is completed
     */
    postLoginRoute: {
      type: Object,
      required: false,
      default: null
    },
    /**
     * Is this a login request from clicking the manage appointment button on a public appointment?
     */
    isManageAppointmentLogin: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      dialogs: {
        forgotPassword: false
      },
      fields: {
        email: {
          component: 'text-field',
          fieldProps: {
            label: 'Email',
            required: true,
            value: '',
            placeholder: 'Enter your email address',
            testId: 'email-field',
            rules: this.$validator.rules.email
          }
        },
        password: {
          component: 'password-field',
          fieldProps: {
            required: true,
            value: '',
            testId: 'password-field',
            label: 'Password',
            placeholder: 'Enter your password',
            bypassRequirements: true
          }
        }
      }
    };
  },
  methods: {
    async submit({ fields }) {
      this.isLoading = true;
      this.$store
        .dispatch('Auth/login', { creds: fields, requestConfig: { suppressNotification: true } })
        .then(res => {
          if (res?.data?.errorCode === NovaErrorCode.UnverifiedEmail) {
            this.$emit('update:emailVerificationData', {
              email: fields.email,
              isEmailVerified: false
            });
          } else {
            this.mixpanel.track(this.mixpanel.events.ACTION.LOGGED_IN);
          }
        })
        .finally(() => (this.isLoading = false));
    }
  },
  mounted() {
    if (this.verificationToken) {
      let alertMessage = '';
      let alertType = '';
      this.verificationMode = true;
      this.$store.dispatch('Auth/verifyEmail', this.verificationToken).then(response => {
        this.fields.email.fieldProps.value = response.data.details.email;
        alertMessage = 'Your email has been verified, you can login now.';
        alertType = 'success';
        this.notify(alertMessage, alertType, { duration: 8000 });
        this.$router.replace('/login');
      });
    }
  },
  watch: {
    async $me(newMe) {
      if (newMe?.id) {
        const isCarrier = isCarrierUser(newMe);

        if (isCarrier) {
          this.mixpanel.identify(newMe);
          const previewCompany = await this.services.company.getPreviewCompany();
          const toBookingPage = newMe.companyId && newMe.companyId !== previewCompany?.id;
          const defaultRoute = {
            name: toBookingPage ? 'warehouse.select' : 'register.company'
          };
          const route = this.postLoginRoute ?? defaultRoute;
          if (
            this.$route.query.redirectPath &&
            this.$route.query.redirectPath !== '/login' &&
            this.$route.query.redirectPath !== '/'
          ) {
            await this.$router.push({ path: this.$route.query.redirectPath });
          } else {
            await this.$router.push(route);
          }
        } else {
          /**
           * TODO: Understand SSO more to make sure this doesn't have any implications there
           * i.e. instead of forcing a login logging in here, how do we allow SSO users to authenticate?
           */
          let routeJSON;
          if (this.isManageAppointmentLogin) {
            routeJSON = JSON.stringify({
              name: 'appointments',
              /**
               * This makes the assumption that the route points to the appointment details page with the
               * appointment ID provided as a route param - see AppointmentDetailsPage.vue
               */
              query: { appointmentId: this.postLoginRoute.params.appointmentId }
            });
          }

          await this.$store.dispatch('Auth/logInToWarehousePortal', routeJSON);
        }
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
