var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "notifications",
    _vm._b(
      {
        attrs: {
          "ignore-duplicates": true,
          "animation-type": "velocity",
          position: _vm.position,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function ({ item, close }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "app-notification",
                    class: item.data.appClasses,
                  },
                  [
                    _c(
                      "v-icon",
                      { attrs: { color: item.data.iconColor, size: "20px" } },
                      [_vm._v(_vm._s(item.data.icon))]
                    ),
                    _c(
                      "div",
                      { staticClass: "flex-1" },
                      [
                        _c(
                          "m-stack",
                          { attrs: { gap: "spacing-1" } },
                          [
                            item.title
                              ? _c(
                                  "m-text",
                                  { attrs: { variant: "body-md-bold" } },
                                  [_c("strong", [_vm._v(_vm._s(item.title))])]
                                )
                              : _vm._e(),
                            _c("m-text", {
                              attrs: { variant: "body-sm" },
                              domProps: { innerHTML: _vm._s(item.text) },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    item.data.actionFn
                      ? _c(
                          item.data.actionComponent,
                          {
                            tag: "component",
                            attrs: {
                              "test-id": _vm.getTestId(item, "action-btn"),
                            },
                            on: { click: item.data.actionFn },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(item.data.actionLabel ?? "test") +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "close",
                        attrs: {
                          "data-testId": _vm.getTestId(item, "close-btn"),
                        },
                        on: { click: close },
                      },
                      [
                        _c("v-icon", { attrs: { size: "20px" } }, [
                          _vm._v("mdi-close"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      },
      "notifications",
      [_vm.$attrs, _vm.$props],
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }