var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-group",
    {
      staticClass: "direction-wrapper",
      class: `load-type-direction-wrapper-${_vm.componentId}`,
      attrs: { align: "center", gap: "none" },
    },
    [
      !_vm.isInbound
        ? _c(
            "v-icon",
            {
              staticClass: "direction-icon",
              attrs: { size: "12px", color: "#005AD8" },
            },
            [_vm._v(" arrow-left-thick ")]
          )
        : _vm._e(),
      _c(
        "m-text",
        {
          staticClass: "load-type-direction",
          attrs: { variant: "body-sm-bold" },
        },
        [_vm._v(" " + _vm._s(_vm.isInbound ? "IN" : "OUT") + " ")]
      ),
      _vm.isInbound
        ? _c(
            "v-icon",
            {
              staticClass: "direction-icon",
              attrs: { size: "12px", color: "#005454" },
            },
            [_vm._v(" arrow-right-thick ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }