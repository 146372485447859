var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        attrs: {
          value: _vm.value,
          size: "large",
          "should-display-close-button": !_vm.isLoading,
        },
        on: { input: _vm.updateVal },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("m-text", { attrs: { variant: "heading-md-bold" } }, [
                  _vm._v("Edit details"),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                !_vm.isLoading
                  ? _c("appointment-details-form", {
                      ref: "appointment-details-form",
                      attrs: {
                        "fields-are-full-width": "",
                        appointment: _vm.extendedAppointment,
                        warehouse: _vm.warehouse,
                        "custom-fields": _vm.customFields,
                      },
                      on: { submit: _vm.submit },
                    })
                  : _c("v-loader", { attrs: { "is-loading": _vm.isLoading } }, [
                      _vm._v("Updating appointment"),
                    ]),
              ]
            },
            proxy: true,
          },
          {
            key: "actions",
            fn: function () {
              return [
                _c(
                  "m-group",
                  {
                    staticClass: "flex-nowrap p-t-4",
                    attrs: { gap: "spacing-2" },
                  },
                  [
                    [
                      _c(
                        "secondary-button",
                        {
                          attrs: {
                            "test-id": "cancel-btn",
                            disabled: _vm.isLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.updateVal(false)
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "primary-button",
                        {
                          attrs: {
                            "test-id": "save-changes-btn",
                            disabled: _vm.isLoading,
                          },
                          on: { click: _vm.triggerSubmit },
                        },
                        [_vm._v(" Save changes ")]
                      ),
                    ],
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "dialog-base",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }