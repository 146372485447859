var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-stack",
    { attrs: { gap: "none", align: _vm.contentAlignment } },
    [
      _vm.wasRegistrationSuccessful
        ? [
            _c(
              "m-stack",
              {
                attrs: {
                  gap: "spacing-3",
                  align: "center",
                  "data-testid": "successful-registration-content-section",
                },
              },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "p-b-2",
                    attrs: { size: "88px", color: "color-primary-60" },
                  },
                  [_vm._v("email-check")]
                ),
                _c("m-text", { attrs: { variant: "heading-md-bold" } }, [
                  _vm._v("Check your e-mail account"),
                ]),
                _c("m-text", { attrs: { variant: "body-md", as: "p" } }, [
                  _vm._v(
                    ' Open the message we sent you and click the "Activate my account" button to start using Opendock '
                  ),
                ]),
              ],
              1
            ),
            _c(
              "m-stack",
              {
                staticClass: "p-y-20",
                attrs: { gap: "spacing-3", align: "center" },
              },
              [
                _c("m-text", { attrs: { variant: "body-md", as: "p" } }, [
                  _vm._v(
                    " Couldn't find our message? Check your spam folder or resend email "
                  ),
                ]),
                _c(
                  "tertiary-button",
                  {
                    attrs: {
                      "leading-icon": "refresh",
                      "test-id": "resend-verification-btn",
                      size: "small",
                      block: "",
                      loading: _vm.isLoading,
                    },
                    on: { click: _vm.sendVerificationEmail },
                  },
                  [_vm._v(" RESEND EMAIL ")]
                ),
              ],
              1
            ),
            _c(
              "m-stack",
              { attrs: { align: "center" } },
              [
                _c("m-text", { attrs: { variant: "body-md" } }, [
                  _vm._v("In case you're having any trouble, contact us on:"),
                ]),
                _c("v-link", { attrs: { href: _vm.$supportUrl } }, [
                  _vm._v("support@opendock.com"),
                ]),
              ],
              1
            ),
          ]
        : [
            _c("m-text", { attrs: { variant: "heading-lg", as: "h1" } }, [
              _vm._v("Register for free now"),
            ]),
            _c(
              "m-group",
              { staticClass: "p-t-4", attrs: { gap: "spacing-3" } },
              [
                _c("m-text", { attrs: { variant: "body-md" } }, [
                  _vm._v("Already have an account?"),
                ]),
                _c(
                  "v-link",
                  {
                    attrs: {
                      "is-bold": false,
                      href: "/login",
                      target: "_blank",
                    },
                  },
                  [_vm._v("Sign in")]
                ),
              ],
              1
            ),
            _c("basic-form", {
              ref: "registrationForm",
              staticClass: "p-t-8",
              attrs: {
                fields: _vm.fields,
                "submit-button": {
                  label: "Register",
                  testId: "register-btn",
                  trailingIcon: "check",
                  isLoading: _vm.isLoading,
                },
              },
              on: { submit: _vm.submit },
            }),
            _c("duplicate-email-error-dialog", {
              on: { close: _vm.reRegister, confirm: _vm.goToLogin },
              model: {
                value: _vm.dialogs.duplicateEmailError,
                callback: function ($$v) {
                  _vm.$set(_vm.dialogs, "duplicateEmailError", $$v)
                },
                expression: "dialogs.duplicateEmailError",
              },
            }),
            _c("account-creation-error-dialog", {
              on: {
                close: function ($event) {
                  _vm.dialogs.accountCreationError = false
                },
                confirm: _vm.retrySubmit,
              },
              model: {
                value: _vm.dialogs.accountCreationError,
                callback: function ($$v) {
                  _vm.$set(_vm.dialogs, "accountCreationError", $$v)
                },
                expression: "dialogs.accountCreationError",
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }