<template>
  <m-stack class="flex-1 height-100">
    <m-group align="center" justify="space-between" class="search-header">
      <m-text variant="heading-sm-bold">Select a dock</m-text>
      <text-field
        class="search-field"
        width="280px"
        test-id="dock-search-field"
        field-name="dock-search"
        placeholder="Search Dock"
        v-model="searchStr"
        :leading-icon="{ icon: 'magnify', color: 'color-text-tertiary' }"></text-field>
    </m-group>

    <v-loader v-if="isLoading && isNull(results)" :is-loading="isLoading">
      Looking for docks
    </v-loader>

    <card-grid
      v-if="!isLoading && !isNull(results)"
      test-id="dock-search-results"
      :items="results"
      :items-per-row="2"
      :display-outer-card="false"
      :selected-item="value"
      @click="handleItemClick">
      <template v-slot:row="{ item }">
        <m-stack gap="spacing-2">
          <m-text variant="body-md-bold">{{ item.name }}</m-text>
          <m-stack gap="none">
            <m-text variant="body-md" color="color-text-secondary">Door number</m-text>
            <m-text v-if="item.doorNumber" variant="body-md">{{ item.doorNumber }}</m-text>
            <no-content v-else></no-content>
          </m-stack>
        </m-stack>
      </template>
      <template v-slot:no-items>
        <no-results></no-results>
      </template>
    </card-grid>
  </m-stack>
</template>

<script>
import { TextField, CardGrid, VLoader, NoResults, NoContent } from '@/components';
import { isNull } from 'lodash';

export default {
  name: 'DockSearch',
  components: {
    TextField,
    CardGrid,
    VLoader,
    NoResults,
    NoContent
  },
  props: {
    /**
     * Selected Dock
     * @model
     */
    value: {
      type: Object,
      required: false,
      default: null
    },
    /**
     * Warehouse where appointment will be booked
     */
    warehouseId: {
      type: String,
      required: true
    },
    /**
     * Loadtype ID to get availability for
     */
    loadTypeId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      searchStr: null,
      results: null,
      isLoading: false
    };
  },
  mounted() {
    this.setResults();
  },
  methods: {
    isNull,
    async setResults() {
      this.results = null;
      this.isLoading = true;
      const queryParams = {
        s: {
          allowCarrierScheduling: true,
          loadTypeIds: { $cont: this.loadTypeId },
          name: { $exclL: '--- capacity' }, // TODO: Feels flimsy if we ever remove this...
          warehouseId: this.warehouseId
        }
      };

      if (this.searchStr) {
        queryParams.s = {
          $and: [{ name: { $contL: this.searchStr } }, { name: queryParams.s.name }, queryParams.s]
        };
        delete queryParams.s.name;
      }

      await this.services.dock
        .getDocks(queryParams)
        .then(response => {
          this.results = response;
        })
        .finally(() => (this.isLoading = false));
    },
    search: _.debounce(async function () {
      await this.setResults();
    }, 350),
    handleItemClick(item) {
      /**
       * v-model input event
       * @event input
       * @property {object} Dock
       */
      this.$emit('input', item);
    }
  },
  watch: {
    searchStr() {
      this.search();
    }
  }
};
</script>

<style lang="scss" scoped>
.search-header {
  position: sticky;
  top: -$m-spacing-6;
  padding: $m-spacing-4 0;
  z-index: 1;
  background: $m-color-background-primary;

  @media (max-width: $midDesktopBreakpoint) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0;
    top: -$m-spacing-4;

    ::v-deep {
      m-field {
        width: 100%;
      }
      m-text-field {
        width: 100% !important;
      }
    }
  }
}

::v-deep {
  .row-items {
    &-2 {
      grid-template-columns: repeat(2, 1fr);

      @media (max-width: $midDesktopBreakpoint) {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>
