<template>
  <m-field :data-testid="testId" :status="hasError ? 'danger' : 'default'">
    <m-switch
      :checked="lazyValue"
      v-bind="$attrs"
      @m-change="e => (internalValue = e.target.checked)">
      <span>
        {{ label }}
      </span>
    </m-switch>
    <m-field-hint :data-testid="`${testId}-errors`">
      <span>{{ errorBucket.join(', ') }}</span>
      <span v-if="errorBucket.length && hint">.</span>
      <span v-if="hint" :class="{ 'p-l-1': errorBucket.length }">{{ hint }}</span>
    </m-field-hint>
  </m-field>
</template>

<script>
import fieldMixin from '@/components/mixins/fieldMixin';
import validatable from '@/components/mixins/validatable';
import testable from '@/components/mixins/testable';

export default {
  mixins: [fieldMixin, validatable, testable],
  props: {
    /**
     * @model
     */
    value: {
      type: Boolean,
      required: false
    }
  }
};
</script>

<style lang="scss" scoped>
m-field {
  display: inline-flex;
}
m-switch {
  height: 25px;
}
</style>
