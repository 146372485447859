<template>
  <m-stack align="center" justify="center" gap="spacing-2" v-if="isLoading" class="height-100">
    <m-spinner-wheel size="48px"></m-spinner-wheel>
    <m-text variant="body-md-bold" v-if="$slots.default">
      <!-- @slot Optional loading message -->
      <slot></slot>
      ...
    </m-text>
  </m-stack>
</template>

<script>
/**
 * Spinning loader with optional custom loading message
 * @displayName V Loader
 */
export default {
  name: 'VLoader',
  props: {
    /**
     * Determines if loader should display
     */
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>
