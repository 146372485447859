<template>
  <m-stack gap="none" :align="contentAlignment">
    <template v-if="wasRegistrationSuccessful">
      <m-stack gap="spacing-3" align="center" data-testid="successful-registration-content-section">
        <v-icon size="88px" color="color-primary-60" class="p-b-2">email-check</v-icon>
        <m-text variant="heading-md-bold">Check your e-mail account</m-text>
        <m-text variant="body-md" as="p">
          Open the message we sent you and click the "Activate my account" button to start using
          Opendock
        </m-text>
      </m-stack>
      <m-stack gap="spacing-3" align="center" class="p-y-20">
        <m-text variant="body-md" as="p">
          Couldn't find our message? Check your spam folder or resend email
        </m-text>
        <tertiary-button
          leading-icon="refresh"
          test-id="resend-verification-btn"
          size="small"
          block
          @click="sendVerificationEmail"
          :loading="isLoading">
          RESEND EMAIL
        </tertiary-button>
      </m-stack>
      <m-stack align="center">
        <m-text variant="body-md">In case you're having any trouble, contact us on:</m-text>
        <v-link :href="$supportUrl">support@opendock.com</v-link>
      </m-stack>
    </template>
    <template v-else>
      <m-text variant="heading-lg" as="h1">Register for free now</m-text>
      <m-group gap="spacing-3" class="p-t-4">
        <m-text variant="body-md">Already have an account?</m-text>
        <v-link :is-bold="false" href="/login" target="_blank">Sign in</v-link>
      </m-group>
      <basic-form
        ref="registrationForm"
        :fields="fields"
        class="p-t-8"
        @submit="submit"
        :submit-button="{
          label: 'Register',
          testId: 'register-btn',
          trailingIcon: 'check',
          isLoading
        }"></basic-form>
      <duplicate-email-error-dialog
        v-model="dialogs.duplicateEmailError"
        @close="reRegister"
        @confirm="goToLogin"></duplicate-email-error-dialog>
      <account-creation-error-dialog
        v-model="dialogs.accountCreationError"
        @close="dialogs.accountCreationError = false"
        @confirm="retrySubmit"></account-creation-error-dialog>
    </template>
  </m-stack>
</template>

<script>
import { BasicForm, VIcon, TertiaryButton, VLink } from '@/components';
import DuplicateEmailErrorDialog from '@/components/elements/auth/dialogs/DuplicateEmailErrorDialog.vue';
import AccountCreationErrorDialog from '@/components/elements/auth/dialogs/AccountCreationErrorDialog.vue';

export default {
  name: 'RegistrationForm',
  components: {
    TertiaryButton,
    BasicForm,
    VIcon,
    AccountCreationErrorDialog,
    DuplicateEmailErrorDialog,
    VLink
  },
  data() {
    return {
      isLoading: false,
      wasRegistrationSuccessful: false,
      registeredEmail: '',
      dialogs: {
        duplicateEmailError: false,
        accountCreationError: false
      },
      fields: {
        firstName: {
          component: 'text-field',
          group: 1,
          fieldProps: {
            label: 'First name',
            required: true,
            value: '',
            rules: this.$validator.rules.required(),
            placeholder: 'Enter your first name',
            testId: 'first-name-field'
          }
        },
        lastName: {
          component: 'text-field',
          group: 1,
          fieldProps: {
            label: 'Last name',
            required: true,
            value: '',
            rules: this.$validator.rules.required(),
            placeholder: 'Enter your last name',
            testId: 'last-name-field'
          }
        },
        email: {
          component: 'text-field',
          fieldProps: {
            label: 'Email',
            required: true,
            value: '',
            placeholder: 'Enter your email address',
            testId: 'email-field',
            rules: this.$validator.rules.email
          }
        },
        password: {
          component: 'password-field',
          fieldProps: {
            shouldShowRequirements: true,
            required: true,
            value: '',
            hasErrors: false,
            label: 'Password',
            testId: 'password-field'
          }
        }
      }
    };
  },
  computed: {
    countries() {
      return Object.entries(this.novaCore.ISO3166Countries).map(entry => {
        return { label: entry[1].name, value: entry[0] };
      });
    },
    contentAlignment() {
      return this.wasRegistrationSuccessful ? 'center' : 'flex-start';
    }
  },
  methods: {
    async submit({ fields }) {
      this.isLoading = true;
      this.dialogs.accountCreationError = false;

      await axios
        .post('/carrier/register', fields)
        .then(() => {
          this.registeredEmail = fields.email;
          this.wasRegistrationSuccessful = true;
          this.mixpanel.track(this.mixpanel.events.VIEW.ACTIVATION_EMAIL);
        })
        .catch(e => {
          const message = e.response?.data?.message;
          const isPending = message?.toLowerCase()?.includes('pending');
          if (e.response?.status === 409 && !isPending) {
            this.dialogs.duplicateEmailError = true;
          }
          if (e.response?.status >= 500 || e.code === 'ERR_NETWORK') {
            this.dialogs.accountCreationError = true;
          }
        })
        .finally(() => (this.isLoading = false));
    },
    reRegister() {
      this.$refs.registrationForm.resetForm();
      this.dialogs.duplicateEmailError = false;
    },
    goToLogin() {
      this.$router.push({ name: 'login' });
    },
    retrySubmit() {
      this.dialogs.accountCreationError = false;
      this.$refs.registrationForm.submitForm();
    },
    async sendVerificationEmail() {
      this.isLoading = true;
      const response = await this.$store
        .dispatch('Auth/sendVerificationEmail', this.registeredEmail)
        .finally(() => {
          this.isLoading = false;
        });

      if (response?.status === 200) {
        this.notify(`Verification email sent to ${this.registeredEmail}!`);
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
