<template>
  <m-link v-bind="[$attrs]" :class="`link-${_uid}`"><slot></slot></m-link>
</template>

<script>
/**
 * Spinning loader with optional custom loading message
 * @displayName V Loader
 */
export default {
  name: 'VLoader',
  props: {
    color: {
      type: String,
      required: false,
      default: 'color-text-link'
    }
  },
  computed: {
    computedColor() {
      const color = this.color;
      return this.mirandaUtil.getTokenCssValue(color) || color;
    }
  },
  mounted() {
    const linkStyles = [`color:${this.computedColor} !important`];
    this.util.appendStyleToShadowDomEl(
      document.querySelector(`.link-${this._uid}`),
      '.m-link',
      linkStyles.join(';')
    );
  }
};
</script>
