var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-field",
    {
      staticClass: "text-field-wrapper",
      class: [{ "flex-1": !_vm.width }, `text-field-${_vm._uid}`],
      attrs: {
        "data-testid": _vm.testId,
        status: _vm.hasError ? "danger" : "default",
      },
    },
    [
      _vm.label
        ? _c(
            "m-field-label",
            {
              attrs: { slot: "label", "data-testid": `${_vm.testId}-label` },
              slot: "label",
            },
            [_vm._v(" " + _vm._s(_vm.label) + " ")]
          )
        : _vm._e(),
      _c(
        "m-text-field",
        _vm._g(
          _vm._b(
            {
              staticClass: "text-field",
              style: _vm.computedWidth,
              attrs: {
                name: _vm.fieldName,
                placeholder: _vm.placeholder,
                value: _vm.lazyValue,
                disabled: _vm.disabled,
                type: _vm.type,
                id: _vm.testId,
              },
              on: {
                focus: _vm.handleFocus,
                blur: _vm.handleBlur,
                "m-clear": function ($event) {
                  return _vm.$emit("clear")
                },
                keydown: function ($event) {
                  _vm.readonly ? $event.preventDefault() : null
                },
              },
            },
            "m-text-field",
            [_vm.$attrs],
            false
          ),
          {
            ..._vm.$listeners,
            input: (event) => (_vm.internalValue = event.target.value),
          }
        ),
        [
          _vm.$slots.leading || _vm.leadingIcon
            ? _c(
                "span",
                {
                  attrs: {
                    slot: "leading",
                    "data-testid": `${_vm.testId}-leading`,
                  },
                  slot: "leading",
                },
                [
                  _vm.$slots.leading ? _vm._t("leading") : _vm._e(),
                  _vm.leadingIcon
                    ? _c(
                        "v-icon",
                        {
                          attrs: {
                            color: _vm.leadingIcon.color,
                            size: _vm.leadingIcon.size,
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.leadingIcon.icon) + " ")]
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm.$slots.trailing || _vm.trailingIcon
            ? _c(
                "span",
                {
                  attrs: {
                    slot: "trailing",
                    "data-testid": `${_vm.testId}-trailing`,
                  },
                  slot: "trailing",
                },
                [
                  _vm.$slots.trailing ? _vm._t("trailing") : _vm._e(),
                  _vm.trailingIcon
                    ? _c(
                        "v-icon",
                        {
                          attrs: {
                            color: _vm.trailingIcon.color,
                            size: _vm.trailingIcon.size,
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.trailingIcon.icon) + " ")]
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
      _vm.shouldDisplayMessages
        ? _c(
            "m-field-hint",
            { attrs: { "data-testid": `${_vm.testId}-errors` } },
            [
              _c("span", [_vm._v(_vm._s(_vm.errorBucket.join(", ")))]),
              _vm.errorBucket.length && _vm.hint
                ? _c("span", [_vm._v(".")])
                : _vm._e(),
              _vm.hint
                ? _c("span", { class: { "p-l-1": _vm.errorBucket.length } }, [
                    _vm._v(_vm._s(_vm.hint)),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }