<template>
  <dialog-base :value="value" @input="updateVal" size="medium">
    <template v-slot:header>Create new password</template>
    <template v-slot:body>
      <m-stack gap="spacing-3">
        <m-text variant="body-md">
          A password reset was requested for your account
          <strong>{{ email }}</strong>
        </m-text>
        <basic-form
          ref="form"
          :fields="fields"
          enable-field-update-events
          @submit="submit"
          @update:new-password="validateNewPassword"
          @update:new-password-confirm="validateNewPassword"
          @update:can-submit="setCanSubmit" />
      </m-stack>
    </template>
    <template v-slot:actions>
      <secondary-button @click="close" test-id="cancel-reset-password-btn">Cancel</secondary-button>
      <primary-button
        :disabled="!canSubmit"
        @click="triggerSubmit"
        test-id="submit-reset-password-btn"
        :is-loading="isLoading">
        Confirm
      </primary-button>
    </template>
  </dialog-base>
</template>

<script>
import { DialogBase, SecondaryButton, PrimaryButton, BasicForm } from '@/components';
import dialogMixin from '@/components/mixins/dialogMixin';
import { isEqual } from 'lodash';

/**
 * Dialog with reset password form
 * @displayName Reset Password Dialog
 */

export default {
  name: 'ResetPasswordDialog',
  mixins: [dialogMixin],
  components: {
    DialogBase,
    SecondaryButton,
    PrimaryButton,
    BasicForm
  },
  props: {
    /**
     * Email address of account
     */
    email: {
      type: String,
      required: false
    },
    /**
     * Password reset token
     */
    resetToken: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      isLoading: false,
      canSubmit: true,
      fields: {
        newPassword: {
          component: 'password-field',
          fieldProps: {
            required: true,
            testId: 'reset-password-1-field',
            rules: this.$validator.rules.password,
            value: '',
            shouldShowRequirements: true
          }
        },
        newPasswordConfirm: {
          component: 'password-field',
          fieldProps: {
            label: 'Repeat Password',
            testId: 'reset-password-2-field',
            rules: this.$validator.rules.password,
            value: ''
          }
        }
      }
    };
  },
  methods: {
    close() {
      this.$refs.form.resetForm();
      this.$emit('close');
    },
    triggerSubmit() {
      this.$set(this.fields.newPasswordConfirm.fieldProps, 'error', '');
      this.$refs.form.submitForm();
    },
    submit({ fields }) {
      if (fields.newPassword !== fields.newPasswordConfirm) {
        this.$set(this.fields.newPasswordConfirm.fieldProps, 'error', 'The passwords do not match');
        this.$refs.form.validateForm();
        return;
      }
      this.isLoading = true;
      axios
        .post(`auth/reset-password/${this.resetToken}`, {
          email: this.email,
          password: fields.newPassword
        })
        .then(() => {
          this.close();
          this.notify('Password has been reset!');
          this.$router.replace('/login');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    setCanSubmit(canSubmit) {
      this.canSubmit = canSubmit;
    },
    /**
     * Additional validation to verify new password and new password confirm match
     */
    validateNewPassword() {
      if (this.$refs.form.hasBeenValidated) {
        const formValues = this.$refs.form.getFieldValues();
        let errorMessage = '';
        if (!isEqual(formValues['newPassword'], formValues['newPasswordConfirm'])) {
          errorMessage = 'Passwords do not match';
        }
        this.$set(this.fields.newPasswordConfirm.fieldProps, 'error', errorMessage);
        this.$refs.form.validateForm();
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
