var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("internal-page", {
    attrs: { "allow-overflow": "" },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("page-header", {
              attrs: {
                title: _vm.pageTitle,
                "has-back-button": !_vm.isRegistrationStep,
              },
              scopedSlots: _vm._u([
                {
                  key: "header-actions",
                  fn: function () {
                    return [
                      _vm.isRegistrationStep
                        ? _c(
                            "v-link",
                            {
                              attrs: {
                                href: "https://community.loadsmart.com/hc/en-us/community/topics/14812876253715-OpenDock",
                                target: "_blank",
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    size: "16px",
                                    color: "color-text-link",
                                  },
                                },
                                [_vm._v("help-circle")]
                              ),
                              _vm._v(" Get Help "),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "m-stack",
              { staticClass: "height-100" },
              [
                _c("m-text", { attrs: { variant: "heading-sm" } }, [
                  _vm._v(
                    " Let's check if your company is already registered with us. If not, you will be able to create a new company. "
                  ),
                ]),
                _c("company-search", {
                  attrs: { "is-registration-step": _vm.isRegistrationStep },
                  on: {
                    companySelected: _vm.saveSelectedCompany,
                    "show-new-company-dialog": function ($event) {
                      _vm.dialogs.companyCreateDialog = true
                    },
                  },
                }),
                _c("create-company-dialog", {
                  on: {
                    close: function ($event) {
                      _vm.dialogs.companyCreateDialog = false
                    },
                    companySelected: _vm.saveSelectedCompany,
                  },
                  model: {
                    value: _vm.dialogs.companyCreateDialog,
                    callback: function ($$v) {
                      _vm.$set(_vm.dialogs, "companyCreateDialog", $$v)
                    },
                    expression: "dialogs.companyCreateDialog",
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }