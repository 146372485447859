<template>
  <form ref="form" class="full-width" @keyup.enter="submitForm">
    <m-stack gap="spacing-4" v-if="Object.keys(internalFields).length > 0" :key="resetIterator">
      <template v-for="(group, groupKey) in groupedFields">
        <m-group v-if="shouldRenderGroup(groupKey)" :key="groupKey">
          <basic-form-field
            v-for="(field, fieldKey) in group"
            :key="fieldKey"
            :field-name="fieldKey"
            :field-component="field.component"
            :field-props="field.fieldProps"
            :validation-iterator="validationIterator"
            @update:value="handleValUpdate"
            :should-validate="hasBeenSubmitted"
            @update:error="hasErrors => setFieldHasErrors(fieldKey, hasErrors)" />
        </m-group>
        <template v-else>
          <basic-form-field
            v-for="(field, fieldKey) in group"
            :key="fieldKey"
            :field-name="fieldKey"
            :field-component="field.component"
            :field-props="field.fieldProps"
            :should-validate="hasBeenSubmitted"
            :validation-iterator="validationIterator"
            @update:value="handleValUpdate"
            @update:error="hasErrors => setFieldHasErrors(fieldKey, hasErrors)" />
        </template>
      </template>

      <!-- @slot Actions that go between the form fields and the submit button e.g. Forgot Password button -->
      <slot name="pre-submit-actions"></slot>
      <template v-if="submitButton">
        <primary-button
          :is-loading="submitButton.isLoading"
          :test-id="submitButton.testId"
          :disabled="!canSubmit"
          :is-block="submitButton.isBlock ?? true"
          class="m-t-2"
          :leading-icon="submitButton.leadingIcon"
          :trailing-icon="submitButton.trailingIcon"
          @click="submitForm">
          {{ submitButton.label }}
        </primary-button>
      </template>
    </m-stack>
  </form>
</template>

<script>
import { PrimaryButton, BasicFormField } from '@/components';
import { objPropExists, isEmptyObject } from '@satellite/../nova/core';
import { cloneDeep } from 'lodash';
import { isFunction, isEqual } from 'lodash';
import { isArray } from 'class-validator';
import { toKebabCase } from '@satellite/../nova/core';
import { propValidatorHelper } from '@satellite/plugins/util';

/**
 * Fully responsive, easy-to-use component that can quickly build out a form that validates itself and creates the
 * fields using the specified components
 *
 * To call methods from the parent, add a ref to the component, then call the method on the ref
 * e.g. <basic-form ref="form" />
 * Then you can call `this.$refs.form.submitForm()` or `this.$refs.form.validateForm()` in the parent component.
 * This is especially handy if you need to add additional form validation or trigger a
 * submission programmatically from the parent.  See `ResetPasswordDialog.vue` in Luna for example usage
 * @displayName Basic Form
 */

export default {
  name: 'BasicForm',
  components: { PrimaryButton, BasicFormField },
  props: {
    /**
     * Submit button properties used to create the form submit button
     * @values { label: string, testId: string, leadingIcon?: string, trailingIcon?: string}
     */
    submitButton: {
      type: Object,
      required: false,
      default: null,
      validator(value) {
        const requiredProperties = ['label', 'testId'];
        const btnProps = Object.keys(value);
        const hasRequiredProps = requiredProperties.every(requiredProp => {
          return btnProps.includes(requiredProp);
        });
        return propValidatorHelper({
          value,
          isValid: hasRequiredProps,
          componentName: 'BasicForm',
          propName: 'submitButton',
          message: ''
        });
      }
    },
    /**
     * Fields Object with component, group, and fieldProps properties
     */
    fields: {
      type: Object,
      required: true,
      validator(value) {
        /**
         * see Form docs for field schema example
         * see RegistrationForm.vue in Luna for internal form actions example usage
         * see ForgotPasswordDialog.vue in Luna for external form actions example
         */
        const requiredAll = ['component', 'fieldProps'];
        const requiredAllFieldProps = ['value', 'testId'];
        const requiredDropDownFieldProps = [...requiredAllFieldProps, 'options'];

        // Validate that each field has the required properties for its type
        const fields = Object.values(value);
        const fieldTypes = fields.map(field => field.component.replace('-field', ''));

        const providedPropEntries = Object.entries(value);

        // eslint-disable-next-line no-unused-vars
        const arePropRequirementsMet = providedPropEntries.every(([key, field]) => {
          let fieldPropsRequirementsMet;
          const topLevelKeysArr = Object.keys(field);
          const fieldPropsKeysArr = Object.keys(field.fieldProps);
          const topLevelRequirementsMet = requiredAll.every(key => {
            return topLevelKeysArr.includes(key);
          });

          {
            const fieldType = field.component.replace('-field', '');
            let requiredFieldProperties = [];
            switch (fieldTypes[fieldType]) {
              case 'drop-down':
                requiredFieldProperties = requiredDropDownFieldProps;
                break;
              default:
                requiredFieldProperties = requiredAllFieldProps;
                break;
            }
            fieldPropsRequirementsMet = requiredFieldProperties.every(el => {
              return fieldPropsKeysArr.includes(el);
            });
          }

          return topLevelRequirementsMet && fieldPropsRequirementsMet;
        });
        return propValidatorHelper({
          value,
          isValid: arePropRequirementsMet,
          componentName: 'BasicForm',
          propName: 'fields',
          message: '',
          docsLink: 'http://localhost:6060/#form'
        });
      }
    },
    /**
     * Allows for emitting field change events as kebab case e.g. 'fieldName1' => '@update:field-name-1'
     */
    enableFieldUpdateEvents: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      internalFields: {},
      validationIterator: 0,
      resetIterator: 0,
      isConfirmButtonEnabled: true,
      hasBeenSubmitted: false,
      hasBeenValidated: false
    };
  },
  computed: {
    /**
     * Checks if any fields require validation
     * @returns {boolean}
     */
    hasRules() {
      return Object.values(this.internalFields).some(field => {
        const rules = field.fieldProps.rules;
        return isFunction(rules) || (isArray(rules) && rules.length > 0);
      });
    },
    /**
     * Determines if the submit button is disabled or not
     * @returns {boolean}
     */
    canSubmit() {
      return (
        (!this.hasRules || !this.hasBeenSubmitted || (this.hasRules && this.isFormValid)) &&
        !this.submitButton?.isLoading
      );
    },
    /**
     * Indicates whether form has passed validation or not
     * @public
     * @returns {boolean}
     */
    isFormValid() {
      return (
        Object.keys(this.internalFields ?? this.fields).filter(fieldName => {
          return this.internalFields[fieldName].hasErrors;
        }).length === 0
      );
    },
    /**
     * Deep clone of internalFields.  This is required in order for newVal/oldVal in the watcher to be accurate
     * See https://stackoverflow.com/questions/62729380/vue-watch-outputs-same-oldvalue-and-newvalue
     * @returns {{}}
     */
    internalFieldsToBeWatched() {
      return cloneDeep(this.internalFields);
    },
    /**
     * Groups the fields by their "group" value
     * @returns {{}}
     */
    groupedFields() {
      const groupedFields = {};
      if (!isEmptyObject(this.internalFields)) {
        Object.keys(this.internalFields).forEach((key, index) => {
          const field = this.internalFields[key];
          const groupKey = `${field.group ? `group-${field.group}` : `single-${index}`}`;
          if (!objPropExists(groupedFields, groupKey)) {
            groupedFields[groupKey] = {};
          }
          if (!objPropExists(groupedFields[groupKey], key)) {
            groupedFields[groupKey][key] = field;
          }
        });
      }

      return groupedFields;
    }
  },
  methods: {
    /**
     * Validates the form, then either scrolls to first error or emits the submit event with fields and rawFields
     * @public
     */
    submitForm() {
      this.hasBeenSubmitted = true;
      this.validateForm();
      this.hasBeenValidated = true;
      this.$nextTick(() => {
        if (this.isFormValid) {
          /**
           * Submit form with both the fields as simple key:value and rawFields as the full field objects provided from the prop
           * @event Submit
           * @property {Object} {fields: {field1: 'field 1 value', field2, 'field 2 value'}, rawFields: {}}
           */
          this.$emit('submit', { fields: this.getFieldValues(), rawFields: this.internalFields });
        } else {
          const formEl = this.$refs.form;
          const firstErrorEl = formEl.querySelector('[status="danger"]');
          firstErrorEl.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
      });
    },
    /**
     * Uses the groupKey to determine if the fields should be rendered in a group or not
     * @private
     * @param key
     * @returns {*}
     */
    shouldRenderGroup(key) {
      return key.includes('group');
    },
    /**
     * Returns an object of fieldName: fieldValue pairs
     * @public
     */
    getFieldValues() {
      const fields = {};
      Object.entries(this.internalFields).forEach(([key, field]) => {
        if (field.fieldKey) {
          if (!objPropExists(fields, field.fieldKey)) {
            fields[field.fieldKey] = {};
          }
          fields[field.fieldKey][key] = field.fieldProps.value;
        } else {
          fields[key] = field.fieldProps.value;
        }
      });
      return fields;
    },
    /**
     * Adds the required validator rules if the "required" flag is set to true on the fieldProps
     * @private
     * @param field
     * @returns {*}
     */
    setRequiredRule(field) {
      if (!field.fieldProps.rules) {
        field.fieldProps.rules = [];
      }

      if (field.fieldProps.required) {
        field.fieldProps.rules = [...field.fieldProps.rules, ...this.$validator.rules.required()];
      }

      return field;
    },
    /**
     * Sets the field's "hasErrors" property
     * @private
     * @param field
     * @param hasErrors
     */
    setFieldHasErrors(field, hasErrors) {
      this.$set(this.internalFields[field], 'hasErrors', hasErrors);
    },
    /**
     * Trigger form validation
     * @public
     */
    validateForm() {
      this.validationIterator++;
    },
    /**
     * Resets the form
     * @public
     */
    resetForm() {
      this.internalFields = cloneDeep(this.fields);
      this.resetIterator++;
    },
    /**
     * Processes internal fields by setting required rule and preserving existing values if field prop changes
     * @param newFields
     * @returns {{}}
     */
    getProcessedInternalFields(newFields) {
      const currentFields = cloneDeep(this.internalFields);
      const newFieldsClone = cloneDeep(newFields);
      const newFieldsWithPreservedValues = {};
      Object.keys(newFieldsClone).forEach(fieldKey => {
        const field = this.setRequiredRule(newFieldsClone[fieldKey]);
        newFieldsWithPreservedValues[fieldKey] = this.setExistingDataOnField(
          currentFields,
          field,
          fieldKey
        );
      });
      return newFieldsWithPreservedValues;
    },
    /**
     * Set existing field value on new internal field
     * When fields prop updates, it does not come with values so we need to carry them over
     * @param currentFields
     * @param field
     * @param fieldKey
     * @returns {*}
     */
    setExistingDataOnField(currentFields, field, fieldKey) {
      const existingValue = currentFields?.[fieldKey]?.fieldProps?.value;
      if (existingValue) {
        field.fieldProps.value = existingValue;
      }
      return field;
    },
    /**
     * Get a field's value by field key
     * @param fieldKey
     * @returns {*}
     */
    getValue(fieldKey) {
      return this.internalFields[fieldKey]?.fieldProps?.value;
    },
    /**
     * Get an array field keys where the field's value has changed
     * @param newFields
     * @param oldFields
     * @returns {*[]}
     */
    getFieldValDiffs(newFields, oldFields) {
      const changedFields = [];
      Object.keys(newFields).forEach(fieldKey => {
        const newValue = newFields[fieldKey].fieldProps.value;
        const oldValue = oldFields[fieldKey].fieldProps.value;
        if (!isEqual(oldValue, newValue)) {
          changedFields.push(fieldKey);
        }
      });
      return changedFields;
    },
    handleValUpdate(val) {
      const fieldKey =
        Object.keys(this.internalFields).find(
          key => this.internalFields[key].fieldProps.testId === val.testId
        ) || '';
      const newField = { ...this.internalFields[fieldKey] };
      newField.fieldProps.value = val.value;
      this.$set(this.internalFields, fieldKey, newField);
    }
  },
  watch: {
    fields: {
      handler(newFields) {
        const fields = this.getProcessedInternalFields(newFields);
        this.internalFields = fields;
      },
      deep: true,
      immediate: true
    },
    canSubmit(canSubmit) {
      /**
       * Emits update:can-submit event anytime the submit button disabled status is updated
       * @event update:can-submit
       * @property {boolean} canSubmit
       */
      this.$emit('update:can-submit', canSubmit);
    },
    internalFieldsToBeWatched: {
      handler(newFields, oldFields) {
        if (this.enableFieldUpdateEvents) {
          const fieldDiffs = this.getFieldValDiffs(newFields, oldFields);
          fieldDiffs.forEach(fieldKey => {
            /**
             * Emits update:{field-name} event anytime a field's value changes
             * Must have 'enable-field-update-events' prop set to true
             * @event update:{field-name}
             * @property {string} update:kebab-case-field-name
             */
            this.$emit(`update:${toKebabCase(fieldKey)}`);
          });
        }
      },
      deep: true
    }
  }
};
</script>
