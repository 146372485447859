<template>
  <m-sidebar
    gap="spacing-4"
    side-width="325px"
    min-content-width="400px"
    padding="none"
    :class="`sidebar-cards-${componentId}`">
    <m-box slot="sidebar" class="sidebar">
      <m-card>
        <m-card-body class="flex-1" ref="sidebar-body">
          <m-stack gap="spacing-8" class="height-100">
            <slot name="sidebar-content"></slot>
          </m-stack>
        </m-card-body>
      </m-card>
    </m-box>
    <m-box class="card-content" slot="content">
      <template v-if="putContentOutsideOfCard">
        <slot name="main-content"></slot>
      </template>
      <m-card v-else>
        <m-card-body class="flex-1" ref="content-body">
          <m-stack :gap="mainContentSpacing" class="height-100">
            <slot name="main-content"></slot>
          </m-stack>
        </m-card-body>
      </m-card>
    </m-box>
  </m-sidebar>
</template>

<script>
export default {
  computed: {
    componentId() {
      return this._uid;
    }
  },
  props: {
    /**
     * Main content m-stack gap spacing
     */
    mainContentSpacing: {
      type: String,
      required: false,
      default: 'spacing-8'
    },
    putContentOutsideOfCard: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted() {
    const shadowRoots = [this.$refs['sidebar-body']];
    if (!this.putContentOutsideOfCard) {
      shadowRoots.push(this.$refs['content-body']);
    }
    this.util.appendStylesToShadowDomEls(
      shadowRoots,
      '.m-card-body',
      'height: 100%;box-sizing: border-box;'
    );
    this.util.appendStylesToShadowDomEls(
      document.querySelectorAll(`.sidebar-cards-${this.componentId} m-box m-card`),
      '.m-card',
      'height: 100%;box-sizing: border-box;'
    );
  }
};
</script>

<style lang="scss" scoped>
.sidebar {
  width: 285px;
  @media (max-width: $smallDesktopBreakpoint) {
    width: 100% !important;
  }
}
m-box {
  padding: 0;

  m-card {
    display: block;
    height: 100%;
  }
}

m-sidebar {
  @media (max-width: $smallDesktopBreakpoint) {
    width: 100%;
    flex-wrap: nowrap;
    flex-direction: column;
  }
}
</style>
