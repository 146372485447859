<template>
  <vuetify-pagination
    v-bind="[$attrs]"
    :data-testid="testId"
    :value="value"
    @input="val => $emit('input', val)"
    class="pagination"
    :class="justify" />
</template>

<script>
import { VPagination as VuetifyPagination } from 'vuetify/lib/components/VPagination';
import testable from '@/components/mixins/testable';
import { propValidatorHelper } from '@satellite/plugins/util';

/**
 * @displayName V Pagination
 */
export default {
  name: 'VPagination',
  components: {
    VuetifyPagination
  },
  mixins: [testable],
  props: {
    /**
     * String used for consistent locating in tests
     */
    testId: {
      type: String,
      required: true
    },
    /**
     * The current page number
     * @model
     */
    value: {
      type: Number,
      required: true
    },
    /**
     * Alignment of the footer inside a flex container
     * @values right, left, center
     */
    justify: {
      type: String,
      required: false,
      default: 'right',
      validator(value) {
        const isValid = ['left', 'center', 'right'].includes(value);
        return propValidatorHelper({
          value,
          isValid,
          componentName: 'VPagination',
          propName: 'justify',
          message: ''
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.pagination {
  :deep {
    &.right {
      .v-pagination {
        justify-content: flex-end;
      }
    }
    &.left {
      .v-pagination {
        justify-content: flex-start;
      }
    }
    .v-pagination {
      padding: $m-spacing-2 $m-spacing-4;

      &__item,
      .v-pagination__navigation {
        border: none;
        box-shadow: none;
        margin: 0;
        color: $m-color-text-primary !important;
        opacity: 0.7;

        &--disabled {
          opacity: 0.2;
        }

        &--active {
          background-color: transparent !important;
          color: $m-color-text-primary !important;
          opacity: 1;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
