var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("dialog-base", {
    attrs: { value: _vm.value, size: "medium" },
    on: { input: _vm.updateVal },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_vm._v("Create new password")]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "m-stack",
              { attrs: { gap: "spacing-3" } },
              [
                _c("m-text", { attrs: { variant: "body-md" } }, [
                  _vm._v(" A password reset was requested for your account "),
                  _c("strong", [_vm._v(_vm._s(_vm.email))]),
                ]),
                _c("basic-form", {
                  ref: "form",
                  attrs: {
                    fields: _vm.fields,
                    "enable-field-update-events": "",
                  },
                  on: {
                    submit: _vm.submit,
                    "update:new-password": _vm.validateNewPassword,
                    "update:new-password-confirm": _vm.validateNewPassword,
                    "update:can-submit": _vm.setCanSubmit,
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c(
              "secondary-button",
              {
                attrs: { "test-id": "cancel-reset-password-btn" },
                on: { click: _vm.close },
              },
              [_vm._v("Cancel")]
            ),
            _c(
              "primary-button",
              {
                attrs: {
                  disabled: !_vm.canSubmit,
                  "test-id": "submit-reset-password-btn",
                  "is-loading": _vm.isLoading,
                },
                on: { click: _vm.triggerSubmit },
              },
              [_vm._v(" Confirm ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }