var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("dialog-base", {
    attrs: { value: _vm.value },
    on: {
      input: function ($event) {
        _vm.value = !_vm.value
      },
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "m-stack",
              { staticClass: "p-t-1", attrs: { align: "center" } },
              [
                _c(
                  "v-icon",
                  { attrs: { size: "48px", color: "color-warning-100" } },
                  [_vm._v("alert-circle")]
                ),
                _c("m-text", { attrs: { variant: "heading-sm-bold" } }, [
                  _vm._v("Sorry, we couldn't create your account"),
                ]),
                _c(
                  "span",
                  [
                    _c("m-text", { attrs: { variant: "body-sm" } }, [
                      _vm._v(
                        "Please try filling out the registration form again."
                      ),
                    ]),
                    _c(
                      "m-text",
                      { attrs: { variant: "body-sm" } },
                      [
                        _vm._v(" If you still run into problems, "),
                        _c("v-link", { attrs: { href: _vm.$supportUrl } }, [
                          _vm._v("contact our support team"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c(
              "primary-button",
              {
                attrs: {
                  "is-block": "",
                  "leading-icon": "refresh",
                  "test-id": "re-register-btn",
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit("confirm")
                  },
                },
              },
              [_vm._v(" Try Again ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }