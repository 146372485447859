var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vuetify-pagination",
    _vm._b(
      {
        staticClass: "pagination",
        class: _vm.justify,
        attrs: { "data-testid": _vm.testId, value: _vm.value },
        on: { input: (val) => _vm.$emit("input", val) },
      },
      "vuetify-pagination",
      [_vm.$attrs],
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }