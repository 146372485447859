import { LoadsmartTheme } from '@loadsmart/miranda-tokens/dist/themes';
import { getToken, toCSSVariable, toCSSValue } from '@loadsmart/miranda-tokens';

export default {
  // Helpers for working with tokens
  getTokenVal(token) {
    return getToken(token);
  },
  getTokenCssVariable(token) {
    return toCSSVariable(token);
  },
  getTokenCssValue(token) {
    return toCSSValue(token);
  },

  // These are helpers for quickly retrieving what tokens are available to the dev
  logThemeTokens(filterStr = null) {
    console.log(
      filterStr ? `${filterStr} tokens:` : 'All tokens',
      this.getFilteredTokenKeys(filterStr)
    );
  },
  getTokenOptions(filterStr) {
    if (!filterStr) {
      console.error('Must provide a filter, i.e. font-size, spacing, etc');
      return;
    }
    const keys = this.getFilteredTokenKeys(filterStr);
    const options = keys.map(key => {
      const keyParts = key.split('-');
      return keyParts[keyParts.length - 1];
    });
    return Array.from(new Set(options));
  },
  getTokenKeys() {
    return Object.keys(LoadsmartTheme);
  },
  getFilteredTokenKeys(filterStr) {
    return this.getTokenKeys()
      .filter(token => !token.includes('brand') && !token.includes('platform'))
      .filter(token => (filterStr ? token.includes(filterStr) : true));
  }
};
