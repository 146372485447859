var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-field",
    {
      staticClass: "field-wrapper",
      class: {
        "flex-1": !_vm.width,
        "military-time": _vm.isMilitaryTimeEnabled,
      },
      attrs: {
        status: _vm.computedErrors.length > 0 ? "danger" : "default",
        "data-testid": _vm.testId,
      },
    },
    [
      _vm.label
        ? _c(
            "m-field-label",
            {
              attrs: { slot: "label", "data-testid": `${_vm.testId}-label` },
              slot: "label",
            },
            [_vm._v(" " + _vm._s(_vm.label) + " ")]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "timestamp-group",
          style: _vm.computedWidth,
          attrs: { align: "center", gap: "spacing-2" },
        },
        [
          _c(
            "single-date-picker",
            _vm._b(
              {
                staticClass: "timestamp-input",
                attrs: {
                  name: _vm.fieldName,
                  timezone: _vm.timezone,
                  placeholder: _vm.placeholder,
                  "test-id": `${_vm.testId}-date-picker-field`,
                },
                on: { focus: _vm.handleFocus, blur: _vm.handleBlur },
                model: {
                  value: _vm.selectedDate,
                  callback: function ($$v) {
                    _vm.selectedDate = $$v
                  },
                  expression: "selectedDate",
                },
              },
              "single-date-picker",
              [_vm.$attrs],
              false
            )
          ),
          _c(
            "text-field",
            _vm._b(
              {
                key: _vm.renderKey,
                ref: "time-input",
                staticClass: "p-b-1 timestamp-input",
                attrs: {
                  "field-name": _vm.fieldName,
                  placeholder: _vm.placeholder,
                  "external-error":
                    _vm.timeErrors.length > 0 ? _vm.timeErrors[0] : null,
                  shouldDisplayMessages: false,
                  "test-id": `${_vm.testId}-text-field`,
                },
                on: {
                  focus: _vm.handleFocus,
                  blur: _vm.handleTimeInputBlur,
                  keydown: _vm.preventBadKeys,
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              },
              "text-field",
              [_vm.$attrs],
              false
            )
          ),
          !_vm.isMilitaryTimeEnabled
            ? _c("button-toggle-group", {
                staticClass: "ampm-buttons",
                attrs: {
                  type: "single-strict",
                  buttons: [
                    { value: "AM", label: "AM" },
                    { value: "PM", label: "PM" },
                  ],
                },
                model: {
                  value: _vm.ampm,
                  callback: function ($$v) {
                    _vm.ampm = $$v
                  },
                  expression: "ampm",
                },
              })
            : _vm._e(),
          _vm._t("trailing"),
        ],
        2
      ),
      _c("m-field-hint", { attrs: { "data-testid": `${_vm.testId}-errors` } }, [
        _c("span", [_vm._v(_vm._s(_vm.computedErrors.join(", ")))]),
        _vm.computedErrors.length && _vm.hint
          ? _c("span", [_vm._v(".")])
          : _vm._e(),
        _vm.hint
          ? _c("span", { class: { "p-l-1": _vm.computedErrors.length } }, [
              _vm._v(_vm._s(_vm.hint)),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }