<template>
  <dialog-base :value="value" @input="updateVal" size="medium" data-testid="forgot-password-dlg">
    <template v-slot:header>Forgot your password?</template>
    <template v-slot:body>
      <m-stack gap="spacing-3">
        <m-text variant="body-md-bold">
          Enter the email address you have registered with Opendock
        </m-text>
        <m-text variant="body-md">
          We will send you instructions on how to create a new password
        </m-text>
        <basic-form
          ref="form"
          :fields="fields"
          @submit="submit"
          @update:can-submit="setCanSubmit" />
      </m-stack>
    </template>
    <template v-slot:actions>
      <secondary-button @click="close" test-id="forgot-password-cancel-btn">
        Cancel
      </secondary-button>
      <primary-button
        :disabled="!canSubmit"
        @click="triggerSubmit"
        test-id="forgot-password-confirm-btn"
        :is-loading="isLoading">
        Confirm
      </primary-button>
    </template>
  </dialog-base>
</template>

<script>
import { DialogBase, SecondaryButton, PrimaryButton, BasicForm } from '@/components';
import dialogMixin from '@/components/mixins/dialogMixin';

/**
 * Dialog with forgot password form
 * @displayName Forgot Password Dialog
 */

export default {
  name: 'ForgotPasswordDialog',
  mixins: [dialogMixin],
  components: {
    DialogBase,
    SecondaryButton,
    PrimaryButton,
    BasicForm
  },
  data() {
    return {
      isLoading: false,
      canSubmit: true,
      fields: {
        email: {
          component: 'text-field',
          fieldProps: {
            required: true,
            testId: 'forgot-email-field',
            placeholder: 'Enter your email address',
            rules: this.$validator.rules.email,
            value: '',
            leadingIcon: {
              icon: 'email',
              color: 'color-text-tertiary',
              size: 'font-size-5'
            }
          }
        }
      }
    };
  },
  methods: {
    close() {
      this.$refs.form.resetForm();
      this.$emit('close');
    },
    triggerSubmit() {
      this.$set(this.fields.email.fieldProps, 'error', '');
      this.$refs.form.submitForm();
    },
    submit({ fields }) {
      this.isLoading = true;
      axios
        .post(`/carrier/password-reset-email/${fields.email.toLowerCase()}/`)
        .then(() => {
          this.notify('Check your email to create a new password', 'success', {
            title: 'Email sent'
          });
          this.close();
        })
        .catch(e => {
          const statusCode = e?.response?.status;
          if (statusCode === 404) {
            this.$set(this.fields.email.fieldProps, 'error', 'This email is not registered');
            this.$refs.form.validateForm();
          }
        })
        .finally(() => (this.isLoading = false));
    },
    setCanSubmit(canSubmit) {
      this.canSubmit = canSubmit;
    }
  }
};
</script>

<style lang="scss" scoped></style>
