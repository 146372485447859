/**
 * Please note that the order matters here!  e.g. If ButtonBase uses VIcon, VIcon must come before or
 * Vue will throw an error that it cannot read undefined of "default".  If you see that error right after
 * adding to this file, chances are the new component export needs to be placed differently.
 */

/**
 * BASIC COMPONENTS
 */
import VLink from '@/components/elements/basic/VLink.vue';
import VIcon from '@/components/elements/basic/VIcon.vue';
import VSpacer from '@/components/elements/basic/VSpacer.vue';
import VLoader from '@/components/elements/basic/VLoader.vue';
import NoContent from '@/components/elements/basic/NoContent.vue';
import ImageMessage from '@/components/elements/basic/ImageMessage.vue';
import AppointmentStatusTag from '@/components/elements/basic/AppointmentStatusTag.vue';

/**
 * BRANDING COMPONENTS
 */
import AuthLinks from '@/components/elements/branding/AuthLinks.vue';
import CopyrightText from '@/components/elements/branding/CopyrightText.vue';
import VLogo from '@/components/elements/branding/VLogo.vue';

/**
 * BUTTON COMPONENTS
 */
import ButtonBase from '@/components/elements/buttons/ButtonBase.vue';
import PrimaryButton from '@/components/elements/buttons/PrimaryButton.vue';
import SecondaryButton from '@/components/elements/buttons/SecondaryButton.vue';
import TertiaryButton from '@/components/elements/buttons/TertiaryButton.vue';
import IconButton from '@/components/elements/buttons/IconButton.vue';
import ErrorButton from '@/components/elements/buttons/ErrorButton.vue';
import WarningButton from '@/components/elements/buttons/WarningButton.vue';
import LinkButton from '@/components/elements/buttons/LinkButton.vue';
import SidebarButton from '@/components/elements/buttons/SidebarButton.vue';
import CopyContentButton from '@/components/elements/buttons/CopyContentButton.vue';
import ButtonToggleGroup from '@/components/elements/buttons/ButtonToggleGroup.vue';

/**
 * STEPPER COMPONENTS
 */
import StepperNav from '@/components/elements/steppers/StepperNav.vue';
import VStepper from '@/components/elements/steppers/VStepper.vue';

/**
 * SEARCH COMPONENTS
 */
import NoResults from '@/components/elements/search/NoResults.vue';

/**
 * SNACKBAR COMPONENTS
 */
import AppNotification from '@/components/elements/snackbars/AppNotification.vue';

/**
 * DATA COMPONENTS
 */
import FieldValue from '@/components/elements/data/FieldValue.vue';
import CustomFieldValue from '@/components/elements/data/CustomFieldValue.vue';
import BigNumberBoxes from '@/components/elements/data/BigNumberBoxes.vue';

/**
 * FORM COMPONENTS
 */
import MultiChipInput from '@/components/elements/form/MultiChipInput.vue';
import TextField from '@/components/elements/form/TextField.vue';
import PasswordField from '@/components/elements/form/PasswordField.vue';
import DropDownField from '@/components/elements/form/DropDownField.vue';
import TextArea from '@/components/elements/form/TextArea.vue';
import SingleDatePicker from '@/components/elements/form/SingleDatePicker.vue';
import VSwitch from '@/components/elements/form/VSwitch.vue';
import PhoneNumberField from '@/components/elements/form/PhoneNumberField.vue';
import TimeStampField from '@/components/elements/form/TimeStampField.vue';
import MultiDropDownField from '@/components/elements/form/MultiDropDownField.vue';
import ComboBox from '@/components/elements/form/ComboBox.vue';
import DocumentUpload from '@/components/elements/form/DocumentUpload.vue';

// THIS ALWAYS GOES LAST OUT OF THE FORM COMPONENTS SO WE CAN USE THE FORM FIELDS ABOVE
import BasicFormField from '@/components/elements/form/BasicFormField.vue';

/**
 * TABLE COMPONENTS
 */
import DataTableBase from '@/components/elements/tables/DataTableBase.vue';

/**
 * LIST COMPONENTS
 */
import HoursOfOperationList from '@/components/elements/lists/HoursOfOperationList.vue';
import VPagination from '@/components/elements/lists/VPagination.vue';

/**
 * CARD COMPONENTS
 */
import CardGrid from '@/components/elements/cards/CardGrid.vue';
import CollapsableCard from '@/components/elements/cards/CollapsableCard.vue';

/**
 * FORMS
 */
import BasicForm from '@/components/elements/form/BasicForm.vue';
import DateRangePicker from '@/components/elements/form/DateRangePicker.vue';

/**
 * ADVANCED BUTTON COMPONENTS
 */
import DateNav from '@/components/elements/buttons/docs/DateNav.vue';

/**
 * DIALOG COMPONENTS
 */
import DialogBase from '@/components/elements/dialogs/DialogBase.vue';

/**
 * LAYOUT COMPONENTS
 */
import PageHeader from '@/components/elements/layout/PageHeader.vue';
import InternalPage from '@/components/elements/layout/InternalPage.vue';
import SidebarCards from '@/components/elements/layout/SidebarCards.vue';

export {
  AppNotification,
  AuthLinks,
  BasicForm,
  BasicFormField,
  ButtonBase,
  ButtonToggleGroup,
  CardGrid,
  CollapsableCard,
  ComboBox,
  CopyContentButton,
  CopyrightText,
  CustomFieldValue,
  DataTableBase,
  DateNav,
  DialogBase,
  DocumentUpload,
  DropDownField,
  ErrorButton,
  FieldValue,
  HoursOfOperationList,
  IconButton,
  ImageMessage,
  InternalPage,
  LinkButton,
  MultiChipInput,
  MultiDropDownField,
  NoContent,
  NoResults,
  PageHeader,
  PasswordField,
  PhoneNumberField,
  PrimaryButton,
  SecondaryButton,
  SidebarButton,
  SidebarCards,
  SingleDatePicker,
  StepperNav,
  TertiaryButton,
  TextArea,
  TextField,
  TimeStampField,
  VIcon,
  VLink,
  VLoader,
  VLogo,
  VPagination,
  VSpacer,
  VStepper,
  VSwitch,
  WarningButton,
  BigNumberBoxes,
  AppointmentStatusTag,
  DateRangePicker
};
