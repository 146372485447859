<template>
  <m-stack align="center" justify="center" class="height-100">
    <img :src="imagePath" :alt="imageAlt" />
    <m-stack class="content-wrapper" gap="spacing-2">
      <slot>
        <m-text variant="body-md-bold">{{ header }}</m-text>
        <m-text variant="body-sm">
          {{ message }}
        </m-text>
      </slot>
    </m-stack>
  </m-stack>
</template>

<script>
export default {
  props: {
    /**
     * Path of the image to be displayed
     */
    imagePath: {
      type: String,
      required: true
    },
    /**
     * The alt message for the image
     */
    imageAlt: {
      type: String,
      required: false,
      default: 'Image over a message'
    },
    /**
     * The message header
     */
    header: {
      type: String,
      required: false
    },
    /**
     * The message
     */
    message: {
      type: String,
      required: false
    }
  }
};
</script>
