var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "internal-page": _vm.$route.meta.requiresAuth } },
    [
      _c(
        "v-app",
        [
          _c(
            "div",
            { class: _vm.$route.meta.pageClass, attrs: { id: "app-wrapper" } },
            [
              _c(
                "div",
                { attrs: { id: "app-notifications" } },
                [
                  _c("app-notification", {
                    staticClass: "m-b-4 m-r-4",
                    attrs: {
                      position: "bottom right",
                      group: "bottom-notifications",
                      max: 5,
                    },
                  }),
                  _c("app-notification", {
                    staticClass: "m-t-4 m-r-4",
                    attrs: {
                      position: "top right",
                      group: "top-notifications",
                      max: 5,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { attrs: { id: "app-body" } },
                [
                  !_vm.$route.meta.hideSidebar
                    ? _c(
                        "m-sidebar",
                        {
                          attrs: {
                            gap: "none",
                            "min-content-width": "400px",
                            "background-color": "color-text-primary-inverted",
                          },
                        },
                        [
                          _c(
                            "m-box",
                            {
                              class: { open: _vm.$isSidebarOpen },
                              attrs: { slot: "sidebar", id: "main-sidebar" },
                              slot: "sidebar",
                            },
                            [
                              _c("sidebar-menu", {
                                attrs: {
                                  "is-disabled": _vm.$route.meta.disableSidebar,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "m-box",
                            {
                              attrs: { slot: "content", id: "main-content" },
                              slot: "content",
                            },
                            [_c("router-view")],
                            1
                          ),
                        ],
                        1
                      )
                    : _c("router-view"),
                ],
                1
              ),
            ]
          ),
          _c("dialog", { attrs: { id: "date-picker-dialog" } }),
          _c("terms-and-conditions-dialog", {
            model: {
              value: _vm.isConfirmationNeeded,
              callback: function ($$v) {
                _vm.isConfirmationNeeded = $$v
              },
              expression: "isConfirmationNeeded",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }