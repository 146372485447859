var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-field",
    {
      staticClass: "input-wrapper",
      class: `multi-chip-input-${_vm._uid}`,
      attrs: { status: _vm.hasError ? "danger" : "default" },
    },
    [
      _c("m-field-label"),
      _c(
        "vuetify-combobox",
        _vm._b(
          {
            style: `${_vm.computedWidth};max-width: 100%;`,
            attrs: {
              multiple: "",
              value: _vm.value,
              delimiters: [",", " "],
              outlined: "",
              label: _vm.label,
              "hide-details": "",
              placeholder: _vm.placeholder,
              "persistent-placeholder": "",
            },
            on: { input: _vm.updateVal },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.$scopedSlots, function (_, slot) {
                  return {
                    key: slot,
                    fn: function (scope) {
                      return [_vm._t(slot, null, null, scope)]
                    },
                  }
                }),
                {
                  key: "selection",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "m-group",
                        {
                          staticClass: "m-r-2 tag-wrapper",
                          attrs: { align: "center" },
                        },
                        [
                          _c(
                            "m-tag",
                            { attrs: { variant: "neutral", size: "small" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.truncateString(item, 25)) + " "
                              ),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "close-icon",
                                  attrs: { size: "10px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeItem(item)
                                    },
                                  },
                                },
                                [_vm._v("close")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "append",
                  fn: function () {
                    return [
                      _c(
                        "v-icon",
                        {
                          attrs: { size: "19px", color: "color-text-tertiary" },
                        },
                        [_vm._v("chevron-down")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          },
          "vuetify-combobox",
          [_vm.$attrs],
          false
        )
      ),
      _vm.shouldDisplayMessages
        ? _c(
            "m-field-hint",
            { attrs: { "data-testid": `${_vm.testId}-errors` } },
            [
              _c("span", [_vm._v(_vm._s(_vm.errorBucket.join(", ")))]),
              _vm.errorBucket.length && _vm.hint
                ? _c("span", [_vm._v(".")])
                : _vm._e(),
              _vm.hint
                ? _c("span", { class: { "p-l-1": _vm.errorBucket.length } }, [
                    _vm._v(_vm._s(_vm.hint)),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }