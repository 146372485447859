<template>
  <m-header :class="[`page-header-${_uid}`, { 'should-collapse': shouldCollapse }]">
    <m-back-button slot="back-button" v-if="$slots['back-button']">
      <!-- @slot Back button -->
      <slot name="back-button" v-if="$slots['back-button']" />
    </m-back-button>

    <m-header-content>
      <m-header-title v-if="$slots.title || title">
        <icon-button
          v-if="!$slots['back-button'] && hasBackButton"
          :test-id="backButtonTestId"
          size="small"
          class="m-r-4 display-desktop"
          icon-size="20px"
          @click="handleBackClick">
          chevron-left
        </icon-button>
        <icon-button
          v-if="!$slots['back-button'] && hasBackButton"
          :test-id="backButtonTestId"
          class="m-r-4 display-tablet"
          icon-size="30px"
          @click="handleBackClick">
          chevron-left
        </icon-button>
        <!-- @slot Header Title -->
        <slot name="title" v-if="$slots.title" />
        <template v-if="title">
          <m-text variant="body-lg-bold">{{ title }}</m-text>
        </template>
      </m-header-title>
      <m-tag v-if="$slots.tag || tag">
        <!-- @slot Header tag -->
        <slot name="tag" v-if="$slots.tag" />
        <template v-if="tag">{{ tag }}</template>
      </m-tag>
    </m-header-content>
    <m-header-actions v-if="$slots['header-actions']" class="flex-1">
      <!-- @slot Header actions -->
      <slot name="header-actions" />
    </m-header-actions>
    <m-sub-header v-if="$slots['sub-header'] || subHeader">
      <!-- @slot Sub header -->
      <slot name="sub-header" v-if="$slots['sub-header']" />
      <template v-if="subHeader">{{ subHeader }}</template>
    </m-sub-header>
  </m-header>
</template>

<script>
import IconButton from '@/components/elements/buttons/IconButton.vue';
import { toKebabCase } from '@satellite/../nova/core';

/**
 * HOC for Miranda WC page header
 * @displayName Page Header
 */
export default {
  name: 'PageHeader',
  components: { IconButton },
  props: {
    /**
     * Page Title
     */
    title: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Sub Header that appears under the main header
     */
    subHeader: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Tag that is placed after the title
     */
    tag: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Tells the component to render the default back button
     */
    hasBackButton: {
      type: Boolean,
      required: false,
      default: false
    },
    isCompact: {
      type: Boolean,
      required: false,
      default: false
    },
    shouldCollapse: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    backButtonTestId() {
      return `${toKebabCase(this.title ?? 'page')}-back-btn}`;
    }
  },
  methods: {
    async handleBackClick() {
      await this.$router.back();
      /**
       * Emits the 'back' event that can be handled in the parent
       * @event back
       */
      this.$emit('back');
    }
  },
  mounted() {
    if (this.isCompact) {
      this.util.appendStyleToShadowDomEl(
        document.querySelector(`.page-header-${this._uid}`),
        '.m-header',
        'padding-top: 5px !important;padding-bottom: 5px !important;'
      );
    }
  }
};
</script>

<style lang="scss" scoped>
m-header-title {
  align-items: center;
  display: flex;
}
m-header-content {
  flex-grow: 1;
}

m-header-actions {
  align-items: center;
  justify-content: flex-end;
}

.should-collapse {
  m-header-actions {
    @media (max-width: $smallDesktopBreakpoint) {
      > m-button:not([variant='icon']) {
        flex: 1;
      }
    }
  }
}
</style>
